import React from 'react';
import Axios from "axios";
import ReactApexchart from "react-apexcharts";
const moment = require("moment");
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/user/NavigationSidebar/Sidebar';
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../images/loading.gif";
import Partnership from "../../_components/user/Partnership/Partnership";
import $ from "jquery";

class SeoOverviewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      IsLoading: false,
      IsGraphLoading:false,
      Date: "",
      ClientID: null,
      Role: "",
      Score: 0,
      DomainsNum: 0,
      Rank: 0,
      OrganicKeywords: 0,
      OrganicTraffic: 0,
      AScore: {
        series: [
          {
            name: "Ascore",
            color: "#360947",
            data: [] // Initialize with an empty array or default data
          }
        ],
        options: {
          chart: {
            type: "line",
            height: 450,
            width: 600,
            toolbar: {
              show: false,  
            } 
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          title: {
            text: 'Ascore',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            title: {
              text: "Date"
            },
            categories: [] // Initialize with an empty array or default categories
          },
          yaxis: {
            title: {
              text: "Ascore"
            }
          },
          markers: {
            size: 4,
            strokeWidth: 1.5,
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        }
      },
      ReferringDomains: {
        series: [
          {
            name: "Referring Domains",
            color: "#360947",
            data: [] // Initialize with an empty array or default data
          }
        ],
        options: {
          chart: {
            type: "line",
            height: 450,
            width: 600,
            toolbar: {
              show: false,  
            } 
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          title: {
            text: 'Referring Domains',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            title: {
              text: "Date"
            },
            categories: [] // Initialize with an empty array or default categories
          },
          yaxis: {
            title: {
              text: "Referring Domains"
            }
          },
          markers: {
            size: 4, // Adjust the size of the markers
            // colors: ['#FFA41B'], // Array of colors for the markers
            // strokeColors: '#fff', // Color of the marker's stroke
            strokeWidth: 1.5, // Width of the marker's stroke
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        }
      },
      DomainRank: {
        series: [
          {
            name: "Domain Rank",
            color: "#360947",
            data: [] // Initialize with an empty array or default data
          }
        ],
        options: {
          chart: {
            type: "line",
            height: 450,
            width: 600,
            toolbar: {
              show: false,  
            } 
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          title: {
            text: 'Domain Rank',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            title: {
              text: "Date"
            },
            categories: [] // Initialize with an empty array or default categories
          },
          yaxis: {
            title: {
              text: "Domain Rank"
            }
          },
          markers: {
            size: 4, // Adjust the size of the markers
            // colors: ['#FFA41B'], // Array of colors for the markers
            // strokeColors: '#fff', // Color of the marker's stroke
            strokeWidth: 1.5, // Width of the marker's stroke
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        }
      },
      OrganicKeywordsGraph: {
        series: [
          {
            name: "Organic Keywords",
            color: "#360947",
            data: [] // Initialize with an empty array or default data
          }
        ],
        options: {
          chart: {
            type: "line",
            height: 450,
            width: 600,
            toolbar: {
              show: false,  
            } 
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          title: {
            text: 'Organic Keywords',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            title: {
              text: "Date"
            },
            categories: [] // Initialize with an empty array or default categories
          },
          yaxis: {
            title: {
              text: "Organic Keywords"
            }
          },
          markers: {
            size: 4, // Adjust the size of the markers
            // colors: ['#FFA41B'], // Array of colors for the markers
            // strokeColors: '#fff', // Color of the marker's stroke
            strokeWidth: 1.5, // Width of the marker's stroke
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        }
      },
      OrganicTrafficGraph: {
        series: [
          {
            name: "Organic Traffic",
            color: "#360947",
            data: [], // Initialize with an empty array or default data 
          }
        ],
        options: {
          chart: {
            type: "line",
            height: 450,
            width: 600,
            toolbar: {
              show: false,  
            } 
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          title: {
            text: 'Organic Traffic',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            title: {
              text: "Date"
            },
            categories: [] // Initialize with an empty array or default categories
          },
          yaxis: {
            title: {
              text: "Organic Traffic"
            }
          },
          markers: {
            size: 4, // Adjust the size of the markers
            // colors: ['#FFA41B'], // Array of colors for the markers
            // strokeColors: '#fff', // Color of the marker's stroke
            strokeWidth: 1.5, // Width of the marker's stroke
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        }
      }
    };
    this.btnAnalyze = this.btnAnalyze.bind(this);
    this.daterefect = React.createRef()

  }

  componentDidMount() {
    document.title = "Overview | SalesHive";
    const details = GetUserDetails();

    if (details != null) {
      this.setState({
        ClientID: details.ClientID,
        Role: details.Role
      });

      document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      const $datepickerElement2 = $(this.daterefect.current);
      $datepickerElement2.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
      });

      var ToDate = moment(document.getElementById("to-date").value).format("MM/DD/YYYY");
      this.setState({ Date: ToDate });
      this.GetSeoOverviewKeymatric(details.ClientID, ToDate)
      this.GetSeoOverviewGraph(details.ClientID)

    }

  }

  btnAnalyze() {

    
    let ToDate = document.getElementById("to-date").value;
    this.setState({ Date: ToDate });
    this.GetSeoOverviewKeymatric(this.state.ClientID,ToDate);
    
  }


  GetSeoOverviewGraph(ClientID) {
    this.setState({ IsGraphLoading: true })
    var requestData = {
      ClientID: ClientID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seooverviewgraph",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.SeoOverViewGraphData.length > 0) {
          let lstdate=[], lstAscore = [],lstReferringDomains=[],lstDomainRank=[],lstOrganicKeywords=[],lstOrganicTraffic=[]
          for (let i = 0; i < response.data.SeoOverViewGraphData.length; i++) {
            lstdate.push(response.data.SeoOverViewGraphData[i]?.WeekDay);
            lstAscore.push(response.data.SeoOverViewGraphData[i]?.ascore);
            lstReferringDomains.push(response.data.SeoOverViewGraphData[i]?.domains_num);
            lstDomainRank.push(response.data.SeoOverViewGraphData[i]?.Rank);
            lstOrganicKeywords.push(response.data.SeoOverViewGraphData[i]?.OrganicKeywords);
            lstOrganicTraffic.push(response.data.SeoOverViewGraphData[i]?.OrganicTraffic);
            
          }
          this.setState(prevState => ({
            AScore: {
              ...prevState.AScore,
              series: [
                {
                  name: "Ascore",
                  color: "#360947",
                  data:lstAscore
                }
              ],
              options: {
                ...prevState.AScore.options,
                xaxis: {
                  ...prevState.AScore.options.xaxis,
                  categories: lstdate // Update this with your new categories
                }
              }
            }
          }));
         
          
          this.setState(prevState => ({
            ReferringDomains: {
              ...prevState.ReferringDomains,
              series: [
                {
                  name: "Referring Domains",
                  color: "#360947",
                  data:lstReferringDomains
                }
              ],
              options: {
                ...prevState.ReferringDomains.options,
                xaxis: {
                  ...prevState.ReferringDomains.options.xaxis,
                  categories: lstdate // Update this with your new categories
                }
              }
            }
          }));

          // this.setState(prevState => ({
          //   DomainRank: {
          //     ...prevState.DomainRank,
          //     series: [
          //       {
          //         name: "Domain Rank",
          //         color: "#360947",
          //         data:lstDomainRank
          //       }
          //     ],
          //     options: {
          //       ...prevState.DomainRank.options,
          //       xaxis: {
          //         ...prevState.DomainRank.options.xaxis,
          //         categories: lstdate // Update this with your new categories
          //       }
          //     }
          //   }
          // }));
        
          this.setState(prevState => ({
            OrganicKeywordsGraph: {
              ...prevState.OrganicKeywordsGraph,
              series: [
                {
                  name: "Organic Keywords",
                  color: "#360947",
                  data:lstOrganicKeywords
                }
              ],
              options: {
                ...prevState.OrganicKeywordsGraph.options,
                xaxis: {
                  ...prevState.OrganicKeywordsGraph.options.xaxis,
                  categories: lstdate // Update this with your new categories
                }
              }
            }
          }));

          this.setState(prevState => ({
            OrganicTrafficGraph: {
              ...prevState.OrganicTrafficGraph,
              series: [
                {
                  name: "Organic Traffic",
                  color: "#360947",
                  data:lstOrganicTraffic
                }
              ],
              options: {
                ...prevState.OrganicTrafficGraph.options,
                xaxis: {
                  ...prevState.OrganicTrafficGraph.options.xaxis,
                  categories: lstdate // Update this with your new categories
                }
              }
            }
          }));
        
      
      
        this.setState({ IsGraphLoading: false })
        
        } else {
          this.setState({ IsGraphLoading: false })
        }
      } else {
        this.setState({ IsGraphLoading: false })
      }
    }).catch(error => {
      console.error('Error fetching Get Get Seo OverviewKeymatric:', error);
      this.setState({ IsGraphLoading: false })
    });
  };


  GetSeoOverviewKeymatric(ClientID, ToDate) {
    this.setState({ IsLoading: true })
    var requestData = {
      ClientID: ClientID,
      Date: ToDate

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/seoreporting/seooverviewkeymatric",
      method: "POST",
      data: requestData,
    }).then(response => {
      if (response.data.StatusMessage == "SUCCESS") {
        if (response.data.PageData.length > 0) {
          this.setState({
            Score: response.data.PageData[0]?.ascore,
            DomainsNum: response.data.PageData[0]?.domains_num,
            Rank: response.data.PageData[0]?.Rank,
            OrganicKeywords: response.data.PageData[0]?.OrganicKeywords,
            OrganicTraffic: response.data.PageData[0]?.OrganicTraffic,
          })
          this.setState({ IsLoading: false })
        } else {
          this.setState({
            Score: 0,
            DomainsNum: 0,
            Rank: 0,
            OrganicKeywords: 0,
            OrganicTraffic: 0,
          })
          this.setState({ IsLoading: false })
        }
      } else {
        this.setState({
          Score: 0,
          DomainsNum: 0,
          Rank: 0,
          OrganicKeywords: 0,
          OrganicTraffic: 0,
        })
        this.setState({ IsLoading: false })
      }
    }).catch(error => {
      console.error('Error fetching Get Get Seo OverviewKeymatric:', error);
      this.setState({ IsLoading: false })
    });
  };

  

  render() {
    const { AScore, ReferringDomains, DomainRank, OrganicKeywordsGraph, OrganicTrafficGraph } = this.state;
    return (
      <>

        <div className="adminmain">
          {this.state.Role  === "Client" ?(<ClientSidebar className="" />):(<Sidebar className="" />)}
          
          <div className="bodyhome partner-space">

            {
              this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
                <img src={loadingicon} />
              </div> : null
            }
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left">SEO Overview</h4>
              </div>
              <div className="col">
                <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      class="form-control datepicker"
                      id="to-date"
                      name="to-date"
                      type="text"
                      autocomplete="off"
                      ref={this.daterefect}
                    />
                  </div>
                  <button
                    onClick={this.btnAnalyze}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i className="la flaticon-diagram"></i>
                    <span>
                      Analyze
                    </span>
                  </button>
                </div>
              </div>
            </div>

 
 
                  <div className="row mb-4">
                    <div className="col">
                      <div className="bg-white boxcardcounter h-100 mb-0">
                        <div class="line-title mb-2"><span class="clr-sky box-main-title">{this.state.Score}</span></div>
                        <h6 className="box-inner-title">
                          <string className='clr-sky'>Ascore</string> 
                        </h6>
                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-white boxcardcounter h-100 mb-0">
                      <div class="line-title mb-2"><span class="clr-sky box-main-title">{this.state.DomainsNum}</span></div>
                        <h6 className="box-inner-title">
                        <string className='clr-sky'>Referring Domains</string> 
                        </h6>

                      </div>
                    </div>
                    <div className="col">
                      <div className="bg-white boxcardcounter h-100 mb-0">
                      <div class="line-title mb-2"><span class="clr-sky box-main-title">{this.state.Rank}</span></div>
                        <h6 className="box-inner-title">
                          <string className='clr-sky'>Domain Rank</string> 
                        </h6>

                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-white boxcardcounter h-100 mb-0">
                      <div class="line-title mb-2"><span class="clr-sky box-main-title">{this.state.OrganicKeywords}</span></div>
                        <h6 className="box-inner-title">
                          <string className='clr-sky'>Organic Keywords</string> 
                        </h6>

                      </div>
                    </div>
                    <div className="col">
                      <div className="bg-white boxcardcounter h-100 mb-0">
                      <div class="line-title mb-2"><span class="clr-sky box-main-title">{this.state.OrganicTraffic}</span></div>
                        <h6 className="box-inner-title">
                          <string className='clr-sky'>Organic Traffic</string>
                        </h6>

                      </div>
                    </div>
                  </div>  


              <div className="row">
                <div className="col-sm-6"> 
                      <div className='whiterow mb-3 p-3' id="chart chart-max450">
                      {
                        this.state.IsGraphLoading === true ? <div id="hideloding" className="loadingchart">
                          <img src={loadingicon} />
                        </div> : null
                      }
                      {AScore && <ReactApexchart options={AScore.options} series={AScore.series} type="line" height={450} />}

                    </div> 
                </div>
                <div className="col-sm-6 mb-3">
                  <div className='whiterow mb-3 p-3' id="chart chart-max450">
                    {
                      this.state.IsGraphLoading === true ? <div id="hideloding" className="loadingchart">
                        <img src={loadingicon} />
                      </div> : null
                    }
                    {ReferringDomains && <ReactApexchart options={ReferringDomains.options} series={ReferringDomains.series} type="line" height={450} />}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-sm-6 mb-3">
                  <div className='whiterow mb-3 p-3' id="chart chart-max450">
                    {
                      this.state.IsGraphLoading === true ? <div id="hideloding" className="loadingchart">
                        <img src={loadingicon} />
                      </div> : null
                    }
                    {DomainRank && <ReactApexchart options={DomainRank.options} series={DomainRank.series}type="line" height={450} />}

                  </div>
                </div> */}
                <div className="col-sm-6 mb-3">
                  <div className='whiterow mb-3 p-3' id="chart chart-max450">
                    {
                      this.state.IsGraphLoading === true ? <div id="hideloding" className="loadingchart">
                        <img src={loadingicon} />
                      </div> : null
                    }
                    {OrganicKeywordsGraph && <ReactApexchart options={OrganicKeywordsGraph.options} series={OrganicKeywordsGraph.series} type="line" height={450} />}
                  </div>
                </div>
              {/* </div>
              <div className="row"> */}
                <div className="col-sm-6 mb-3">
                  <div className='whiterow mb-3 p-3' id="chart chart-max450">
                    {
                      this.state.IsGraphLoading === true ? <div id="hideloding" className="loadingchart">
                        <img src={loadingicon} />
                      </div> : null
                    }
                    {OrganicTrafficGraph && <ReactApexchart options={OrganicTrafficGraph.options} series={OrganicTrafficGraph.series} type="line" height={450} />}

                  </div>
                </div>
              </div>
              <Partnership />

            
          </div>


          <Footer />


        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedSeoOverviewPage = connect(mapState, actionCreators)(SeoOverviewPage);
export { connectedSeoOverviewPage as SeoOverviewPage };