import React from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
const moment = require("moment");
import Axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import "react-toastify/dist/ReactToastify.css";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";

class CEditPotentialMeetingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      Fields1: {},
      Errors1: {},
      statusAvailable: null,
      meetingsource: [],
      meetingaccount: [],
      meetingsetbyResponder: [],
      meetingsetbyColdCallers: [],
      potentialowner: [],
      potentialstatus: [],
      defaultselect: [],
      defaultselecteng: [],
      defaultselecttimeowner: [],
      Data: [],
      Rows: [],
      Rlen: 0,
      Flen: 0,
      CountPage: 0,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Page: 1,
      RowsPerPage: 100,
      Search: "",
      SortField: "Title",
      NoteSortedColumn:"Title",
      SortedBy: 1,
      Sflag: false,
      MeetingID: null,
      ClientPotential: false,
      ClientStatusL: "",
      ShowNotePopup: false,
      ShowNotePopupUpdate: false,
      TitleAvailable: null,
      OldTitleUpdate: null,
      EmailAvailable: null,
      Oldname: "",
      CName: "",
      Role: "",
      EngagementStatusIDCheck: null,
      CheckEnggforStatusUpdate: null,
      IsButtonDisabled: false,
      BtnDisabledSave: false,
      BtnDisabledUpdate: false,
      IsLoading: false,
      campaignStepEmailDetails: {
        LastEmailStepNumber: "",
        LastEmailCampaignName: "",
      },
    };
    this.backbtn = this.backbtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
    this.SortData = this.SortData.bind(this);
    this.SaveUpdateBtn = this.SaveUpdateBtn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEngagement = this.handleEngagement.bind(this);
    this.handleChangeowner = this.handleChangeowner.bind(this);
    this.handleChangeSetby = this.handleChangeSetby.bind(this);
    this.handleChangeReminderEmail = this.handleChangeReminderEmail.bind(this);
    this.handleChangeSource = this.handleChangeSource.bind(this);
    this.savemainbtn = this.savemainbtn.bind(this);
    this.Handlevalidationfornotesedit =
      this.Handlevalidationfornotesedit.bind(this);
    this.Handlevalidationfornotesadd =
      this.Handlevalidationfornotesadd.bind(this);
    this.HandleChangeBlur = this.HandleChangeBlur.bind(this);
    this.HandleChangeotherFieldBlur =
      this.HandleChangeotherFieldBlur.bind(this);
  }
  componentDidMount() {
    document.title = `Edit Potential Meetings | SalesHive`;
    var Details = GetUserDetails();
   // console.log("this.props.location.state",this.props.location.state)
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    this.setState({ IsLoading: true });
    if (this.props.location.state["Pagename"] == "clientPotenial") {
      this.setState({ ClientPotential: true });
    }

    this.EditPotentialMeetingstatus();
    this.NotesGet();
    this.GetPotentialmeeting();
  }

  async FromValidation() {
    try {
      let formIsValid = true;
      let fields = this.state.fields;
      this.setState({ IsValid: false });
      let errors = {};
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Title = document.getElementById("Title").value;
      var Company = document.getElementById("Company").value;
      var Email = document.getElementById("Email").value.trim();
      var MeetingSourceID = document.getElementById("MeetingSourceID").value;
      var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
      var CompanyPhones = document.getElementById("CompanyPhone").value;
      var EngagementStatusID =
        document.getElementById("EngagementStatusID").value;
      var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
      let IsExist = await this.CheckExistPotentialMeetingEmail(Email);
      var DirectPhones = document.getElementById("DirectPhone").value;
      var DirectPhoneExts = document.getElementById("DirectPhoneExt").value;

      if (isNaN(DirectPhones)) {
        formIsValid = false;
        errors["DirectPhone"] = "Invalid direct phone";
      }
      if (isNaN(DirectPhoneExts)) {
        formIsValid = false;
        errors["DirectPhoneExt"] = "Invalid direct phone ext";
      }

      if (FirstName == "") {
        formIsValid = false;
        errors["FirstName"] = "Please enter first name";
      }
      if (LastName == "") {
        formIsValid = false;
        errors["LastName"] = "Please enter last name";
      }
      if (Title == "") {
        formIsValid = false;
        errors["Title"] = "Please enter Title";
      }
      if (Company == "") {
        formIsValid = false;
        errors["Company"] = "Please enter Company";
      }
      if (Email == "") {
        formIsValid = false;
        errors["Email"] = "Please enter Email";
      }
      if (MeetingSourceID == "") {
        formIsValid = false;
        errors["MeetingSourceID"] = "Please select Meeting Source";
      }
      if (ReminderEmailAccount == "") {
        formIsValid = false;
        errors["ClientPOCID"] = "Please select reminder email account";
      }
      if (MeetingOwnerID == "") {
        formIsValid = false;
        errors["MeetingOwnerID"] = "Please select owner";
      }
      if (EngagementStatusID == "") {
        formIsValid = false;
        errors["EngagementStatusID"] = "Please select status";
      }
      if (IsExist == "InvalidEmail") {
        formIsValid = false;
        errors["Email"] = "invalid email.";
      }
      if (IsExist == "ExistEmail") {
        formIsValid = false;
        errors["Email"] = "Email Already Exist";
      }

      if (IsExist == "BlankData" && Email == "") {
        formIsValid = false;
        errors["Email"] = "Please enter email";
      }

      if (this.state.IsValid == true) {
        formIsValid = false;
        errors["Email"] = "Invalid from email.";
      }

      if (CompanyPhones?.trim() === "") {
        errors["CompanyPhone"] = null; // Blank is considered fine
      } else if (!/^\d+$/.test(CompanyPhones?.trim())) {
        errors["CompanyPhone"] = "Invalid company phone";
        formIsValid = false;
      } else {
        errors["CompanyPhone"] = null; // Valid phone number
      }

      if (this.state.ClientPotential == true) {
        var ClientPotentialID =
          document.getElementById("ClientPotentialID").value;
        if (ClientPotentialID == "") {
          formIsValid = false;
          errors["ClientPotentialID"] = "Please select client";
        }
      }

      this.setState({ errors: errors });
      return formIsValid;
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Email != "") {
      this.state.errors.Email = null;
      this.state.EmailAvailable = null;
      this.CheckExistPotentialMeetingEmail(e.target.value);
    } else {
      this.state.EmailAvailable = null;
      this.state.errors.Email = null;
    }
  }

  async HandleChangeBlur() {
    try {
      let errors = { ...this.state.errors };
      var Email = document.getElementById("Email").value.trim();
      if (Email == "") {
        errors["Email"] = "Please enter Email";
      } else {
        let IsExist = await this.CheckExistPotentialMeetingEmail(Email);
        if (IsExist == "InvalidEmail") {
          errors["Email"] = "invalid email.";
        }
        if (IsExist == "ExistEmail") {
          errors["Email"] = "Email Already Exist";
        }
        if (IsExist == "AllDone") {
          errors["Email"] = null;
        }
      }

      this.setState({ errors: errors });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  async HandleChangeotherFieldBlur(Name) {
    try {
      let errors = { ...this.state.errors };
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Title = document.getElementById("Title").value;
      var Company = document.getElementById("Company").value;
      var CompanyPhones = document.getElementById("CompanyPhone").value;

      var DirectPhones = document.getElementById("DirectPhone").value;
      var DirectPhoneExts = document.getElementById("DirectPhoneExt").value;

      if (Name == "DirectPhone") {
        if (isNaN(DirectPhones)) {
          errors["DirectPhone"] = "Invalid direct phone";
        } else {
          errors["DirectPhone"] = null;
        }
      }

      if (Name == "DirectPhoneExt") {
        if (isNaN(DirectPhoneExts)) {
          errors["DirectPhoneExt"] = "Invalid direct phone ext";
        } else {
          errors["DirectPhoneExt"] = null;
        }
      }

      if (Name == "FirstName") {
        if (FirstName == "") {
          errors["FirstName"] = "Please enter first name";
        } else {
          errors["FirstName"] = null;
        }
      }
      if (Name == "LastName") {
        if (LastName == "") {
          errors["LastName"] = "Please enter last name";
        } else {
          errors["LastName"] = null;
        }
      }
      if (Name == "Title") {
        if (Title == "") {
          errors["Title"] = "Please enter Title";
        } else {
          errors["Title"] = null;
        }
      }
      if (Name == "Company") {
        if (Company == "") {
          errors["Company"] = "Please enter Company";
        } else {
          errors["Company"] = null;
        }
      }

      if (Name === "CompanyPhone") {
        if (CompanyPhones?.trim() === "") {
          errors["CompanyPhone"] = null; // Blank is considered fine
        } else if (!/^\d+$/.test(CompanyPhones?.trim())) {
          errors["CompanyPhone"] = "Invalid company phone";
        } else {
          errors["CompanyPhone"] = null; // Valid phone number
        }
      }

      this.setState({ errors: errors });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  // check exit Email
  async CheckExistPotentialMeetingEmail(Email) {
    try {
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (Email != "") {
        if (!regexp.test(Email)) {
          return "InvalidEmail";
        } else {
          // this.setState({ IsExist: false });
          var str_in = {
            ClientID: this.state.ClientID,
            Email: Email,
            Role: this.state.Role,
          };
          let resdata = await Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/potentialmeeting/PotentialMeetingCheckExists",
            method: "POST",
            data: str_in,
          });
          if (resdata.data.StatusMessage == "SUCCESS") {
            if (resdata.data.Data.length > 0) {
              if (this.state.Oldname == Email) {
                return "AllDone";
              } else {
                this.setState({ EmailAvailable: resdata.data.Data[0].Email });
                return "ExistEmail";
              }
            } else {
              return "AllDone";
            }
          }
        }
      } else {
        return "BlankData";
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  EditPotentialMeetingstatus() {
    try {
      const id = this.props.location.state.data;
      this.setState({ id: id });
      var str_in = {
        id: id,
        Role: this.state.Role,
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialMeetingByID",
        method: "POST",
        data: str_in,
      })
        .then((res) => {
          this.setState({ Oldname: res.data[0]?.Email });
          GetClientDetails(res.data[0]?.ClientID)
            .then((result) => {
              this.setState({ CName: result[0]?.Name });
            })
            .catch((error) => {
              toast.error("An error occurred. Please try again.");
              this.setState({ IsLoading: false });
            });
          document.getElementById("FirstName").value = res.data[0].FirstName;
          document.getElementById("LastName").value = res.data[0].LastName;
          document.getElementById("Title").value = res.data[0].Title;
          document.getElementById("Company").value = res.data[0].Company;
          document.getElementById("Email").value = res.data[0].Email;
          document.getElementById("LinkedInUrl").value =
            res.data[0].LinkedInUrl;
          document.getElementById("MeetingSourceID").value =
            res.data[0].MeetingSourceID;
          document.getElementById("MeetingOwnerID").value =
            res.data[0].MeetingOwnerID;
          document.getElementById("EngagementStatusID").value =
            res.data[0].EngagamentStatusID;

          this.setState({
            EngagementStatusIDCheck: res.data[0].EngagamentStatusID,
          });
          document.getElementById("DirectPhone").value =
            res.data[0].DirectPhone;
          document.getElementById("DirectPhoneExt").value =
            res.data[0].DirectPhoneExt;
          document.getElementById("CompanyPhone").value =
            res.data[0].CompanyPhone;
          this.setState({ defaultselect: res.data[0].MeetingSourceID });
          this.setState({ defaultselecteng: res.data[0].EngagamentStatusID });
          this.setState({
            defaultselecttimeowner: res.data[0].MeetingOwnerID,
          });
          this.setState({
            defaultselectmeetingsetby: res.data[0].ResponderColdCallersID,
          });
          this.setState({
            defaultselectreminderemail: res.data[0].ReminderEmailAccountID,
          });

          this.setState({
            campaignStepEmailDetails: {
              LastEmailStepNumber:
                res.data[0]?.prospectslist?.CampaignDetails?.LastEmailStep ||
                "",
              LastEmailCampaignName:
                res.data[0]?.prospectslist?.CampaignDetails?.Name || "",
            },
          });

          // notes
          this.setState({ MeetingID: res.data[0]?._id });
        })
        .catch((error) => {
          toast.error("An error occurred while saving. Please try again.");
          this.setState({ IsLoading: false });
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  // meetings add
  MeetingsConvertAdd = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to convert potential meeting into meeting?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, convert it!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          var str_in = {
            id: this.props.location.state.data,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/potentialmeeting/PotentialMeetingByID",
            method: "POST",
            data: str_in,
          }).then((res) => {
            history.push({
              pathname: "/caddbookedmeetings",
              state: {
                Pagename: "Potential",
                data: res.data[0],
                BackPage: "/cbookedmeetings",
              },
            });
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  };

  GetPotentialmeeting() {
    this.setState({ IsLoading: true });
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      UserID: Details.ParentUserID,
      Role: this.state.Role,
    };
    //
    const rows = Axios({
      url:
        CommonConstants.MOL_APIURL + "/potentialmeeting/PotentialAllStatusGet",
      method: "POST",
      data: str_in,
    });
    rows
      .then((result) => {

        var RArr= result.data?.meetingsetby[0]?.ResponderDetails || [];
        var CArr= result.data?.meetingsetby[0]?.ColdCallersDetails || [];
        RArr.forEach(RArr1 => {
          RArr1.value = "R"+ RArr1.value;
      });
      CArr.forEach(CArr1 => {
        CArr1.value = "C"+ CArr1.value;
    });
        const combinedArray = [...RArr, ...CArr];
        combinedArray.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({
          meetingsource: result.data?.meetingsource,
          potentialowner: result.data?.potentialowner,
          potentialstatus: result.data?.potentialstatus,
          meetingaccount: result.data?.meetingaccount,
          meetingsetbyResponder: combinedArray,
          meetingsetbyColdCallers:
            result.data?.meetingsetby[0]?.ColdCallersDetails,
        });
        this.setState({ IsLoading: false });
        //  return result.data
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  }



  backbtn() {
    if (this.props.location.state["Pagename"] == "Contact") {
      history.push({
        pathname: "/cviewcontacts",
        state: { data: this.props.location.state?.dataprospect },
      });
    } else if (this.props.location.state["Pagename"] == "clientLinkedin") {
      history.push("/clientlinkedinfollowUp");
    } else if (this.props.location.state["Pagename"] == "clientPotenial") {
      history.push("/clientpotentialmeetings");
    } else if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/ceditaccount",
        state: { data: this.props.location.state?.AccountData },
      });
    } else {
      history.push("/cpotentialmeetings");
    }
  }

  async savemainbtn(e) {
    try {
      // e.preventDefault();
      // e.currentTarget.disabled = true;
      this.setState({ IsButtonDisabled: true });
      var Final_flag = await this.FromValidation();

      if (Final_flag == true) {
        var FirstName = document.getElementById("FirstName").value;
        var LastName = document.getElementById("LastName").value;
        var Title = document.getElementById("Title").value;
        var Company = document.getElementById("Company").value;
        var Email = document.getElementById("Email").value;
        var LinkedInUrl = document.getElementById("LinkedInUrl").value;
        var MeetingSourceID = document.getElementById("MeetingSourceID").value;
        var MeetingOwnerID = document.getElementById("MeetingOwnerID").value;
        var EngagementStatusID =
          document.getElementById("EngagementStatusID").value;
        var DirectPhone = document.getElementById("DirectPhone").value;
        var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
        var CompanyPhone = document.getElementById("CompanyPhone").value;
        var ReminderEmailAccount = document.getElementById("ClientPOCID").value;
        var MeetingSetbyID = document.getElementById("MeetingSetbyID").value;
        var data = {
          _id: this.state.id,
          FirstName: FirstName,
          LastName: LastName,
          Email: Email,
          Title: Title,
          Company: Company,
          LinkedInUrl: LinkedInUrl,
          MeetingOwnerID: MeetingOwnerID,
          MeetingSourceID: MeetingSourceID,
          EngagamentStatusID: EngagementStatusID,
          ResponderColdCallersType: MeetingSetbyID.charAt(0),
          ResponderColdCallersID: MeetingSetbyID,
          ReminderEmailAccountID: ReminderEmailAccount,
          Domain: Email.substring(Email.lastIndexOf("@") + 1),
          SalesReply_MessageID: null,
          ConversionCampaignID: null,
          ProspectLastReply: null,
          DirectPhone: DirectPhone,
          DirectPhoneExt: DirectPhoneExt,
          CompanyPhone: CompanyPhone,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
          Role: this.state.Role,
          StatusOnChangeUpdate: this.state.CheckEnggforStatusUpdate,
          ClientID: this.state.ClientID,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotentialMeetingUpdate",
          method: "POST",
          data: data,
        })
          .then((res) => {
            //
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div className="toastsize">
                  Potential meeting Update
                  <br />
                  Potential meeting Updated successfully.
                </div>
              );
              if (this.props.location.state["Pagename"] == "Contact") {
                history.push({ pathname: "/cviewpotentialmeetings", state: { data: this.state.id, pid: this.props.location.state?.Dataprospect, EmailData: this.props.location.state.EmailData, Pagename: "Contact" } });
              } else if (
                this.props.location.state["Pagename"] == "clientLinkedin"
              ) {
                history.push("/clientlinkedinfollowUp");
              } else if (
                this.props.location.state["Pagename"] == "clientPotenial"
              ) {
                history.push("/clientpotentialmeetings");
              } else {
                history.push("/cpotentialmeetings");
              }

              //
            } else {
              this.setState({ IsButtonDisabled: false });
              toast.error(res.data.Message);
            }
          })
          .catch((error) => {
            toast.error("An error occurred while updating. Please try again.");
            this.setState({ IsButtonDisabled: false });
            this.setState({ IsLoading: false });
          });
      } else {
        // document.getElementById("submit").disabled = false;
        this.setState({ IsButtonDisabled: false });
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      this.setState({ IsButtonDisabled: false });
      this.setState({ IsLoading: false });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ Page: newPage });
    var str_in = {
      MeetingID: this.state.MeetingID,
      page: newPage,
      rowsPerPage: this.state.RowsPerPage,
      serchbox: false,
      sort: true,
      field: this.state.SortField,
      sortby: this.state.SortedBy,
      type: "User",
      Role: this.state.Role,
    };
    const Rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/GetPotentialMeetingNotes",
      method: "POST",
      data: str_in,
    });
    Rows1.then((resnotes) => {
      this.setState({ Data: resnotes.data.pagedata });
      this.setState({ Rows: resnotes.data.pagedata });
      this.setState({ Rlen: resnotes.data.totalcount });
      this.setState({ Flen: resnotes.data.totalcount });
      this.setState({ CountPage: resnotes.data.pagecount });
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    });
  };

  //Search
  RequestSearch = (Event) => {
    this.setState({ IsLoading: true });
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      this.setState({ Page: 1 });
      this.setState({ RowsPerPage: 10 });
      var Searchbox;
      if (SearchedVal == "") {
        Searchbox = false;
        this.setState({ Sflag: false });
      } else {
        Searchbox = true;
      }
      var str_in = {
        MeetingID: this.state.MeetingID,
        page: 1,
        rowsPerPage: this.state.RowsPerPage,
        sort: true,
        field: this.state.SortField,
        sortby: this.state.SortedBy,
        Search: SearchedVal,
        type: "User",
        Searchbox: Searchbox,
        Role: this.state.Role,
      };
      const Rows1 = Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/potentialmeeting/GetPotentialMeetingNotes",
        method: "POST",
        data: str_in,
      });
      Rows1.then((resnotes) => {
        this.setState({ Data: resnotes.data.pagedata });
        this.setState({ Rows: resnotes.data.pagedata });
        this.setState({ Rlen: resnotes.data.totalcount });
        this.setState({ Flen: resnotes.data.totalcount });
        this.setState({ CountPage: resnotes.data.pagecount });
        this.setState({ IsLoading: false });
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
    }
  };

  //notes

  NotesGet = () => {
    this.setState({ IsLoading: true });
    var str_in2 = {
      MeetingID: this.props.location.state?.data,
      page: this.state.Page,
      rowsPerPage: this.state.RowsPerPage,
      sort: true,
      field: this.state.SortField,
      sortby: this.state.SortedBy,
      Search: this.state.Search,
      type: "User",
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/GetPotentialMeetingNotes",
      method: "POST",
      data: str_in2,
    })
      .then((resnotes) => {
        this.setState({ IsLoading: false });
        if (resnotes === null) {
          this.setState({ Data: null });
          this.setState({ Rows: null });
          this.setState({ Rlen: null });
          this.setState({ Flen: null });
          this.setState({ CountPage: null });
        } else {
          this.setState({ Data: resnotes.data.pagedata });
          this.setState({ Rows: resnotes.data.pagedata });
          this.setState({ Rlen: resnotes.data.totalcount });
          this.setState({ Flen: resnotes.data.totalcount });
          this.setState({ CountPage: resnotes.data.pagecount });
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  };

  //Delete notes details
  DeleteClientName = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a note.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          var data = {
            _id: id,
            LastUpdatedDate: new Date(),
            LastUpdatedBy: this.state.CUserID,
            Role: this.state.Role,
          };
          Axios({
            url:
              CommonConstants.MOL_APIURL +
              "/potentialmeeting/DeletePotentialNotesMeeting",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "Delete Potential Notes Meeting successfully.",
                  "success"
                );
                this.NotesGet();
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  };

  chengeRowSelected = (event) => {
    this.setState({ rowsPerPage: Number(event.target.value) });
    this.setState({ page: 1 });
  };

  async fromValidationNotes() {
    let formIsValid = true;
    let fileds = this.state.fields;
    let errors = {};
    var Title22 = document.getElementById("Title22").value.trim();
    var Notes22 = document.getElementById("Notes22").value.trim();
    var IsExist = await this.CheckExistPotentialMeetingTitle(Title22);
    if (Title22 == "") {
      formIsValid = false;
      errors["Title22"] = "Please Enter Title";
    }
    if (Notes22 == "") {
      formIsValid = false;
      errors["Notes22"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChangeNotes(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (fields.Title != "") {
      this.state.errors.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistPotentialMeetingTitle(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.errors.Title = null;
    }
  }

  async CheckExistPotentialMeetingTitle(Title) {
    var str_in = {
      MeetingID: this.state.MeetingID,
      ClientID: this.state.ClientID,
      Title:Title,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingStatusExistsNotes",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        let errors = {};
        this.setState({ TitleAvailable: resdata.data.Data[0].Title });
        errors["Notes22"] = "";
        this.setState({ errors: errors });
        return true;
      } else {
        return false;
      }
    }
  }

  async SaveBtn(e) {
    try {
      var Title1 = document.getElementById("Title22").value.trim();
      var Notes2 = document.getElementById("Notes22").value.trim();
      // e.preventDefault();
      // e.currentTarget.disabled = true;
      this.setState({ BtnDisabledSave: true });
      var Final_flag = await this.fromValidationNotes();
      if (Final_flag == true) {
        var Data = {
          ClientID: this.state.ClientID,
          MeetingID: this.state.MeetingID,
          Title: Title1,
          Note: Notes2,
          IsDeleted: false,
          CreatedBy: this.state.UserID,
          CreatedDate: new Date(),
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/AddPotentialNotesMeeting",
          method: "POST",
          data: Data,
        })
          .then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div>
                  Add Note <br />
                  Note added successfully.
                </div>
              );
              this.setState({ ShowNotePopup: false });
              this.NotesGet();
              this.setState({ BtnDisabledSave: false });
            } else {
              toast.error(res.data.Message);
              this.setState({ BtnDisabledSave: false });
            }
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
          });
      } else {
        this.setState({ BtnDisabledSave: false });
        // document.getElementById("submitSave").disabled = false;
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  EditsPage(id) {
    // var mnid = this.state.Rows.PotentialMeetingNoteID
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingNotesByID",
      method: "POST",
      data: str_in,
    })
      .then((res) => {
        this.setState({ OldTitleUpdate: res.data[0]?.Title });
        document.getElementById("Title1").value = res.data[0].Title;
        document.getElementById("Notes").value = res.data[0].Note;
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        this.setState({ IsLoading: false });
      });
  }

  async fromValidationNote() {
    let formIsValid = true;

    let fields = this.state.Fields1;
    let Errors1 = {};

    var Title1 = document.getElementById("Title1").value.trim();
    var Notes = document.getElementById("Notes").value.trim();
    var IsExist = await this.CheckExistBookedMeetingTitle1(Title1);
    if (Title1 == "") {
      formIsValid = false;
      Errors1["Title1"] = "Please Enter Title";
    }
    if (Notes == "") {
      formIsValid = false;
      Errors1["Notes"] = "Please Enter Notes";
    }
    if (IsExist == true) {
      formIsValid = false;
    }
    // if (this.state.nameAvailable != null) {
    //   formIsValid = false;
    // }
    this.setState({ Errors1: Errors1 });

    return formIsValid;
  }

  handleChangeNote(field, e) {
    let Fields1 = this.state.Fields1;
    Fields1[field] = e.target.value;
    this.setState({ Fields1 });
    if (Fields1.Title != "") {
      this.state.Errors1.Title = null;
      this.state.TitleAvailable = null;
      this.CheckExistBookedMeetingTitle1(e.target.value);
    } else {
      this.state.TitleAvailable = null;
      this.state.Errors1.Title = null;
    }
  }

  async CheckExistBookedMeetingTitle1(Title) {
    var str_in = {
      MeetingID: this.state.MeetingID,
      ClientID: this.state.ClientID,
      Title:Title,
      Role: this.state.Role,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/potentialmeeting/PotentialMeetingStatusExistsNotes",
      method: "POST",
      data: str_in,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (this.state.OldTitleUpdate == Title) {
          return false;
        } else {
          let Errors1 = {};
          this.setState({ TitleAvailable: resdata.data.Data[0].Title });
          Errors1["Title1"] = "";
          this.setState({ Errors1: Errors1 });
          return true;
        }
      } else {
        return false;
      }
    }
  }

  async SaveUpdateBtn(e, closeCallback) {
    try {
      var Title = document.getElementById("Title1").value.trim();
      var Notes = document.getElementById("Notes").value.trim();
      // e.preventDefault();
      // e.currentTarget.disabled = true;
      this.setState({ BtnDisabledUpdate: true });
      var FinalValidation = await this.fromValidationNote();
      if (FinalValidation == true) {
        var Data = {
          _id: this.state.id,
          Title: Title,
          Note: Notes,
          LastUpdatedBy: this.state.CUserID,
          LastUpdatedDate: new Date(),
          Role: this.state.Role,
        };
        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/PotenialNotesMeetingUpdate",
          method: "POST",
          data: Data,
        })
          .then((res) => {
            if (res.data.StatusMessage == "SUCCESS") {
              toast.success(
                <div>
                  Update Note <br />
                  Note update successfully.
                </div>
              );
              // Call the close callback function
              closeCallback();
              this.NotesGet();
              this.setState({ BtnDisabledUpdate: false });
            } else {
              this.setState({ BtnDisabledUpdate: false });
              toast.error(res.data.Message);
            }
          })
          .catch((error) => {
            toast.error("An error occurred. Please try again.");
            this.setState({ IsLoading: false });
            this.setState({ BtnDisabledUpdate: false });
          });
      } else {
        this.setState({ BtnDisabledUpdate: false });
        // document.getElementById("submitupdate").disabled = false;
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  }

  Handlevalidationfornotesedit() {
    let Errors1 = {};
    Errors1["Title1"] = "";
    Errors1["Notes"] = "";
    this.state.TitleAvailable = null;
    this.setState({ Errors1: Errors1 });
  }

  Handlevalidationfornotesadd() {
    this.setState({ ShowNotePopup: false });
    let Errors = {};
    Errors["Title22"] = "";
    Errors["Notes22"] = "";
    this.state.TitleAvailable = null;
    this.setState({ errors: Errors });
  }

  // To set default
  handleChangeowner(e) {
    this.setState({ defaultselecttimeowner: e.target.defaultselecttimeowner });
  }
  handleChangeSetby(e) {
    this.setState({
      defaultselectmeetingsetby: e.target.defaultselectmeetingsetby,
    });
  }
  handleChangeReminderEmail(e) {
    this.setState({
      defaultselectreminderemail: e.target.defaultselectreminderemail,
    });
  }
  handleEngagement(e) {
    var EngagementStatusID =
      document.getElementById("EngagementStatusID").value;
    if (this.state.EngagementStatusIDCheck != EngagementStatusID) {
      this.setState({ CheckEnggforStatusUpdate: new Date() });
    }
    this.setState({ defaultselecteng: e.target.defaultselecteng });
  }
  handleChangeSource(e) {
    this.setState({ defaultselect: e.target.defaultselect });
  }

  //get sort field Data
  SortData(Field) {
    try {
      this.setState({ IsLoading: true });
      var SearchedVal = document.getElementById("Search").value;
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        this.setState({ Sflag: false });
        var SortField = Field;
        var SortBy;
        if (this.state.SortedBy == 1) {
          SortBy = -1;
          this.setState({ SortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedBy: 1 });
        }
        this.setState({ SortField: Field });
        var InputParameter = {
          MeetingID: this.state.MeetingID,
          page: this.state.Page,
          rowsPerPage: this.state.RowsPerPage,
          sort: true,
          field: this.state.SortField,
          sortby: this.state.SortedBy,
          Search: this.state.Search,
          type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/GetPotentialMeetingNotes",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((resnotes) => {
          this.setState({ Data: resnotes.data.pagedata });
          this.setState({ Rows: resnotes.data.pagedata });
          this.setState({ Rlen: resnotes.data.totalcount });
          this.setState({ Flen: resnotes.data.totalcount });
          this.setState({ CountPage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (this.state.SortedBy == 1) {
          SortBy = -1;
          this.setState({ SortedBy: -1 });
        } else {
          SortBy = 1;
          this.setState({ SortedBy: 1 });
        }
        this.setState({ SortField: Field });
        var InputParameter = {
          MeetingID: this.state.MeetingID,
          page: this.state.Page,
          rowsPerPage: this.state.RowsPerPage,
          sort: true,
          field: this.state.SortField,
          sortby: this.state.SortedBy,
          Search: this.state.Search,
          type: "User",
          Role: this.state.Role,
        };
        const AccountCategoriesList = Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/potentialmeeting/GetPotentialMeetingNotes",
          method: "POST",
          data: InputParameter,
        });
        AccountCategoriesList.then((resnotes) => {
          this.setState({ Data: resnotes.data.pagedata });
          this.setState({ Rows: resnotes.data.pagedata });
          this.setState({ Rlen: resnotes.data.totalcount });
          this.setState({ Flen: resnotes.data.totalcount });
          this.setState({ CountPage: resnotes.data.pagecount });
          this.setState({ IsLoading: false });
        }).catch((error) => {
          toast.error("An error occurred. Please try again.");
          this.setState({ IsLoading: false });
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    }
  }

  render() {
    return (
      <>
        {this.state.IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
             
              <div className="row px-2">
                <div className="col-md-6 col-sm-6 d-flex align-items-center">
                  <h3 className=" float-left lg-headertitle addpotential_header my-4 mt-0">
                    Edit Potential Meeting
                  </h3>
                </div>
                <div className="col-md-6 col-sm-8 pt-3 my-1 pb-3 text-right">
                  <a
                    onClick={() => {
                      this.MeetingsConvertAdd();
                    }}
                    className="btn btngroup m-btn mr-1"
                  >
                    <i class="la la-exchange mr-1"></i>
                    <span className="Mobile_button">Convert</span>
                  </a>

                  <button
                    id="submit"
                    className="btn btngroup1 m-btn mr-1"
                    onClick={this.savemainbtn}
                    disabled={this.state.IsButtonDisabled}
                  >
                    <i class="la la-save mr-1"></i>
                    <span className="Mobile_button">Save</span>
                  </button>

                  <button
                    className="btn btngroup2 m-btn"
                    onClick={this.backbtn}
                  >
                    <i class="la la-arrow-circle-left mr-1"></i>
                    <span className="Mobile_button">Back</span>
                  </button>
                </div>
              </div>
              <div className="bg-white p-3">
                <div className="row">
                  {this.state.ClientPotential == true ? (
                    <>
                      <div className="col-lg-6 boxsinput_group">
                        <div className="row max-cols d-flex align-items-center">
                          <label className="col-lg-4 view-lable-meet potential_text">
                            Client
                          </label>
                          <div className="col-lg-7">
                            <select
                              className="form-control  m-input"
                              data-val="true"
                              data-val-number="The field ContactSourceID must be a number."
                              disabled
                            >
                              <option value="">{this.state.CName}</option>
                            </select>
                            <span style={{ color: "red" }}>
                              {this.state.errors["ClientPotentialID"]}
                            </span>
                          </div>
                        </div>
                      </div> 
                    <div className="col-lg-6 boxsinput_group"></div>
                  </>
                ) : (
                  <></>
                )}
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("FirstName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("LastName")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("Title")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        name="Company"
                        type="text"
                        onBlur={() =>
                          this.HandleChangeotherFieldBlur("Company")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        name="Email"
                        type="text"
                        onBlur={this.HandleChangeBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                      {this.state.EmailAvailable && (
                        <span style={{ color: "red" }}>
                          {this.state.errors["FirstName"]}
                        </span>)}
                      </div>
                    </div>
                  </div>
              
                  {/* <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Title
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Title"
                          name="Title"
                          type="text"
                          //  onBlur={this.handleChange.bind(this, "Title")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Title"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="Company"
                          name="Company"
                          type="text"
                          // onBlur={this.handleChange.bind(this, "Company")}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["Company"]}
                        </span>
                      </div>
                    </div>
                  </div> */}
              
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        LinkedIn URL
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="LinkedInUrl"
                          name="LinkedInUrl"
                          type="text"
                          onChange={this.handleChange.bind(this, "LinkedInUrl")}
                          value={this.state.fields["LinkedInUrl"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Source
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ContactSourceID must be a number."
                          id="MeetingSourceID"
                          name="MeetingSourceID"
                          value={this.state.defaultselect}
                          onChange={this.handleChangeSource}
                        >
                          {this.state.meetingsource.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["MeetingSourceID"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Owner
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ContactOwnerID must be a number."
                          id="MeetingOwnerID"
                          name="MeetingOwnerID"
                          value={this.state.defaultselecttimeowner}
                          onChange={this.handleChangeowner}
                        >
                          {this.state.potentialowner.map((value) => (
                            <option value={value._id}>
                              {value.Name}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["MeetingOwnerID"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Reminder Email Account
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ClientPOCID must be a number."
                          id="ClientPOCID"
                          name="ClientPOCID"
                          value={this.state.defaultselectreminderemail}
                          onChange={this.handleChangeReminderEmail}
                        >
                          {this.state.meetingaccount.map((value) => (
                            <option value={value._id}>
                              {value.SMTPFromEmail}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["ClientPOCID"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Engagement Status
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field ReasonUnqualifiedID must be a number."
                          id="EngagementStatusID"
                          name="EngagementStatusID"
                          value={this.state.defaultselecteng}
                          onChange={this.handleEngagement}
                        >
                          {this.state.potentialstatus.map((value) => (
                            <option value={value._id}>
                              {value.Status}
                            </option>
                          ))}
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors["EngagementStatusID"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid direct phone"
                          data-val-regex-pattern="^[0-9]*$"
                          id="DirectPhone"
                          name="DirectPhone"
                          type="text"
                          onBlur={() =>
                            this.HandleChangeotherFieldBlur("DirectPhone")
                          }
                        />
                      <span style={{ color: "red" }}>
                        {this.state.errors["DirectPhone"]}
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Direct Phone Ext
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid direct phone ext"
                          data-val-regex-pattern="^[0-9]*$"
                          id="DirectPhoneExt"
                          name="DirectPhoneExt"
                          type="text"
                          onBlur={() =>
                            this.HandleChangeotherFieldBlur("DirectPhoneExt")
                          }
                        />
                      <span style={{ color: "red" }}>
                        {this.state.errors["DirectPhoneExt"]}
                      </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Company Phone
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          data-val="true"
                          data-val-regex="Invalid company phone"
                          data-val-regex-pattern="^[0-9]*$"
                          id="CompanyPhone"
                          name="CompanyPhone"
                          type="text"
                          onBlur={() =>
                            this.HandleChangeotherFieldBlur("CompanyPhone")}
                        />
                         <span style={{ color: "red" }}>
                        {this.state.errors["CompanyPhone"]}
                      </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                        Meeting Set By
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control  m-input"
                          data-val="true"
                          data-val-number="The field MeetingSetbyID must be a number."
                          id="MeetingSetbyID"
                          name="MeetingSetbyID"
                          value={this.state.defaultselectmeetingsetby}
                          onChange={this.handleChangeSetby}
                        >
                          {this.state.meetingsetbyResponder && this.state.meetingsetbyResponder.length > 0
                            ? this.state.meetingsetbyResponder.map((value) => (
                                <option value={value.value}>
                                  {value.label}
                                </option>
                              ))
                            : ""}
                          {/* {this.state.meetingsetbyColdCallers && this.state.meetingsetbyColdCallers.length > 0
                            ? this.state.meetingsetbyColdCallers.map((value) => (
                                <option value={"C" + value.value}>
                                  {value.label}
                                </option>
                              ))
                            : ""} */}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-5 view-lable-meet potential_text">
                      Last Email Campaign:
                      </label>
                      <div className="col-lg-7 View_text">
                      {this.state.campaignStepEmailDetails?.LastEmailCampaignName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 boxsinput_group">
                    <div className="row max-cols d-flex align-items-center">
                      <label className="col-lg-4 view-lable-meet potential_text">
                      Last Email Step
                      </label>
                      <div className="col-lg-7 View_text">
                      {this.state.campaignStepEmailDetails?.LastEmailStepNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            
              <div className="bg-white mt-3">

                <div className="row mx-0 align-items-center border-bottom py-3">
                  <div className="col d-flex align-items-center">
                    <h3 className=" float-left xs-headertitle mb-0 addpotential_header">Notes</h3>
                  </div>
                  <div className="col text-right">
                    <button
                      onClick={() => {
                        this.setState({ ShowNotePopup: true });
                      }}
                      className="btn btngroup m-btn "
                    >
                      <i class="la la-plus la-icon-font-size-13"></i>
                      <span className="Mobile_button">Add</span>
                    </button>
                    <Popup open={this.state.ShowNotePopup}>
                      <div>
                        <div className="modal-black"></div>
                        <div className="filterPopup largerPopup">
                          <div className="paddingboxTerms">
                            <div className="modal-header py-3 px-3">
                              <h4 className="mb-0">Note</h4>
                            </div>
                            <div className="modal-body px-5 py-3">
                              <div class="row mb-3">
                                <label class="col-lg-2 col-form-label text-left">
                                  Title
                                </label>
                                <div class="col-lg-10">
                                  <input
                                    class="form-control m-input"
                                    Data-val="true"
                                    Data-val-remote="Title already exist."
                                    Data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                    Data-val-required="Please enter title"
                                    id="Title22"
                                    name="Title22"
                                    placeholder="Enter title"
                                    type="text"
                                    onBlur={this.handleChangeNotes.bind(
                                      this,
                                      "Title22"
                                    )}
                                  />
                                  <span style={{ color: "red" }}>
                                    {this.state.errors["Title22"]}
                                  </span>
                                  {this.state.TitleAvailable && (
                                    <span style={{ color: "red" }}>
                                      Title already exist.
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div class="row">
                                <label class="col-lg-2 col-form-label text-left">
                                  Note
                                </label>
                                <div class="col-lg-10">
                                  <textarea
                                    class="form-control m-input minheight390"
                                    Data-val="true"
                                    Data-val-remote="Title already exist."
                                    Data-val-required="Please enter title"
                                    id="Notes22"
                                    name="Notes22"
                                    placeholder="Enter note"
                                    type="text"
                                    // onChange={this.handleChangeNotes.bind(
                                    //   this,
                                    //   "Notes22"
                                    // )}
                                  >
                                    {" "}
                                  </textarea>
                                  <span style={{ color: "red" }}>
                                    {this.state.errors["Notes22"]}
                                  </span>
                                </div>
                              </div>
                            </div>
                        <div className="modal-fotter border-top text-right py-4 px-4">
                          <div class="row">
                            <div class="col-lg-12 pull-right">
                              <a
                                id="backtolist"
                                class="btn btn-secondary mr-2"
                                onClick={this.Handlevalidationfornotesadd}
                              >
                                cancel
                              </a>
                              <button
                                id="submitSave"
                                class="btn btn-primary btn-lightgreen mr-1"
                                //href="javascript:void(0);"
                                value="Save"
                                onClick={this.SaveBtn}
                                disabled={this.state.BtnDisabledSave}
                              >
                                <i class="la la-save"></i> Save
                              </button>
                            </div>
                          </div>
                        </div>
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-sm-12 col-md-6">
                    <label className="textlabelte">
                      Show
                      <select
                        name="tbl_meeting_length"
                        aria-controls="tbl_meeting"
                        className="form-control form-control-sm"
                        onChange={this.chengeRowSelected}
                        value={this.state.RowsPerPage}
                      >
                        {CommonConstants.show_rows.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </select>
                      entries
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-6 full-right">
                    <label className="textlabelte">
                      Search:{" "}
                      <input
                        type="Search"
                        id="Search"
                        onKeyPress={(event) => this.RequestSearch(event)}
                        className="form-control form-control-sm ml-2"
                        placeholder=""
                        aria-controls="tbl_meeting"
                      />
                    </label>
                  </div>
                </div>
                <div class='px-3'>
                <div className="table-bordered">
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                      <TableRow>
                      <TableCell
                          onClick={() => {
                            this.SortData("Title");
                            this.setState({  NoteSortedColumn : "Title"})
                          }}
                        >
                          Title
                          <span className="shorting">
                         <ArrowUpward className={this.state.SortedBy === 1 && this.state.NoteSortedColumn === "Title" ? "active" : null} />
                          <ArrowDownward  className={this.state.SortedBy === -1 && this.state.NoteSortedColumn === "Title"  ? "active" : null} />
                          </span>
                      </TableCell>
                      <TableCell
                          onClick={() => {
                            this.SortData("Note");
                            this.setState({  NoteSortedColumn : "Note"})
                          }}
                        >
                          Note
                          <span className="shorting">
                         <ArrowUpward className={this.state.SortedBy === 1 && this.state.NoteSortedColumn === "Note" ? "active" : null} />
                          <ArrowDownward  className={this.state.SortedBy === -1 && this.state.NoteSortedColumn === "Note"  ? "active" : null} />
                          </span>
                      </TableCell>
                      <TableCell
                          onClick={() => {
                            this.SortData("CreatedDate");
                            this.setState({  NoteSortedColumn : "CreatedDate"})
                          }}
                        >
                          Created Date
                          <span className="shorting">
                         <ArrowUpward className={this.state.SortedBy === 1 && this.state.NoteSortedColumn === "CreatedDate" ? "active" : null} />
                          <ArrowDownward  className={this.state.SortedBy === -1 && this.state.NoteSortedColumn === "CreatedDate"  ? "active" : null} />
                          </span>
                      </TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.Rows?.length === 0 ? (
                          <p className="text-center">No Data available in table</p>
                        ) : (
                          this.state.Rows?.map((row) => (
                            <TableRow>
                              <TableCell>{row.Title}</TableCell>
                              <TableCell>{row.Note}</TableCell>
                              <TableCell>
                                {moment(
                                  new Date(row.CreatedDate).toDateString()
                                ).format("MM/DD/YYYY")}
                              </TableCell>
                              <TableCell>
                                <a
                                  onClick={() => {
                                    // this.editpotentialmeetingnote(row.PotentialMeetingNoteID);
                                    this.EditsPage(row._id);
                                  }}
                                >
                                  <Popup
                                    trigger={
                                      <i class="la flaticon-edit-1 edit-icon"></i>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div>
                                        <div className="modal-black"></div>
                                        <div className="filterPopup largerPopup">
                                          <div className="paddingboxTerms">
                                            <div className="modal-header py-3 px-3">
                                              <h4 className="mb-0">Note</h4>
                                            </div>
                                            <div className="modal-body p-5">
                                              <div class="row mb-3">
                                                <label class="col-lg-2 col-form-label text-left">
                                                  Title
                                                </label>
                                                <div class="col-lg-10">
                                                  <input
                                                    class="form-control m-input"
                                                    Data-val="true"
                                                    Data-val-remote="Title already exist."
                                                    Data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                                    Data-val-required="Please enter title"
                                                    id="Title1"
                                                    name="Title1"
                                                    placeholder="Enter title"
                                                    type="text"
                                                    onBlur={this.handleChangeNote.bind(
                                                      this,
                                                      "Title1"
                                                    )}
                                                    // value={
                                                    //   this.state.Fields1["Title1"]
                                                    // }
                                                  />
                                                  <span style={{ color: "red" }}>
                                                    {this.state.Errors1["Title1"]}
                                                  </span>
                                                  {this.state.TitleAvailable && (
                                                    <span style={{ color: "red" }}>
                                                      Title already exist.
                                                    </span>
                                                  )}
                                                </div>
                                              </div>

                                              <div class="row">
                                                <label class="col-lg-2 col-form-label text-left">
                                                  Note
                                                </label>
                                                <div class="col-lg-10">
                                                  <textarea
                                                    class="form-control m-input minheight390"
                                                    Data-val="true"
                                                    Data-val-remote="Title already exist."
                                                    Data-val-required="Please enter title"
                                                    id="Notes"
                                                    name="Notes"
                                                    placeholder="Enter note"
                                                    type="text"
                                                    // onChange={this.handleChangeNote.bind(
                                                    //   this,
                                                    //   "Notes"
                                                    // )}
                                                    // value={
                                                    //   this.state.Fields1["Notes"]
                                                    // }
                                                  >
                                                    {" "}
                                                  </textarea>
                                                  <span style={{ color: "red" }}>
                                                    {this.state.Errors1["Notes"]}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="modal-content bordernone text-right">
                                            <div class="row">
                                              <div class="col-lg-12 pull-right">
                                                <a
                                                  id="backtolist"
                                                  class="btn btn-secondary mr-2"
                                                  onClick={() => {
                                                    close(); this.Handlevalidationfornotesedit();
                                                  }}
                                                >
                                                  cancel
                                                </a>
                                                <button
                                                  id="submitupdate"
                                                  class="btn btn-primary button-dark mr-1"
                                                  href="javascript:void(0);"
                                                  value="Save"
                                                  disabled={this.state.BtnDisabledUpdate}
                                                  onClick={(e) => {
                                                    // Pass the close function as a callback
                                                    this.SaveUpdateBtn(e, () => {
                                                      close(); // Call the close function when the update is successful
                                                    });
                                                  }}
                                                >
                                                  <i class="la la-save"></i> Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </a>
                                <a
                                  className="btn-eyesicon"
                                  onClick={() => {
                                    this.DeleteClientName(
                                      row._id
                                    );
                                  }}
                                >
                                  <i class="la flaticon-delete-1 delete-icon"></i>
                                </a>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {this.state.Sflag ? (
                  <div class="row">
                     <div class="col pageright">
                    <Pagination
                      component="div"
                      count={this.state.CountPage}
                      onChange={this.handleChangePage}
                      showFirstButton
                      showLastButton
                    />
                    </div>
                    <div class="col dataTables_info">
                      <p>
                        Showing{" "}
                        {this.state.Rlen == 0
                          ? 0
                          : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                        to{" "}
                        {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                          ? this.state.Rlen
                          : this.state.Page * this.state.RowsPerPage}{" "}
                        of {this.state.Rlen} entries (filtered from{" "}
                        {this.state.Flen} total entries)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div class="row pb-2">
                    <div class="col dataTables_info">
                      <p>
                        Showing{" "}
                        {this.state.Rlen == 0
                          ? 0
                          : (this.state.Page - 1) * this.state.RowsPerPage + 1}{" "}
                        to{" "}
                        {this.state.Page * this.state.RowsPerPage > this.state.Rlen
                          ? this.state.Rlen
                          : this.state.Page * this.state.RowsPerPage}{" "}
                        of {this.state.Rlen} entries
                      </p>
                    </div>
                    <div class="col pageright">
                      <Pagination
                        component="div"
                        count={this.state.CountPage}
                        onChange={this.handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </div>
                )} 
                </div>
            </div> 
        </div> 
        </div> 
        <Footer />
      </>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddSalesHiveContactPage = connect(
  mapState,
  actionCreators
)(CEditPotentialMeetingsPage);
export { connectedAddSalesHiveContactPage as CEditPotentialMeetingsPage };
