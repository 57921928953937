import React, { useEffect } from 'react';
import Axios from "axios";
const Moment = require("moment");
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CSVDownloader } from "react-papaparse";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetClientDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";

import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyleHeader from "../../user/StickyHeader/StickyHeader";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function PotentialMeetingstable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Email");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [PotenationalMeetingExportData, SetPotenationalMeetingExportData] = React.useState([]);
  const [PotentialMeetingStatus, setPotentialMeetingStatus] = React.useState([]);
  const [CName, SetCName] = React.useState([]);
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    PotentialMeetingGet(Details.ClientID, Details.Role);
    ExportPotenationalMeeting(Details.ClientID, Details.Role)
  }, [Search, Page, RowsPerPage, SortedBy]);

  const PotentialMeetingGet = (CID, Role) => {
    SetIsLoading(true)
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      Role: Role,
      IsPagingRequired: true
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiPotentialMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
      if (Res.data.StatusMessage == "SUCCESS") {
        SetRows(Res.data.PageData);
        SetRlen(Res.data.TotalCount);
        SetFlen(Res.data.TotalCount);
        SetCountPage(Res.data.PageCount);
        setPotentialMeetingStatus(Res.data.PotentialMeetingStatus)
        SetIsLoading(false)
        setIsDataLoaded(true);
      }else{
        SetIsLoading(false)
        setIsDataLoaded(true);

      }

    });
  };

  //update potential status dropdown
  const PotentialStatusSelect = (event, id) => {
    var data = {
      _id: id,
      EngagamentStatusID: event.target.value,
      LastUpdatedBy: CUserID,
      LastUpdatedDate: new Date(),
      Role:Role,
      LastStatusChangeDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/potentialmeeting/UpdatePotentialMeetingdropdown",
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        toast.success(
          "Potential meeting select option Status update successfully.",
          "Update Potential select option  meeting Status"
        );
        PotentialMeetingGet(ClientID, Role)
      } else {
        toast.error(res.data.Message);
      }
    });
  };

  const SortData = (Field) => {
    if (SortedBy == 1) {

       SetSortedBy(-1);
     } else {

       SetSortedBy(1);
     }
     SetSortField(Field);

 };

  const ExportPotenationalMeeting = (CID,Role) => {
    var data = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: "",
      Type: "User",
      ClientID: CID.ClientID != null?CID.ClientID:CID,
      Role: Role.Role != null ?Role.Role :Role,
      IsPagingRequired:false
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiPotentialMeeting",
      method: "POST",
      data: data,
    }).then((Res) => {
     if (Res.data.StatusMessage == "SUCCESS") {
        var ExportData=[]
        if(Res.data.PageData.length > 0)
        {
          for(var i=0;i < Res.data.PageData.length; i++)
          {
            var objCampaign=Res.data.PageData[i];
            var data={
              "Engagement Status":objCampaign.PMS.Status || "",
              "Company":objCampaign.Company || "",
              "Contact Name":objCampaign.Firstname +" "+objCampaign.Lastname || "",
              "Email":objCampaign.Email || "",
              "Domain":objCampaign.Domain || "",
              "Title":objCampaign.Title || "",
              "LinkedIn Url":objCampaign.LinkedInUrl || "",
              "Meeting Source Name":objCampaign.MS.Name || "",
              "Owner Name":(objCampaign.MO != null && objCampaign.MO != "" ) ? objCampaign.MO?.Name :"",
              "Reminder Email Account":objCampaign.A.length > 0 ? objCampaign.A[0].SMTPFromEmail:"",
              "Created Date":objCampaign?.CreatedDate != null
              ? Moment(objCampaign?.CreatedDate).format(
                  "MM/DD/YYYY"
                )
              : ""
            }
            ExportData.push(data);
          }
          SetPotenationalMeetingExportData(ExportData);
        }else{
          var data={
            "Engagement Status":"",
            "Company":"",
            "Contact Name":"",
            "Email":"",
            "Domain":"",
            "Title":"",
            "LinkedIn Url":"",
            "Meeting Source Name":"",
            "Owner Name":"",
            "Reminder Email Account":"",
            "Created Date":""
          }
          SetPotenationalMeetingExportData(data);
        }
      }
    });
  };
  const ExportCSV=()=>{
    toast.success(<div className="toastsize">Kpi <br/>Data exported successfully.</div>);
  }
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Event.target.value);
    SetPage(1);
  };
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  const EditContactHandle = (id, Email) => {
    history.push({
      pathname: "/cviewcontacts",
      state: { data: id, EmailData: Email, Pagename: "Kpi7DaysPotential" },
    });
  };


  return (
    <>
    {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <div className="row mxl-30 border-bottom mx-0 align-items-center">
        <div className="col">
          <h4 className="headertitle xs-headertitle float-left padx-0 px-1">Potential Meetings</h4>

          <div className="listing-li float-right padb-15">
            <ul>
              <li>
                <CSVDownloader data={PotenationalMeetingExportData} filename={CName +"-Potential_Meeting"} bom={true}>
                  <a onClick={() => { ExportCSV(); }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la la-icon-font-size-13 la-download"></i>Export
                  </a>
                </CSVDownloader>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row padt-15 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">

          <label className="textlabelte">Search: <input type="search" id="search" onKeyPress={RequestSearch} class="form-control form-control-sm ml-2" placeholder="" aria-controls="tbl_meeting" /></label>
        </div>
      </div>
      <div class='px-3'>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table class='table-ref' aria-label="collapsible table">
            <TableHead>
            <TableRow>
          <TableCell onClick={() => { SortData("EngagamentStatus"); setSortedColumn("EngagementStatus"); }}>
            Engagement Status
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "EngagementStatus" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "EngagementStatus" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Company"); setSortedColumn("Company"); }}>
            Company
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Company" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Company" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("ContactName"); setSortedColumn("ContactName"); }}>
            Contact Name
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContactName" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "ContactName" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email"); }}>
            Email
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Domain"); setSortedColumn("Domain"); }}>
            Domain
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Domain" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Domain" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("Title"); setSortedColumn("Title"); }}>
            Title
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "Title" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "Title" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("LinkedInUrl"); setSortedColumn("LinkedInUrl"); }}>
            LinkedIn Url
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "LinkedInUrl" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "LinkedInUrl" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("MeetingSourceName"); setSortedColumn("MeetingSourceName"); }}>
            Meeting Source Name
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingSourceName" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingSourceName" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("MeetingOwnerName"); setSortedColumn("MeetingOwnerName"); }}>
            Owner Name
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "MeetingOwnerName" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "MeetingOwnerName" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("ReminderEmailAccount"); setSortedColumn("ReminderEmailAccount"); }}>
            Reminder Email Account
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "ReminderEmailAccount" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "ReminderEmailAccount" ? "active" : null} />
            </span>
          </TableCell>
          <TableCell onClick={() => { SortData("CreatedDate"); setSortedColumn("CreatedDate"); }}>
            Created Date
            <span className="shorting">
              <ArrowUpward className={SortedBy === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
              <ArrowDownward className={SortedBy === -1 && sortedColumn === "CreatedDate" ? "active" : null} />
            </span>
          </TableCell>
        </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <>
                    <TableRow key={row._id}>

                      <TableCell className="labeltds" scope="row">
                        {/* <select
                          onChange={(e) => {
                            PotentialStatusSelect(e, row._id);
                          }}
                          class="form-control  m-input"
                          id="Potentialstatus"
                          name="Potentialstatus"
                          autoComplete="off"
                          value={row.EngagamentStatusID}
                        >
                          {PotentialMeetingStatus.map((value) => (
                            <option value={value._id}>
                              {value.Status}
                            </option>
                          ))}
                        </select> */}
                        {row?.EngagamentStatus}
                      </TableCell>
                      <TableCell>{row?.Company}</TableCell>
                      <TableCell > <a
                                  href={`/cviewcontacts?id=${row?.Prospectid}&email=${encodeURIComponent(row?.Email)}&pagename=Kpi7DaysPotential`}
                                  onClick={(e) => {
                                      e.preventDefault();
                                      EditContactHandle(row?.Prospectid, row?.Email);
                                  }}
                        >
                          <u> {row?.Firstname +" "+  row?.Lastname }</u>
                        </a></TableCell>
                      <TableCell>{row?.Email}</TableCell>
                      <TableCell> <a
                                href={`http://${row?.Domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "underline",
                                  color: "inherit",
                                }}
                              >
                                <u>
                                  {" "}
                                  {row?.Domain}
                                </u>
                              </a></TableCell>
                      <TableCell>{row?.Title}</TableCell>
                      <TableCell>{row?.LinkedInUrl}</TableCell>
                      <TableCell>{row?.MS?.Name}</TableCell>
                      <TableCell>{row?.MO != null ? row?.MO?.Name : ""}</TableCell>
                      <TableCell>{row?.A.length > 0 ? row?.A[0]?.SMTPFromEmail : ""}</TableCell>
                      <TableCell>{row?.CreatedDate != null
                                ? Moment(row?.CreatedDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}</TableCell>
                    </TableRow>


                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </div>
      {/* {Sflag ? (
        <div class="row pb-2 mx-0">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : ( */}
        <div class="row pb-2 mx-0">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      {/* )} */}
    </>
  );
}
