import React from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css"; 
import { userActions } from "../../_actions"; 
import Sidebar from "../../_components/user/NavigationSidebar/MainSidebar";
import Footer from "../../_components/user/footer/footer"; 
import Keywords from "../../_components/user/Keywords/Keywords"
import $ from 'jquery';
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.js'; // Import datetime picker JS
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css';
import Partnership from "../../_components/user/Partnership/Partnership";
import { GetUserDetails } from "../../_helpers/Utility";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';

class KeywordsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsAnalyze: false,
      date:new Date(),
      ClientID: null,
      Role: "",
      DisplayDomainName:""
    }
    this.Analyze = this.Analyze.bind(this);
    this.daterefect = React.createRef()
  }

  componentDidMount() {
    const details = GetUserDetails();
    if (details != null) {
      this.setState({
        ClientID: details.ClientID,
        Role: details.Role
      });
    }
    
    document.getElementById("datetimepicker").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const $datepickerElement =  $(this.daterefect.current); 
    
     $datepickerElement.datetimepicker({
      format: 'mm/dd/yyyy',
      todayBtn: true,
      autoclose: true,
      container: '#app',
      minView: '2',
    });
  }

  Analyze() {
    this.setState({IsAnalyze :true, date: document.getElementById("datetimepicker").value})
}
displayDomain = (name) => {
  this.setState({DisplayDomainName:name})
};

  render() {
    return (
      <>
       <div className="adminmain mheight"> 
       {this.state.Role  === "Client" ?(<ClientSidebar className="" />):(<Sidebar className="" />)}
          <div className="bodyhome partner-space">
            <div className="row align-items-center ">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left">Keywords 
                  {/* {this.state.DisplayDomainName} */}
                  </h4>
              </div> 
              <div className="col padright">
                <div className="listing-li float-right padb-15">
                <div className="btn-group datepicker-hed">
                    <div className="col-lg-6 timedatepikker date-input">
                      <input className="form-control m-input"
                        type='text'
                        id='datetimepicker'
                        ref={this.daterefect}
                      />
                    </div>
                    <button
                    onClick={this.Analyze}
                      id="aCompanyState"
                      class="btn btn-primary btnColor m-btn brrb72"
                      autocomplete="off"
                    >
                      <i class="la flaticon-diagram"></i>
                      <span> Analyze</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="px-1">
              <div className="row">
                <div className="col">
                  <Keywords   ObjKeywords={this.state} DisplayDomain={this.displayDomain}/>
                </div>
              </div>
            </div> 
            <Partnership /> 
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedKeywordsPage = connect(mapState, actionCreators)(KeywordsPage);
export { connectedKeywordsPage as KeywordsPage };
