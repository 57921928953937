import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";

import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";



class CEditCampaignCategoriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Code: "",
      Fields: {},
      Errors: {},
      NameAvailable: null,
      ClientID: null,
      UserID: null,
      Role:null,
      UserIDby:null
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }
  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.UserIDby = Details.ChildUserID;
      this.state.Role  = Details.Role
    }
    this.CampaignCategoriesGet();
    firstname_e.style.display = "none";
  }

//form validation
FromValidation() {
  let formIsValid = true;
  if (Object.entries(this.state.Fields).length > 0) {
    let Fields = this.state.Fields;
    let Errors = {};
    if (!Fields["Name"]) {
      formIsValid = false;
      Errors["Name"] = "Please enter Name";
    }
    if (this.state.StatusAvailable != null) {
      formIsValid = false;
    }
    this.setState({ Errors: Errors });
  }
  return formIsValid;
}

//change to check error
HandleChange(Field, e) {
  document.getElementById("firstname_e").style.display = "none";
  let Fields = this.state.Fields;
  Fields[Field] = e.target.value;
  this.setState({ Fields });
  if (Fields.Name != "") {
    this.state.Errors.Name = null;
    this.state.StatusAvailable = null;
    this.CheckExistCategoryName(e.target.value);
  } else {
    this.state.StatusAvailable = null;
    this.state.Errors.Name = null;
  }
}

//check exist Status
CheckExistCategoryName(Name) {
    var str_in = {
      ClientID: this.state.ClientID,
      Name: Name,
      Role:this.state.Role
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/Campaigncategory/CategoryExists",
      method: "POST",
      data: str_in,
    }).then((resdata) => {
      if (resdata.data.StatusMessage == "SUCCESS") {
        if (resdata.data.Data.length > 0) {
          this.setState({ StatusAvailable: resdata.data.Data[0].Name });
          console.log(this.state.StatusAvailable);
          this.setState({ IsExist: true });
        }
      }
    });
  }

//campaign data get by id
  CampaignCategoriesGet() {
    
    const id = this.props.location.state;
    this.setState({ id: id });
    var str_in = {
      id: id,
      Role:this.state.Role

    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryGetByID",
      method: "POST",
      data: str_in,
    }).then((res) => {
      
      if (res.data?.StatusMessage == "SUCCESS") {
        document.getElementById("Name").value = res.data?.Data[0].Name;
      } else {
        toast.error(res.data?.Message);
      }
    });
  }

  BackBtn() {
    history.push("/CCampaignCategories");
  }

  //Update client status
  SaveBtn() {
    var Name = document.getElementById("Name").value;
    if (Name == undefined || Name == "") {
      firstname_e.style.display = "block";
    } else {
      var data = {
        id: this.state.id,
        Name: Name,
        LastUpdatedBy: this.state.UserIDby,
        LastUpdatedDt: new Date(),
        Role:this.state.Role

      };
      Axios({
        url:
          CommonConstants.MOL_APIURL +
          "/Campaigncategory/CategoryUpdate",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(<div className="toastsize" >Campaign Category<br/>Campaign Category updated successfully.</div>);
          history.push("/CCampaignCategories");
        } else {
          toast.error(res.data.Message);
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
              <div className="row">
                <div className="col">
                  <h4 className="headertitlepad  lg-headertitle pl-0 mb-0">
                    Edit Campaign Category
                  </h4>
                </div>
              </div>

            <div class="bg-white">
              <div className="row py-3 mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols">
                    <label class="col-lg-4 view-lable-meet">Category</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter name"
                        onChange={this.HandleChange.bind(this, "Name")}
                        value={this.state.Fields["Name"]}
                        id="Name"
                        name="Name"
                        placeholder="Enter Name"
                        type="text"
                      />
                      <label id="firstname_e" className="errorinput">
                        {" "}
                        Please enter Name{" "}
                      </label>
                      {this.state.StatusAvailable && (
                        <span style={{ color: "red" }}>
                          Name already exist.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row mb-3 px-2 mt-4">
                <div class="col-lg-12 pull-left">
                  <a
                    id="submit"
                    onClick={this.SaveBtn}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </a>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCEditCampaignCategoriesPage = connect(
  mapState,
  actionCreators
)(CEditCampaignCategoriesPage);
export { connectedCEditCampaignCategoriesPage as CEditCampaignCategoriesPage };
