import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'; 
import Footer from '../../_components/user/footer/footer'; 
import CustomVariableSet from '../../_components/clients/customvariableset/CustomvariablesetTabing.js';
import{ GetUserDetails } from '../../_helpers/Utility';
const moment = require("moment");
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
class CCustomVariableSetPage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            ClientID:null ,
            UserID:null,
            StartDate:null,
            EndDate:null,
            exportdata: {
                "Start":moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY/MM/DD'),
                "End":moment(new Date()).format('YYYY/MM/DD')
            },
            pageData:null
        };
        this.DateAnalize = this.DateAnalize.bind(this);
      }
      componentDidMount() {
        document.title = `Custom Variable Sets | SalesHive`;

        var Details=GetUserDetails();
        if(Details != null)
        {
            this.state.ClientID= (Details.ClientID);
            this.state.UserID=(Details.ChildUserID);
            
        }
        var d = new Date();
          d.setMonth(d.getMonth() - 1);
          var start = moment(d).format('YYYY-MM-DD');
          var d1 = new Date();
          var end = moment(d1).format('YYYY-MM-DD');
          this.setState({StartDate:start});
          this.setState({EndDate: end});
          document.getElementById("from-date").value = start;
          document.getElementById("to-date").value = end;
          var d = new Date();
          d.setMonth(d.getMonth() - 1);
          var start = moment(d).format('YYYY/MM/DD');
          var d1 = new Date();
          var end = moment(d1).format('YYYY/MM/DD');
          this.setState({exportdata:{
              "Start":start,
              "End":end
          }})
      }
      DateAnalize() {
        var fromdate = document.getElementById("from-date").value;
        var todate = document.getElementById("to-date").value;
        if (moment(fromdate, "YYYY-MM-DD").isAfter(moment(todate, "YYYY-MM-DD"))) {
          toast.error("Please select an appropriate date range");
        } else {
          this.setState({
            exportdata: {
              Start: fromdate,
              End: todate,
            },
          });
        }
       
      }
        //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };
    render() { 
        return (
            <>
                     
                

                <div className="adminmain mheight"> 

                <Sidebar className=""/> 

                <div className="bodyhome">
                    <div className="row">
                        <div className="col padd-0"> 
                            <h4 className="headertitlebd lg-headertitle">Custom Variable Sets</h4>
                        </div>
                        <div className="col padright">
                            <div className="listing-li float-right padb-15">
                            <div className="btn-group">
                                <input id="fromtab" name="fromtab" type="hidden" value="" />
                                <input className="form-control datepicker" id="from-date" name="from-date" type="date" autocomplete="off" />
                                <input className="form-control datepicker" id="to-date" name="to-date" type="date" autocomplete="off" />
                                    <button onClick={this.DateAnalize} id="aCompanyState" className="btn btn-primary btnColor m-btn btn-radius" autocomplete="off">
                                        <i className="la flaticon-diagram"></i>
                                        <span>Analyze</span>
                                </button>
                            </div>

                            </div>
                        </div>
                    </div> 
                

                    <div className="row"> 
                         <div className="col">
                         <CustomVariableSet
                daterange={this.state.exportdata}
                updateFromChild={this.UpdateFromChild}
              />
                        </div> 
                    </div> 
                </div>
                </div>
                <Footer />
            </>
        );
    }

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCustomVariableSetPage = connect(mapState, actionCreators)(CCustomVariableSetPage);
export { connectedCCustomVariableSetPage as CCustomVariableSetPage };