import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import AccountTabing from '../../_components/clients/accounts/AccountTabing';
import CollapsibleTable from '../../_components/usertable';



class CAccountsPage extends React.Component {
   

    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome px-3">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd lg-headertitle pl-0">Accounts</h4>
                        </div>
                        <div className="col padright">
                             
                        </div>
                    </div> 
                    <div className="col px-0">
                        <AccountTabing />
                    </div>
                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCAccountsPage = connect(mapState, actionCreators)(CAccountsPage);
export { connectedCAccountsPage as CAccountsPage };