import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const Moment = require("moment");

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import { makeStyles } from "@material-ui/core/styles";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { AddGlobalPhoneDncPage } from "../../../user/GlobalPhoneDNC/AddGlobalPhoneDncPage";

import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unSet",
    },
  },
});

export default function MeertingOwnerTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Phone");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID, SetCUserID] = React.useState(0);
  const [IsLoading, SetIsLoading] = React.useState(true);
  const [sortedColumn, setSortedColumn] = React.useState("Phone");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [isAddingAndEdit, setIsAddingAndEdit] = React.useState(false);

  useEffect(() => {
    let Details = GetUserDetails();
    if (Details != null) {
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    PhoneDNCGet(Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  // PhoneDNCGet owner list
  const PhoneDNCGet = (UID, URole) => {
    SetIsLoading(true);
    let MeetingOwnerData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      UserID: UID,
      Role: URole,
    };
    const rows1 = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/globalphonedncblacklist/PhoneDncBlackListGet",
      method: "POST",
      data: MeetingOwnerData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
    });
  };

  // search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      SetPage(1);
    }
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;

    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }

    SetSortField(Field);
    SetSflag(SearchedVal !== "");

    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      UserID: UserID,
      Role: Role,
    };
    const ApiResponse = Axios({
      url:
        CommonConstants.MOL_APIURL +
        "/globalphonedncblacklist/PhoneDncBlackListGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false);
    }).catch((error) => {
      SetIsLoading(false);
    });
  };

  // Callback function to update parent state
  const UpdateFromChild = (value) => {
    if (value == true) {
      PhoneDNCGet(UserID, Role);
      setIsAddingAndEdit(false);
    }
  };

  const AddBtn = () => {
    setIsAddingAndEdit(true); // Set the flag to indicate adding/editing
  };

  return (
    <div>
      {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      {isAddingAndEdit ? (
        <AddGlobalPhoneDncPage onUpdate={UpdateFromChild} />
      ) : (
        <>
          <div className="row mx-0 align-items-center mt-2">
            <div className="col px-0">
              <h4 className="headertitle xs-headertitle float-left padx-0"></h4>
            </div>
            <div className="col">
              <div className="listing-li float-right">
                <ul class="my-0">
                  <li>
                    <a
                      onClick={() => {
                        AddBtn();
                      }}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i className="la la-icon-font-size-13 la-plus"></i>
                      <span>Add</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col padd-0"></div>
          </div>
          <div className="row pt-3">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  class="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label class="textlabelte">
                Search:{" "}
                <input
                  type="search"
                  id="search"
                  onKeyPress={RequestSearch}
                  class="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>
          <div className="table-bordered">
            <TableContainer component={Paper}>
              <StyleHeader isDataLoaded={isDataLoaded} />
              <Table className="table-ref" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      onClick={() => {
                        SortData("Phone");
                        setSortedColumn("Phone");
                      }}
                    >
                      Phone
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "Phone"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "Phone"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        SortData("CreatedDate");
                        setSortedColumn("CreatedDate");
                      }}
                    >
                      {" "}
                      Created Date
                      <span className="shorting">
                        <ArrowUpward
                          className={
                            SortedBy === 1 && sortedColumn === "CreatedDate"
                              ? "active"
                              : null
                          }
                        />
                        <ArrowDownward
                          className={
                            SortedBy === -1 && sortedColumn === "CreatedDate"
                              ? "active"
                              : null
                          }
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows?.length === 0 ? (
                    <p className="text-center">No data available in table</p>
                  ) : (
                    Rows?.map((row) => (
                      <TableRow key={row?._id}>
                        <TableCell>{row?.Phone}</TableCell>
                        <TableCell>
                          {row?.CreatedDate != null
                            ? Moment(row?.CreatedDate).format("MM/DD/YYYY")
                            : ""}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <div className="row"></div>
              </Table>
            </TableContainer>
          </div>
          <div class="row">
            <div class="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div class="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
