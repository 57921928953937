import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.js'; // Import datetime picker JS
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css'; // Import datetime picker CSS

import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import Popup from "reactjs-popup";
const moment = require('moment');
import Select from "react-select";
import Swal from "sweetalert2";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from "@material-ui/core/Collapse";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import loadingicon from "../../../images/loading.gif";

import { history } from "../../../_helpers";
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

//Edit Filter Component
import EditFilter from './EditFilter';
import { Tooltip } from '@material-ui/core';
import { applyMiddleware } from 'redux';
import Cookies from 'js-cookie';
import StyleHeader from "../StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});





export default function AccountAllTable() {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [SalesRepList, SetSalesRepList] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("CreatedDate");
  const [SortValue, SetSortValue] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [CUserID,SetCUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Open, SetOpen] = React.useState(false);
  const [FollowUpNotesOpen, SetFollowUpNotesOpen] = React.useState(false);
  const [ShowExportPopUp,SetShowExportPopUp] = React.useState(false)
  const [AddBurnerModel, SetAddBurnerModel] = useState(false)
  const [checkeddata, setChecked] = React.useState({ "IsMobilePhone": false, "IsCompanyPhone": false, "IsDirectPhone": false, "IsOtherPhone1": false, "IsOtherPhone2": false });
  const [AllColdCallingCondetail, SetAllColdCallingContacts] = useState({})
  const [ColdCallingContactsDetails, SetColdCallingContactsDetails] = useState([]);
  const [LastCallResultDropDown, SetLastCallResultDropDown] = useState([
    { label: 'Never Been Called', value: 'never been called' },
    { label: 'Call Back Later', value: 'call back later' },
    { label: 'Called', value: 'called' },
    { label: 'Voicemail 1', value: 'voicemail 1' },
    { label: 'Voicemail 2', value: 'voicemail 2' },
    { label: 'Voicemail 3', value: 'voicemail 3' },
    { label: 'Email Only No Voicemail', value: 'email only no voicemail'},
    { label: 'Not Available', value: 'not available' },
    { label: 'Leave No Message / Email', value: 'leave no message / email'},
    { label: 'Asked for Info / Follow Up Later', value: 'asked for info/follow up later'},
    { label: 'Not Interested', value: 'not interested' },
    { label: 'Not The Right Person', value: 'not the right person' },
    { label: 'Bad Data', value: 'bad data' },
    { label: 'Prospect Hang Up', value: 'prospect hang up' },
    { label: 'Registry Complete + Gen Info', value: 'registry complete + gen info' },
  ]);
  const CallResultArr = [
    { label: 'Never Been Called', value: 'Never Been Called' },
    { label: 'Call Back Later', value: 'Call Back Later' },
    { label: 'Called', value: 'Called' },
    { label: 'Voicemail 1', value: 'Voicemail 1' },
    { label: 'Voicemail 2', value: 'Voicemail 2' },
    { label: 'Voicemail 3', value: 'Voicemail 3' },
    { label: 'Email Only No Voicemail', value: 'Email Only No Voicemail'},
    { label: 'Not Available', value: 'Not Available' },
    { label: 'Leave No Message / Email', value: 'Leave No Message / Email'},
    { label: 'Asked for Info / Follow Up Later', value: 'Asked for Info / Follow Up Later'},
    { label: 'Not Interested', value: 'Not Interested' },
    { label: 'Not The Right Person', value: 'Not The Right Person' },
    { label: 'Bad Data', value: 'Bad Data' },
    { label: 'Prospect Hang Up', value: 'Prospect Hang Up' },
    { label: 'Registry Complete + Gen Info', value: 'Registry Complete + Gen Info' },
  ]
  const [TimeZone] = useState([
    {value:"AT",label:"AT"},
    {value:"CA",label:"CA"},
    {value:"CT",label:"CT"},
    {value:"ET",label:"ET"},
    {value:"MT",label:"MT"},
    {value:"PT",label:"PT"},
  ]);
  const [SelectedFilterID, SetSelectedFilterID] = useState(0)
  const [FilterDefaultID,SetFilterDefaultID] = useState(0)
  const [ColdContactFilterList, SetColdContactFilterList] = useState([])
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const [DisabledContactExportBtn,SetDisabledContactExportBtn] = React.useState(false);
  const [Reset,SetReset] = React.useState(true);
  const [SelectAllRecords,SetSelectAllRecords] = React.useState(true)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  //Filter states starts from here
  const [OpenFilterBox,SetOpenFilterBox] = React.useState(false);
  const [OpenEditFilterBox,SetOpenEditFilterBox] = React.useState(false);
  const [SelectedEditFilter,SetSelectedEditFilter] = React.useState(0);
  const [FieldName,SetFieldName] = React.useState([]); //Fieldname for the filter select box
  const [GetDefaultFilterValues,SetGetDefaultFilterValues] = React.useState([])
  const [OpenAddFilter, SetOpenAddFilter] = React.useState(false);
  const [IsApply, SetIsApply] = React.useState(false);
  const [CustomColumnData, SetCustomColumnData] = React.useState([]);
  const [sortedColumn, setSortedColumn] = React.useState("CreatedDate");
  
  const [FieldAttributes,SetFieldAttributes] =React.useState([ {
    "ExpressionName": "LessThan",
    "FieldType": "empty",
    "FilterPropertyName": "TotalCalls",
    "FilterPropertyValue1": "9",
    "FilterPropertyValue2": ""
},
{
    "ExpressionName": "GreaterThanEqualTo",
    "FieldType": "empty",
    "FilterPropertyName": "DaysSinceLastCall",
    "FilterPropertyValue1": "1",
    "FilterPropertyValue2": ""
},
{
    "ExpressionName": "EqualTo",
    "FieldType": "empty",
    "FilterPropertyName": "LastCallResult",
    "FilterPropertyValue1": "never been called,voicemail 1,voicemail 2,email only no voicemail,not available,leave no message / email",
    "FilterPropertyValue2": ""
},
{
    "ExpressionName": "False",
    "FieldType": "empty",
    "FilterPropertyName": "IsBlacklisted",
    "FilterPropertyValue1": "y",
    "FilterPropertyValue2": ""
},
{
    "ExpressionName": "EqualTo",
    "FieldType": "empty",
    "FilterPropertyName": "ContactTag",
    "FilterPropertyValue1": "",
    "FilterPropertyValue2": "",
},
])
  const [FilterParameters, SetFilterParameters] = React.useState([]);
  const [FilterID, SetFilterID] = React.useState(null);
  const [EditFieldAttributes, SetEditFieldAttributes] = React.useState([]);
  const [IsFiltered, SetIsFiltered] = React.useState(false);
  const [ContactTags, SetContactTags] = React.useState([]);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [ContactTag, SetContactTag] = React.useState([]);
  const [ContactSalesDevlopMenData, setContactSalesDevlopMenData] =React.useState([]);
  const [ContactCustomFieldData, setContactCustomFieldData] = React.useState([]);
  const [ContactReassignData, SetContactReassignData] = React.useState([]);
  const [CampaignData, SetCampaignData] = React.useState([]);
  const [IsSelectedAll,SetIsSelectedAll] = React.useState(false)
 

  //Applied Filter Data
  const [AppliedFilterData,SetAppliedFilterData] = React.useState([]);
  //Edit Save ButtonDisplay
  const [IsSaveButton,SetIsSaveButton] = React.useState(false)
  //Contact tags option
  const [ContactTagsOptions,SetContactTagsOptions] = React.useState([])
  //Contact Status
  const [ContactStatus, SetContactStatus] = React.useState([])

  //Data model for filter drop down create a structure for filter properties
  const [FilterDataModel,SetFitlerDataModel] = React.useState([])
  const [FilterDeleteByID,SetFilterDeleteByID] = React.useState(null)
  const [DataModelFilterStatusLoad,SetDataModelFilterStatusLoad] = React.useState("MainDropDown");
  const [TempFilterID,SetTempFilterID] = React.useState(null)
  const [selectedDate, setSelectedDate] = useState(null);

const [IsCalled,SetIsCalled] = useState(false)
const [Loader1,SetLoader1] = useState(true)
const [isDataLoaded, setIsDataLoaded] = React.useState(false);


  let IsClientID = true;
  if (IsClientID && ClientID == 0) {
    const userDetails = GetUserDetails();
    if (userDetails) {
      const { ClientID, ParentUserID, ChildUserID, Role, Username } = userDetails;
      SetClientID(ClientID);
      SetUserID(ParentUserID);
      SetCUserID(ChildUserID);
      SetRole(Role);

      setEmail(Username);
      IsClientID = false;
    }
  }

useEffect(() => {
  const fetchData = async () => {
    try {

        await Promise.all([
          GetCustomFiledsData(ClientID, UserID, Role),
          ContactInfoGetData(ClientID, UserID, Role),
          ColdCallingContactFilterGet(ClientID, UserID, CUserID),
          GettingContactStatus(Role, ClientID)
        ]);
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      SetIsLoading(false);
    }
  };
if(ClientID !=0)
  {
    fetchData();
  }
}, []);

useEffect(() => {
  // Initialize datetime pickers for each input with a specific class
  FieldAttributes.forEach((el)=>{
    $(`.datetimepicker${el.id}`).each(function() {
      
      $(this).datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
      });
    });
  })
}, [FieldAttributes]);

  const ColdCallingContactFilterGet = async (ClientID, UserID,CUserID) => {
    const InputParameter = {
      ClientID: ClientID,
      UserID: UserID
    };
  
    
    try {
      const Result = await Axios.post(CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactFilterGetDropDown", InputParameter);
      if (Result.data.StatusMessage === "SUCCESS") {
          if(Result.data.Data.length > 0)
          {
              var dyfID = localStorage.getItem("DynamicColdCallingFilterID");
              const Data = Result.data?.Data?.find(item => item.IsDefault);
              SetFilterDefaultID(Data?._id);
              localStorage.setItem("DefaultFilterID",Data?._id)
              SetColdContactFilterList(Result.data.Data);
            if(dyfID != null){   
                SetDataModelFilterStatusLoad("MainDropDown")
                SetFilterDeleteByID(dyfID)
                SetSelectedFilterID(dyfID);
                SetSelectedEditFilter(dyfID);
                Getfilterpropertylist(dyfID,"MainDropDown");
                SetReset(false);
              }else{
                SetDataModelFilterStatusLoad("MainDropDown")
                SetFilterDeleteByID(Data?._id)
                SetSelectedFilterID(Data?._id);
                SetSelectedEditFilter(Data?._id);
                Getfilterpropertylist(Data?._id,"MainDropDown");
                SetReset(true);
              }
          }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Getting All Filter Name (Not for Globle use it use only for save and Update)
  const GettingColdCallingContactFilterList = async(ClientID, UserID) => {
    const InputParameter = {
      ClientID: ClientID,
      UserID: UserID
    };
  
    try {
      const Result = await Axios.post(CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactFilterGetDropDown", InputParameter);
      if (Result.data.StatusMessage === "SUCCESS") {
          SetColdContactFilterList(Result.data.Data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  //get Cold calling recording  list
  const GetColdCallingContact = async (CID, UID, URole, CUID, FieldAttributes) => {
    try {
      SetFilterParameters(FieldAttributes)
      
      //SetIsLoading(true);
      const InputParameter = {
        Page: Page,
        RowsPerPage: parseInt(RowsPerPage),
        Sort: true,
        Field: SortField,
        SortBy: SortValue,
        Search: Search,
        Type: "User",
        ClientID: CID,
        UserID: UID,
        CUserID: CUID,
        Role: URole,
        IsApply: true,
        FilterProperties: FieldAttributes
      };
  
      const response = await Axios.post(CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactsGet", InputParameter);
  
      if (response.data.StatusMessage === "SUCCESS") {
        const { PageData, TotalCount, PageCount } = response.data;
        SetRows(PageData);
        SetRlen(TotalCount);
        SetFlen(TotalCount);
        const allSelected = PageData.every(row => selectedIds.includes(row._id));
        setSelectAll(allSelected);
        SetCountPage(PageCount);
      SetIsLoading(false);

      } else {
        console.error("Failed to fetch cold calling contacts:", response.data.ErrorMessage);
      SetIsLoading(false);

      }
    } catch (error) {
      console.error("Error fetching cold calling contacts:", error);
    } finally {
      SetIsLoading(false);
    }
  };
  
  //Getting Contact Status details
  const GettingContactStatus = async (URole,CID) => {
    const InputParameter = {
      Role:URole,
      ClientID:CID
    };
  
    try {
      const Result = await Axios.post(CommonConstants.MOL_APIURL + "/contacts/GettingContactStatus", InputParameter);
      if (Result.data.StatusMessage === "SUCCESS") {
        const Data = Result.data?.Data;
        const labelValuePairs = Data.map(item => ({
          label: item.Status,
          value: item._id
      }));

        SetContactStatus(labelValuePairs)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //Get Notes
  const GetNotes = (CID, UID) => {
    let InputParameter = {

      ClientID: CID,
      UserID: UID,
    };

    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingNotes",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      
      let Notes = Result.data.Data;
      let UpdatedArr = [];
      let Data = Rows;
      Data.forEach((elem) => {

        let indexed = Notes.findIndex(v => v.ProspectID == elem.ProspectID);
        if (indexed == -1) {
          let Object = elem;
          Object.Notes = "No notes available"
          UpdatedArr.push(Object);
          
        } else {

        }
        
      })

    });
  }

  //get sort field data
  const SortData = async (Field) => {
   // SetIsLoading(true);
    
    let SearchedVal = document.getElementById("search").value;
    let SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      let SortField = Field;
      let Sort = SortValue;
      if (Sort == 1) {
        Sort = -1;
        SetSortValue(-1);
      } else {
        Sort = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      SetPage(1)

      await GetColdCallingContactDetailsUsingFilter(true,FilterParameters,1,RowsPerPage,Field,Sort)
    } else {
      SerchBox = true;
      SetSflag(true);
      let SortField = Field;
      let Sorted = SortValue;
      if (Sorted == 1) {
        Sorted = -1;
        SetSortValue(-1);
      } else {
        Sorted = 1;
        SetSortValue(1);
      }
      SetSortField(Field);
      SetPage(1)
      await GetColdCallingContactDetailsUsingFilter(true,FilterParameters,1,RowsPerPage,Field,Sorted)
    }
  };

  //search for record
  const RequestSearch = async (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1)
   await GetColdCallingContactDetailsUsingFilter(true,FilterParameters,1,RowsPerPage,SortField,SortValue)


    }
  };

  //change Page
  const HandleChangePage = async (Event, NewPage) => {
    
    if(localStorage.getItem("SelectAllContact") && localStorage.getItem("SelectAllContact") === true){
      
      await SelectAll()
    }
    document.body.scrollTop = 0;
    SetPage(NewPage);
    await GetColdCallingContactDetailsUsingFilter(true,FilterParameters,NewPage,RowsPerPage,SortField,SortValue)
  };

  //change display rows
  const ChangeRowSelected = async (Event) => {
    SetRowsPerPage(Event.target.value);
    let SearchedVal = document.getElementById("search").value;
   await GetColdCallingContactDetailsUsingFilter(true,FilterParameters,1,Event.target.value,SortField,SortValue)
    SetPage(1);
  };

  //Data retrieve method using filter properties
  const GetColdCallingContactDetailsUsingFilter = (IsApply,FilterProperties,Page,RowsPerPage,SortField,SortValue) => {
    if(CUserID != 0 && ClientID!= 0 && UserID != 0){
      SetIsLoading(true)
      SetFilterParameters(FilterProperties)
     // let filterStringify = JSON.stringify(FilterProperties);
     // localStorage.setItem("DynamicQuery",filterStringify)
      let SearchedVal = document.getElementById("search").value;
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortValue,
      Search: SearchedVal,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      CUserID:CUserID,
      Role: Role,
      IsApply:IsApply,
      FilterProperties:FilterProperties
    };

    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingContactsGet",
      method: "POST",
      data: InputParameter,
    });
    Row.then((Result) => {
      
      if (Result.data.StatusMessage == "SUCCESS") {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        const allSelected = Result.data.PageData.every((row) => selectedIds.includes(row._id));
        setSelectAll(allSelected);
        SetIsLoading(false)
        setIsDataLoaded(true);

      }else{
        SetIsLoading(false)
        setIsDataLoaded(true);

        
      }
      // UnSelectAll();
      SetIsLoading(false)
      setIsDataLoaded(true);

    });
  }
    
  };

  const EditBtn = (ID,Email) => {
    history.push({ pathname: "/viewcontacts", state: { data: ID, Pagename: "ColdCallingContacts",EmailData:Email} });
  }

  const SelectAllCheckBox = async (e) => {
    Rows?.map(async (val) => {
      if (e.target.checked) {
        document.getElementById(val?._id).checked = true;
        await SetColdCallingContactsDetails((prevItems) => [...prevItems, val]);
      } else if (!e.target.checked) {
        document.getElementById(val?._id).checked = false;
        await SetColdCallingContactsDetails((prevItems) =>
          prevItems.filter((prevItem) => prevItem._id !== val._id) 
        );
      }
    });
  }

  const HandleCall = async (e, data) => {
    if (e.target.checked) {
      await SetColdCallingContactsDetails((prevItems) => [...prevItems, data]);
    }
    else {
      await SetColdCallingContactsDetails((prevItems) =>
        prevItems.filter((prevItem) => prevItem._id !== data._id)
      );
    }
    await SetAllColdCallingContacts({})
  }

  const MultipleCallDial = async () => {
    let Objects ={
      IsCompanyPhone:false, IsDirectPhone:false, IsMobilePhone:false, IsOtherPhone1:false, IsOtherPhone2:false
    }
    setChecked(Objects)
    if (selectedIds.length > 0) {
      SetAddBurnerModel(true)
    }
    else {
      toast.error(<div className="toastsize">Start Dial Session<br />Please select at least one cold calling contact from list.</div>)
    }

  }

  const OpenDialPopup = async (e, data) => {
    await SetAllColdCallingContacts(data)
    await SetColdCallingContactsDetails([])
    SetAddBurnerModel(true)
  }

  const CallBtn = async () => {
    try {
      SetIsLoading(true);
      if(checkeddata.IsCompanyPhone || checkeddata.IsDirectPhone || checkeddata.IsMobilePhone || checkeddata.IsOtherPhone1 || checkeddata.IsOtherPhone2){
        // SetAddBurnerModel(false)
        let ColdCallerUserID = "", UserID = "", ClientID = "";
        let Details = GetUserDetails();
        if (Details != null) {
          ColdCallerUserID = Details.ChildUserID;
          UserID = Details.ParentUserID;
          ClientID = Details.ClientID;
        }
        let ColdCallingContacts = [];
        ColdCallingContacts.push(AllColdCallingCondetail)
        let InputParameter = {
          UserID: UserID,
          ClientID: ClientID,
          ColdCallerUserID: ColdCallerUserID,
          AllColdCallingContacts: ColdCallingContactsDetails.length > 0 ? ColdCallingContactsDetails : ColdCallingContacts,
          ObjPhoneNumberdata: checkeddata
        };
        SetIsCalled(true)
        const response = Axios({
          url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetPhonebrnerAccessToken",
          method: "POST",
          data: InputParameter,
        });
        response.then((Result) => {
          
          if (Result.data.StatusMessage == "SUCCESS") {
            Cookies.set('dialSession', 'Started', { path: '/' });
            const windowFeatures = 'width=500,height=500,resizable,scrollbars';
            window.open(Result.data.returnUrl, '_blank',windowFeatures);
            SetIsLoading(false);
            SetIsCalled(true)
          }
          else if (Result.data.StatusMessage == "NotExist") {
            toast.error(Result.data.Message);
            SetIsLoading(false);

          }else if(Result.data.StatusMessage == "ERROR"){
            let message = Result.data.Message;
            if(message == "The refresh token is invalid."){
              message = "Please reauthenticate your phone burner account."
            }
            toast.error(message);
            SetIsLoading(false);
          }
          
  
        });
      }else{
        toast.error(<div className='toastsize'>Start Dial Session <br/>Please select at least one phone number.</div>)
         SetIsLoading(false);
      }
    } catch (error) {
      SetIsLoading(false);
    }
  }

  const handleChange1 = async (event) => {
    await setChecked({ ...checkeddata, ["IsMobilePhone"]: event.target.checked, ["IsCompanyPhone"]: event.target.checked, ["IsDirectPhone"]: event.target.checked, ["IsOtherPhone1"]: event.target.checked, ["IsOtherPhone2"]: event.target.checked });
  };

  const handleChange = async (event, phoneType) => {
    await setChecked({ ...checkeddata, [phoneType]: event.target.checked });
    // 
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', ml: 3, flexWrap: 'wrap' }}>
      <FormControlLabel
        label="Direct Phone"
        control={<Checkbox checked={checkeddata["IsDirectPhone"]} onChange={(e) => handleChange(e, "IsDirectPhone")} />}
      />
      <FormControlLabel sx={{ fontSize: '10px' }}
        label="Mobile Phone"
        control={<Checkbox checked={checkeddata["IsMobilePhone"]} onChange={(e) => handleChange(e, "IsMobilePhone")} />}
      />
      <FormControlLabel
        label="Company Phone"
        control={<Checkbox checked={checkeddata["IsCompanyPhone"]} onChange={(e) => handleChange(e, "IsCompanyPhone")} />}
      />
      
      <FormControlLabel
        label="Other Phone1"
        control={<Checkbox checked={checkeddata["IsOtherPhone1"]} onChange={(e) => handleChange(e, "IsOtherPhone1")} />}
      />
      <FormControlLabel
        label="Other Phone2"
        control={<Checkbox checked={checkeddata["IsOtherPhone2"]} onChange={(e) => handleChange(e, "IsOtherPhone2")} />}
      />
    </Box>
  );

const AddBtn = () =>{
  history.push({
    pathname: "/addcontacts",
    state: {Pagename: "ColdCallingContacts" },
  });
}

const OpenExportPopup = () =>{
  SetDisabledContactExportBtn(false)
  SetShowExportPopUp(true)
  setErrors("")

}

const CloseExportPopup = ()=> {
  SetShowExportPopUp(false)
  setErrors("")
  SetDisabledContactExportBtn(false)
}

 // Function to validate email
 const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

// Function to handle email input onBlur event
const handleEmailBlur = (e) => {
  const { name, value } = e.target;
  const newErrors = { ...errors };

  if (!value) {
    newErrors[name] = 'Please enter email.';
  } else if (!validateEmail(value)) {
    newErrors[name] = 'Invalid email';
  } else {
    newErrors[name] = ''; // No error
  }

  setErrors(newErrors);
};

const SubmitExport = (e) => {
  SetDisabledContactExportBtn(true)

  e.preventDefault();
  if (!errors.email && email != "") {
    const contactFilterSecArray = [];
    let InputParameters = {
      UserID:UserID,
      ClientID:ClientID,
      Email:email,
      IsDeleted:false,
      CreatedDate:new Date(),
      CreatedBy:CUserID,
      DynamicQuery:FilterParameters,
      UserRoleName:Role,
      ColdCallerUserID:UserID
    }
    const Row = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/ColdCallingSaveExport",
      method: "POST",
      data: InputParameters,
    });
    Row.then((Result) => {
        if(Result.data.StatusMessage == 'SUCCESS'){
          toast.success(<div className='toastsize'>Contact <br/>
          You will get file soon on Submitted Email.</div>)
          CloseExportPopup()
          SetDisabledContactExportBtn(false)
          const userDetails =  GetUserDetails();
          const {Username } = userDetails;
          setEmail(Username)


        }else{
  SetDisabledContactExportBtn(false)

        }
    });

  }else{
    const newErrors = { ...errors };
    newErrors['email'] = 'Please enter email.';
    setErrors(newErrors);
  SetDisabledContactExportBtn(false)


  }
};

  // Get All Contact Info Data
const ContactInfoGetData = (CID, UID, Role) => {
    let InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      Role: Role,
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/ContactData",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      setContactSalesDevlopMenData(result.data?.ContactSalesDevlopMenData);
      setContactCustomFieldData(result.data?.ContactCustomFieldData);
      SetContactTag(result.data?.ContactTagData);
      const options = result.data?.ContactsReassignData.map((val) => {
        return { value: val._id, label: val.ColdCallersName };
      });
      const options1 = result.data?.ContactTagData.map((val) => {
        return { value: val._id, label: val.Tag };
      });
      SetContactTagsOptions(options1)
      SetContactReassignData(options)
      // SetContactToReassignData(options);   
      SetCampaignData(result.data?.CampaignGetData);

    });
};

let contactoptions = ContactTag.map((val) => {
  return { value: val.Tag, label: val.Tag, cid: val._id };
});

let ContactTagsArr = ContactTags.map((val) => {
  return { value: val.ContactTagID, label: val.Tag, cid: val.ContactTagID };
});

const handleSelectAll = () => {
  setSelectAll(!selectAll);
  if (!selectAll) {
    const allIds = Rows.map((row) => row._id);
    setSelectedIds(allIds);
    SetColdCallingContactsDetails(Rows)
  } else {
    setSelectedIds([]);
    SetColdCallingContactsDetails([])
  }
};

const handleRowCheckboxChange = async (id) => {
  // await SetColdCallingContactsDetails([...ColdCallingContactsDetails,id])
  await SetAllColdCallingContacts(id)
  if (selectedIds.includes(id._id)) {
    setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id._id));
    SetColdCallingContactsDetails(ColdCallingContactsDetails.filter((selectedId) => selectedId._id !== id._id));
    localStorage.setItem("SelectAllContact",false)
  } else {
    setSelectedIds([...selectedIds, id._id]);
    SetColdCallingContactsDetails([...ColdCallingContactsDetails, id]);
  }
};

//Select all records 
const SelectAll = async() => {
  
  try{
    SetIsLoading(true)
    SetIsSelectedAll(true)
    const InputParameters = {
      ClientID,
      UserID,
      CUserID,
      Role,
      FilterProperties:FilterParameters
    }
    const Result = await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/SelectAllRowsOfContacts",
      method: "POST",
      data: InputParameters,
    });
      if (Result.data.StatusMessage == "SUCCESS") {
        setSelectAll(!selectAll);
        if (!selectAll) {
        setSelectedIds(Result.data.Data?.map((el) => el?._id));
        SetColdCallingContactsDetails(Result.data.Data)
        SetIsLoading(false)
        }
        SetIsLoading(false)
        localStorage.setItem("SelectAllContact",true)
      }else{
        SetIsLoading(false)
        
      }
  }catch(err){
    console.error(err)
  }
  // setSelectAll(!selectAll);
  // if (!selectAll) {
  //   const allIds = Rows.map((row) => row._id);
  //   setSelectedIds(allIds);
  //   SetColdCallingContactsDetails(allIds)
  // }
}

//Unselect all records
const UnSelectAll = () => {
  setSelectedIds([]);
  localStorage.setItem("SelectAllContact",false)

}

//Open Filter model
const OpenFilterModel = async () => {
  SetIsLoading(true)
  SetOpenFilterBox(true)
 await GetDefaultProperties(FilterDefaultID,ClientID,UserID,CUserID)
}

//Close Fitler model
const CloseFilterModel = () => {
  SetOpenFilterBox(false)
}

//Getting Custom fields
const GetCustomFiledsData = (CID, UID, Role) => {
  const InputParameters = {
    ClientID: CID,
    UserID: UID,
    Role: Role,
  };

  Axios({
    url: CommonConstants.MOL_APIURL + "/contacts/GetCustomFields",
    method: "POST",
    data: InputParameters,
  }).then((res) => {
    
    
    let Arr = [
      {
        FieldValue: "AccountName",
        ColumnName: "Account Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "AccountCategory",
        ColumnName: "Account Category",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "AccountType",
        ColumnName: "AccountType",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Address1",
        ColumnName: "Address1",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Address2",
        ColumnName: "Address2",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "CallCount",
        ColumnName: "Call Count",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between "},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "City",
        ColumnName: "City",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ClientPOC",
        ColumnName: "Client POC",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Company",
        ColumnName: "Company",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "CompanyPhone",
        ColumnName: "Company Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactCategory",
        ColumnName: "Contact Category",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactOwner",
        ColumnName: "Contact Owner",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactSource",
        ColumnName: "Contact Source",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        
        ]
      },
      {
        FieldValue: "ContactStatus",
        ColumnName: "Contact Status",
        Expression: "EqualTo",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "Country",
        ColumnName: "Country",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "CreatedDate",
        ColumnName: "Created Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:true,
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "TotalSent",
        ColumnName: "Deliveries",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "Email",
        ColumnName: "Email",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "FollowUpDate",
        ColumnName: "Follow Up Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:true,
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "Name",
        ColumnName: "Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},

        ]
      },
      {
        FieldValue: "TotalHardBounce",
        ColumnName: "Hard Bounce",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      {
        FieldValue: "Industry",
        ColumnName: "Industry",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "IsBlacklisted",
        ColumnName: "Is Blacklisted",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"False",label:"No"},
          {value:"True",label:"Yes"},
        ],
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:true,
      },
      {
        FieldValue: "LastCallDate",
        ColumnName: "Last Call Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:true,
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false
      },
      // {
      //   FieldValue: "LastName",
      //   ColumnName: "Last Name",
      //   Expression: "Contains",
      //   FilterPropertyValue: "",
      //   FilterPropertyValue1: "",
      //   Options:[
      //     {value:"Contains",label:"Contains"},
      //     {value:"EqualTo",label:"Equals"},
      //     {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
      //     {value:"IsNotBlank",label:"Is Not Blank"},
      //   ]
      // },
      {
        FieldValue: "LastSendDate",
        ColumnName: "Last Send Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:true,
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
  
      },
      {
        FieldValue: "LastValidationDate",
        ColumnName: "Last Validation Date",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:true,
        IsNum:false,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "LinkedInURL",
        ColumnName: "LinkedIn URL",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ListName",
        ColumnName: "List Name",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "MobilePhone",
        ColumnName: "Mobile Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalOpen",
        ColumnName: "Open",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "HoneyScore",
        ColumnName: "Honey Score",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "ReasonUnqualified",
        ColumnName: "Reason Unqualified",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalReply",
        ColumnName: "Replies",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "ColdCallersName",
        ColumnName: "Sales Development Reps",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "SalesRep",
        ColumnName: "Sales Rep",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalSoftBounce",
        ColumnName: "Soft Bounce",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"LessThan",label:"Less Than"},
          {value:"LessThanEqualTo",label:"Less Than Equal To"},
          {value:"GreaterThan",label:"Greater Than"},
          {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          {value:"between",label:"Between"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ],
        IsDate:false,
        IsNum:true,
        IsSelect:false,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "State",
        ColumnName: "State",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ContactTag",
        ColumnName: "Tag",
        Expression: "EqualTo",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
            {value:"EqualTo",label:"Equals"},
          {value:"NotEqualTo",label:"Not Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "Title",
        ColumnName: "Title",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "ValidationScore",
        ColumnName: "Validation Score",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Website",
        ColumnName: "Website",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Zip",
        ColumnName: "Zip",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "TotalCalls",
        ColumnName: "Total Calls",
        Expression: "Equals",
        IsSelect:false,
        IsNum:true,
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"LessThan",label:"Less Than"},
        ]
      },
      {
          FieldValue: "DaysSinceLastCall",
          ColumnName: "Days Since Last Call",
          Expression: "GreaterThanEqualTo",
          IsSelect:false,
          IsNum:true,
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          Options:[
            {value:"GreaterThanEqualTo",label:"Greater Than Equal To"},
          ],
         
      },
      {
        FieldValue: "LastCallResult",
        ColumnName: "Last Call Result",
        Expression: "EqualTo",
        IsSelect:true,
        IsNum:false,
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          {value:"NotEqualTo",label:"Not Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "CallResult",
        ColumnName: "Has Ever Had the Call Result",
        Expression: "EqualTo",
        IsSelect:true,
        IsNum:false,
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
          // {value:"NotEqualTo",label:"Not Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "TimeZone",
        ColumnName: "Time Zone",
        Expression: "Equals",
        IsSelect:true,
        IsNum:false,
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"EqualTo",label:"Equals"},
        ],
        IsDate:false,
        IsNum:false,
        IsSelect:true,
        IsCustom:false,
        IsDefault:false,
        IsBool:false,
      },
      {
        FieldValue: "BestPhone",
        ColumnName: "Best Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "BestPhoneStatus",
        ColumnName: "Best Phone Status",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone",
        ColumnName: "Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "PhoneStatus",
        ColumnName: "Phone Status",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone2",
        ColumnName: "Phone2",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone2Status",
        ColumnName: "Phone2 Status",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone3",
        ColumnName: "Phone3",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone3Status",
        ColumnName: "Phone3 Status",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone4",
        ColumnName: "Phone4",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "Phone4Status",
        ColumnName: "Phone4 Status",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "DirectPhone",
        ColumnName: "Direct Phone",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "DirectPhoneExt",
        ColumnName: "Direct Phone Ext",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
      {
        FieldValue: "PRLLeadID",
        ColumnName: "PRL LeadID (SalesForce ID)",
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsBlank",label:"Is Blank"},
          {value:"IsNotBlank",label:"Is Not Blank"},
        ]
      },
    ];
    res.data.Data?.forEach((el) => {
      Arr.push({
        FieldValue: el.FieldName,
        ColumnName: el.FieldName,
        Expression: "Contains",
        FilterPropertyValue: "",
        FilterPropertyValue1: "",
        FieldFor: "Text",
        ContactTagIds: "",
        IsCustom: true,
        IsSelect: false,
        IsBool: false,
        CustomFieldID:el._id,
        Options:[
          {value:"Contains",label:"Contains"},
          {value:"DoesNotEqual/DoesNotContain",label:"Does Not Equal / Does Not Contain"},
          {value:"EqualTo",label:"Equals"},
          {value:"IsNotBlank",label:"Is Not Blank"},
          {value:"IsBlank",label:"Is Blank"},
        ]
      });
    });
    let SortedArr = Arr.sort(function (a, b) {
      return a.ColumnName.toLowerCase().localeCompare(
        b.ColumnName.toLowerCase()
      );
    });
    SetCustomColumnData(SortedArr);
  });
};

//Fieldname handler
const ColumnhandledDropdownChange = (id, Index) => {
  // Get the selected ColumnName from the dropdown
  const dropdown = document.getElementById("ColumnName" + id);
  const ColumnName = dropdown.value;

  // Clone the FieldAttributes array to avoid mutating the original
  const FieldAttributesDatas = [...FieldAttributes];

  // Find the index of the selected ColumnName in CustomColumnData
  const indexed = CustomColumnData.findIndex((v) => v.FieldValue === ColumnName);

  // Update properties based on the selected ColumnName
  FieldAttributesDatas[Index].FieldValue = ColumnName;
  FieldAttributesDatas[Index].IsCustom = CustomColumnData[indexed].IsCustom;
  FieldAttributesDatas[Index].IsDate = CustomColumnData[indexed].IsDate;

  if(CustomColumnData[indexed].IsCustom){
    FieldAttributesDatas[Index].CustomFieldID = CustomColumnData[indexed].CustomFieldID;
  }

  // Check if ColumnName is "IsBlacklisted" and set IsBool accordingly
  FieldAttributesDatas[Index].IsBool = ColumnName === "IsBlacklisted";

  // Check if ColumnName is a numeric field and set IsNum accordingly
  const numericFields = ["CallCount", "TotalSent", "TotalHardBounce", "TotalOpen", "TotalReply", "TotalSoftBounce","TotalCalls", "HoneyScore"];
  FieldAttributesDatas[Index].IsNum = numericFields.includes(ColumnName);

  // Check if ColumnName is "ContactTag" and set IsSelect and Expression accordingly
  if (ColumnName === "ContactTag" || ColumnName === 'TimeZone' || ColumnName === 'LastCallResult' || ColumnName === "ContactStatus" || ColumnName === "CallResult") {
    FieldAttributesDatas[Index].IsSelect = true;
    FieldAttributesDatas[Index].Expression = "EqualTo";
  } else {
    FieldAttributesDatas[Index].IsSelect = false;
  }

  // Set FieldFor based on IsCustom
  FieldAttributesDatas[Index].FieldFor = CustomColumnData[indexed].IsCustom ? "Text" : "";

  // Update the FieldAttributes array
  SetFieldAttributes(FieldAttributesDatas);
};

//Expression handler
const ExpressionHandler = (id) => {
  // Clone the FieldAttributes array to avoid mutating the original
  const StateArr = [...FieldAttributes];

  // Get the selected ColumnName and ExpressionName from their respective inputs
  const ColumnName = document.getElementById("ColumnName" + id).value;
  const ExpressionName = document.getElementById("expression" + id).value;

  // Find the index of the element in StateArr with the given id
  const indexed = StateArr.findIndex((v) => v.id === id);

  // If no element with the given id is found, push a new object into StateArr
  if (indexed === -1) {
    StateArr.push({
      id: id,
      ColumnName: ColumnName,
      Expression: ExpressionName,
    });
  } else {
    // Otherwise, update the existing element with the new ColumnName and ExpressionName
    StateArr[indexed].ColumnName = ColumnName;
    StateArr[indexed].Expression = ExpressionName;
  }

  // Check if ExpressionName is "IsNotBlank" or "IsBlank" and set NullCondition accordingly
  StateArr[indexed].NullCondition = ExpressionName === "IsNotBlank" || ExpressionName === "IsBlank";

  // Update the FieldAttributes array
  SetFieldAttributes(StateArr);
};

const FilterhandleChangeContactOption = (val, id, FieldName) => {
  // Clone the FieldAttributes array to avoid mutating the original
  const Arr = [...FieldAttributes];

  // Initialize the CidArr based on the FieldName condition
  let CidArr;

  // Check if val is not null before mapping
  if (val !== null) {
    if (FieldName !== "ContactTag") {
      CidArr = val.map((elem) => elem.value);
    } else {
      CidArr = val.map((elem) => elem.cid);
    }
  } else {
    CidArr = []; // Set CidArr to an empty array if val is null
  }

  // Find the index of the element in Arr with the given id
  const indexed = Arr.findIndex((v) => v.id === id);

  // Update ContactTagIds and the corresponding searchvalue input field
  if (indexed !== -1) {
    Arr[indexed].ContactTagIds = CidArr.toString();
    Arr[indexed].FilterPropertyValue = CidArr.toString();
    document.getElementById("searchvalue" + id).value = CidArr.toString();

    // Update the FieldAttributes array
    SetFieldAttributes(Arr);
  }
};


//Add new Filter properties
const handleClick = () => {
  // Clone the FieldAttributes array to avoid mutating the original
  const StateArray = [...FieldAttributes];

  // Generate a unique id (you can use any method you prefer)
  const uniqueId = Math.floor(Math.random() * 1000000000);

  // Define the new element with default values
  const newElement = {
    id: uniqueId,
    FieldValue: "AccountCategory",
    ColumnName: "Account Category",
    Expression: "Contains",
    FilterPropertyValue: "",
    FilterPropertyValue1: "",
    IsCustom: false,
    IsDate: false,
    IsBool: false,
    NullCondition: false,
  };

  // Push the new element to the StateArray
  StateArray.push(newElement);

  // Update the FieldAttributes array
  SetFieldAttributes(StateArray);
};

//Remove handler properties
const handleRemoveClick = (id) => {
  if (FieldAttributes.length === 1) {
    // Display an error message when trying to remove the last filter property
    toast.error(
      <div className="toastsize">Filter <br /> Required filter property at least one</div>
    );
  } else {
    // Create a new array that excludes the element with the specified id
    const updatedAttributes = FieldAttributes.filter((element) => element.id !== id);

    // Update the FieldAttributes array
    SetFieldAttributes(updatedAttributes);
  }
};

//Filter validations
const FilterValidation = () => {
  // Initialize an array to store validation results
  let validationArr = [];
  
  FieldAttributes.forEach((newvalue) => {
    if (!newvalue.IsBool && !newvalue.IsSelect) {
      const searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value.trim();
      
      // Check if the search value is empty
      if (searchedVal1 === "") {
        validationArr.push(false);
      } else {
        validationArr.push(true);
      }
      
      const expressionVal = document.getElementById("expression" + newvalue.id).value;
      
      // Check if the expression is "between" and validate the second value
      if (expressionVal === "between" && !newvalue.IsSelect) {
        const filterPropertyValue2 = document.getElementById("EndsWith" + newvalue.id).value.trim();
        
        // Check if the second value is empty
        if (filterPropertyValue2 === "") {
          validationArr.push(false);
        } else {
          validationArr.push(true);
        }
      }
    }else if(newvalue.FieldValue === "LastCallResult"){
      let data;
      if(typeof(newvalue?.FilterPropertyValue) === "object"){
        data = newvalue?.FilterPropertyValue?.map(obj => obj.value).join(",")
      }else{
        data = newvalue?.FilterPropertyValue
      }
      
      if(data?.trim() === ""){
        validationArr.push(false);

      }else{
        validationArr.push(true);

      }
    }
  });
  
  // Check if any validation result is false (i.e., there is an invalid filter condition)
  return validationArr.includes(false);
};

const handleSelectChange = (index, selectedOption) => {
  const updatedFilterProperties = [...filterProperties];
  
  updatedFilterProperties[index].selectValue = selectedOption;

  // Check if selectedOption is defined before accessing its properties
  if (selectedOption) {
    // Create a comma-separated string from selected values
    const selectedValues = selectedOption.map((option) => option.value);
    updatedFilterProperties[index].FilterPropertyValue1 = selectedValues.join(',');
  } else {
    // Handle the case when selectedOption is undefined (e.g., no options selected)
    updatedFilterProperties[index].FilterPropertyValue1 = '';
  }

  SetFilterProperties(updatedFilterProperties);
};

//Apply Filter search
const filterSearch = async() => {
  // Check if filter validation is successful
  if (!FilterValidation()) {
    // Check if there are no filter properties added
    if (FieldAttributes.length <= 0) {
      toast.error(
        <div className="toastsize">
          Filter
          <br />
          Please add at least one filter property
        </div>
      );
    } else {
      // Initialize an array to store filter conditions
      const contactFilterSecArray = [];
      
      FieldAttributes.forEach((newvalue) => {
        const searchedVal1 = document.getElementById("searchvalue" + newvalue.id).value.trim();
        const expressionVal = document.getElementById("expression" + newvalue.id).value.trim();
        const ColumnName = document.getElementById("ColumnName" + newvalue.id).value;
        
        if (expressionVal === "between") {
          const searchedVal2 = document.getElementById("EndsWith" + newvalue.id).value;
          contactFilterSecArray.push({
            FilterPropertyName: ColumnName,
            ExpressionName: expressionVal,
            FilterPropertyValue1: searchedVal1,
            FilterPropertyValue2: searchedVal2,
            FieldType: newvalue.FieldFor,
            IsCustom: newvalue.IsCustom,
          });
        } else {
          contactFilterSecArray.push({
            FilterPropertyName: ColumnName,
            ExpressionName: expressionVal,
            FilterPropertyValue1: searchedVal1,
            FilterPropertyValue2: "", // Set to an empty string if not using "between"
            FieldType: newvalue.FieldFor,
            IsCustom: newvalue.IsCustom,
            CustomFieldID:newvalue.IsCustom ? newvalue?.CustomFieldID :null
          });
        }
      });

      SetPage(1)
      // Update filter parameters, close the filter box, and set flags
      await GetColdCallingContactDetailsUsingFilter(true,contactFilterSecArray,Page,RowsPerPage,SortField,SortValue)
      delete localStorage.DynamicColdCallingFilterID;
      SetSelectedFilterID(0)
      SetFilterParameters(contactFilterSecArray);
      SetOpenFilterBox(false);
      SetIsApply(true);
      SetIsFiltered(true);
    
      // Reset the filter attributes to default values
      SetFieldAttributes([
        {
          id: Math.floor(Math.random() * 1000000000),
          FieldValue: "AccountCategory",
          ColumnName: "Account Category",
          Expression: "Contains",
          FilterPropertyValue: "",
          FilterPropertyValue1: "",
          IsCustom: false,
          IsDate: false,
          IsBool: false,
          NullCondition: false,
        },
      ]);
      
      SetOpenAddFilter(false);
      toast.success( <div className="toastsize"> Filter <br/>Filter Applied Successfully.</div>);
    }
  } else {
    toast.error(<div className="toastsize"> Filter<br/>Property value cannot be empty</div>);
  }
};

//Getting default filter properties
const GetDefaultProperties = (FilterID) => {
  // SetLoader1(true); // Start loader
  Axios({
    url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetDefaultFilterDetails",
    method: "POST",
    data: { FilterID }
  })
    .then((res) => {
      if (res.data.StatusMessage === 'SUCCESS') {
        const convertedData = res.data.Data.map((item) => {
          let filterPropertyValue;
          if (item.FilterPropertyName === "LastCallResult") {
              filterPropertyValue = item.FilterPropertyValue1.split(',').map(value => ({ label: value, value }));
              filterPropertyValue.push({ label: "called", value: "called" });
          } else {
              filterPropertyValue = item.FilterPropertyValue1;
          }

          let options = [];
          if (item.FilterPropertyName === "TotalCalls") {
            options.push({ value: "LessThan", Label: "Less Than" });
          } else if (item.FilterPropertyName === "DaysSinceLastCall") {
            options.push({ value: "GreaterThanEqualTo", Label: "Greater Than Equal To" });
          } else if (item.FilterPropertyName === "LastCallResult") {
            options.push({ value: "EqualTo", Label: "Equals" },{ value: "NotEqualTo", Label: "Not Equal" });
          } else if (item.FilterPropertyName === "IsBlacklisted") {
            options.push({ value: "False", Label: "No" }, { value: "True", Label: "Yes" });
          } else if (item.FilterPropertyName === "ContactTag") {
            options.push({ value: "EqualTo", Label: "Equals" }, { value: "NotEqualTo", Label: "Not Equal" });
          }

          return {
            id: Math.floor(Math.random() * 1000000000),
            FieldValue: item.FilterPropertyName,
            ColumnName: item.FilterPropertyName,
            Expression: item.ExpressionName,
            FilterPropertyValue: filterPropertyValue,
            FilterPropertyValue1: item.FilterPropertyName === "LastCallResult" ? item.FilterPropertyValue2 : null,
            IsCustom: false,
            IsDate: false,
            IsNum: ["TotalCalls", "DaysSinceLastCall"].includes(item.FilterPropertyName),
            IsBool: item.FilterPropertyName === "IsBlacklisted",
            IsSelect: ["ContactTag", "LastCallResult"].includes(item.FilterPropertyName),
            NullCondition: false,
            IsDefault: true,
            Options: options,
            IsDefault: item.ColdCallingContactFilterID?.toString() == FilterDefaultID ? true : false
          };
        });
        let FilterData = convertedData.filter(item => !(item.FieldValue === "ContactTag" && item.Expression === "NotEqualTo"));
        console.log(FilterData)
        SetFieldAttributes(FilterData); // Update state with the converted data
      } else {
        console.error("Failed to retrieve data:", res.data.ErrorMessage);
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    })
    .finally(() => {
      // SetLoader1(false); // Ensure loader is stopped in both success and error cases
      SetIsLoading(false)
    });
};

//Reset filter
const ResetFilter = async() => {
  delete localStorage.DynamicColdCallingFilterID;
  document.getElementById("search").value = ""
  SetSearch("");
  SetRowsPerPage(100)
  SetPage(1)
  UnSelectAll()
  SetTempFilterID(FilterDefaultID);
  GetDefaultProperties(FilterDefaultID)
  SetSelectedFilterID(FilterDefaultID);
  SetSelectedEditFilter(FilterDefaultID);
  ColdCallingContactFilterGet(ClientID, UserID,CUserID);
  await GetColdCallingContactDetailsUsingFilter(true,[
    {
        "ExpressionName": "LessThan",
        "FieldType": "empty",
        "FilterPropertyName": "TotalCalls",
        "FilterPropertyValue1": "9",
        "FilterPropertyValue2": ""
    },
    {
        "ExpressionName": "GreaterThanEqualTo",
        "FieldType": "empty",
        "FilterPropertyName": "DaysSinceLastCall",
        "FilterPropertyValue1": "1",
        "FilterPropertyValue2": ""
    },
    {
        "ExpressionName": "EqualTo",
        "FieldType": "empty",
        "FilterPropertyName": "LastCallResult",
        "FilterPropertyValue1": "never been called,voicemail 1,voicemail 2,email only no voicemail,not available,leave no message / email",
        "FilterPropertyValue2": ""
    },
    {
        "ExpressionName": "False",
        "FieldType": "empty",
        "FilterPropertyName": "IsBlacklisted",
        "FilterPropertyValue1": "y",
        "FilterPropertyValue2": ""
    },
    {
        "ExpressionName": "EqualTo",
        "FieldType": "empty",
        "FilterPropertyName": "ContactTag",
        "FilterPropertyValue1": "",
        "FilterPropertyValue2": ""
    },
    {
        "ExpressionName": "NotEqualTo",
        "FieldType": "empty",
        "FilterPropertyName": "ContactTag",
        "FilterPropertyValue1": "",
        "FilterPropertyValue2": ""
    }
],1,100,SortField,SortValue)

  SetReset(true)  
  SetIsApply(false);
  // SetFilterParameters([]);
};

//Filter value handler method
const ValueHandler = (ID, FieldAlias, value) => {
  SetFieldAttributes((prevArray) =>
    prevArray.map((obj) => {
      if (obj.id === ID) {
        if (FieldAlias === "searchvalue") {
          return { ...obj, FilterPropertyValue: value };
        } else if (FieldAlias === "EndsWith") {
          return { ...obj, FilterPropertyValue1: value };
        }
      }
      return obj;
    })
  );
};

// Check filter name exist or not
const CheckFilterName = async () => {
  try {
    const FilterName = document.getElementById("FilterName").value.trim();

    if (!FilterName) {
      // Handle empty filter name
      return false;
    }

    const InputParameters = {
      ClientID: ClientID, // Assuming ClientID is defined elsewhere
      FilterName: FilterName,
    };

    const response = await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/CheckColdCallingContactFilterName",
      method: "POST",
      data: InputParameters,
    });

    if (response.data.StatusMessage === "SUCCESS") {
      // Check if filter name already exists
      return response.data.Data <= 0;
    } else {
      // Handle API error
      toast.error("Something went wrong in filter name exists API");
      return false;
    }
  } catch (error) {
    // Handle unexpected errors
    console.error("An error occurred:", error);
    return false;
  }
};

//save filter properties and filter name
const AddColdCallingContactFilter = async () => {
  try {
    const FilterName = document.getElementById("FilterName").value.trim();
    if (FilterName === "") {
      toast.error(<div className="toastsize">Filter<br />Please enter filter name</div>);
      return;
    }
    
    if (!(await CheckFilterName())) {
      toast.error(<div className="toastsize">Filter<br />Filter value already exists</div>);
      return;
    }
    
    if (FilterValidation()) {
      toast.error(<div className="toastsize">Filter<br />Property value cannot be empty</div>);
      return;
    }

    if (FieldAttributes.length <= 0) {
      toast.error(<div className="toastsize">Filter<br />Please add at least one filter property</div>);
      return;
    }
    
    const ContactFilterSecArray = FieldAttributes.map((newvalue) => {
      const searchedVal1 = document.getElementById(`searchvalue${newvalue.id}`).value;
      const expressionval = document.getElementById(`expression${newvalue.id}`).value;
      const ColumnName = document.getElementById(`ColumnName${newvalue.id}`).value;
      
      if (expressionval === "between") {
        const searchedVal2 = document.getElementById(`EndsWith${newvalue.id}`).value;
        return {
          FilterPropertyName:ColumnName,
          ExpressionName: expressionval,
          FilterPropertyValue1: searchedVal1,
          FilterPropertyValue2: searchedVal2,
          FieldType: newvalue.FieldFor,
          IsCustom: newvalue?.IsCustom,
          IsSelect: newvalue?.IsSelect,
          CustomFieldID: newvalue?.IsCustom ? newvalue?.CustomFieldID : null
        };
      } else {
        return {
          FilterPropertyName:ColumnName,
          ExpressionName: expressionval,
          FilterPropertyValue1: searchedVal1,
          FilterPropertyValue2: null,
          FieldType: newvalue.FieldFor,
          IsCustom:newvalue?.IsCustom,
          IsSelect:newvalue?.IsSelect,
          CustomFieldID:newvalue?.IsCustom ? newvalue?.CustomFieldID : null
        };
      }
    });

    const InputParameters = {
      ClientID: ClientID, // Assuming ClientID is defined elsewhere
      FilterName: FilterName,
      FilterParameters: ContactFilterSecArray,
      CreatedDate: new Date(),
      UserID: UserID, // Assuming UserID is defined elsewhere
      CreatedBy:CUserID
    };

    const res = await Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/SaveColdCallingContactFilter",
      method: "POST",
      data: InputParameters,
    });

    if (res.data.StatusMessage === "SUCCESS") {
      GettingColdCallingContactFilterList(ClientID, UserID)
      SetSelectedFilterID(res.data.Data?._id)
      SetSelectedEditFilter(res.data.Data?._id)
      SetFilterDeleteByID(res.data.Data?._id)
      SetFieldAttributes(ContactFilterSecArray);
      localStorage.setItem("DynamicColdCallingFilterID", res.data.Data?._id);
      GetColdCallingContact(ClientID, UserID, Role, CUserID, ContactFilterSecArray)
      //SetDataModelFilterStatusLoad("MainDropDown")
      SetOpenAddFilter(false);
      SetOpenFilterBox(false);
      SetIsApply(true);
      SetIsFiltered(true);
      // ColdCallingContactFilterGet(ClientID, UserID,CUserID);
      toast.success(<div className="toastsize">Filter<br />Filter Added Successfully.</div>);
    } else {
      toast.error("Error in saving the filter");
    }
  } catch (error) {
    console.error("An error occurred:", error);
    toast.error("An error occurred while adding the filter");
  }
};

//Open Edit model
const OpenEditFilterModel = () => {
  SetDataModelFilterStatusLoad("");
  // ColdCallingContactFilterGet(ClientID, UserID, CUserID),
  SetOpenEditFilterBox(true)
  SetSelectedEditFilter(SelectedFilterID)
  // GetDefaultProperties(SelectedFilterID,ClientID,UserID,CUserID)
  if(SelectedFilterID == 0){
    Getfilterpropertylist(FilterDefaultID,"EditFilter")
  }else{
    Getfilterpropertylist(SelectedFilterID,"EditFilter")
  }
}


//Update filter select box
const UpdateFilterSelectBox = (e) => {
        if(e.target.value == FilterDefaultID){
          SetIsSaveButton(false)
        }else{
          SetIsSaveButton(true)
        }
        SetSelectedEditFilter(e.target.value)
        
        SetFilterDeleteByID(e.target.value)
        // SetSelectedFilterID(e.target.value)
        SetTempFilterID(e.target.value)
        Getfilterpropertylist(e.target.value,"EditFilter");
}

// Get FilteProperty list with dropdown
const Getfilterpropertylist = (filterid,type) => {
      SetFilterID(filterid);
  
      let InputParameters = {
        ClientID: ClientID,
        FilterID: filterid,
        IsDeleted: false,
      };
      const rows4 = Axios({
        url: CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetColdCallingContactFilterProperty",
        method: "POST",
        data: InputParameters,
      });
      rows4.then((result) => {
        
        let FilteredArr = [];
        result?.data?.Data?.forEach((el, i) => {
          const selectedOption = CustomColumnData.find((option) => option.FieldValue === el.FilterPropertyName);
          
          el.IsDefault = el.IsDefault;
          el.id = i;
          el.IsSaved = true;
          if (el.FilterPropertyName == "LastSendDate" || el.FilterPropertyName == "LastValidationDate" || el.FilterPropertyName == "CreatedDate" || el.FilterPropertyName == "FollowUpDate" || el.FilterPropertyName == "LastCallDate" || el.FilterPropertyName == "FollowUpDate" 
          ) {
            el.IsDate = true;
          } else {
            if (selectedOption?.IsCustom) { el.IsCustom = true; el.CustomFieldID = selectedOption?._id;} else {  el.IsCustom = false; }
            el.IsDate = false;
          }
          if (el.FilterPropertyName == "IsBlacklisted") {
            el.IsBool = true;
          } else {
            el.IsBool = false;
          }
          if (
            el.FilterPropertyName == "CallCount" ||
            el.FilterPropertyName == "TotalSent" ||
            el.FilterPropertyName == "TotalHardBounce" ||
            el.FilterPropertyName == "TotalOpen" ||
            el.FilterPropertyName == "TotalReply" ||
            el.FilterPropertyName == "HoneyScore" ||
            el.FilterPropertyName == "TotalSoftBounce"
          ) {
            el.IsNum = true;
          } else {
            el.IsNum = false;
          }
          if (el.FilterPropertyName == "ContactTag") {
            el.IsSelect = true;
            el.Expression = "EqualTo";
            let ArrayObj = [];

            if(el.FilterPropertyValue1 !== "" && el.FilterPropertyValue1 !== "empty"){
              let ContactTagIds = el.FilterPropertyValue1?.split(",");
              ContactTagIds.forEach((elem) => {
                let Indexed = ContactTagsOptions?.findIndex((v) => v.value == elem);
                ArrayObj.push({
                  label: ContactTagsOptions[Indexed]?.label,
                  value: ContactTagsOptions[Indexed]?.value,
                  cid: ContactTagsOptions[Indexed]?.value,
                });
              });
            el.DefaultSelectIDs = ArrayObj;

            }else{
              ArrayObj = [];
            }

            
          }else {
            el.IsSelect = false;
            el.DefaultSelectIDs = [];
          }
          if ( el.ExpressionName == "IsNotBlank" || el.ExpressionName == "IsBlank") {
            el.NullCondition = true;
          } else {
            el.NullCondition = false;
          }
      
          el.IsDefault=String(el.ColdCallingContactFilterID) == String(FilterDefaultID)  ? true : false;
          if(String(el.ColdCallingContactFilterID) == localStorage.getItem("DefaultFilterID") ){
            if(el.FilterPropertyName == "LastCallResult"){
              let LCRData = el?.FilterPropertyValue1?.split(',');
              LCRData.push('called');
              el.FilterPropertyValue1 = LCRData?.join(",")
            }
          }

          el.FieldType = el.FieldType;
          
          FilteredArr.push(el);
        });
       let Data =  DataModel(FilteredArr,type)
       
       SetFitlerDataModel(Data)
       SetEditFieldAttributes(FilteredArr);
      });
};

//Applied Filter
const AppliedFilter = async(data,ID=null) => {
  SetDataModelFilterStatusLoad("MainDropDown")
      if(data?.length === 0){
      }else{
        SetAppliedFilterData(data);
        // ColdCallingContactFilterGet(ClientID, UserID,CUserID)
        await GetColdCallingContactDetailsUsingFilter(true,data,1,RowsPerPage,SortField,SortValue);
        localStorage.setItem("DynamicColdCallingFilterID", ID);
        SetSelectedFilterID(ID)
        SetSelectedEditFilter(ID)
        SetIsApply(true)
        SetOpenEditFilterBox(false)
        
      }
}

//Close Edit Filter Model 
const CloseModel = data => {
      SetOpenEditFilterBox(data);
      // SetDataModelFilterStatusLoad("MainDropDown")
      // ColdCallingContactFilterGet(ClientID, UserID,CUserID)
      // SetIsApply(false)
}

//Update filter Drop down
const UpdateFilterDropDown = (data) => {
    if(data){
      ColdCallingContactFilterGet(ClientID, UserID,CUserID)
    }
}

//Filter Drop Down Handler
const FilterDropDownHandler = async(e) =>{
  SetIsLoading(true)
  SetSearch("");
  SetPage(1)
  document.getElementById("search").value = "";
  if(e.target.value == '--Select--'){
    toast.error(<div className='toastsize'>Please select filter.</div>)
    SetSelectedFilterID(0)
    SetIsLoading(false)
  } 
  
  else {
    SetDataModelFilterStatusLoad("MainDropDown")
    SetFilterDeleteByID(e.target.value)
    SetSelectedFilterID(e.target.value);
    SetSelectedEditFilter(e.target.value);
    Getfilterpropertylist(e.target.value,"MainDropDown");
    localStorage.setItem("DynamicColdCallingFilterID", e.target.value);
    if(FilterDefaultID == e.target.value){
      SetReset(true);
    }else{
      SetReset(false);
    }
  }
}

const DataModel = async (filterProperties,type) => {
  // Check if any search value is empty excluding "ContactTag" properties
  const hasEmptySearchValue = filterProperties.some((filterProperty) => {
    // Skip validation for properties with FilterPropertyName as "ContactTag"
    if (filterProperty.FilterPropertyName === "ContactTag" || filterProperty.FilterPropertyName === "TimeZone") {
      return false;
    }
    // Check for empty FilterPropertyValue1 for other properties
    return filterProperty.FilterPropertyValue1 === "";
  });

  if (hasEmptySearchValue) {
    toast.error(<div className="toastsize">Filter <br/> Property value can not be an empty</div>);
    return;
  }

  // Create an array of filter objects
  const filters = filterProperties.map((filterProperty) => {
    const selectedOption = CustomColumnData.find((option) => option.FieldValue === filterProperty.FilterPropertyName);
    
    return {
      _id: filterProperty._id,
      FilterPropertyName: filterProperty.FilterPropertyName,
      ExpressionName: filterProperty.ExpressionName,
      FilterPropertyValue1: filterProperty.FilterPropertyValue1,
      FilterPropertyValue2: filterProperty.FilterPropertyValue2,
      FieldType: "empty", // You can set the FieldType based on your requirements
      IsCustom: filterProperty.IsCustom, // Set IsCustom based on the field's IsCustom value
      IsSelect: filterProperty.IsSelect, // Include IsSelect in the filter object
      CustomFieldID: filterProperty.IsCustom ? selectedOption?.CustomFieldID : null
    };
  });
  
  SetFieldAttributes(filters)
  SetFilterParameters(filters)
  if(type != "EditFilter"){
       GetColdCallingContactDetailsUsingFilter(true,filters,1,RowsPerPage,SortField,SortValue)
  }
};

  //Delete filter
  const DeleteFilterBtn = (DeleteHandle) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a filter.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          if(DeleteHandle === "UPDATE"){

            var data = {
              ID: SelectedEditFilter,
              LastUpdatedDate: new Date(),
              LastUpdatedBy: CUserID,
             // Role: Role,
            };
          }else{
            var data = {
              ID: SelectedFilterID,
              LastUpdatedDate: new Date(),
              LastUpdatedBy: CUserID,
             // Role: Role,
            };
          }
          
          Axios({
            url:
              CommonConstants.MOL_APIURL + "/cold_calling_contacts/GetColdCallingContactFilterPropertyDelete",
            method: "POST",
            data: data,
          }).then((res) => {
            if (res) {
              if ((res.statusText = "Ok")) {
                Swal.fire(
                  "Deleted!",
                  "filter deleted successfully.",
                  "success"
                );
                delete localStorage.DynamicColdCallingFilterID;
                if(DeleteHandle==="UPDATE"){
                  SetOpenEditFilterBox(false)
                }
               
                ColdCallingContactFilterGet(ClientID, UserID,CUserID);
              } else {
              }
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  // Apply Save As Filter Properties
  const ApplySaveAsFilterProperties = (NewFilterID,FilterProperties) => {
    try{
      GettingColdCallingContactFilterList(ClientID, UserID)
      SetSelectedFilterID(NewFilterID)
      SetSelectedEditFilter(NewFilterID)
      SetFieldAttributes(FilterProperties);
      localStorage.setItem("DynamicColdCallingFilterID", NewFilterID);
      GetColdCallingContact(ClientID, UserID, Role, CUserID, FilterProperties)
      SetIsApply(true);
    }catch(err){
      console.error(err)
    }
  }


  const handleDateChange = async (date, Id) => {
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const InputParameter = {
        ID: Id,
        UpdateDate: formattedDate, // Convert UTC date to ISO string
      };
      const Result = await Axios.post(CommonConstants.MOL_APIURL + "/cold_calling_contacts/UpdateFollowUpDate", InputParameter);
      if (Result.data.StatusMessage === "SUCCESS") {
        toast.success(<div className='toastsize'>Cold Calling Contact Details<br/>Update Follow Up Date</div>);
        GetColdCallingContactDetailsUsingFilter(true,FieldAttributes,Page,RowsPerPage,SortField,SortValue)
        // Update the state with the new follow-up date
        const updatedRows = Rows.map(row => {
          if (row._id === Id) {
            return { ...row, FollowUpDate1: localDate };
          }
          return row;
        });
  
        SetRows(updatedRows);
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  //Cancel Model call
  const CancelCallBtn = async() => {
    SetAddBurnerModel(false)
    if(IsCalled){
      location.reload();
    }
    setChecked({ "IsMobilePhone": false, "IsCompanyPhone": false, "IsDirectPhone": false, "IsOtherPhone1": false, "IsOtherPhone2": false });
  }

 // Date formatter for last call date YYYY-MM-DD to MM/DD/YYYY
const LastCallDateFormatter = (date) => {
  if (date && date.trim() !== "") {
      const parts = date.split("-");
      if (parts.length === 3) {
          let [yyyy, mm, dd] = parts;
          return `${mm}/${dd}/${yyyy}`;
      }
  }
  return "";
}

//Check all are selected or not
const CheckAllAreSelectedOrNot = () => {
  if(((Rows?.length <= selectedIds?.length) && (Rows?.length !== 0))){
    
    localStorage.setItem("SelectAllContact",true)
    return true;
  }else{
    localStorage.setItem("SelectAllContact",false)
    

    return false;
  }
}
  
  return (
    <>
    {IsLoading ? (
        <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}
      <Popup open={ShowExportPopUp}>
        <div>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header py-4 px-3">
                <div className="w-100 d-flex px-3">
                  <h5 className="mb-0">Email</h5>
                  <button
                    className="close"
                    onClick={() => CloseExportPopup(false)}
                  >
                    <span aria-hidden="true" className="la la-remove"></span>
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="modal-body">
                  <div className="col-sm-6 mb-5 px-0">
                    <div className="row mb-3">
                      <label className="col-lg-5 col-form-label text-left">
                        Email
                      </label>
                      <div className="col-lg-7">
                        <input
                          className="form-control m-input"
                          id="UserEmail"
                          data-val="true"
                          name="email"
                          placeholder='Enter email'
                          onBlur={handleEmailBlur}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          type="text"
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-top modal-content text-right">
                  <div className="row">
                    <div className="col-lg-12 pull-right">
                      <a
                        id="backtolist"
                        className="btn btn-secondary mr-2"
                        onClick={() => CloseExportPopup()}
                      >
                        Cancel
                      </a>
                      <button
                        id="submit"
                        disabled={DisabledContactExportBtn}
                        onClick={(e) => SubmitExport(e)}
                        className="btn btn-primary btn-lightgreen mr-1"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <Popup open={AddBurnerModel}>
        <div className="modal-black"></div>
        <div className="filterPopup largerPopup">
          <div className="paddingboxTerms">
            <div className="modal-header py-4 px-3">
              <div className="w-100 d-flex px-3">
                <h5 className="mb-0">Phone Number</h5>
                <button className="close"
                  onClick={CancelCallBtn}
                >
                  <span aria-hidden="true" class="la la-remove"></span>
                </button>
              </div>
            </div>
            <div className='p-3'>
              <div className="modal-body">
                <div className='col-sm-12 px-0'>
                  <div class="d-flex mb-3 alllabeset">
                    <FormControlLabel  
                      label="All"
                      control={
                        <Checkbox
                          checked={checkeddata["IsMobilePhone"] && checkeddata["IsCompanyPhone"] && checkeddata["IsDirectPhone"] && checkeddata["IsOtherPhone1"] && checkeddata["IsOtherPhone2"]}
                          onChange={handleChange1}
                        />
                      }
                    />
                    {children}

                  </div>
                </div>
              </div>
              <div className="border-top modal-content text-right">
                <div class="row">
                  <div class="col-lg-12 pull-right">

                    <a id="backtolist" class="btn btn-secondary mr-2"
                      onClick={CancelCallBtn}
                    >
                      Cancel
                    </a>
                    <a id="submit"
                      onClick={() => { CallBtn() }}
                      class="btn btn-primary btn-lightgreen mr-1">
                      call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <div className="row align-items-center py-1">
          <div class="col-md-4">
            <h4 class="lg-headertitle px-0 my-0">Cold Calling Contacts </h4>
          </div>
          <div className="col-md-8"> 
            
          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul>
              <li className='pt-2 pr-2'><b>Filter :</b></li>
              <li>
              <select value={SelectedFilterID != 0? SelectedFilterID : ''} className="selectboxdef" onChange={(e)=>FilterDropDownHandler(e)}>
                <option value="--Select--">--Select--</option>
                {ColdContactFilterList.map(item => (
                  <option key={item._id} value={item._id}>
                    {item.FilterName}
                  </option>
                ))}
                </select>
              </li>
              {
               (FilterDefaultID != SelectedFilterID ) &&  (SelectedFilterID != 0)? (
                  <li>
                  <Tooltip title="Delete Filter">
                    <a onClick={() => { DeleteFilterBtn("ADD") }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                      <i class="la la-icon-font-size-13 la-trash"></i>
                    </a>
                    </Tooltip>
                  </li>
                ) : (null)
              }

              <li onClick={() => { OpenFilterModel()}}>
                <Tooltip title="Add Filter">
                <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-plus"></i>
                </a>
                </Tooltip>
              </li>

              <li onClick={() => { OpenEditFilterModel()}}>
                <Tooltip title="Edit Filter">
                <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-pencil"></i>
                </a></Tooltip>
              </li>

              <Popup open={OpenFilterBox}>
                  <div className="modal-black"></div>
                  <div className="filterPopup largerPopup1000">
                    <div className="paddingboxTerms">
                      <div className="modal-header py-4 px-3">
                        <div className="w-100 d-flex px-3">
                          <h5 className="mb-0">Filter</h5>
                          <button className="close" onClick={() => CloseFilterModel()}>
                            <span aria-hidden="true" className="la la-remove"></span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="row px-4">
                          <div className="col-lg-8 row mb-4">
                            <label className="col-lg-3 col-form-label pl-0">
                              Filter Name
                            </label>
                            <div className="col-lg-9 d-flex">
                              <input
                                className="form-control m-input"
                                data-val-required="Please enter title"
                                id="FilterName"
                                name="FilterName"
                                placeholder="Filter Name"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="table-bordered table-scroll">  
                            <Table id="tab_logic" size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Property</TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right"></TableCell>  
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {FieldAttributes.map((itemcount, i) => (
                                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={itemcount.id}>
                                    
                                    <TableCell align="right">
                                      <select className="form-control m-input" id={"ColumnName" + itemcount.id}
                                        onChange={() => { ColumnhandledDropdownChange(itemcount.id, i);}}
                                        value={itemcount.FieldValue}
                                        autoComplete="off"
                                        disabled={itemcount.IsDefault ? true : false}
                                      >
                                        {CustomColumnData.map((el) => ( <option key={el.FieldValue} value={el.FieldValue}>{el.ColumnName}</option>))}
                                      </select>
                                    </TableCell>
                                    <TableCell>
                                    {itemcount.IsCustom == true ? (
                                                  <select className="form-control m-input" autocomplete="off">
                                                    <option id="text" value="Text"> {" "}Text </option>
                                                  </select>                            
                                      ) : null} 
                                    </TableCell> 
                                    {itemcount.IsDefault === true ? (
                                      <>
                                        <TableCell align="right">
                                          <select id={"expression" + itemcount.id} onChange={() => { ExpressionHandler(itemcount.id);}} defaultValue={itemcount.Expression} className="form-control m-input">
                                            {itemcount.Options.map((el, index) => ( <option key={index} value={el.value}>{el.Label}</option>))}
                                          </select>
                                        </TableCell>
                                        <TableCell align="right"> 
                                          {itemcount.IsSelect === true ? (
                                            <>
                                              {itemcount.FieldValue === "ContactTag" ? (
                                                <Select
                                                  closeMenuOnSelect={false}
                                                  hideSelectedOptions={false}
                                                  options={contactoptions}
                                                  defaultValue={itemcount.FilterPropertyValue}
                                                  id="ContactTagID"
                                                  isMulti
                                                  onChange={(val) => FilterhandleChangeContactOption(val,itemcount.id,"ContactTag")}
                                                />
                                              ) : (
                                                itemcount.FieldValue === "TimeZone" ? <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={LastCallResultDropDown}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="TimeZone"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val,itemcount.id,"TimeZone")
                                                }
                                              /> : (
                                                itemcount.FieldValue === "ContactStatus" ? <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={ContactStatus}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="ContactStatus"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val,itemcount.id,"ContactStatus")
                                                }
                                              /> : <Select
                                              closeMenuOnSelect={false}
                                              hideSelectedOptions={false}
                                              options={LastCallResultDropDown}
                                              defaultValue={itemcount.FilterPropertyValue}
                                              id="ContactTagID"
                                              isMulti
                                              onChange={(val) => FilterhandleChangeContactOption(val,itemcount.id,"LastCallResult")
                                              }
                                            />
                                              )
                                                
                                              )}
                                              {
                                                (typeof(itemcount.FilterPropertyValue) === "object") ?
                                                  <input type="hidden" readOnly id={"searchvalue" + itemcount.id} value={(itemcount.FilterPropertyValue || []).map(obj => obj.value).join(",")} className="form-control m-input required"/>
                                                :
                                                  <input type="hidden" readOnly id={"searchvalue" + itemcount.id} value={(itemcount.FilterPropertyValue || "")} className="form-control m-input required"/> 
                                                
                                              }
                                            </>
                                          ) : itemcount.IsBool === true ||
                                            itemcount.NullCondition === true ? (
                                            <input type="hidden" hidden={true} readOnly id={"searchvalue" + itemcount.id} value="y" className="form-control m-input required"/>
                                          ) : itemcount.IsDate === true ? (
                                            itemcount.Expression === "between" ? (
                                              <>
                                                <input type="date" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id,"searchvalue", e.target.value)} className="form-control m-input required"
                                                  value={itemcount.FilterPropertyValue}
                                                />
                                                <input type="date" id={"EndsWith" + itemcount.id} onChange={(e) =>ValueHandler( itemcount.id,"EndsWith", e.target.value)} className="form-control m-input required"
                                                  value={itemcount.FilterPropertyValue1}
                                                />
                                              </>
                                            ) : (
                                              <input type="date" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id, "searchvalue",e.target.value)} className="form-control m-input required" value={itemcount.FilterPropertyValue}/>
                                            )
                                          ) : itemcount.IsNum === true ? (
                                            itemcount.Expression === "between" ? (
                                              <>
                                                <input type="text" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id,"searchvalue", e.target.value )} className="form-control m-input required" value={itemcount.FilterPropertyValue}/>
                                                <input type="text" id={"EndsWith" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id,"EndsWith", e.target.value)} className="form-control m-input required" value={itemcount.FilterPropertyValue1}/>
                                              </>
                                            ) : (
                                              <input type="text" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler(itemcount.id, "searchvalue", e.target.value)} className="form-control m-input required"value={itemcount.FilterPropertyValue}/>
                                            )
                                          ) : (
                                            <input type="text" id={"searchvalue" + itemcount.id} onChange={(e) =>ValueHandler( itemcount.id, "searchvalue", e.target.value)} className="form-control m-input required" value={itemcount.FilterPropertyValue}/>
                                          )}
                                        </TableCell>
                                      </>
                                    ) : (
                                      <>
                                        <TableCell align="right">
                                          {itemcount.IsSelect === true ? (
                                            <select id={"expression" + itemcount.id}
                                              onChange={() => { ExpressionHandler(itemcount.id);}}
                                              className="form-control m-input"
                                            >
                                              {
                                                itemcount.FieldValue == "TimeZone" || itemcount.FieldValue == 'LastCallResult' || itemcount.FieldValue == 'ContactStatus'?  
                                                <>
                                                <option id="EqualTo" value="EqualTo"> Equals</option>
                                                <option id="NotEqualTo" value="NotEqualTo">Not Equal</option>
                                              </> : 
                                                <>
                                                  <option id="EqualTo" value="EqualTo"> Equals</option>
                                                  {/* <option id="NotEqualTo" value="NotEqualTo">Not Equal</option> */}
                                                </>
                                                
                                              }
                                              
                                            </select>
                                          ) : itemcount.IsBool === true ? (
                                            <div>
                                              <select id={"expression" + itemcount.id} onChange={() => { ExpressionHandler(itemcount.id);}}className="form-control m-input">
                                                <option id="false" value="False">No</option>
                                                <option id="true" value="True"> Yes</option>
                                              </select>
                                              <input type="hidden" readOnly id={"searchvalue" + itemcount.id} value="y" className="form-control m-input required"/>
                                            </div>
                                          ) : itemcount.IsNum === true ? (
                                            <>
                                            {itemcount.FieldValue == 'TotalCalls' ? 
                                            <select id={"expression" + itemcount.id} className="form-control m-input" onChange={() => { ExpressionHandler(itemcount.id);}}>
                                              <option id="Lessthan" value="LessThan"> Less Than</option>
                                            </select> : 
                                            <select id={"expression" + itemcount.id} className="form-control m-input" onChange={() => { ExpressionHandler(itemcount.id);}}>
                                              <option id="Equals" value="Equals"> Equals</option>
                                              <option id="Lessthan" value="LessThan"> Less Than</option>
                                              <option id="LessThanEqualTo" value="LessThanEqualTo">Less Than Equal To</option>
                                              <option id="greaterthan" value="GreaterThan"> Greater Than </option>
                                              <option id="greaterthanequalto" value="GreaterThanEqaulTo">Greater Than Equal To</option>
                                              <option id="between" value="between"> Between</option>
                                              {itemcount.FieldValue == "TotalSent" || itemcount.FieldValue == "TotalHardBounce" || itemcount.FieldValue == "TotalOpen" || itemcount.FieldValue == "TotalReply" || itemcount.FieldValue == "TotalSoftBounce"? <><option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option><option id="IsBlank" value="IsBlank"> Is Blank</option></>:null }
                                            </select>}
                                            </>
                                          ) : itemcount.IsDate === true ? (
                                            <select id={"expression" + itemcount.id} className="form-control m-input" onChange={() => {ExpressionHandler(itemcount.id);}}>
                                              <option id="Equals" value="Equals"> Equals </option>
                                              <option id="Lessthan" value="LessThan"> Less Than </option>
                                              <option id="LessThanEqualTo"value="LessThanEqualTo"> Less Than Equal To</option>
                                              <option id="greaterthan" value="GreaterThan"> Greater Than</option>
                                              <option id="greaterthanequalto" value="GreaterThanEqaulTo">Greater Than Equal To</option>
                                              <option id="between" value="between"> Between</option>
                                              <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                                              <option id="IsBlank" value="IsBlank"> Is Blank</option>
                                            </select>
                                          ) : (
                                            itemcount.IsCustom === true ? (
                                              <select id={"expression" + itemcount.id} onChange={() => { ExpressionHandler(itemcount.id); }} className="form-control m-input">
                                          
                                              <option id="Contains" value="Contains"> Contains </option>
                                              <option id="IsBlank" value="DoesNotEqual/DoesNotContain"> Does Not Equal / Does Not Contain</option>
                                              <option id="EqualTo" value="EqualTo"> Equals</option>
                                              <option id="IsNotBlank" value="IsNotBlank">Is Not Blank</option>
                                              <option id="IsBlank" value="IsBlank"> Is Blank</option>
                                            </select>
                                            ):
                                            ( 
                                              itemcount.FieldValue == 'Zip' || itemcount.FieldValue == 'ZoomID' || itemcount.FieldValue == 'BestPhone' || itemcount.FieldValue == 'BestPhoneStatus' || itemcount.FieldValue == 'Phone' || itemcount.FieldValue == 'PhoneStatus' || itemcount.FieldValue == 'Phone2' || itemcount.FieldValue == 'Phone2Status' || itemcount.FieldValue == 'Phone3' || itemcount.FieldValue == 'Phone3Status' || itemcount.FieldValue == 'Phone4' || itemcount.FieldValue == 'Phone4Status'|| itemcount.FieldValue == 'PRLLeadID'|| itemcount.FieldValue == 'DirectPhone' || itemcount.FieldValue == 'DirectPhoneExt' || itemcount.FieldValue == 'CompanyPhone' || itemcount.FieldValue == 'MobilePhone' || itemcount.FieldValue == 'ValidationScore'? 
                                            <select id={"expression" + itemcount.id} onChange={() => { ExpressionHandler(itemcount.id); }} className="form-control m-input">
                                          
                                            <option id="Contains" value="Contains"> Contains </option>
                                            <option id="EqualTo" value="EqualTo"> Equals</option>
                                            <option id="IsBlank" value="IsBlank"> Is Blank</option>
                                            <option id="IsNotBlank" value="IsNotBlank"> Is Not Blank</option>
                                          </select> :  itemcount.FieldValue == 'DaysSinceLastCall' ? <select id={"expression" + itemcount.id} onChange={() => { ExpressionHandler(itemcount.id); }} className="form-control m-input">
                                          
                                          <option id="greaterthanequalto" value="GreaterThanEqaulTo">Greater Than Equal To</option>
                                        </select>  :
                                        <select id={"expression" + itemcount.id} onChange={() => { ExpressionHandler(itemcount.id); }} className="form-control m-input">
                                        
                                          
                                          <option id="Contains" value="Contains"> Contains </option>
                                          <option id="EqualTo" value="EqualTo"> Equals</option>
                                          <option id="IsNotBlank" value="IsNotBlank"> Is Not Blank</option>
                                          {
                                            
                                            itemcount.FieldValue=="Company" || itemcount.FieldValue == "MobilePhone" || itemcount.FieldValue == "Name" || itemcount.FieldValue == "ContactCategory" || itemcount.FieldValue == "Title" || itemcount.FieldValue == "ValidationScore"? <option id="IsBlank" value="IsBlank"> Is Blank</option>:null
                                          }
                                       <option id="IsBlank" value="DoesNotEqual/DoesNotContain"> Does Not Equal / Does Not Contain</option>
                                          
                                        </select>)
                                          
                                          )}
                                        </TableCell>
                                        <TableCell align="right">
                                          {itemcount.IsSelect === true ? (
                                            <>
                                            {itemcount.FieldValue === "ContactTag" ? (
                                              <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={contactoptions}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="ContactTagID"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val, itemcount.id, "ContactTag")}
                                              />
                                            ) : itemcount.FieldValue === "TimeZone" ? (
                                              <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={TimeZone}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="TimeZone"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val, itemcount.id, "TimeZone")}
                                              />
                                            ) : itemcount.FieldValue === "ContactStatus" ? (
                                              <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={ContactStatus}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="ContactStatus"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val, itemcount.id, "ContactStatus")}
                                              />
                                            ) : itemcount.FieldValue === "CallResult" ? (
                                              <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={CallResultArr}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="CallResult"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val, itemcount.id, "CallResult")}
                                              />
                                            ) : (
                                              <Select
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={LastCallResultDropDown}
                                                defaultValue={itemcount.FilterPropertyValue}
                                                id="ContactTagID"
                                                isMulti
                                                onChange={(val) => FilterhandleChangeContactOption(val, itemcount.id, "LastCallResult")}
                                              />
                                            )}
                                            <input
                                              type="hidden"
                                              readOnly
                                              id={"searchvalue" + itemcount.id}
                                              className="form-control m-input required"
                                            />
                                          </>
                                          ) : itemcount.IsBool === true ||
                                            itemcount.NullCondition === true ? (
                                            <input type="hidden" hidden={true} readOnly id={"searchvalue" + itemcount.id} value="y" className="form-control m-input required" />
                                          ) : itemcount.IsDate === true ? (
                                            itemcount.Expression === "between" ? (
                                              <>
                                              <div className="col-lg-6 timedatepikker date-input">
                                               <input
                                                  type='text'
                                                  defaultValue={itemcount.FilterPropertyValue}
                                                  id={"searchvalue" + itemcount.id}
                                                  onChange={(e) => ValueHandler( itemcount.id, "searchvalue",e) }
                                                  className={`datetimepicker${itemcount.id} form-control`}
                                                />
                                            </div>
                                            <div className="col-lg-6 timedatepikker date-input">
                                              <input
                                                  type='text'
                                                  defaultValue={itemcount.FilterPropertyValue}
                                                  id={"EndsWith" + itemcount.id}
                                                  onChange={(e) => ValueHandler( itemcount.id, "EndsWith",e) }
                                                  className={`datetimepicker${itemcount.id} form-control`}
                                              />
                                            </div>
                                              
                                              </>
                                            ) : (
                                              
                                              <div className="col-lg-6 timedatepikker date-input">
                                                <input
                                                  type='text'
                                                  defaultValue={itemcount.FilterPropertyValue}
                                                  id={"searchvalue" + itemcount.id}
                                                  onChange={(e) => ValueHandler( itemcount.id, "searchvalue",e) }
                                                  className={`datetimepicker${itemcount.id} form-control`}
                                                />
                                              </div>
                                            )
                                          ) : itemcount.IsNum === true ? (
                                            itemcount.Expression === "between" ? (
                                              <>
                                                <input type="text" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id,"searchvalue",e.target.value)} className="form-control m-input required"/>
                                                <input type="text" id={"EndsWith" + itemcount.id} onChange={(e) =>ValueHandler( itemcount.id,"EndsWith", e.target.value)} className="form-control m-input required" />
                                              </>
                                            ) : (
                                              <input type="text" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id,"searchvalue",e.target.value)} className="form-control m-input required"/>
                                            )
                                          ) : (
                                            <input type="text" id={"searchvalue" + itemcount.id} onChange={(e) => ValueHandler( itemcount.id,"searchvalue", e.target.value)} className="form-control m-input required"/>
                                          )}
                                        </TableCell>
                                      </>
                                    )} 
                                    <TableCell align="left">
                                      <button type="button" title="Delete" id={"delete" + itemcount.id} className="btn btngroup btnhov-delete" onClick={() => { handleRemoveClick(itemcount.id);}}>
                                        <i className="la flaticon-delete-1"></i>
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table> 
                        </div>
                        <div className="row">
                          <div className="col-sm-12 text-left">
                            <button type="button" className="btn btngroup" value="ADD Row" onClick={handleClick}>
                              <i className="la la-plus la-icon-font-size-13"></i> Add Property
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => CloseFilterModel()}>
                          Cancel
                        </button>
                        <button id="m_emailInvite_submit" className="btn btn-primary btnColor" onClick={() => filterSearch()}>
                          Apply
                        </button>
                        <button id="m_emailInvite_submit" className="btn btn-primary btnColor" onClick={() => AddColdCallingContactFilter()}>
                          <i className="la la-save mr-2"></i> Save
                        </button>
                      </div>
                    </div>
                  </div>
              </Popup>



              <Popup open={OpenEditFilterBox}>
              <div>
                                      <div className="modal-black"></div>
                                      <div className="filterPopup largerPopup1000">
                                        <div className="paddingboxTerms">
                                          <div className="modal-header py-4 px-3">
                                            <div className="w-100 d-flex px-3">
                                              <h5 className="mb-0"> Edit Filter</h5>
                                              <button className="close" onClick={ ()=>{CloseModel(false);SetOpenEditFilterBox(false)}}>
                                                <span aria-hidden="true"  className="la la-remove" ></span>
                                              </button>
                                            </div>
                                          </div>
                                          <div className="px-3">
                                          <div className="modal-body">
                                            <div class="col-lg-8 row">
                                              <label class="col-lg-3 col-form-label pl-0">
                                                Filter Name
                                              </label>
                                              <div class="col-lg-9">
                                              <ul className='d-flex'>
                                               <li>
                                              <select value={SelectedEditFilter != 0? SelectedEditFilter : localStorage.getItem("DefaultFilterID")} 
                                                className="selectboxdef" 
                                                onChange={(e)=>UpdateFilterSelectBox(e)}>
                                                  {ColdContactFilterList.map(item => (
                                                    <option key={item._id} value={item._id}>
                                                      {item.FilterName}
                                                    </option>
                                                  ))}
                                              </select>
                                              </li>
                                              {
                                                (FilterDefaultID != SelectedEditFilter ) &&  (SelectedEditFilter != 0)? (
                                                    <li>
                                                    <Tooltip title="Delete">
                                                      <a onClick={() => { DeleteFilterBtn("UPDATE") }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                                        <i class="la la-icon-font-size-13 la-trash"></i>
                                                      </a>
                                                      </Tooltip>
                                                    </li>
                                                  ) : (null)
                                                }
                                              </ul>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
                                           <EditFilter 
                                            options={CustomColumnData} 
                                            data={EditFieldAttributes} 
                                            AppliedFilter={AppliedFilter}
                                            CloseModel={CloseModel}
                                            IsSaveButton={IsSaveButton}
                                            ContactTags={ContactTagsOptions}
                                            ClientID={ClientID}
                                            UserID={UserID}
                                            CUserID={CUserID}
                                            FilterID={SelectedEditFilter !== 0 ? SelectedEditFilter : localStorage.getItem("DefaultFilterID")}
                                            UpdateFilterDropDown = {UpdateFilterDropDown}
                                            ApplySaveAsFilterProperties={ApplySaveAsFilterProperties}
                                            ContactStatus = {ContactStatus}
                                            // IsDefault={(SelectedEditFilter === FilterDefaultID)}
                                            IsDefault={SelectedEditFilter !== 0 ? (SelectedEditFilter === FilterDefaultID) : localStorage.getItem("DefaultFilterID")}
                                           />
                                      </div>
                                      </div>
              </div>
              </Popup>


              <li>
                <Tooltip title="Add">
                <button onClick={AddBtn} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-user-plus"></i>
                </button>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Export">
                <button onClick={OpenExportPopup} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                  <i class="la la-icon-font-size-13 la-download"></i>
                </button>
                </Tooltip>
              </li>
              {Role == "ColdCallers" ?
                <li><Tooltip title="Call">
                  <a onClick={() => { MultipleCallDial() }} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                    <i class="la-icon-font-size-13 fa fa-phone-square"></i>
                  </a>
                </Tooltip>
                </li> : null}


            </ul>
          </div>
          </div>
      </div>
      
      <div className='bg-border-box p-3'>

      <div className="row"> 
          <div className="col"> 
              <div class="paddcols pt-0">  
     

      <div className="row mb-2">
        <div className="col-sm-12 col-md-6"></div>
        <div className="col-sm-12 col-md-12 full-right">
          {
          
          selectedIds.length === 0 ? <a onClick={SelectAll} className='select-all'>Select All</a> : <a onClick={UnSelectAll} className='select-all'>Unselect All</a>

          }
          
        </div>

        {(!Reset) && (FilterDefaultID != SelectedFilterID ) || IsApply ? (
            <div className="col-sm-12 col-md-12 full-right">
              <a onClick={ResetFilter} className='select-all'>Reset</a>
            </div>
          ) : null}
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}

            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
<TableContainer component={Paper}> 
              <StyleHeader isDataLoaded={isDataLoaded} />
              <Table className="table-ref" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Call Notes</TableCell>
              <TableCell>Follow Up Notes</TableCell>
              <TableCell><input name="select_all" type="checkbox" 
              // checked={(selectedIds.length != 0)&&(Rows.length != 0) ? true :false}
              checked={CheckAllAreSelectedOrNot()}
                  onChange={handleSelectAll} /></TableCell>
              {Role == "ColdCallers" ? <TableCell></TableCell> : null}
              <TableCell style={{ minWidth: '110px' }} onClick={()=>{SortData("Name"); setSortedColumn("Name");}}>Name <span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Name" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Name" ? "active" : null} />
                    </span></TableCell>
              <TableCell  onClick={()=>{SortData("Title"); setSortedColumn("Title");}}>Title<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Title" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Title" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("MobilePhone"); setSortedColumn("MobilePhone");}}>Mobile Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "MobilePhone" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "MobilePhone" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("DirectPhone"); setSortedColumn("DirectPhone");}}>Direct Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "DirectPhone" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "DirectPhone" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("DirectPhoneExt"); setSortedColumn("DirectPhoneExt");}}>Direct Phone Ext.<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "DirectPhoneExt" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "DirectPhoneExt" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("CompanyPhone"); setSortedColumn("CompanyPhone");}}>Company Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "CompanyPhone" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("OtherPhone1"); setSortedColumn("OtherPhone1");}}>Other Phone 1<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "OtherPhone1" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "OtherPhone1" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("OtherPhone2"); setSortedColumn("OtherPhone2");}}>Other Phone 2<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "OtherPhone2" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "OtherPhone2" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Company"); setSortedColumn("Company");}}>Company<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Company" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Company" ? "active" : null} />
                    </span></TableCell>
              <TableCell style={{ minWidth: '130px'}} onClick={()=>{SortData("LastCallResult"); setSortedColumn("LastCallResult");}}>Last Call Result<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "LastCallResult" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "LastCallResult" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Tagnames"); setSortedColumn("Tagnames");}}>Tag<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Tagnames" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Tagnames" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("TotalCalls"); setSortedColumn("TotalCalls");}}>Call Count<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "TotalCalls" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "TotalCalls" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("TimeZoneArea"); setSortedColumn("TimeZoneArea");}}>Time Zone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "TimeZoneArea" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "TimeZoneArea" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("LastCallDate"); setSortedColumn("LastCallDate");}}>Last Call Date<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "LastCallDate" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "LastCallDate" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Email"); setSortedColumn("Email");}}>Email<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Email" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Email" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("BestPhone"); setSortedColumn("BestPhone");}}>Best Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "BestPhone" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "BestPhone" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("BestPhoneStatus"); setSortedColumn("BestPhoneStatus");}}>Best Phone Status<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "BestPhoneStatus" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "BestPhoneStatus" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone1"); setSortedColumn("Phone1");}}>Phone<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone1" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone1" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone1Status"); setSortedColumn("Phone1Status");}}>Phone Status<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone1Status" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone1Status" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone2"); setSortedColumn("Phone2");}}>Phone2<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone2" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone2" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone2Status"); setSortedColumn("Phone2Status");}}>Phone2 Status<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone2Status" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone2Status" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone3"); setSortedColumn("Phone3");}}>Phone3<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone3" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone3" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone3Status"); setSortedColumn("Phone3Status");}}>Phone3 Status<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone3Status" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone3Status" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone4"); setSortedColumn("Phone4");}}>Phone4<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone4" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone4" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("Phone4Status"); setSortedColumn("Phone4Status");}}>Phone4 Status<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "Phone4Status" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "Phone4Status" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("PRLLeadID"); setSortedColumn("PRLLeadID");}}>PRL LeadID (SalesForce ID)<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "PRLLeadID" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "PRLLeadID" ? "active" : null} />
                    </span></TableCell>
              
              <TableCell onClick={()=>{SortData("FollowUpDate"); setSortedColumn("FollowUpDate");}}>Follow Up Date<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "FollowUpDate" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "FollowUpDate" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("CreatedDate"); setSortedColumn("CreatedDate");}}>Created Date<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "CreatedDate" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("ColdCallersName"); setSortedColumn("ColdCallersName");}}>Sales Development Reps<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "ColdCallersName" ? "active" : null} />
                    </span></TableCell>
              <TableCell onClick={()=>{SortData("SalesRep"); setSortedColumn("SalesRep");}}>Sales Rep<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "SalesRep" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "SalesRep" ? "active" : null} />
                    </span></TableCell>
                    <TableCell onClick={()=>{SortData("HoneyScore"); setSortedColumn("HoneyScore");}}>Honey Score<span className="shorting">
                      <ArrowUpward className={SortValue === 1 && sortedColumn === "HoneyScore" ? "active" : null} />
                      <ArrowDownward className={SortValue === -1 && sortedColumn === "HoneyScore" ? "active" : null} />
                    </span></TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows.length === 0 ? (<p className="text-center">No data available in table</p>):(
            Rows.map((row) => (
              <>
                <TableRow key={row._id} row={row} >
                  <TableCell>
                    <IconButton aria-label="expand row" size="small"
                      onClick={() =>
                        SetOpen((prev) => ({
                          ...prev,
                          [row._id]:
                            !prev[row._id],
                        }))
                      }
                    >


                      {Open[row._id] ? (
                        <i
                          className="fa fa-minus-circle minusl"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-plus-circle plusbl"
                          aria-hidden="true"
                        ></i>
                      )}
                    </IconButton></TableCell>
                  <TableCell> <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      SetFollowUpNotesOpen((prev) => ({
                        ...prev,
                        [row._id]:
                          !prev[row._id],
                      }))
                    }
                  >


                    {FollowUpNotesOpen[row._id] ? (
                      <i
                        className="fa fa-minus-circle minusl"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-plus-circle plusbl"
                        aria-hidden="true"
                      ></i>
                    )}
                  </IconButton></TableCell>
                  {/* <TableCell><input name="select" value={row} id={row._id} type="checkbox" onChange={(e) => HandleCall(e, row)} /></TableCell> */}
                  <TableCell><input name="select" type='checkbox' checked={selectedIds.includes(row._id)} onChange={() => handleRowCheckboxChange(row)}/></TableCell>
                  {Role == "ColdCallers" ? <TableCell><IconButton aria-label="expand row" size="small" onClick={(e) => OpenDialPopup(e, row)} ><i class="fa fa-phone-square col-white phoneicons"></i></IconButton></TableCell> : null}
                  <TableCell>{row.FirstName} {row.LastName}</TableCell>
                  <TableCell>{row.Title}</TableCell>
                  <TableCell>{row.MobilePhone}</TableCell>
                  <TableCell>{row.DirectPhone}</TableCell>
                  <TableCell>{row.DirectPhoneExt}</TableCell>
                  <TableCell>{row.CompanyPhone}</TableCell>
                  <TableCell>{row.OtherPhone1}</TableCell>
                  <TableCell>{row.OtherPhone2}</TableCell>
                  <TableCell>{row.Company}</TableCell>
                  <TableCell>{row.LastCallResult != null ? row.LastCallResult : "Never Been Called"}</TableCell>
                  <TableCell>{row.Tagnames}</TableCell>
                  <TableCell>{row.TotalCalls != null ? row.TotalCalls : 0}</TableCell>
                  <TableCell>{row?.TimeZoneArea}</TableCell>
                  {/* <TableCell>{row.LastCallDate != null ? moment(row.LastCallDate).format("MM/DD/YYYY"):""}</TableCell> */}
                  <TableCell>{LastCallDateFormatter(row.LastCallDate1)}</TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.BestPhone}</TableCell>
                  <TableCell>{row.BestPhoneStatus}</TableCell>
                  <TableCell>{row.Phone1}</TableCell>
                  <TableCell>{row.Phone1Status}</TableCell>
                  <TableCell>{row.Phone2}</TableCell>
                  <TableCell>{row.Phone2Status}</TableCell>
                  <TableCell>{row.Phone3}</TableCell>
                  <TableCell>{row.Phone3Status}</TableCell>
                  <TableCell>{row.Phone4}</TableCell>
                  <TableCell>{row.Phone4Status}</TableCell>
                  <TableCell>{row.PRLLeadID}</TableCell>
                  <TableCell className='custom-datepicker'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={false}  
                        value={row.FollowUpDate1}
                        minDate={moment()}
                        onChange={(newDate) => handleDateChange(moment(newDate), row._id)}
                        renderInput={(params) => <TextField {...params} />}
                        showToolbar={false} 
                      />
                    </LocalizationProvider></TableCell>
                  <TableCell>{row.CreatedDate != null ? moment(new Date(row.CreatedDate1).toDateString()).format("MM/DD/YYYY"):""}</TableCell>
                  <TableCell>{row.coldcallers?.ColdCallersName} {row.coldcallers?.LastName}</TableCell>
                  <TableCell>{row.SalesRep}</TableCell>
                  <TableCell>{row.HoneyScore}</TableCell>
                  <TableCell> <a
                    onClick={() => { EditBtn(row._id,row.Email);}}
                    className="btn-eyesicon"
                  >
                    <i className="la flaticon-edit-1 edit-icon"></i>
                  </a></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}
                  >
                    <Collapse
                      in={Open[row._id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {Open[row._id] && (
                        <Box margin={1} className="innertables">
                          {!row.CallNotes ? "No notes available" : row.CallNotes}
                        </Box>
                      )}
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={15}
                  >
                    <Collapse
                      in={FollowUpNotesOpen[row._id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {FollowUpNotesOpen[row._id] && (
                        <Box margin={1} className="innertables">
                          {!row.FollowUpNotes ? "No notes available" : row.FollowUpNotes}
                        </Box>
                      )}
                    </Collapse>
                  </TableCell>
                </TableRow>


              </>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>

      <div className="row">
            <div className="col dataTables_info">
              <p>
                Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of{" "}
                {Rlen} entries
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={CountPage}
                onChange={HandleChangePage}
                page={Page}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </div>
      </div> 
  </div>
  </div>
      {/* {Sflag ? (
        <div className="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )} */}
    </>
  );
}
