import React from "react";
const moment = require("moment");

import { connect } from "react-redux";
import { toast } from "react-toastify";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
import { userActions } from "../../../_actions";
import Sidebar from "../../../_components/clients/nav-sidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import { history } from "../../../_helpers";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";

import "react-toastify/dist/ReactToastify.css";
toast.configure();


class CAddSendingSchedularPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fields: {},
      Errors: {},
      ClientID: null,
      UserID: null,
      CUserID: null,
      BtnDisabled:false
    };
    this.BackBtn = this.BackBtn.bind(this);
    this.SaveBtn = this.SaveBtn.bind(this);
  }

  componentDidMount() {
    document.title = "Add Sending Schedules | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
  
    }
    document.getElementById("starttime").value = "06:00";
    document.getElementById("endtime").value = "18:00";
  }

//form validation
  FromValidation() {
    let formIsValid = true;
    let Errors = {};
    if (Object.entries(this.state.Fields).length > 0) {
      let Fields = this.state.Fields;
     
      if (this.state.statusAvailable != null) {
        formIsValid = false;
      }
      this.setState({ Errors: Errors });
    }
    let StartTime = document.getElementById('starttime').value.trim();
    let StartEnd = document.getElementById('endtime').value.trim();
   
    if(StartTime == ""){
      Errors['starttime'] ="The SchedularStartTime field is required."
      formIsValid = false;
    }
    if(StartEnd == ""){
      Errors['endtime'] ="The SchedularEndTime field is required."
      formIsValid = false;
    }
    const startTimeParts = StartTime.match(/^(\d+):(\d+)$/);
    const endTimeParts = StartEnd.match(/^(\d+):(\d+)$/);
    const startHour = parseInt(startTimeParts[1]);
    const startMinute = parseInt(startTimeParts[2]);
    const endHour = parseInt(endTimeParts[1]);
    const endMinute = parseInt(endTimeParts[2]);
    
    if (startHour > endHour || (startHour === endHour && startMinute <= endMinute)) {
      toast.error(<div className="toastsize">Add Sending Schedules <br/>Sending Schedules end time greater than start time.</div>)
      formIsValid = false;
    }
 
 
    this.setState({ Errors: Errors });
    return formIsValid;
  }

//change handler
  HandleChangeStart(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    let errors = this.state.Errors;
    if("starttime" === field ){
      if(e.target.value.trim() == ""){
          errors['starttime'] ="The SchedularStartTime field is required."
      }
    }
    
    this.setState({ Fields });
    this.setState({Errors:errors})
  }

  HandleChangeEnd(field, e) {
    let Fields = this.state.Fields;
    Fields[field] = e.target.value;
    let errors = this.state.Errors;
   if("endtime" === field){
      if(e.target.value.trim() == ""){
        errors['endtime'] ="The SchedularEndTime field is required."
       }
    }
    this.setState({ Fields });
    this.setState({Errors:errors})
  }
  
//Add Schedular
  SaveBtn(e) {
    this.setState({BtnDisabled:true});
    var ScheduleStartTime = document.getElementById("starttime").value;
      var SchedularEndTime = document.getElementById("endtime").value;
      
      
    e.preventDefault();
    if (this.FromValidation()) {
      var ScheduleStartTime = document.getElementById("starttime").value;
      var SchedularEndTime = document.getElementById("endtime").value;
      let UtcStartTime = moment(ScheduleStartTime,'hh:mm A').format("MM/DD/YYYY HH:mm A");
      let UtcEndTime = moment(SchedularEndTime,'hh:mm A').format("MM/DD/YYYY HH:mm A");

      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        ScheduleStartTime: ScheduleStartTime,
        ScheduleEndTime: SchedularEndTime,
        ScheduleStartUTCTime:UtcStartTime,
        ScheduleEndUTCTime:UtcEndTime,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        IsDeleted:false
      };
      Axios({
        url:
          CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularCheckExists",
        method: "POST",
        data: data,
      }).then((res) => {
        if(res.data.IsExists == true){
          toast.error(
              <div className="toastsize">Add Sending Schedules <br/>Sending Schedules already exists.</div>
                );
                this.setState({BtnDisabled:false});
        }else{
            Axios({
              url:
                CommonConstants.MOL_APIURL + "/sendingschedular/SendingSchedularAdd",
              method: "POST",
              data: data,
            }).then((res) => {
              if (res.data.StatusMessage == "SUCCESS") {
                toast.success(
              <div className="toastsize">Add Sending Schedules <br/>Sending Schedules added successfully.</div>
                );
                history.push("/CSendingSchedular");
              } else {
                toast.error(res.data.Message);
                this.setState({BtnDisabled:false});
              }
            });
        }
      });
      

    } else {
      this.setState({BtnDisabled:false});
    }
  }

//back
  BackBtn() {
    history.push("/CSendingSchedular");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome"> 
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle float-left">
                  Add Sending Schedules
                </h4>
              </div>
            </div>

            <div class="bg-white">
              <div className="row py-3 mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Schedule Start Time
                    </label>
                    <div class="col-lg-8">
                      <TextField
                        class="form-control m-input timerborder"
                        id="starttime"
                        onBlur={this.HandleChangeStart.bind(this, "starttime")}
                        type="time"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["starttime"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 boxsinput_group">
                  <div class="row max-cols d-flex align-items-baseline">
                    <label class="col-lg-4 view-lable-meet">
                      Schedule End Time
                    </label>
                    <div class="col-lg-8">
                      <TextField
                        class="form-control m-input timerborder"
                        onBlur={this.HandleChangeEnd.bind(this, "endtime")}
                        id="endtime"
                        type="time"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.Errors["endtime"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div class="row my-4">
                <div class="col-lg-12 pull-left">
                  <button
                    id="submit"
                    onClick={this.SaveBtn}
                    disabled={this.state.BtnDisabled}
                    class="btn btn-primary btn-lightgreen mr-1"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    onClick={this.BackBtn}
                    class="btn btn-secondary"
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCAddSendingSchedularPage = connect(
  mapState,
  actionCreators
)(CAddSendingSchedularPage);
export { connectedCAddSendingSchedularPage as CAddSendingSchedularPage };
