import React from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/MainSidebar";
import Footer from "../../_components/user/footer/footer";
import CoreWebVitals from "../../_components/user/CoreWebVitals/CoreWebVitals"
import SpeedIcon from '@material-ui/icons/Speed'
import { Button } from "@material-ui/core";
import Partnership from "../../_components/user/Partnership/Partnership";
import semrushLogo from "../../images/Google-Core-Web-Vitals.png";
import { GetUserDetails } from "../../_helpers/Utility";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';

class CoreWebVitalsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRerunCoreWebVitals: false,
      ClientID: null,
      Role: "",
      IsSeoReporting: false
    }
    this.RerunCoreWebVitals = this.RerunCoreWebVitals.bind(this);
  }

  componentDidMount() {
    const details = GetUserDetails();
    if (details != null) {
      this.setState({
        ClientID: details.ClientID,
        Role: details.Role
      });
    }
  }

  RerunCoreWebVitals() {
    this.setState({ IsRerunCoreWebVitals: true })

  }
  ReRunbuttonHideShow = (name) => {
    this.setState({ IsSeoReporting: name })
  };

  render() {
    return (
      <>

        <div className="adminmain mheight">
          {this.state.Role  === "Client" ?(<ClientSidebar className="" />):(<Sidebar className="" />)}
          <div className="bodyhome partner-space">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left pl-0">Core Web Vitals</h4>
              </div>
              {this.state.IsSeoReporting == true ?
                <div className="col padright">
                  <div className="disbox">
                    <a onClick={this.RerunCoreWebVitals} className="speededbtn btn  ">
                      <SpeedIcon className="mr-2" /> Re-run
                    </a>
                    <span></span>
                  </div>
                </div>
                : ""}
            </div>
            {/* <div className="col-xs-12 border-top pt-4 my-2 px-4">
              
            </div> */}
            <div className="row">
              <div className="col">
                <CoreWebVitals IsRerunCoreWebVitals={this.state.IsRerunCoreWebVitals} SeoReporting={this.ReRunbuttonHideShow} />
              </div>
            </div> 
            {/* <Partnership />  */}
            <div className="partner-box">
                <h6>In Partnership With:</h6> <img src={semrushLogo} width={100} />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCoreWebVitalsPage = connect(mapState, actionCreators)(CoreWebVitalsPage);
export { connectedCoreWebVitalsPage as CoreWebVitalsPage };
