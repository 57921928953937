import React from "react";
import Axios from "axios";

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";

import { CommonConstants } from "../../_constants/common.constants";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { history } from "../../_helpers";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../_helpers/Utility";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import loadingicon from "../../images/loading.gif";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const SortableItem = SortableElement(({ value }) => (
  <li className="boxcorder">{value}</li>
));

const SortableList = SortableContainer(({ newItems }) => {
  return (
    <ul>
      {newItems.map((value, Index) =>
        value.IsStatic == true ? (
          <SortableItem
            id={Index}
            key={`item-${Index}`}
            index={Index}
            value={value.ColumnName}
          />
        ) : (
          <SortableItem
            id={Index}
            key={`item-${Index}`}
            index={Index}
            value={value.ColumnName}
          />
        )
      )}
    </ul>
  );
});

class ContactCustomColumnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItems: [],
      newItems: [],
      items: [],
      page: {},
      isChecked: true,
      ClientID: null,
      UserID: null,
      BtnDisabled:false
    };
    
  }

  componentDidMount() {
    document.title = "Contact Custom Column | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
    }

    this.ContactCustomColumnGet();
  }

// contact custom column get
  ContactCustomColumnGet() {
    var str_in = {
      Page: 1,
      RowsPerPage: 100,
      TableName: "ContactPage",
      Field: "OrderBy",
      SortBy: 1,
      Type: "User",
      ClientID: this.state.ClientID,
    };
    
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/contactcuscol/ContactCustomColumnGet",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      const includeTrueArray = [];
      const includeFalseArray = [];
  
      result.data.PageData.forEach((value) => {
        if (value.IsInclude) {
          includeTrueArray.push(value);
        } else {
          includeFalseArray.push(value);
        }
      });
  
      const sortedIncludeTrueArray = includeTrueArray
        .slice()
        .sort((a, b) => a.OrderBy - b.OrderBy);
  
      this.setState({
        items: sortedIncludeTrueArray.concat(includeFalseArray),
        editItems: result.data.PageData,
        newItems: sortedIncludeTrueArray,
      });
      
      document.getElementById("hideloding").style.display = "none";

    });
  }

  // on sort end
  OnSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      newItems: arrayMoveImmutable(this.state.newItems, oldIndex, newIndex),
    });
  };

  // Save order data
  SaveOrder() {
    this.setState({BtnDisabled:true})
    let OrderBy = 0;
    for (let i = 0; i < this.state.newItems.length; i++) {
      this.state.newItems[i].OrderBy = OrderBy + 1;
      OrderBy++;
    }
    this.SaveData();
  }

  SaveData() {
   
    this.state.newItems.map((changeitem) => {
      this.state.editItems.map((edititems) => {
        if (changeitem._id == edititems._id) {
          edititems.OrderBy = changeitem.OrderBy;
        }
      });
    });
  
    Swal.fire({
      title: "Are you sure?",
      text: "you want to save contact custom columns.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
         document.getElementById("hideloding").style.display = "block";
        const str_in = {
          ContactcustomcolumnList: this.state.editItems,
          LastUpdatedBy:this.state.UserID
        };
        const rows1 = Axios({
          url: CommonConstants.MOL_APIURL + "/contactcuscol/ContactCustomcolumnOrderUpdate",
          method: "POST",
          data: str_in,
        });
        rows1.then((result) => {
          document.getElementById("hideloding").style.display = "none";
          if (result.data.StatusMessage === "SUCCESS") {
            toast.success(
              "Custom contact column order updated successfully.",
              "Contact custom column order"
            );
            history.push("/contacts");
          } else {
            toast.error(result.data.Message);
            this.setState({ BtnDisabled: false });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
        this.setState({ BtnDisabled: false });
      }
    });
  }
  


  // contact custom update list data 
  // ContactCustomUpdateList(e, value) {
    
  //   if (e.target.checked) {
  //     var tempArray = this.state.newItems;
  //     tempArray.push(value);
  //     this.setState({
  //       newItems: tempArray,
  //     });
      
  //     this.state.editItems.map((updateItems) => {
  //       if (value._id == updateItems._id) {
  //         updateItems.IsInclude = true;
  //       }
  //     });
  //   } else {
  //     //remove from array
  //     this.setState({
  //       newItems: this.state.items.filter(function (val) {
  //         return val !== value;
  //       }),
  //     });
  //     this.state.editItems.map((updateItems) => {
  //       if (value._id == updateItems._id) {
  //         updateItems.IsInclude = false;
  //       }
  //     });
  //   }
  // }
  ContactCustomUpdateList(e, value) {
    const isChecked = e.target.checked;
  
    this.setState((prevState) => {
      const updatedEditItems = prevState.editItems.map((updateItems) => {
        if (value._id === updateItems._id) {
          return { ...updateItems, IsInclude: isChecked };
        }
        return updateItems;
      });
  
      let newItemsWithChange = prevState.newItems.slice(); 
  
      if (isChecked) {
        if (!newItemsWithChange.includes(value)) {
          newItemsWithChange.push(value);
        }
      } else {
        newItemsWithChange = newItemsWithChange.filter(
          (item) => item._id !== value._id
        );
      }
  
      const sortedIncludeTrueArray = newItemsWithChange
        .filter((newfilterarray) => newfilterarray.IsInclude === true)
        .sort((a, b) => a.OrderBy - b.OrderBy);
        console.log(newItemsWithChange)
        const includeFalseArray = newItemsWithChange.filter(
          (newfilterarray) => newfilterarray.IsInclude === false
        );
        
        const originalarray = this.state.items;
        
        const reordereddata = originalarray.filter((ele) =>
          includeFalseArray.some((ele2) => ele._id === ele2._id)
        );
          console.log(this.state.items,includeFalseArray,reordereddata)
  
      const finalSortedItems = sortedIncludeTrueArray.concat(reordereddata);
  
      return {
        newItems: finalSortedItems,
        editItems: updatedEditItems,
      };
    });
  }

  // back button
  BackBtn() {
    history.push("/contacts");
  }

  // toggle change
  ToggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  render() {
    
    return (
      <>
         <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div>
        <div className="adminmain"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div class="bg-white bluechecked pt-0 my-3">
              <div className="row borderbtnm align-items-center mx-0">
                <div className="col-md-6 text-right">
                  <h4 className="headertitlepad lg-headertitle float-left">
                    Contact Custom Column
                  </h4>
                </div>
                <div class="col-md-6 pull-left text-right">
                  <button
                  disabled={this.state.BtnDisabled}
                    id="submit"
                    onClick={() => {
                      this.SaveOrder();
                    }}
                    class="btn btn-primary btn-lightgreen mr-2"
                  >
                    <i class="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    class="btn btn-secondary"
                    onClick={() => {this.BackBtn()}}
                  >
                    <i class="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

              <div className="row borderbtnm mx-0 align-items-center">
                <div className="col-md-6 text-right border-right">
                  <h4 className="headertitlepad xs-headertitle float-left">
                  All Column
                  </h4>
                </div>
                <div class="col-md-6 text-right border-right">
                  <h4 className="headertitlepad xs-headertitle float-left">
                  Column Ordering
                  </h4>
                </div>
              </div>

              <div className="row pt-2 mx-0">
                <div class="col-lg-6 boxsinput_group">
                  <div class="m-section">
                    <h3 class="m-section__heading">Fixed Column</h3>
                    <ul class="ddlist">
                      {this.state.items
                        .filter(
                          (contactcolumn) => contactcolumn.IsStatic == true
                        )
                        .map((value, Index) => (
                          <li key={`item-${Index}`}>
                            <div class="col-lg-6 row">
                              <label class="">
                                <input
                                  type="checkbox"
                                  id={Index}
                                  defaultChecked={value.IsInclude}
                                  onChange={(e) =>
                                    this.ContactCustomUpdateList(e, value)
                                  }
                                  key={`item-${Index}`}
                                  index={Index}
                                />
                                {value.ColumnName}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div class="m-section mb-0">
                    <h3 class="m-section__heading">Custom Column</h3>
                    <ul class="ddlist">
                    {this.state.items
                        .filter((value) => value.IsStatic !== true) // Exclude fixed columns
                        .map((value, Index) => (
                          <li key={`item-${Index}`}>
                            <div class="col-lg-6 row">
                              <label class="">
                                <input
                                  type="checkbox"
                                  id={Index}
                                  defaultChecked={value.IsInclude}
                                  onChange={(e) =>
                                    this.ContactCustomUpdateList(e, value)
                                  }
                                />
                                {value.ColumnName}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div class="col-lg-6 boxsinput_group">
                  <SortableList
                    newItems={this.state.newItems}
                    onSortEnd={this.OnSortEnd}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedContactCustomColumnPage = connect(
  mapState,
  actionCreators
)(ContactCustomColumnPage);
export { connectedContactCustomColumnPage as ContactCustomColumnPage };
