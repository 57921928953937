import Axios from 'axios';
import Swal from "sweetalert2";
const moment = require("moment");
import React, { useEffect } from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import StyleHeader from "../../user/StickyHeader/StickyHeader";
import { CommonConstants } from "../../../_constants/common.constants";
import { history } from "../../../_helpers";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
export default function CamapignCategoryTable() {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [UserIDby, SetUserIDby] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [IsLoading,SetIsLoading] = React.useState(true)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  useEffect(() => {
    document.title = "Campaign Categories | SalesHive";
    var getaccountcatelist = GetUserDetails();
    if (getaccountcatelist != null) {
      SetClientID(getaccountcatelist.ClientID);
      SetUserID(getaccountcatelist.ParentUserID);
      SetUserIDby(getaccountcatelist.ChildUserID);
      SetRole(getaccountcatelist.Role);
    }
    CampaignCategorylistGet(getaccountcatelist.ClientID,getaccountcatelist.ParentUserID,getaccountcatelist.Role);
  }, [ Search, Page, RowsPerPage, SortedBy]);

//Get Category List
  const CampaignCategorylistGet = (CID,UID,role) => {
    
    var str_in = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID:UID,
      Role:role
    };

    const Rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryListGet",
      method: "POST",
      data: str_in,
    });
    Rows1.then((result) => {
      
      console.log(result);
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    });
  };

  //Sort for fields 
  const SortData = (Feild) => {
    
    if(SortedBy == 1){
        SetSortedBy(-1)
        SetSortField(Feild)
        SetIsLoading(true)
    }else{
      SetSortedBy(1)
      SetSortField(Feild)
      SetIsLoading(true)
    }

  }

//Delete category
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a Category.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons:true,
    }).then((result) => {
      if (result.isConfirmed) {
        SetIsLoading(true)
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedBy: UserIDby,
          LastUpdatedDt: new Date(),
          Role:Role
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/Campaigncategory/CategoryDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.data.StatusMessage = "SUCCESS")) {
              Swal.fire(
                "Deleted!",
                "Category deleted successfully.",
                "success"
              );
              CampaignCategorylistGet(ClientID,UserID,Role);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

//Search category list
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var searchedVal = document.getElementById("search").value;
      SetSearch(searchedVal);
      CampaignCategorylistGet(ClientID,UserID,Role);
    }
  };

//change row
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
    CampaignCategorylistGet(ClientID,UserID,Role);
    SetIsLoading(true)
  };

  //Handle change page
  const HandleChangePage = (event, newPage) => {
    SetPage(newPage);
    CampaignCategorylistGet(ClientID,UserID,Role);
    SetIsLoading(true)
  };

//edit page
  const EditCategory = (id) => {
    history.push("/CEditCampaignCategories", id);
  };

  return (
    <div>
        {
      IsLoading === true ?     <div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div> : null
    }
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table class='table-ref w-100' stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell><a
                    onClick={() => {
                      SortData("Name");
                    }}>Name</a></TableCell>
                <TableCell><a
                    onClick={() => {
                      SortData("CDate");
                    }}>Created Date</a></TableCell>
                <TableCell>Total Campaign</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {Rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
              Rows?.map((row) => (
                <TableRow>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>
                    {row.CreatedDt}
                  </TableCell>
                  <TableCell>{row.TotalCampaign}</TableCell>

                  <TableCell>
                    <a
                      onClick={() => {
                        EditCategory(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}


