import React from "react";
import { connect } from "react-redux";

import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import MeetingStatusTable from '../../_components/clients/meetingstatuses/meetingstatuses';

import { userActions } from "../../_actions";
import { history } from "../../_helpers";

class CMeetingStatusPage extends React.Component {
  // add button
  AddBtn() {
    history.push("/CAddMeetingStatus");
  }

  // set order meeting status button
  MeetingStatusSetOrder() {
    history.push("/CMeetingStatusesOrder");
  }

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left pl-0 mb-0">Meeting Statuses</h4>
              </div> 
              <div className="col">
                <div className="listing-li float-right padb-15 pt-1">
                  <ul>
                    <li>
                      <a
                        onClick={this.MeetingStatusSetOrder.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 flaticon-settings"></i>
                        <span>Set Order</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn.bind(this)}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <div className="row mx-0 pb-3">
                <div className="col">
                  <MeetingStatusTable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer /> 
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCMeetingStatusPage = connect(mapState, actionCreators)(CMeetingStatusPage);
export { connectedCMeetingStatusPage as CMeetingStatusPage };