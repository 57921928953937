import React from "react";
import { toast } from "react-toastify";

import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientSidebar from '../../_components/clients/nav-sidebar/Sidebar';
import PhoneSettingsTabing from "../../_components/user/PhoneSetting/PhoneSettingsTabing";
import { GetUserDetails } from "../../_helpers/Utility";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class PhoneSettingsPage extends React.Component {
  constructor(props) {
    super(props);
     const urlParams = window.location.pathname;
      var NavigationParams = urlParams === "/defaultphonesettings" ? "Default" : ""
    this.state = {
      NavigationParams,
      ClientID: null,
      UserID: null,
      CUserID: null,
      Role: null,
    };
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
    }
  }


  render() {
    
    return (
      <>
        <div className="adminmain mheight"> 
        {this.state.Role  === "Client" ?(<ClientSidebar className="" />):(<Sidebar className="" />)}
          <div className="bodyhome">
          <div className="row">
              <div className="col">
              {
                this.state.NavigationParams === "Default" ?  <h4 className="pt-4 pb-3 mb-0 headertitle lg-headertitle float-left pl-0">Default Phone Settings</h4> : <h4 className="pt-4 pb-3 mb-0 headertitle lg-headertitle float-left pl-0">Phone Settings</h4>
              }

              </div>
            </div>
            <div className="row">
              <div className="col">
              <PhoneSettingsTabing />
            </div>
          </div>
        </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PhoneSettingsPage;
