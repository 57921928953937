import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import MeetingGoal from '../../_components/clients/meetinggoal/meetinggoal';
import Footer from "../../_components/user/footer/footer";

import { CSVReader, CSVDownloader } from "react-papaparse";
import { CommonConstants } from "../../_constants/common.constants";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
toast.configure();
import "react-toastify/dist/ReactToastify.css";

const Fileupload = require("../../_helpers/fileupload");
import loadingicon from "../../images/loading.gif";

class CMeetingGoalPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Files: [],
      Reset: false,
      CsvData: [],
      Dropboxdata: [],
      Filename: "",
      ExportData: [],
      ClientName: "",
      TotalDisplayCount: false,
      TotalResult: 0,
      TotalDuplicate: 0,
      TotalError: 0,
      TotalSuccess: 0,
      CName: "",
      ListUpdate:false,
      CountGoals:0,
      CountMeetings:0,
      CountCrushs:0,
      Role:null
    };
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.ImportCsv = this.ImportCsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.CancleCsv = this.CancleCsv.bind(this);
    this.Savecsv = this.Savecsv.bind(this);
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    document.getElementById("hidelod").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";

    var Details = GetUserDetails();
    if (Details != null) {
        this.state.ClientID = Details.ClientID;
        this.state.UserID = Details.ParentUserID;
        this.state.Role = Details.Role;
        this.state.UserIDby = Details.ChildUserID;
    }

    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });
    var MeetingGoalExportData = {
      ClientID: this.state.ClientID,
      Role:this.state.Role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalExport",
      method: "POST",
      data: MeetingGoalExportData,
    }).then((res) => {
      console.log(res.data.Data.length)
      this.setState({ ExportData: res.data.Data });
      this.setState({ ClientName: res.data.ClientName.Name });
    });

//Totalcount
    Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/CountsMeetingGoal",
      method: "POST",
      data: MeetingGoalExportData,
    }).then((res) => {
      this.setState({ CountGoals: res.data?.CountGoal});
      this.setState({ CountMeetings: res.data?.CountMeeting });
      this.setState({ CountCrushs: res.data?.CountCrush });
    });
  }

  componentDidUpdate() {}




  // reset csv btn
  Resetcsv() {
    this.setState({ Reset: true });
  }
  // handle dropdown
  HandleOnDrop(Data, FileInfo) {
    var Filename = FileInfo.name;
    this.setState({ Files: FileInfo });
    var sep = Filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      this.setState({ Reset: true });
      this.Resetcsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];

      this.setState({ Filename: csvfile });

      this.setState({ CsvData: Data });

      this.setState({ Dropboxdata: Data[0].data });
      document.getElementById("Week").value =
        this.state.Dropboxdata.indexOf("Week");
      document.getElementById("Goal").value =
        this.state.Dropboxdata.indexOf("Goal");
    }
  }

  HandleOnError(err, file, inputElem, reason) {}

  HandleOnRemoveFile(data) {}
  // import csv
  ImportCsv() {
    this.setState({ListUpdate:false})
    document.getElementById("import_csv").style.display = "block";
  }
  // map csv
  MapCsv() {
    document.getElementById("hidelod").style.display = "block";
    if (this.state.CsvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      this.state.Dropboxdata.map((dddd, index) => {});
      this.Resetcsv();
      document.getElementById("hidelod").style.display = "none";
    } else {
      this.Resetcsv();
      document.getElementById("hidelod").style.display = "none";
    }
  }

  // cancel csv
  CancleCsv() {
    this.Resetcsv();
    this.setState({ TotalDisplayCount: false });
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
  }

  // save csv
  Savecsv() {
    var Week = document.getElementById("Week").value;
    var Goal = document.getElementById("Goal").value;
    var CsvData = this.state.CsvData;
    Fileupload.getBase64(this.state.Files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];

      var myresult = {
        fields: "Coverimg",
        path: "./Content/UploadedFiles/UserMeetingGoal",
        file: _base64,
        imageName: this.state.Filename,
        filedata: CsvData,
        Goal: Goal,
        Week: Week,
        UserID: this.state.UserID,
        ClientID: this.state.ClientID,
        CreatedDate: new Date(),
        Role:this.state.Role
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/meetinggoal/filesave",
        method: "POST",
        data: myresult,
      }).then((res) => {
        toast.success(
          <div className="toastsize">Meeting Goal added successfully.</div>
        );
        this.setState({ListUpdate:true})
        document.getElementById("hidelod").style.display = "none";
        this.setState({ TotalDisplayCount: true });
        this.setState({ TotalResult: res.data.Total });
        this.setState({ TotalDuplicate: res.data.TotalDuplication });
        this.setState({ TotalError: res.data.TotalError });
        this.setState({ TotalSuccess: res.data.TotalSuccess });
        document.getElementById("map_attribute").style.display = "none";
        document.getElementById("import_csv").style.display = "none";
      });
    });
  }

  // meeting goal add page
  AddBtn() {
    history.push("/CAddMeetinggoal");
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  // meeitng owner export csv
  ExportCsv() {
    toast.success(
      <div>
        Data exported successfully.
      </div>
    )
  }

  render() {
    return (
      <>
        <div id="hidelod" className="loding-display">
          <img src={loadingicon} />
        </div>
        <div className="adminmain mheight"> 
          <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle float-left pl-0 mb-0">Meeting Goals </h4>
              </div>
            </div>
 
            <div className="row colfive">
              <div className="col-xl-4">
                <div class="whitebg boxcardcounter align-center">
                  <div class="d-flex">
                    <div className="contectboxes w-100">
                      <div class="line-title d-flex text-center justify-content-center">
                        <span class="clr-sky box-main-title">{this.state.CountGoals==undefined?0:this.state.CountGoals}</span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">
                          Total goals this quarter{" "}
                        </string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div class="whitebg boxcardcounter align-center">
                  <div class="d-flex">
                    <div className="contectboxes w-100">
                      <div class="line-title d-flex text-center justify-content-center">
                        <span class="clr-sky box-main-title">{this.state.CountMeetings==undefined?0:this.state.CountMeetings}</span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">
                          Total Meetings this quarter{" "}
                        </string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div class="whitebg boxcardcounter align-center">
                  <div class="d-flex">
                    <div className="contectboxes w-100">
                      <div class="line-title d-flex text-center justify-content-center">
                        <span class="clr-sky box-main-title">{this.state.CountCrushs==undefined?0:this.state.CountCrushs}%</span>
                      </div>
                      <div class="box-inner-title">
                        <string class="clr-sky">Crush %</string>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

            <div className="row">
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <a
                        onClick={this.ImportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.ExportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>
                          <CSVDownloader className="px-0"
                            data={this.state.ExportData}
                            filename={`${this.state.CName}-MeetingGoal`}
                            bom={true}
                          >
                            Export
                          </CSVDownloader>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          <div className="whitebg">
            <div className="portletbody mt-0" id="import_csv">
              <div className="row">
                <div class="col-xl-8 offset-xl-2">
                  <h3 class="uploadheadingtitle">Import Meeting Goal</h3>
                  <div className="uplodfilesbox">
                    <CSVReader
                      accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                      onDrop={this.HandleOnDrop}
                      onError={this.HandleOnError}
                      addRemoveButton
                      removeButtonColor="#659cef"
                      onRemoveFile={this.HandleOnRemoveFile}
                      isReset={this.state.Reset}
                    >
                      <span>Select .CSV file</span>
                      <p>Add meeting goal data from csv file to import.</p>
                    </CSVReader>
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2">
                  <div class="alignright">
                    <a onClick={this.MapCsv} class="btn btn-primary btnColor">
                      <i class="la la-upload"></i> Upload
                    </a>
                    <a onClick={this.CancleCsv} class="btn btn-secondary">
                      <i class="la flaticon-cancel"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="portletbody px-4 portletshadow m-3" id="map_attribute">
              <div className="row col-xs-12">
                <h4 className="headertitle float-left">Map attributes</h4>
              </div>
              <div className="row">
                <div class="form-group m-form__group row col-lg-6 mx-0">
                  <div class="col-lg-5 nopadding">
                    <div>
                      <select
                        class="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Week">
                          Week
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2 text-center">
                    <span class="fa fa-arrows-h fa-2x "></span>
                  </div>
                  <div class="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Week"
                        class="csvcontent form-control  m-input"
                        autocomplete="off"
                      >
                        <option value="not mapped">Not Mapped</option>
                        {this.state.Dropboxdata.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group m-form__group row col-lg-6 mx-0">
                  <div class="col-lg-5 nopadding">
                    <div>
                      <select
                        class="dbcontent form-control  m-input"
                        disabled="disabled"
                      >
                        <option selected="" value="Goal">
                          Goal
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2 text-center">
                    <span class="fa fa-arrows-h fa-2x"></span>
                  </div>
                  <div class="col-lg-5 nopadding">
                    <div>
                      <select
                        id="Goal"
                        class="csvcontent form-control  m-input"
                        autocomplete="off"
                      >
                        <option value="not mapped">Not Mapped</option>
                        {this.state.Dropboxdata.map((dbdata, index) => (
                          <option value={index}>{dbdata}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 border-top pt-4 my-2 px-4">
                  <a
                    onClick={this.Savecsv}
                    class="btn btn-primary btn-lightgreen"
                  >
                    <i class="la la-save"></i>Save
                  </a>
                </div>
              </div>
            </div>
          </div>

            {this.state.TotalDisplayCount == true ? (
              <div className="portletbody px-4" id=" ">
                <div className="row col-xs-12">
                  <h4 className="headertitle float-left">Results</h4>
                </div>

                <div className="col-xs-12">
                  <div class="row colfive">
                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title d-flex text-center justify-content-center mb-2">
                              <span class="box-main-title font-primary">
                                {this.state.TotalResult}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title d-flex text-center justify-content-center mb-2">
                              <span class="box-main-title font-success">
                                {this.state.TotalSuccess}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total success</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title d-flex text-center justify-content-center mb-2">
                              <span class="box-main-title font-danger">
                                {this.state.TotalError}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total error</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="shadowcard boxcardcounter">
                        <div class="d-flex align-center">
                          <div class="contectboxes w-100">
                            <div class="line-title d-flex text-center justify-content-center mb-2">
                              <span class="box-main-title font-warning">
                                {this.state.TotalDuplicate}
                              </span>
                            </div>
                            <div class="box-inner-title">
                              <string class="clr-sky">Total Duplicate</string>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div class="bg-white">
              <div className="row mx-0 pb-3">
                <div className="col">
                  <MeetingGoal listupdate={this.state.ListUpdate} updateFromChild={this.UpdateFromChild} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};


const connectedCMeetingGoalPage = connect(mapState, actionCreators)(CMeetingGoalPage);
export { connectedCMeetingGoalPage as CMeetingGoalPage };