import React, { useEffect, useState } from "react";
const moment = require("moment");
const Fileupload = require("../../../_helpers/fileupload");
import Axios from "axios";
import loadingicon from "../../../images/loading.gif";
import Pagination from "@material-ui/lab/Pagination";

import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";

import "froala-editor/js/plugins.pkgd.min.js";

import { CommonConstants } from "../../../_constants/common.constants";

import { GetUserDetails } from "../../../_helpers/Utility";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { GetClientDetails } from "../../../_helpers/Utility";
import $ from "jquery";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../user/StickyHeader/StickyHeader";


export default function AccountAllTable({
  FeatureName,
  FeatureFieldID,
  dater,
  updateData,
}) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("FeatureValue");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [PUserID, SetPUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [Errors, SetErrors] = React.useState({});
  const [VariableSet, SetVariableSet] = useState("");
  const [AFID, SetAFID] = useState("");
  const [ExportData, SetExportData] = useState([]);
  const [Reset, SetReset] = React.useState(false);
  const [ResetBulk, SetResetBulk] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);
  const [FeatureNameForDrop, SetFeatureNameForDrop] = React.useState(null);
  const [VariableAvailable, SetVariableAvailable] = React.useState(null);
  const [VariableAvailableEdit, SetVariableAvailableEdit] =
    React.useState(null);
  const [IsExist, SetIsExist] = React.useState(false);
  const [OldVariableName, SetOldVariableName] = React.useState("");
  const [CName, SetCName] = React.useState("");

  // total display
  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [AddBtnDisable, SetAddBtnDisable] = React.useState(false);
  const [EditBtnDisable, SetEditBtnDisable] = React.useState(false);
  const [IsBulkDeleteDisabled, SetIsBulkDeleteDisabled] = React.useState(false);
  const [MapAttrBox, SetMapAttrBox] = useState(false);
  const [IsLoading, SetIsLoading] = useState(true);
  const [IsDisableActive, SetIsDisableActive] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);



  useEffect(() => {
    document.title = "Call To Action | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ChildUserID);
      SetRole(Details.Role);
      SetPUserID(Details.ParentUserID);
    }
  }, []);
  useEffect(() => {
    var Details = GetUserDetails();
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    CallToActionGet(Details.ClientID, Details.ParentUserID, dater);
    CallToActionExportData(Details.ClientID, Details.ParentUserID, FeatureFieldID, FeatureName)
  }, [Search, Page, RowsPerPage, dater]);

  // show editor btn
  const AddBtn = () => {
    document.getElementById("text-editor").style.display = "block";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("text-editor-edit").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("delete_map_attribute").style.display = "none";

  };
  const CallToActionExportData = (CID, UID, FID, FName) => {
    const Inputparameters = {
      ClientID: CID,
      UserID: UID,
      FeatureFieldID: parseInt(FID),
      FeatureField: FName,
    };

    Axios({
      url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionExport",
      method: "POST",
      data: Inputparameters,
    }).then((Res) => {
      SetExportData(Res.data.Data);
    });
  }
  // Hide editor btn
  const CancelBtn = () => {
    document.getElementById("text-editor").style.display = "none";
    document.getElementById("FeatureName").value = "";
    SetErrors({});
    SetVariableAvailable(null)
  };

  // Hide editor btn for edit
  const CancelBtnEdit = () => {
    document.getElementById("text-editor-edit").style.display = "none";
    document.getElementById("FeatureNameEdit").value = "";
    SetErrors({});
    SetVariableAvailable(null)
  };
  // form validation for add
  const FromValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var FeatureName = document.getElementById("FeatureName").value.trim();
    var IsExist = await CallToActionCheckExits(FeatureName);

    if (FeatureName == "") {
      FormIsValid = false;

      Errors["FeatureName"] = "Please enter value";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    SetErrors(Errors);
    return FormIsValid;
  };
  // check variable set exists edit
  const CallToActionCheckExits = async (FeatureName) => {
    var VariableData = {
      ClientID: ClientID,
      UserID: UserID,
      FeatureFieldID: parseInt(FeatureFieldID) ,
      FeatureValue: FeatureName,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionCheckExits",
      method: "POST",
      data: VariableData,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        SetVariableAvailable(resdata.data.Data[0]?.FeatureValue);
        SetIsExist(true);
        return true;
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };

  // check variable set exists add
  const CallToActionCheckExitsForEdit = async (FeatureValue) => {
    var VariableData = {
      ClientID: ClientID,
      UserID: UserID,
      FeatureFieldID: parseInt(FeatureFieldID),
      FeatureValue: FeatureValue,
    };
    var resdata = await Axios({
      url:
        CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionCheckExits",
      method: "POST",
      data: VariableData,
    });
    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Data.length > 0) {
        if (OldVariableName == FeatureValue) {
          SetIsExist(true);
          return false;
        } else {
          SetVariableAvailableEdit(resdata.data.Data[0]?.FeatureValue);
          SetIsExist(true);
          return true;
        }
      } else {
        SetIsExist(false);
        return false;
      }
    }
  };
  // form validation for edit
  const FromValidationForEdit = async () => {
    let FormIsValid = true;
    let Errors = {};
    var FeatureNameEdit = document.getElementById("FeatureNameEdit").value;

    var IsExist = await CallToActionCheckExitsForEdit(FeatureNameEdit);

    if (FeatureNameEdit == "") {
      FormIsValid = false;

      Errors["FeatureNameEdit"] = "Please enter value";
    }
    if (IsExist == true) {
      FormIsValid = false;
    }
    SetErrors(Errors);
    return FormIsValid;
  };

  // add variable sets
  const VariablesSetAdd = async () => {
    SetAddBtnDisable(true)
    Errors.FeatureValue = null;

    let Final_flag = await FromValidation();
    if (Final_flag == true) {
  
      SetIsLoading(true)
      var FeatureValue = document.getElementById("FeatureName").value.trim();
      let InputParaMeter = {
        ClientID: ClientID,
        UserID: PUserID,
        FeatureFieldID: FeatureFieldID,
        FeatureValue: FeatureValue,
        IsActive: true,
        CreatedDate: new Date(),
        CreatedBy: UserID,
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionAdd",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          SetAddBtnDisable(false)
          CancelBtn();
          toast.success(
            <div className="toastsize">
              {FeatureName}
              <br />
              {FeatureName} added successfully.{" "}
            </div>
          );
          CallToActionGet(ClientID, PUserID, dater);
          updateData(true);
          document.getElementById("text-editor").style.display = "none";
          CallToActionExportData(ClientID, PUserID, FeatureFieldID, FeatureName)
          SetIsLoading(false)


        } else {
          toast.error(res.data.Message);
          SetAddBtnDisable(false)
          SetIsLoading(false)

        }
      });
    } else {
      SetAddBtnDisable(false)
      SetIsLoading(false)

    }

  };
  //get admin list
  const CallToActionGet = (CID, UID, DateRange) => {
    SetIsLoading(true)
    setIsDataLoaded(true);
    var InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      FeatureFieldID: FeatureFieldID,
      DateRange: DateRange,
      Role: Role,
    };
    const AutomatorFeatureData = Axios({
      url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionGet",
      method: "POST",
      data: InputParameter,
    });
    AutomatorFeatureData.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);

    });


  };

  //change display rows
  const ChangeRowSelectedCallToAction = (e) => {
    debugger
   /// console.log(e.target.value,"vcv")
    SetRowsPerPage(Number(e.target.value));
    SetPage(1);
    // SetIsLoading(true)

  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
        SetIsLoading(true)

      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      // document.getElementById("hideloding").style.display = "block";
      SetIsLoading(true)

    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("Search").value;
    var SerchBox;
    if (SearchedVal == "") {
      SetIsLoading(true)
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        FeatureFieldID: FeatureFieldID,
        DateRange: dater,
        Role: Role,
      };

      const AutomatorFeatureData = Axios({
        url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionGet",
        method: "POST",
        data: InputParameter,
      });
      AutomatorFeatureData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    } else {
      SetIsLoading(true)
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Search: Search,
        Type: "User",
        ClientID: ClientID,
        UserID: PUserID,
        FeatureFieldID: FeatureFieldID,
        DateRange: dater,
        Role: Role,
      };

      const AutomatorFeatureData = Axios({
        url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionGet",
        method: "POST",
        data: InputParameter,
      });
      AutomatorFeatureData.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false)
      });
    }
  };

  // Delete for auto feature
  const DeleteBtn = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: `you want to delete a ${FeatureName}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          CallToActionID: ID,
          LastUpdatedDate: new Date(),
          LastUpdatedBy: PUserID,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                FeatureName + " deleted successfully.",
                "success"
              );
              CallToActionGet(ClientID, PUserID, dater);
              updateData(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // automator feature  edit
  const EditBtn = (CallToActionID) => {
    // Set loading state to true when you start the operation
    SetIsLoading(true);
    SetAFID(CallToActionID);
    document.getElementById("text-editor-edit").style.display = "block";
    document.getElementById("text-editor").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("delete_map_attribute").style.display = "none";

    var InputParaMeter = {
      CallToActionID: CallToActionID,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionGetIdByData",
      method: "POST",
      data: InputParaMeter,
    }).then((res) => {
      if (res.data.StatusMessage === "SUCCESS") {
        document.getElementById("FeatureNameEdit").value = res.data.Data[0].FeatureValue;
        SetVariableSet(res.data.Data[0].FeatureValue);
        SetOldVariableName(res.data.Data[0].FeatureValue);
      } else {
        toast.error(res.data.Message);
      }

      // Set loading state to false when the operation is completed
      SetIsLoading(false);
    });
  };

  // save button for automator update
  const CallToActionUpdate = async () => {
    var FinalValidation = await FromValidationForEdit();

    if (FinalValidation == true) {
      SetEditBtnDisable(true)
      SetIsLoading(true)
      var FeatureValueEdit = document.getElementById("FeatureNameEdit").value;
      var AutomatorFeatureData = {
        CallToActionID: AFID,
        FeatureValue: FeatureValueEdit,
        LastUpdatedBy: UserID,
        LastUpdatedDate: new Date(),
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionUpdate",
        method: "POST",
        data: AutomatorFeatureData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              {FeatureName}
              <br />
              {FeatureName} updated successfully.{" "}
            </div>
          );
          CancelBtnEdit();
          SetEditBtnDisable(false)
          CallToActionGet(ClientID, PUserID, dater);
          updateData(true);
          SetIsLoading(false)

        } else {
          toast.error(res.data.Message);
          SetEditBtnDisable(false)
          SetIsLoading(false)

        }
      });
    } else {
      SetEditBtnDisable(false)
      SetIsLoading(false)
    }


  };

  //Update Status is active
  const UpdateStatus = (CallToActionID, Flag) => {
    SetIsDisableActive(true)
    const InputParameters = {
      CallToActionID: CallToActionID,
      IsActive: Flag,
      LastUpdatedBy: UserID,
      LastUpdatedDate: new Date(),
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/call_to_actions/CallToActionIsActive",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res) {
        if ((res.statusText = "Ok")) {
          if (res.data.Data.IsActive == true) {
            CallToActionExportData(ClientID, PUserID, FeatureFieldID, FeatureName)
            toast.success(
              <div className="toastsize">
                CallToAction
                <br />
                {FeatureName} CallToAction value Active successfully.{" "}
              </div>
            );
            SetIsDisableActive(false)
            CallToActionGet(ClientID, PUserID, dater);
            updateData(true);
          } else {
            CallToActionExportData(ClientID, PUserID, FeatureFieldID, FeatureName)
            toast.success(
              <div className="toastsize">
                CallToAction <br /> {FeatureName} CallToAction value Inactivated
                successfully.{" "}
              </div>
            );
          }
          CallToActionExportData(ClientID, PUserID, FeatureFieldID, FeatureName)
          CallToActionGet(ClientID, PUserID, dater);
          updateData(true);
          SetIsDisableActive(false)
        } else {
          SetIsDisableActive(false)
        }
      }
    });
  };

  // export csv
  const ExportCsv = () => {
    if (FeatureFieldID == 1) {
      toast.success(
        <div className="toastsize">
          Day <br />
          Data exported successfully.
        </div>
      );
    } else if (FeatureFieldID == 2) {
      toast.success(
        <div className="toastsize">
          Time <br />
          Data exported successfully.
        </div>
      );
    } else if (FeatureFieldID == 3) {
      toast.success(
        <div className="toastsize">
          Length <br />
          Data exported successfully.
        </div>
      );
    } else if (FeatureFieldID == 4) {
      toast.success(
        <div className="toastsize">
          Action <br />
          Data exported successfully.
        </div>
      );
    }
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("text-editor").style.display = "none";
    document.getElementById("text-editor-edit").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    document.getElementById("delete_map_attribute").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
  };

  // import csv
  const VariableSetImportCsv = () => {
    SetMapAttrBox(false);
    document.getElementById("import_csv").style.display = "block";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("text-editor").style.display = "none";
    document.getElementById("text-editor-edit").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    document.getElementById("delete_map_attribute").style.display = "none";
  };

  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;

    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      SetReset(true);

      ResetCsv();
    } else {
      var csvfile =  sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''))
      setfilename(csvfile);

      setcsvData(filteredData);

      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => { };

  // handle on remove file
  const HandleOnRemoveFile = (data) => { };

  // map csv
  const VariableSetImportCsvMapCsv = () => {
    SetReset(!Reset)
    SetMapAttrBox(true);
    SetIsLoading(true)

    if (csvData.length != 0) {
      document.getElementById("map_attribute").style.display = "block";
      document.getElementById("import_csv").style.display = "block";
      SetReset(!Reset)
      SetMapAttrBox(false);
      SetIsLoading(false)

      DropboxData.map((dddd, index) => { });
    } else {
      SetReset(!Reset)
      SetMapAttrBox(false);
      document.getElementById("map_attribute").style.display = "none";
      document.getElementById("import_csv").style.display = "block";
      document.getElementById("total_div_display").style.display = "none";
      toast.error(<div> {FeatureName}<br />Please select file.</div>)
      SetIsLoading(false)

    }
    hideCSVHeaderOptions();
  };

  // map csv for delte
  const DeleteForMapCsv = () => {
    SetResetBulk(!ResetBulk)
    if (csvData.length != 0) {
      document.getElementById("delete_map_attribute").style.display = "block";
      SetResetBulk(!ResetBulk)
      DropboxData.map((dddd, index) => { });
    } else {
      SetResetBulk(!ResetBulk)
      toast.error(<div> {FeatureName}<br />Please select file.</div>)
    }
    hideCSVHeaderOptions();
  };

  // show bulk delete
  const DeleteBtnBulk = () => {
    document.getElementById("delete_csv").style.display = "block";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("text-editor").style.display = "none";
    document.getElementById("text-editor-edit").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    document.getElementById("map_attribute").style.display = "none";
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");

    if (Name == FeatureName) {
      SetFeatureNameForDrop(value);
    } else {
      SetFeatureNameForDrop(value);
    }
    hideCSVHeaderOptions();
  });

  // cancel button import
  const VariableSetCancelBtn = () => {
    SetReset(!Reset)
    SetMapAttrBox(false);
    setcsvData([]);
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_csv").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    SetFeatureNameForDrop(null)
  };

  const BulkDeleteCancelBtn = () => {
    SetResetBulk(!ResetBulk)
    document.getElementById("delete_map_attribute").style.display = "none";
    document.getElementById("delete_csv").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
    SetFeatureNameForDrop(null)
  };
  // handle change
  const HandleChange = () => {

    var FeatureName = document.getElementById("FeatureName").value.trim();
    if (FeatureName != "" && FeatureName.indexOf(" ") != "") {
      SetVariableAvailable(null);
      SetErrors({});
      CallToActionCheckExits(FeatureName);
    } else {
      SetVariableAvailable(null);
      SetErrors({});
    }
  };

  // handle change
  const HandleChangeForEdit = () => {
    var FeatureName = document.getElementById("FeatureNameEdit").value.trim();
    if (FeatureName != "" && FeatureName.indexOf(" ") != "") {
      SetVariableAvailableEdit(null);
      SetErrors({});
      CallToActionCheckExitsForEdit(FeatureName);
    } else {
      SetVariableAvailableEdit(null);
      SetErrors({});
    }
  };
  // save csv
  const Savecsv = () => {
    var FeatureField = document.getElementById(FeatureName).value;

    if (FeatureField === "Not Mapped") {
      toast.error(<div>{FeatureName}<br />{FeatureName} not mapped!</div>)
    } else {
      SetIsLoading(true)
      SetMapAttrBox(true)
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParaMeter = {
          fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserCallToAction",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          FeatureValue: FeatureField,
          UserID: PUserID,
          ClientID: ClientID,
          CreatedBy: UserID,
          CreatedDate: new Date(),
          FeatureFieldID: FeatureFieldID,
          ValueFrom: "Variable",
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/call_to_actions/calltoactionimportfile",
          method: "POST",
          data: InputParaMeter,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                {FeatureName} data import successfully.
              </div>
            );
            SetReset(!Reset)
            SetMapAttrBox(true)
            document.getElementById("map_attribute").style.display = "block";
            document.getElementById("import_csv").style.display = "block";
            document.getElementById("total_div_display").style.display = "block";
            SetTotal(res.data.Total);
            SetTotalDuplicate(res.data.TotalDuplication);
            SetTotalSucess(res.data.Totalsuccess);
            SetTotalError(res.data.TotalError);
            setcsvData([]);
            SetFeatureNameForDrop(null)

            CallToActionExportData(ClientID,PUserID, FeatureFieldID, FeatureName)
            CallToActionGet(ClientID,PUserID, dater);
            SetIsLoading(false)
          } else {
            toast.error(res.data.Message);
            SetReset(!Reset)
            SetMapAttrBox(true)
            SetIsLoading(false)
            CallToActionExportData(ClientID,PUserID, FeatureFieldID, FeatureName)
            CallToActionGet(ClientID,PUserID, dater);
          }
        });
      });
    }

  };

  // bulk delete variables set
  const BulkDeleteSave = () => {
    var FeatureField = document.getElementById(FeatureName).value;
    if (FeatureField === "Not Mapped") {
      toast.error(<div>{FeatureName}<br />{FeatureName} not mapped!</div>)
    } else {
      SetIsBulkDeleteDisabled(true)
      var CsvData = csvData;
      Fileupload.getBase64(files, (result) => {
        var rs = result;
        var splirs = rs.split("base64,");
        var _base64 = splirs[1];

        var InputParaMeter = {
          fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserCallToAction",
          File: _base64,
          ImageName: filename,
          FileData: CsvData,
          FeatureValue: FeatureField,
          UserID: PUserID,
          ClientID: ClientID,
          LastUpdatedBy: UserID,
          LastUpdatedDate: new Date(),
          FeatureFieldID: FeatureFieldID,
        };

        Axios({
          url:
            CommonConstants.MOL_APIURL +
            "/call_to_actions/CallToActionBulkDelete",
          method: "POST",
          data: InputParaMeter,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">{FeatureName} deleted successfully.</div>
            );
            SetResetBulk(!ResetBulk)
            document.getElementById("delete_map_attribute").style.display =
              "none";
            document.getElementById("delete_csv").style.display = "none";

            SetIsBulkDeleteDisabled(false)
            SetFeatureNameForDrop(null)

            setcsvData([]);
            CallToActionExportData(ClientID,PUserID, FeatureFieldID, FeatureName)
            CallToActionGet(ClientID,PUserID, dater);
            SetIsLoading(true)
          } else {
            toast.error(res.data.Message);
            SetResetBulk(!ResetBulk)
            SetIsBulkDeleteDisabled(false)

            setcsvData([]);
            SetIsLoading(true)
          }
        });
      });
    }

  };
  return (
    <>
      {
        IsLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : null
      }

      <div className="row borderbtnm mx-0">
        <div className="col-md-6">
          <h4 className="headertitlebd xs-headertitle mt-0 pl-0">{FeatureName}</h4>
        </div>
        <div className="col-md-6">
          <div className="listing-li float-right padb-15 iconbox-pad">
            <ul>
              <li className="px-1">
                <Tooltip title="Add">
                  <a
                    onClick={AddBtn}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-plus"></i>
                  </a>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Import">
                  <a
                    onClick={VariableSetImportCsv}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la la-icon-font-size-13 la-upload"></i>
                  </a>
                </Tooltip>
              </li>
              <li className="px-1">
                <CSVDownloader
                  className="px-0"
                  data={ExportData}
                  filename={CName + "-" + FeatureName}
                  bom={true}
                >
                  <Tooltip title="Export">
                    <a
                      onClick={ExportCsv}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>
                    </a>
                  </Tooltip>
                </CSVDownloader>
              </li>
              <li>
                <Tooltip title="Delete">
                  <a
                    onClick={DeleteBtnBulk}
                    className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                  >
                    <i class="la flaticon-delete-1"></i>
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Import Csv for variable sets*/}
      <div className="portletbody" id="import_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import {FeatureName}</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add {FeatureName} data from csv file to import.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a
                onClick={VariableSetImportCsvMapCsv}
                className="btn btn-primary btnColor"
              >
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={VariableSetCancelBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow p-4 m-1 map_attribute"
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left pl-0">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="ListImports">
                    {FeatureName}
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id={FeatureName}
                  disabled={MapAttrBox}
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    FeatureNameForDrop == null
                      ? DropboxData.indexOf(FeatureName)
                      : FeatureNameForDrop
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-xs-12 border-top pt-4 mt-2">
            <button
              onClick={Savecsv}
              disabled={MapAttrBox}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>

      {/* Total Details for csv */}
      <div
        className="portletbody px-4"
        id="total_div_display"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Results</h4>
        </div>

        <div className="col-xs-12">
          <div class="row colfive">
            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-primary">{Total}</span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-success">
                        {TotalSucess}
                      </span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total success</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-danger">
                        {TotalError}
                      </span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total error</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="shadowcard boxcardcounter">
                <div class="d-flex align-center">
                  <div class="contectboxes w-100">
                    <div class="line-title mb-2">
                      <span class="box-main-title font-warning">
                        {TotalDuplicate}
                      </span>
                    </div>
                    <div class="box-inner-title">
                      <string class="clr-sky">Total Duplicate</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bulk delete for variables set */}
      <div className="portletbody" id="delete_csv" style={{ display: "none" }}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Bulk Delete {FeatureName}</h3>
            <div className="uplodfilesbox">
              <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={ResetBulk}
              >
                <span>Select .CSV file</span>
                <p>Add {FeatureName} data from csv file to bulk delete.</p>
              </CSVReader>
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2">
            <div className="alignright">
              <a onClick={DeleteForMapCsv} className="btn btn-primary btnColor">
                <i className="la la-upload"></i> Upload
              </a>
              <a onClick={BulkDeleteCancelBtn} className="btn btn-secondary">
                <i className="la flaticon-cancel"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mapped div */}
      <div
        className="portletbody portletshadow p-4 m-1 map_attribute"
        id="delete_map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left pl-0">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group row mx-0">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  disabled="disabled"
                >
                  <option selected="" value="ListImports">
                    {FeatureName}
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  id={FeatureName}
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    FeatureNameForDrop == null
                      ? DropboxData.indexOf(FeatureName)
                      : FeatureNameForDrop
                  }
                >
                  <option>Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-xs-12 border-top pt-4 mt-2">
            <button
              disabled={IsBulkDeleteDisabled}
              onClick={BulkDeleteSave}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>
      {/* Add flora editor code */}
      <div
        className="col-lg-12 whitebg pt-2 pb-5 px-0"
        style={{ display: "none" }}
        id="text-editor"
      >
        <div className="row">
          <div className="col-lg-6 boxsinput_group">
            <div className="row max-cols mx-0">
              <label className="col-lg-4 view-lable-meet">{FeatureName}</label>
              <div className="col-lg-8">
                <input
                  className="form-control m-input"
                  onBlur={HandleChange}
                  name="FeatureName"
                  type="text"
                  id="FeatureName"
                  data-val="true"
                  data-val-required=""
                  placeholder=""
                />
                <span style={{ color: "red" }}>{Errors["FeatureName"]}</span>
                {VariableAvailable && (
                  <span style={{ color: "red" }}>Value already exist.</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="row border-top mx-0 border-bottom py-4 mt-4">
          <div class="text-right col-lg-12 px-4">
            <button
              id="submitAdd"
              disabled={AddBtnDisable}
              onClick={VariablesSetAdd}
              class="btn btn-primary btn-lightgreen mr-1"
            >
              <i class="la la-save"></i> Save
            </button>
            <button class="btn btn-secondary" onClick={CancelBtn}>
              <i class="la flaticon-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </div>
      {/* Edit flora editor code */}
      <div
        className="col-lg-12 whitebg pt-2 pb-5 px-0"
        style={{ display: "none" }}
        id="text-editor-edit"
      >
        <div className="row">
          <div className="col-lg-6 boxsinput_group">
            <div className="row max-cols mx-0">
              <label className="col-lg-4 view-lable-meet">{FeatureName}</label>
              <div className="col-lg-8">
                <input
                  className="form-control m-input"
                  id="FeatureNameEdit"
                  onBlur={HandleChangeForEdit}
                  data-val="true"
                  data-val-required=""
                  placeholder=""
                  type="text"
                />
                <span style={{ color: "red" }}>
                  {Errors["FeatureNameEdit"]}
                </span>
                {VariableAvailableEdit && (
                  <span style={{ color: "red" }}>Value already exist.</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="row border-top border-bottom py-4 mt-4">
          <div class="text-right col-lg-12 px-4">
            <button
              id="submitedit"
              disabled={EditBtnDisable}
              onClick={CallToActionUpdate}
              class="btn btn-primary btn-lightgreen mr-1"
            >
              <i class="la la-save"></i> Save
            </button>
            <button class="btn btn-secondary" onClick={CancelBtnEdit}>
              <i class="la flaticon-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="row padt-25 px-3">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={(e)=>{ChangeRowSelectedCallToAction(e)}}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              defaultValue={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="Search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div class='px-3'>
      <div className="table-bordered">
      <TableContainer component={Paper}>
      <StyleHeader isDataLoaded={isDataLoaded} />
          <Table class='table-ref w-100' aria-label="collapsible table">
          <TableHead>
              <TableRow>
                <TableCell>Active</TableCell>
                <TableCell onClick={() => {
                      SortData("FeatureValue");
                    }}>
                  {" "}
                  <a
                    
                  >
                    {FeatureName}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "FeatureValue" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "FeatureValue" ? "active" : null} />
                    </span>
                  </a>{" "}
                </TableCell>
                <TableCell onClick={() => {
                      SortData("Sent");
                    }}>
                  <a
                    
                  >
                    {" "}
                    Sent{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Sent" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Sent" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                {/* <TableCell>
                  <a
                    onClick={() => {
                      SortData("FeatureValue");
                    }}
                  >
                    {" "}
                    Tracked{" "}
                  </a>
                </TableCell> */}
                <TableCell onClick={() => {
                      SortData("Reply");
                    }}>
                  <a
                    
                  >
                    {" "}
                    Replies{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Reply" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Reply" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell    onClick={() => {
                      SortData("Intrested");
                    }}>
                  <a
                 
                  >
                    {" "}
                    Interested{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "Intrested" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "Intrested" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell     onClick={() => {
                      SortData("OpenPercentage");
                    }}>
                  <a
                
                  >
                    {" "}
                    Open %{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "OpenPercentage" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "OpenPercentage" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell      onClick={() => {
                      SortData("ReplyPercentage");
                    }}>
                  <a
               
                  >
                    {" "}
                    Reply %{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "ReplyPercentage" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "ReplyPercentage" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell   onClick={() => {
                      SortData("IntrestedPercentage");
                    }}>
                  <a
                  
                  >
                    Interested %{" "}
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "IntrestedPercentage" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "IntrestedPercentage" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell onClick={() => {
                      SortData("CreatedDate");
                    }}>
                  <a
                    
                  >
                    Created Date
                    <span className="shorting">
                      <ArrowUpward className={SortedBy === 1 && SortField === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={SortedBy === -1 && SortField === "CreatedDate" ? "active" : null} />
                    </span>
                  </a>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length == 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow>
                    <TableCell>
                      <div class="toggleswich">
                        <input
                          type="checkbox"
                          disabled={IsDisableActive}
                          onChange={() => {
                            UpdateStatus(row._id, !row.IsActive);
                          }}
                          class="checktogle"
                          checked={row?.IsActive}
                        />
                        <b class="switch">
                          <span class="checkion"></span>
                          <span class="uncheckion"></span>
                        </b>
                        <b class="track"></b>
                      </div>
                    </TableCell>
                    <TableCell>{row?.FeatureValue}</TableCell>
                    <TableCell>{row?.Sent  === undefined || null ? 0 : row?.Sent}</TableCell>
                    {/* <TableCell>{row?.Tracked  === undefined || null ? 0 : row?.Tracked}</TableCell> */}
                    <TableCell>{row?.Reply  === undefined || null ? 0 : row?.Reply}</TableCell>
                    <TableCell>{row?.Intrested  === undefined || null ? 0 : row?.Intrested}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          <>
                            <span>
                              Opens: {row?.Open === undefined || null || NaN ? "0%" : row?.Open}
                            </span>
                            <br />
                            <span>
                              Tracked:{" "}
                              {row.Tracked === undefined || null || NaN  ? "0%" : row?.Tracked}
                            </span>
                            <br />
                            <span>
                              Open %:{" "}
                              {parseInt(row?.OpenPercentage) + "%"}
                                {/* {"0 %"} */}
                            </span>
                          </>
                        }
                        placement="right"
                      >
                        <span>
                        {parseInt(row?.OpenPercentage) + "%"}
                            {/* {"0 %"} */}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          <>
                            <span>
                              Replies:{" "}
                              {row.Reply === undefined || null || NaN || 0 ? 0 : row?.Reply}
                            </span>
                            <br />
                            <span>
                              Sent: {row.Sent === undefined || null || NaN  ? 0 : row?.Sent}
                            </span>
                            <br />
                            <span>
  Reply %:{" "}
  {parseInt(row?.ReplyPercentage) + "%"}
</span>


                          </>
                        }
                        placement="right"
                      >
                        <span>
                        {parseInt(row?.ReplyPercentage) + "%"}
                            {/* {"0 %"} */}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          <>
                            <span>
                              Replies:{" "}
                              {row.Reply === undefined || null || NaN  ? 0 : row?.Reply}
                            </span>
                            <br />
                            <span>
                              Interested:{" "}
                              {row.Intrested === undefined || null || NaN || 0 ? 0 : row?.Intrested}
                            </span>
                            <br />
                            <span>
                              Interested %:{" "}
                              {parseInt(row?.IntrestedPercentage) + "%"}
                                       {/* {"0 %"} */}
                            </span>
                          </>
                        }
                        placement="right"
                      >
                        <span>
                        {parseInt(row?.IntrestedPercentage) + "%"}
                            {/* {"0 %"} */}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment(new Date(row?.CreatedDate).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                    </TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      <a
                        onClick={() => {
                          DeleteBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Delete"
                      >
                        <i className="la flaticon-delete-1 delete-icon"></i>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      </div>
      {Sflag ? (
        <div className="row px-2 pb-3">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
        </div>
      ) : (
        <div className="row px-2 pb-2">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
