import React from 'react';
import Axios from "axios";
const moment = require("moment");
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { getFormattedFirstDayOfQuarterUTC} from "../../_helpers/Utility";
import ReactApexchart from "react-apexcharts";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../images/loading.gif";
import $ from "jquery";
import ColdCallingDisposition from '../../_components/clients/coldcalling/CallDisposition'
import PhoneReporting from '../../_components/clients/coldcalling/PhoneReporting';
import DispositionByDate from '../../_components/clients/coldcalling/DispositionByDate';


class CColdCallingReportingPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      FromDate: "",
      ToDate: "",
      IsAnalyze: false,
      ClientID: null,
      UserID: null,
      CUserID: null,
      ContactTag: [],
      SaleDevlopMentReps: [],
      TotalL90TotalConnected: 0,
      TotalL30TotalConnected: 0,
      TotalL7TotalConnected: 0,
      TotalAllTimeTotalConnected: 0,

      TotalL90TotalDialBegin: 0,
      TotalL30TotalDialBegin: 0,
      TotalL7TotalDialBegin: 0,
      TotalAllTimeTotalDialBegin: 0,
      L90ConnectedRate: "0%",
      L30ConnectedRate: "0%",
      L7ConnectedRate: "0%",
      AllTimeConnectedRate: "0%",
      TotalL90MeetingCounts: 0,
      TotalL30MeetingCounts: 0,
      TotalL7MeetingCounts: 0,
      TotalAllTimeMeetingCounts: 0,
      AllTimeAvgMeetingWeek: 0,
      L90AvgMeetingWeek: 0,
      L30AvgMeetingWeek: 0,
      L7AvgMeetingWeek: 0,

      TotalL90MeetingRate: "0%",
      TotalL30MeetingRate: "0%",
      TotalL7MeetingRate: "0%",
      TotalAllTimeMeetingRate: "0%",


      CallingGraph: {

        series: [{
          name: 'Dials',
          color: '#302e49',
          data: []
        }, {
          name: 'Connections',
          color: '#e9826e',
          data: []
        }, {
          name: 'Voicemails',
          color: '#ffb963',
          data: []
        }],
        options: {
          chart: {
            type: 'line',
            height: 450,
            width: 600,
            toolbar: {
              show: false
            }
          },
          // plotOptions: {
          //   bar: {
          //     horizontal: false,
          //     columnWidth: '55%',
          //     endingShape: 'rounded'
          //   },
          // },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: 'Count'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "Count"
              }
            }
          },
          markers: {
            size: 4, // Adjust the size of the markers
            //colors: ['red'], // Array of colors for the markers
            //strokeColors: '#fff', // Color of the marker's stroke
            strokeWidth: 1.5, // Width of the marker's stroke
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        },


      },
      ConnectionandMeetingState: {

        series: [{
          name: 'Connections',
          color: '#e9826e',
          data: []
        }, {
          name: 'Meetings',
          color: '#ffb963',
          data: []
        }],
        options: {
          chart: {
            type: 'line',
            height: 450,
            width: 600,
            toolbar: {
              show: false
            }
          },
          // plotOptions: {
          //   bar: {
          //     horizontal: false,
          //     columnWidth: '55%',
          //     endingShape: 'rounded'
          //   },
          // },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [2, 2, 2, 2],
            curve: 'smooth'
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: 'Count'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "Count"
              }
            }
          },
          markers: {
            size: 4, // Adjust the size of the markers
            // colors: ['#FFA41B'], // Array of colors for the markers
            // strokeColors: '#fff', // Color of the marker's stroke
            strokeWidth: 1.5, // Width of the marker's stroke
            hover: {
              size: 6 // Size of the marker when hovered
            }
          }
        },


      },
      IsLoading: true,
      IsCallingLoading: false,
      TagID: null,
      ColdCallerID: null,
    };
    this.btnAnalyze = this.btnAnalyze.bind(this);
    this.btnSearch = this.btnSearch.bind(this);
    this.btnReset = this.btnReset.bind(this);
    this.daterefect = React.createRef()
    this.daterefect2 = React.createRef()

  }
  componentDidMount() {
    document.title = "Call Reporting | SalesHive"
    const details = GetUserDetails();

    if (details != null) {
      this.setState({
        ClientID: details.ClientID,
        UserID: details.ParentUserID,

      });
  
      // Format the date
      const formattedDate = getFormattedFirstDayOfQuarterUTC()

      // Set the value of the "from-date" input field
      document.getElementById("from-date").value = formattedDate;
      const $datepickerElement = $(this.daterefect.current);
      $datepickerElement.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
      });
      document.getElementById("to-date").value = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      const $datepickerElement2 = $(this.daterefect2.current);
      $datepickerElement2.datetimepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        container: '#app',
        todayBtn: true,
        todayHighlight: true,
        minView: '2',
      });

      var FromDate = moment(document.getElementById("from-date").value).format("MM/DD/YYYY");
      var ToDate = moment(document.getElementById("to-date").value).format("MM/DD/YYYY");
      this.setState({ FromDate: FromDate, ToDate: ToDate });
      this.TagandSalesDevlopmentRepsGet(details.ClientID, details.ParentUserID);
      this.getColdCallingReporting(details.ClientID, details.ParentUserID, FromDate, ToDate, this.state.TagID, this.state.ColdCallerID)
      this.getColdCallingGraph(details.ClientID, details.ParentUserID, FromDate, ToDate, this.state.TagID, this.state.ColdCallerID)
    }

  }

  btnAnalyze() {

    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    var FromDate1 = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
    var ToDate1 = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
    
    if (moment(FromDate1, "YYYY-MM-DD").isAfter(moment(ToDate1, "YYYY-MM-DD"))) {
      toast.error("Please select an appropriate date range");
    } else {
      this.setState({ FromDate: FromDate, ToDate: ToDate, IsAnalyze: true });
      this.getColdCallingReporting(this.state.ClientID,this.state.UserID,FromDate,ToDate,this.state.TagID,this.state.ColdCallerID,);
      this.getColdCallingGraph(this.state.ClientID, this.state.UserID, FromDate, ToDate, this.state.TagID, this.state.ColdCallerID)
    }
  }


  // contact tag and sales devlopment reps dropdown get
  TagandSalesDevlopmentRepsGet(CID, UID) {
    const InputParameter = {
      ClientID: CID,
      UserID: UID
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_reporting/CCRTagandSDRGet",
      method: "POST",
      data: InputParameter,
    });
    rows.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        this.setState({
          ContactTag: result.data?.CTData,
          SaleDevlopMentReps: result.data?.SDRData,
        });
      } else {
        this.setState({
          ContactTag: [],
          SaleDevlopMentReps: [],
        });
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    });
  }
  // Get all count data
  getColdCallingReporting(CID, UID, FromDate, ToDate, TID, CCID) {
    this.setState({ IsLoading: true })
    const InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      FromDate: FromDate,
      Todate: ToDate,
      TagID: TID,
      ColdCallerID: CCID,
    }
    console.log(InputParaMeter)
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_reporting/ColdCallingReportingAllCountData",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        this.setState({
          TotalL90TotalDialBegin: result.data?.TotalL90TotalDialBegin,
          TotalL30TotalDialBegin: result.data?.TotalL30TotalDialBegin,
          TotalL7TotalDialBegin: result.data?.TotalL7TotalDialBegin,
          TotalAllTimeTotalDialBegin: result.data?.TotalAllTimeTotalDialBegin,
          L90ConnectedRate: result.data?.L90ConnectedRate,
          L30ConnectedRate: result.data?.L30ConnectedRate,
          L7ConnectedRate: result.data?.L7ConnectedRate,
          AllTimeConnectedRate: result.data?.AllTimeConnectedRate,
          TotalL90MeetingCounts: result.data?.TotalL90MeetingCounts,
          TotalL30MeetingCounts: result.data?.TotalL30MeetingCounts,
          TotalL7MeetingCounts: result.data?.TotalL7MeetingCounts,
          TotalAllTimeMeetingCounts: result.data?.TotalAllTimeMeetingCounts,

          TotalL90TotalConnected: result.data?.TotalL90TotalConnected,
          TotalL30TotalConnected: result.data?.TotalL30TotalConnected,
          TotalL7TotalConnected: result.data?.TotalL7TotalConnected,
          TotalAllTimeTotalConnected: result.data?.TotalAllTimeTotalConnected,

          L90AvgMeetingWeek: result.data?.L90AvgMeetingWeek,
          L30AvgMeetingWeek: result.data?.L30AvgMeetingWeek,
          L7AvgMeetingWeek: result.data?.L7AvgMeetingWeek,
          AllTimeAvgMeetingWeek: result.data?.AllTimeAvgMeetingWeek,

          TotalL90MeetingRate: result.data?.TotalL90MeetingRate,
          TotalL30MeetingRate: result.data?.TotalL30MeetingRate,
          TotalL7MeetingRate: result.data?.TotalL7MeetingRate,
          TotalAllTimeMeetingRate: result.data?.TotalAllTimeMeetingRate,
        });
        this.setState({ IsLoading: false })
      } else {
        this.setState({
          TotalL90TotalConnected: 0,
          TotalL30TotalConnected: 0,
          TotalL7TotalConnected: 0,
          TotalAllTimeTotalConnected: 0,
          TotalL90TotalDialBegin: 0,
          TotalL30TotalDialBegin: 0,
          TotalL7TotalDialBegin: 0,
          TotalAllTimeTotalDialBegin: 0,
          L90ConnectedRate: "0%",
          L30ConnectedRate: "0%",
          L7ConnectedRate: "0%",
          AllTimeConnectedRate: "0%",
          TotalL90MeetingCounts: 0,
          TotalL30MeetingCounts: 0,
          TotalL7MeetingCounts: 0,
          TotalAllTimeMeetingCounts: 0,
          AllTimeAvgMeetingWeek: 0,
          L90AvgMeetingWeek: 0,
          L30AvgMeetingWeek: 0,
          L7AvgMeetingWeek: 0,

          TotalL90MeetingRate: "0%",
          TotalL30MeetingRate: "0%",
          TotalL7MeetingRate: "0%",
          TotalAllTimeMeetingRate: "0%",
        });

        this.setState({ IsLoading: false })
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsLoading: false });
    });
  }


  // Search with Tag and Sales devlopment reps 
  btnSearch() {
    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    let TagValue = document.getElementById("ContactTagID").value;
    let ColdCallerValue = document.getElementById("ColdCallersID").value;
    this.setState({ TagID: TagValue, ColdCallerID: ColdCallerValue, FromDate: FromDate, ToDate: ToDate, IsAnalyze: true });
    this.getColdCallingGraph(
      this.state.ClientID,
      this.state.UserID,
      FromDate,
      ToDate,
      TagValue,
      ColdCallerValue,
    );
    this.getColdCallingReporting(
      this.state.ClientID,
      this.state.UserID,
      FromDate,
      ToDate,
      TagValue,
      ColdCallerValue
    );
  }
  btnReset() {
    document.getElementById("ContactTagID").value = "";
    document.getElementById("ColdCallersID").value = "";
    let FromDate = document.getElementById("from-date").value;
    let ToDate = document.getElementById("to-date").value;
    let TagValue = document.getElementById("ContactTagID").value;
    let ColdCallerValue = document.getElementById("ColdCallersID").value;

    this.setState({ TagID: TagValue, ColdCallerID: ColdCallerValue, FromDate: FromDate, ToDate: ToDate });

    this.getColdCallingReporting(this.state.ClientID, this.state.UserID, FromDate, ToDate, null, null);
    this.getColdCallingGraph(this.state.ClientID, this.state.UserID, FromDate, ToDate, null, null);
  }

  getColdCallingGraph(CID, UID, FromDate, ToDate, TID, CCID) {
    this.setState({ IsCallingLoading: true })
    const InputParaMeter = {
      ClientID: CID,
      UserID: UID,
      FromDate: FromDate,
      Todate: ToDate,
      TagID: TID,
      ColdCallerID: CCID,
    }
    console.log(InputParaMeter)
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/cold_calling_reporting/ColdCallingReportingGraph",
      method: "POST",
      data: InputParaMeter,
    });
    rows.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {

        let ListofDateCreatedCall = []
        let CallCountTotalGraph = []
        let ConnectedTotalGraph = []
        let VoiceMailTotalGraph = []
        for (let i = 0; i < result.data?.CallingGraph?.length; i++) {

          CallCountTotalGraph.push(result.data?.CallingGraph[i]?.CallCount);
          ConnectedTotalGraph.push(result.data?.CallingGraph[i]?.Connected);
          VoiceMailTotalGraph.push(result.data?.CallingGraph[i]?.VoiceMail)
          ListofDateCreatedCall.push(result.data?.CallingGraph[i]?.CallDay);
        }
        this.setState({
          CallingGraph: {

            series: [{
              name: 'Dials',
              color: '#302e49',
              data: CallCountTotalGraph.length > 0 ? CallCountTotalGraph : []
            }, {
              name: 'Connections',
              color: '#e9826e',
              data: ConnectedTotalGraph.length > 0 ? ConnectedTotalGraph : []
            }, {
              name: 'Voicemails',
              color: '#ffb963',
              data: VoiceMailTotalGraph.length > 0 ? VoiceMailTotalGraph : []
            }],
            options: {
              chart: {
                type: 'line',
                height: 450,
                width: 600,
                toolbar: {
                  show: false
                }
              },
              // plotOptions: {
              //   bar: {
              //     horizontal: false,
              //     columnWidth: '55%',
              //     endingShape: 'rounded'
              //   },
              // },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                categories: ListofDateCreatedCall.length > 0 ? ListofDateCreatedCall : [],
              },
              yaxis: {
                title: {
                  text: 'Count'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              markers: {
                size: 4,
                strokeWidth: 1.5,
                hover: {
                  size: 6 // Size of the marker when hovered
                }
              }
            },


          }
        })

        // second graph
        let ListofDateSecondGraph = []
        let ConnectionTotalSecondGraph = []
        let MeetingTotalSecondGraph = []

        for (let i = 0; i < result.data?.MeetingGraph?.length; i++) {
          ConnectionTotalSecondGraph.push(result.data?.MeetingGraph[i]?.Connection);
          MeetingTotalSecondGraph.push(result.data?.MeetingGraph[i]?.TotalMeeting)
          ListofDateSecondGraph.push(result.data?.MeetingGraph[i]?.Date);
        }

        this.setState({
          ConnectionandMeetingState: {

            series: [{
              name: 'Connections',
              color: '#e9826e',
              data: ConnectionTotalSecondGraph.length > 0 ? ConnectionTotalSecondGraph : [],
            }, {
              name: 'Meetings',
              color: '#ffb963',
              data: MeetingTotalSecondGraph.length > 0 ? MeetingTotalSecondGraph : [],
            }],
            options: {
              chart: {
                type: 'line',
                height: 350,
                toolbar: {
                  show: false
                }
              },
              // plotOptions: {
              //   bar: {
              //     horizontal: false,
              //     columnWidth: '55%',
              //     endingShape: 'rounded'
              //   },
              // },
              dataLabels: {
                enabled: false
              },
              stroke: {
                width: [2, 3, 4, 2],
                curve: 'smooth'
              },
              xaxis: {
                categories: ListofDateSecondGraph.length > 0 ? ListofDateSecondGraph : [],
              },
              yaxis: {
                title: {
                  text: 'Count'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              markers: {
                size: 4,
                strokeWidth: 1.5,
                hover: {
                  size: 6 // Size of the marker when hovered
                }
              }
            },


          },
        })


        // top box count

        this.setState({ IsCallingLoading: false })
      } else {

        this.setState({ IsCallingLoading: false })
      }
    }).catch((error) => {
      toast.error("An error occurred. Please try again.");
      this.setState({ IsCallingLoading: false });
    });
  }

  render() {
    const { CallingGraph, ConnectionandMeetingState } = this.state;
    return (
      <>
        {
          this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div> : null
        }
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">

            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left py-4">Cold Calling Reporting</h4>
              </div>
              <div className="col pt-3">
                <div className="btn-group datepicker-hed">
                  <input id="fromtab" name="fromtab" type="hidden" value="" />
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      class="form-control datepicker"
                      id="from-date"
                      name="from-date"
                      type="text"
                      autocomplete="off"
                      ref={this.daterefect}

                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      class="form-control datepicker"
                      id="to-date"
                      name="to-date"
                      type="text"
                      autocomplete="off"
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                    onClick={this.btnAnalyze}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i className="la flaticon-diagram"></i>
                    <span>
                      Analyze
                    </span>
                  </button>
                </div>
              </div>
            </div>


            <div className="mb-3">

              <div className="row pb-4 pt-4">
                <div className="col col-lg-3 d-flex pr-4 pl-3">
                  <label className="mt-2 mr-2 nowrap px-1"> Tag</label>
                  <select
                    className="form-control  m-input"
                    name="ContactTagID"
                    id="ContactTagID"
                  >
                    <option value="">--Select--</option>
                    {this.state.ContactTag.map((value) => (
                      <option value={value._id} key={value._id}>
                        {value.Tag}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col col-lg-4 d-flex">
                  <label className="mt-2 mr-2 nowrap"> Sales Development Reps</label>
                  <select
                    className="form-control  m-input"
                    name="ColdCallersID"
                    id="ColdCallersID"
                  >
                    <option value="">--Select--</option>
                    {this.state.SaleDevlopMentReps.map((value) => (
                      <option value={value._id} key={value._id}>
                        {value.ColdCallersName} {value?.LastName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col col-lg-5 d-flex justify-content-end">
                  <button className="btn btn-primary btnColor" onClick={this.btnSearch}><i className="la la-search"></i>Search</button>
                  <button onClick={this.btnReset} className="ml-3 btn btn-secondary"><i className="la la-refresh"></i> Reset</button>
                </div>
              </div>
            </div>
            <div className="mb-0">

              <div className="row px-3 pt-4 pb-3">
                <div className="w-100 hed-reporting">
                  <div className="row">
                    <div className="col">
                      <div className="bg-light-sky boxcardcounter card-reporting">
                      <h2 className="clr-sky box-main-title xs-headertitle">Last 90 Days</h2>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Dials:</b>
                          {this.state.TotalL90TotalDialBegin}
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Connections:</b>
                          {this.state.TotalL90TotalConnected} ({this.state.L90ConnectedRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Meetings:</b>
                          {this.state.TotalL90MeetingCounts} ({this.state.TotalL90MeetingRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Avg Meetings / Week:</b>
                          {this.state.L90AvgMeetingWeek}
                        </h6>
                      </div>
                    </div>
                    <div className="col">
                      <div className="bg-light-sky boxcardcounter  card-reporting">
                      <h2 className="clr-sky box-main-title xs-headertitle">Last 30 Days</h2>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Dials:</b>
                          {this.state.TotalL30TotalDialBegin}
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Connections:</b>
                          {this.state.TotalL30TotalConnected} ({this.state.L30ConnectedRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Meetings:</b>
                          {this.state.TotalL30MeetingCounts} ({this.state.TotalL30MeetingRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Avg Meetings / Week:</b>
                          {this.state.L30AvgMeetingWeek}
                        </h6>
                      </div>
                    </div>
                    <div className="col">
                      <div className="bg-light-sky boxcardcounter card-reporting">
                      <h2 className="clr-sky box-main-title xs-headertitle">Last 7 Days</h2>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Dials:</b>
                          {this.state.TotalL7TotalDialBegin}
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Connections:</b>
                          {this.state.TotalL7TotalConnected} ({this.state.L7ConnectedRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Meetings:</b>
                          {this.state.TotalL7MeetingCounts} ({this.state.TotalL7MeetingRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Avg Meetings / Week:</b>
                          {this.state.L7AvgMeetingWeek}
                        </h6>
                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-light-sky boxcardcounter card-reporting">
                      <h2 className="clr-sky box-main-title xs-headertitle">Date Range</h2>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Dials:</b>
                          {this.state.TotalAllTimeTotalDialBegin}
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Connections:</b>
                          {this.state.TotalAllTimeTotalConnected} ({this.state.AllTimeConnectedRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Meetings:</b>
                          {this.state.TotalAllTimeMeetingCounts} ({this.state.TotalAllTimeMeetingRate})
                        </h6>
                        <h6 className="clr-sky">
                          <b className='mr-2'>Avg Meetings / Week:</b>
                          {this.state.AllTimeAvgMeetingWeek}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-sm-6">
                  <div className="whiterow mb-4 px-3">
                    <h6 class="chartheading pl-1 mb-3">Dials and Connections</h6>
                    <div id="chart chart-max450">
                      {
                        this.state.IsCallingLoading === true ? <div id="hideloding" className="loadingchart">
                          <img src={loadingicon} />
                        </div> : null
                      }
                      {CallingGraph && <ReactApexchart options={CallingGraph.options} series={CallingGraph.series} type="line" height={450} />}

                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="whiterow mb-4 px-3">
                    <h6 class="chartheading pl-1 mb-3">Connections and Meetings</h6>
                    <div id="chart chart-max450">
                      {
                        this.state.IsCallingLoading === true ? <div id="hideloding" className="loadingchart">
                          <img src={loadingicon} />
                        </div> : null
                      }
                      {ConnectionandMeetingState && <ReactApexchart options={ConnectionandMeetingState.options} series={ConnectionandMeetingState.series} type="line" height={450} />}
                    </div>
                  </div>
                </div>
              </div>

       
              <div className='row pt-4'>
                <div className="col-md-12">
                <div className="whiterow  mb-4">
                      
                      <div className="row">
                      <div className="col">
                            <DispositionByDate  FromDate={this.state.FromDate} ToDate={this.state.ToDate} TagID={this.state.TagID} ColdCallerID={this.state.ColdCallerID} /> 
                          </div> 
                      </div>
                    </div>
                </div>
              </div>

              <div className="row mx-0">
                      <div className="col-md-6 pl-0"> 

                    <div className="whiterow px-3 mb-4">
                      <div className="row">
                        <div className="col">
                          <h4 className="headertitlepad xs-headertitle float-left py-4">Disposition</h4>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col px-0">
                            <ColdCallingDisposition  ObjDisposition={this.state}/> 
                          </div> 
                      </div>
                    </div> 

                 
                  </div>  
                  <div className="col-md-6 bg-white px-0"> 
                      <PhoneReporting  FromDate={this.state.FromDate} ToDate={this.state.ToDate} TagID={this.state.TagID} ColdCallerID={this.state.ColdCallerID} /> 

                      </div> 
                </div>  



            </div>
          </div>
          <Footer />


        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedCColdCallingReportingPage = connect(mapState, actionCreators)(CColdCallingReportingPage);
export { connectedCColdCallingReportingPage as CColdCallingReportingPage };