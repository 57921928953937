import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CustomVariable from '../../_components/clients/customvariable/customvariable';
//import CollapsibleTable from '../_components/usertable';
import { CSVDownloader } from "react-papaparse";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "../../_helpers";
import { GetUserDetails } from "../../_helpers/Utility";
import { CommonConstants } from "../../_constants/common.constants";
class CCustomVariablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ExportData: [],
            ClientName:""
          };
        this.ExportCsv = this.ExportCsv.bind(this);
        this.AddBtn = this.AddBtn.bind(this);
      }
      componentDidMount() {
        var Details = GetUserDetails();
        const InputParameter={
          ClientID:Details.ClientID,
          UserID:Details.ParentUserID
        }
        Axios({
          url: CommonConstants.MOL_APIURL + "/customvariable/ExportCustomVariable",
          method: "POST",
          data:InputParameter
        }).then((res) => {
          this.setState({ ExportData: res.data });
          this.setState({ ClientName: res.data.ClientName });
        });
      }

    AddBtn(){
        history.push("/CAddCustomVariable");
    }
    UpdateFromChild = (value) => {
        if(value == true){
          this.componentDidMount()
        }
      }

      ExportCsv() {
        toast.success(<div>
          Data exported successfully.
        </div>)
      }
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle lg-headertitle float-left py-4 pl-0">Custom Variable</h4>
                            <div className="listing-li float-right padb-15">
                                <ul>
                                <li>
                                <CSVDownloader className='px-0'
                                        data={this.state.ExportData}
                                        filename={`Client Varible Sets`}
                                        bom={true}
                                      >
                                  <a onClick={this.ExportCsv} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la la-icon-font-size-13 la-download"></i>
                                    <span>
                                
                                        Export
                                  
                                    </span>
                                  </a>
                                  </CSVDownloader>
                                </li>
                                <li>
                                <a onClick={this.AddBtn} className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air">
                                    <i class="la la-icon-font-size-13 la-plus"></i>
                                    <span>Add</span>
                                </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white px-3"> 
                        <div className="row">
                            <div className="col"> 
                                <CustomVariable updateFromChild={this.UpdateFromChild}/> 
                            </div>
                        </div>       
                    </div>

                </div> 
                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCustomVariablePage = connect(mapState, actionCreators)(CCustomVariablePage);
export { connectedCCustomVariablePage as CCustomVariablePage };