 import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import CampaignSummaryTable from '../../_components/clients/campaignsummary/campaignsummary';
import CollapsibleTable from '../../_components/usertable';



class CCampaignSummaryPage extends React.Component {
    render() {
        return (
            <>
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle lg-headertitle float-left pl-0">Campaign - Summary Total</h4> 
                        </div>
                    </div>
                    <div class="whiterow px-3 pb-3"> 
                        <div className="row">
                            <div className="col"> 
                                    <CampaignSummaryTable /> 
                            </div>
                        </div>      
                    </div>

                </div>

                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCCampaignSummaryPage = connect(mapState, actionCreators)(CCampaignSummaryPage);
export { connectedCCampaignSummaryPage as CCampaignSummaryPage };