import React from "react";
import Axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
toast.configure();

import { history } from "../../../_helpers";
import { userActions } from "../../../_actions";
import { GetUserDetails } from "../../../_helpers/Utility";
import { CommonConstants, Client_Limits } from "../../../_constants/common.constants";
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
const Fileupload = require("../../../_helpers/fileupload");
import ProfileImageUpload from '../../../_components/ProfileUpload';

const imgstyle = { width: "100px", height: "100px" };
import loadingicon from "../../../images/loading.gif";

class EditClientsPage extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      ClientID: null,
      UserID: null,
      ResponderList: [],
      ColdCallerLists: [],
      SalesStrategistList: [],
      ClientStatusList: [],
      ReasonLostList: [],
      LogoApprovalList: [],
      TimezoneList: [],
      ClientBillingMethodList: [],
      ClientBillingStatusList: [],
      ChannelList: [],
      ClosedByList: [],
      ClientBillingPeriodList: [],
      errors: {},
      ResponderArr: [],
      SalesStrategistArr: [],
      ColdCallersArr: [],
      ChannelArr: [],
      FinalImg: "",
      FileImg: "",
      ImgFlag: false,
      SalesOption: [],
      ColdCallerOption: [],
      DefaultColdCallersOption: [],
      DefaultSalesHacker: [],
      DefaultResponder: [],

      ClientStatusID: null,
      ReasonLostID: null,
      LogoApprovalID: null,
      TimeZoneID: null,
      SalesRepID: null,
      ClientBillingMethodID: null,
      BillingStatusID: null,
      BillingPeriodID: null,
      FormData: {
        ClientName: "",
        ResponderName: [],
        SalesStrategistName: [],
        ColdCallersName: [],
        ClientStatus: "",
        ClientStartDate: "",
        ClientEndDate: "",
        TargetAccountsperQtr: "1000",
        DefaultContractValue: "",
        Website: "",
        ReasonLost: "",
        LogoApproval: "",
        TimeZone: "",
        TrackDomain: "app.saleshive.com",
        ClosedBy: "",
        BCCEmail: "",
        SlackChannelInternal: "",
        SlackChannelExternal: "",
        DoNotAddMeetingsToBlacklist: false,
        IsSentMeetingCreateNotification: false,
        IsSentMeetingRescheduleNotification: false,
        Channels: [],
        ServerNumber: "",
        ProfileImage: "",
        ClientBillingMethod: "",
        ClientBillingContact: "",
        ClientBillingEmail: "",
        ClientBillingAddress: "",
        ClientBillingDate: "",
        ClientBillingStatus: "",
        ClientBillingAmount: "",
        ClientBillingPeriod: "",
        AvgMonthlyRevenue: "",
        PPMEstMonths: "",
        OldImage: "",
        InternalChannelID: "",
        ExternalChannelID: "",
        IsAllowEmod:false,
        LimitEmailSentPerClient:0,
        LimitEmailsEmodPerMonthPerClient:0,
        LimitEmailsPerMonthPerClient:0,
        LimitUsersPerClient:0,
        ZoomInfoContactLimitType:"",
        ZoomInfoContactLimit:0,
        LimitTotalContactsPerClient:0,
        OpenAI_API_KEY:"",
        IsAllowEmod:true,
        IsSeoReporting:false
      },
      IsButtonDisabled: false,
      IsLoading: true,
      ID: "",
      ClientNameExists:true,
      ClientName:"",
      ParentUserID:""

    };

    this.BackBtn = this.BackBtn.bind(this);
    this.ClientGet();
  }

  componentDidMount() {
    this.setState({ ID: this.props.location.state.data });

    document.title = "Edit Client | SalesHive";

    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ChildUserID });
      this.setState({ ParentUserID: Details.ParentUserID });
    }
    this.AllDropDownFetching(Details.ClientID, Details.ParentUserID);
  }

  //Fetching all drop down data
  AllDropDownFetching(CID, UID) {
    const InputParameters = {
      UserID: UID,
      ClientID: CID,
    };
    //Responder Drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ResponderDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ResponderList: res.data.Data });
    });

    //sales strategist Drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/SalesHackerDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ SalesStrategistList: res.data.Data });
      let SalesStrategistOption = this.state.SalesStrategistList.map((val) => {
        return {
          value: val._id,
          label: `${val.SalesHackerName} ${val.LastName}`,
        };
      });
      this.setState({ SalesOption: SalesStrategistOption });
    });

    //Cold caller drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ColdCallersDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ColdCallerLists: res.data.Data });
      let ColdCallerOptionss = this.state.ColdCallerLists?.map((val) => {
        return {
          value: val?._id,
          label: `${val?.ColdCallersName} ${val.LastName}`,
        };
      });
      this.setState({ ColdCallerOption: ColdCallerOptionss });
    });

    //Client Status drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClientStatusDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientStatusList: res.data.Data });
    });

    //Reason lost drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ReasonLostDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ReasonLostList: res.data.Data });
    });

    //Logo Approval drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/LogoApprovalDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ LogoApprovalList: res.data.Data });
    });

    //Time zone drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/TimeZoneDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ TimezoneList: res.data.Data });
    });

    //Client billing method drop down
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/clients/ClientBillingMethodDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientBillingMethodList: res.data.Data });
    });

    //Client billing status drop down
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/clients/ClientBillingStatusDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientBillingStatusList: res.data.Data });
    });

    //channels drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ChannelsDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ChannelList: res.data.Data });
    });

    //closedby drop down
    Axios({
      url: CommonConstants.MOL_APIURL + "/clients/ClosedByDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClosedByList: res.data.Data });
    });

    //client billing method period drop down
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/clients/ClientBillingPeriodDropDownGet",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      this.setState({ ClientBillingPeriodList: res.data.Data });
    });
  }

  //Fetching client details
  ClientGet() {
    this.setState({ IsLoading: true });
    const InputParameters = {
      ClientID: this.props.location.state.data,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Clients/ClientGetByID",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if (res.data.StatusMessage == "SUCCESS") {
        let ResponderOption = [];
        res.data.Data.ResponderDetails?.map((val) => {
          ResponderOption.push({ value: val._id, label: val.ResponderName });
        });

        let ColdCallersOptions = [];
        res.data.Data.ColdCallersDetails?.map((val) => {
          ColdCallersOptions.push({
            value: val._id,
            label: val.ColdCallersName,
          });
        });
        let SalesHackerOptions = [];
        res.data.Data.SalesHackerDetails?.map((val) => {
          SalesHackerOptions.push({
            value: val._id,
            label: val.SalesHackerValue,
          });
        });

        // document.getElementById("IsUpdateExistEmod")?.checked =res?.data?.Data?.IsAllowEmod ?? false;
          this.setState({ClientName:res.data.Data.Name})
        let EditObj = {
          ClientName: res.data.Data.Name,
          ResponderName: res.data.Data.ResponderDetails,
          SalesStrategistName: res.data.Data.SalesHackerDetails,
          ColdCallersName: res.data.Data.ColdCallersDetails,
          ClientStatus: res.data.Data.ClientStatusID,
          ClientStartDate: moment(
            new Date(res.data.Data.ClientStartDate).toDateString()
          ).format("YYYY-MM-DD"),
          ClientEndDate: res.data.Data.ClientEndDate !== null && res.data.Data.ClientEndDate.trim() !== ""
          ? moment(new Date(res.data.Data.ClientEndDate).toDateString()).format("YYYY-MM-DD")
          : null,
          TargetAccountsperQtr: res.data.Data.TargetAccountsperQtr,
          DefaultContractValue: res.data.Data.MeetingValue,
          Website: res.data.Data.Website,
          ReasonLost: res.data.Data.ReasonLostID,
          LogoApproval: res.data.Data.LogoApprovalID,
          TimeZone: res.data.Data.TimeZoneID,
          TrackDomain: res.data.Data.TrackDomain,
          ClosedBy: res.data.Data.SalesRepID,
          BCCEmail: res.data.Data.BCCEmail,
          SlackChannelInternal: res.data.Data.SlackChannelInternal,
          SlackChannelExternal: res.data.Data.SlackChannelExternal,
          DoNotAddMeetingsToBlacklist: res.data.Data.ISAddMeetingsToBlacklist,
          IsSentMeetingCreateNotification:
            res.data.Data.IsSentMeetingCreateNotification,
          IsSentMeetingRescheduleNotification:
            res.data.Data.IsSentMeetingRescheduleNotification,
          Channels: res.data.Data.ChannelsDetails,
          ServerNumber: res.data.Data.ServerNumber,
          ProfileImage: `${
            CommonConstants.Image_url + res.data.Data.ProfileImage
          }`,
          ClientBillingMethod: res.data.Data.ClientBillingMethodID,
          ClientBillingContact: res.data.Data.BillingContact,
          ClientBillingEmail: res.data.Data.BillingEmail,
          ClientBillingAddress: res.data.Data.BillingAddress,
          ClientBillingDate: res.data.Data.BillingDate !== null && res.data.Data.BillingDate.trim() !== ""
          ? moment(new Date(res.data.Data.BillingDate).toDateString()).format("YYYY-MM-DD")
          : null,
          ClientBillingStatus: res.data.Data.BillingStatusID,
          ClientBillingAmount: res.data.Data.BillingAmount,
          ClientBillingPeriod: res.data.Data.BillingPeriodID,
          AvgMonthlyRevenue: res.data.Data.AvgMonthlyRevenue,
          PPMEstMonths: res.data.Data.PPMEstMonths,
          OldImage: res.data.Data.ProfileImage,
          IsAllowEmod: res.data.Data.IsAllowEmod,
          IsSeoReporting: res.data.Data?.IsSeoReporting ?? false,
          InternalChannelID: res.data.Data.InternalChannelID
            ? res.data.Data.InternalChannelID
            : "",
          ExternalChannelID: res.data.Data.ExternalChannelID
            ? res.data.Data.ExternalChannelID
            : "",
            LimitEmailsPerMonthPerClient: res.data.Data?.LimitEmailsPerMonthPerClient ?? Client_Limits.LIMIT_EMAIL_SENT_PER_MONTH_PER_CLIENT,
            LimitEmailsEmodPerMonthPerClient: res.data.Data?.LimitEmailsEmodPerMonthPerClient ?? Client_Limits.LIMIT_EMAILS_EMOD_PER_MONTH_PER_CLIENT,
            LimitUsersPerClient: res.data.Data?.LimitUsersPerClient ?? Client_Limits.LIMIT_USERS_PER_CLIENT,
            ZoomInfoContactLimitType: res.data.Data?.ZoomInfoContactLimitType ?? Client_Limits.LIMIT_TYPE_ZOOM_INFO_CONTACT,
            ZoomInfoContactLimit: res.data.Data?.ZoomInfoContactLimit ?? Client_Limits.LIMIT_ZOOM_INFO_CONTACT,
            LimitTotalContactsPerClient: res.data.Data?.LimitTotalContactsPerClient ?? Client_Limits.LIMIT_TOTAL_CONCTACTS_PER_CLIENT,
            OpenAI_API_KEY: res.data.Data?.OpenAI_API_KEY ?? ""
        };
        this.setState({ FormData: EditObj });
        this.setState({ FinalImg: res.data.Data.ProfileImage });
        this.setState({ ReasonLostID: res.data.Data.ReasonLostID });
        this.setState({ ClientStatusID: res.data.Data.ClientStatusID });
      } else {
      }
      this.setState({ IsLoading: false });
    });
  }

  //Responder drop down handler
  ResponderDropDownHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        ResponderName: val,
      },
    }));
  }

  //Sales strategist drop down handler
  SalesStrategistHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        SalesStrategistName: val,
      },
    }));
  }

  //cold callers drop down handler
  ColdCallersHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        ColdCallersName: val,
      },
    }));
  }

  //Channel drop down handler
  ChannelDropDownHandler(val) {
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        Channels: val,
      },
    }));
  }

  //Back Btn
  BackBtn() {
    if(this.props.location.state.Pagename === "AllClientkpi"){
      history.push("/clientkpis");
    }else{
      history.push("/clients");
    }
  }

  //upload
  HandleUpload(field, e) {
    e.preventDefault();
    if (
      Fileupload.checkMimeType(e.target.files[0]) &&
      Fileupload.checkFileSize(e.target.files[0])
    ) {
      var temp = URL.createObjectURL(e.target.files[0]);

      this.setState({
        FinalImg: e.target.files[0],
      });
      this.setState({ ImgFlag: true });
      this.setState({ FileImg: temp });
    } else {
    }
  }

  //calculate amount
  CalulateAmount = () => {
    let ClientBillingPeriod = document.getElementById(
      "ClientBillingPeriod"
    ).value;
    let ClientBillingAmount = this.state.FormData.ClientBillingAmount;
    let AvgMonthlyRevenue = this.state.FormData.AvgMonthlyRevenue;
    let PPMEstMonths = this.state.FormData.PPMEstMonths;
    if (ClientBillingAmount == "" || ClientBillingAmount == null) {
      this.setState((prevState) => ({
        FormData: {
          ...prevState.FormData,
          ClientBillingAmount: 0,
        },
      }));
    }
    if (PPMEstMonths == "" || PPMEstMonths == null) {
      PPMEstMonths = 0;
    }
    if (ClientBillingPeriod == 1) {
      AvgMonthlyRevenue = ClientBillingAmount;
      PPMEstMonths = 0;
    } else if (ClientBillingPeriod == 2) {
      AvgMonthlyRevenue = ClientBillingAmount / 3;
    } else if (ClientBillingPeriod == 3) {
      AvgMonthlyRevenue = (ClientBillingAmount * 26) / 12;
    } else if (ClientBillingPeriod == 4) {
      if (PPMEstMonths !== "") {
        AvgMonthlyRevenue = ClientBillingAmount / PPMEstMonths;
      } else {
        AvgMonthlyRevenue = 0.0;
      }
    } else {
      AvgMonthlyRevenue = 0.0;
    }
    if (
      Number.isNaN(AvgMonthlyRevenue) ||
      AvgMonthlyRevenue === Infinity ||
      AvgMonthlyRevenue === -Infinity
    ) {
      AvgMonthlyRevenue = 0.0;
    }
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        AvgMonthlyRevenue: parseInt(AvgMonthlyRevenue).toFixed(2),
      },
    }));
  };

   // //check client exists or not
   CheckClient = (name) => {

    if(this.state.ClientName !== name){
      let existFlag = false;
      const inputParameters = {
        UserID: this.state.ParentUserID,
        ClientName: name,
      };
    
      return Axios({
        url: CommonConstants.MOL_APIURL + "/clients/ClientCheckExists",
        method: "POST",
        data: inputParameters,
      })
        .then((response) => {
          if (response.data.Data.length > 0) {
            this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                ["ClientName"]: "Client name already exist.",
              },
            }));
            this.setState({ClientNameExists:true})
            existFlag = true;
          } else {
            this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                ["ClientName"]: "",
              },
            }));
            this.setState({ClientNameExists:false})
            existFlag = false;
          }
          return existFlag;
        })
        .catch((error) => {
          console.error(error);
          return existFlag; // or handle the error accordingly
        });
    }else{
      this.setState({ClientNameExists:false})
    }
  };
  

  validateField = (fieldName, value) => {
    let error = "";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 
    if (fieldName === "ClientName" && !value) {
      error = "Please enter client name.";
    }

    if (fieldName === "ClientName" && value.trim() !== "" && value.trim() !== this.state.ClientName.trim()) {
      this.CheckClient(value.trim())
        .then((exists) => {
          if (exists) {
            error = "Client name already exists.";
          }
        })
        .catch((error) => {
          // Handle error if CheckClient fails
          console.error("Error checking client:", error);
        });
    }
  

    if (
      fieldName === "ResponderName" &&
      (!this.state.FormData.ResponderName || this.state.FormData.ResponderName.length === 0)
    ) {
      error = "Please select responder.";
    }

    if (
      fieldName === "SalesStrategistName" &&
      (!this.state.FormData.SalesStrategistName || this.state.FormData.SalesStrategistName.length === 0)
    ) {
      error = "Please select sales strategist.";
    }

    // if (
    //   fieldName === "ColdCallersName" && (!this.state.FormData.ColdCallersName || this.state.FormData.ColdCallersName.length === 0)
    // ) {
    //   error = "Please select cold callers.";
    // }

    if (fieldName === "ClientStatus" && !value) {
      error = "Please select Client Status.";
    }

    if (fieldName === "ClientStartDate" && !value) {
      error = "Please select client start date.";
    }

    if (fieldName === "Website" && !value) {
      error = "Please enter website.";
    }

    // if (fieldName === "ReasonLost" && !value) {
    //   error = "Please select reason lost.";
    // }

    if (fieldName === "TimeZone" && !value) {
      error = "Please select timezone.";
    }

    if (fieldName === "ClosedBy" && !value) {
      error = "Please select closed by.";
    }

    if (fieldName === "ServerNumber" && !value) {
      error = "Please select server number.";
    }

    if (fieldName === "ClientBillingPeriod" && !value) {
      error = "Please enter client billing period.";
    }

    if (fieldName === "LimitEmailsPerMonthPerClient") {
      const numValue = parseFloat(value);
      if (value === undefined || value === "") {
        error = "Limit email sent per month per client cannot be blank. It must be at least -1.";
      } else if (!Number.isInteger(numValue)) {
        error = "Limit email sent per month per client must be a whole number.";
      } else if (value < -1) {
        error = "Limit email sent per month per client cannot be less than -1.";
      }
    }
    if (fieldName === "LimitEmailsEmodPerMonthPerClient") {
      const numValue = parseFloat(value);
      if (value === undefined || value === "") {
        error = "Limit emails emod per month per client cannot be blank. It must be at least -1";
      } else if (!Number.isInteger(numValue)) {
        error = "Limit email emod per month per client must be a whole number.";
      } else if (value < -1) {
        error = "Limit emails emod per month per client cannot be less than -1.";
      }
    }
    if (fieldName === "LimitUsersPerClient") {
      const numValue = parseFloat(value);
      if (value === undefined || value === "") {
        error = "Limit users per client cannot be blank. It must be at least -1";
      } else if (!Number.isInteger(numValue)) {
        error = "Limit users per client must be a whole number.";
      } else if (value < -1) {
        error = "Limit users per client cannot be less than -1.";
      }
    }
    if (fieldName === "ZoomInfoContactLimit") {
      const numValue = parseFloat(value);
      if (value === undefined || value === "") {
        error = "Zoom info contact limit cannot be blank. It must be at least -1";
      } else if (!Number.isInteger(numValue)) {
        error = "Zoom info contact limit must be a whole number.";
      } else if (value < -1) {
        error = "Zoom info contact limit cannot be less than -1.";
      }
    }
    if (fieldName === "LimitTotalContactsPerClient") {
      const numValue = parseFloat(value);
      if (value === undefined || value === "") {
        error = "Limit total contacts per client cannot be blank. It must be at least -1";
      } else if (!Number.isInteger(numValue)) {
        error = "Limit total contacts per client must be a whole number.";
      } else if (value < -1) {
        error = "Limit total contacts per client cannot be less than -1.";
      }
    }

    if (fieldName === "ClientBillingAmount" && !value) {
      error = "Please enter client billing amount.";
    } else if (fieldName === "ClientBillingAmount" && value) {
      this.CalulateAmount();
    }

    if (fieldName === "BCCEmail" && value) {
      if (!emailRegex.test(value)) {
        error = "Invalid email.";
      }
    }
    if (fieldName === "ClientBillingEmail" && value) {
      if (!emailRegex.test(value)) {
        error = "Invalid email.";
      }
    }

    return error;
  };

  //Getting Slack ChannelID and name
  SlackChannelMethod = (name, value) => {
    this.setState({ IsLoading: true });
    const InputParameters = {
      ChannelName: value,
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/Utility/CheckSlackChannelExists",
      method: "POST",
      data: InputParameters,
    }).then((res) => {
      if ( res.data.IsChannelExists === false &&name === "SlackChannelInternal" ) {
        this.setState((prevState) => {
          const updatedFormData = {
            ...prevState.FormData,
          };

          // Adding conditions here
          if (name === "SlackChannelInternal") {
            updatedFormData.InternalChannelID = res.data.ChannelID;
          } else if (name === "SlackChannelExternal") {
            updatedFormData.ExternalChannelID = res.data.ChannelID;
          }
          return {
            FormData: updatedFormData,
          };
        });
        toast.error(<div className="toastsize">Client <br />Please enter valid slack channel internal</div>);
      } else if (res.data.IsChannelExists === false &&name === "SlackChannelExternal") {
        this.setState((prevState) => {
          const updatedFormData = {
            ...prevState.FormData,
          };

          // Adding conditions here
          if (name === "SlackChannelInternal") {
            updatedFormData.InternalChannelID = res.data.ChannelID;
          } else if (name === "SlackChannelExternal") {
            updatedFormData.ExternalChannelID = res.data.ChannelID;
          }
          return {
            FormData: updatedFormData,
          };
        });
        toast.error(
          <div className="toastsize">
            Client
            <br />
            Please enter valid slack channel external
          </div>
        );
      } else {
        this.setState((prevState) => {
          const updatedFormData = {
            ...prevState.FormData,
          };

          // Adding conditions here
          if (name === "SlackChannelInternal") {
            updatedFormData.InternalChannelID = res.data.ChannelID;
          } else if (name === "SlackChannelExternal") {
            updatedFormData.ExternalChannelID = res.data.ChannelID;
          }
          return {
            FormData: updatedFormData,
          };
        });
      }

      this.setState({ IsLoading: false });
      //
    });
  };

  //Handle Change
  HandleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    // const error = this.validateField(name, fieldValue);

    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        [name]: fieldValue,
      },
      // errors: {
      //   ...prevState.errors,
      //   [name]: error,
      // },
    }));
    if (name === "ClientBillingPeriod" && value != -1) {
      this.CalulateAmount();
    }
    if (name === "PPMEstMonths") {
      this.CalulateAmount();
    }
  };

  //Handle Blur
  HandleBlur = (e) => {
    const { name, value } = e.target;
    const error = this.validateField(name, value);
    if (
      (name === "SlackChannelInternal" || name === "SlackChannelExternal") &&
      value.trim() !== ""
    ) {
      this.SlackChannelMethod(name, value);
    }
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: error,
      },
    }));
  };

  handleSubmit = async(e) => {
    this.setState({ IsLoading: true, IsButtonDisabled: true });
    e.preventDefault();
    const { FormData } = this.state;
    const errors = {};

    Object.keys(FormData).forEach((fieldName) => {
      const error = this.validateField(fieldName, FormData[fieldName]);
      // const clientName = FormData[fieldName].trim();
      // const currentClientName = this.state.ClientName;
      
      if (fieldName === "ClientName" &&  FormData[fieldName].trim() !== "" &&  FormData[fieldName].trim() !== this.state.ClientName) {
        if (typeof this.CheckClient === "function") {
          this.CheckClient( FormData[fieldName].trim())
            .then((exists) => {
              if (exists) {
                errors[fieldName] = "Client name already exists.";
              }
            })
            .catch((error) => {
              // Handle error if CheckClient fails
              console.error("Error checking client:", error);
            });
        } else {
          console.error("this.CheckClient is not a function or is undefined.");
        }
      }
      if (error) {
        errors[fieldName] = error;
      }
      this.setState({ IsLoading: false, IsButtonDisabled: false });
    });

    var IsUpdateExistEmods = document.getElementById("IsUpdateExistEmod")?.checked ?? false;
    
    
  if(Object.keys(errors).length === 0){
    if (Object.keys(errors).length === 0 && (this.state.ClientNameExists === false || this.state.FormData.ClientName === this.state.ClientName)) {
      if (new Date(this.state.FormData.ClientEndDate) < new Date(this.state.FormData.ClientStartDate) && this.state.FormData.ClientEndDate !=null ) {
        // ClientEndDate is less than ClientStartDate
        // Your code for handling this condition goes here
        toast.error("End date must be equal or greater than started date.")
        this.setState({IsLoading:false});
        this.setState({ IsButtonDisabled: false });
      } else{
        var InputParameters;
        let Image = await this.childRef.current.SubmitData();
      let ImageName = Image.FileName;
      this.setState({ FinalImg: ImageName, ButtonDisabled: true });
        if (this.state.ImgFlag == false) {
          InputParameters = {
            ID: this.state.ID,
            Name: this.state.FormData.ClientName,
            ResponderID: this.state.FormData.ResponderName,
            SalesStrategistID: this.state.FormData.SalesStrategistName,
            ColdsCallerID: this.state.FormData.ColdCallersName || [],
            ClientStatusID: this.state.FormData.ClientStatus,
            ClientStartDate: this.state.FormData.ClientStartDate,
            ClientEndDate: this.state.FormData.ClientEndDate,
            TargetAccountsperQtr: this.state.FormData.TargetAccountsperQtr,
            DefaultContractValue: this.state.FormData.DefaultContractValue,
            Website: this.state.FormData.Website,
            ReasonLostID: this.state.FormData.ReasonLost,
            LogoApprovalID: this.state.FormData.LogoApproval,
            TimeZoneID: this.state.FormData.TimeZone,
            TrackDomain: this.state.FormData.TrackDomain,
            ClosedBy: this.state.FormData.ClosedBy,
            BCCEmail: this.state.FormData.BCCEmail,
            SlackChannelInternal: this.state.FormData.SlackChannelInternal,
            SlackChannelExternal: this.state.FormData.SlackChannelExternal,
            ISAddMeetingsToBlacklist:
              this.state.FormData.DoNotAddMeetingsToBlacklist,
            IsSentMeetingCreateNotification:
              this.state.FormData.IsSentMeetingCreateNotification,
            IsSentMeetingRescheduleNotification:
              this.state.FormData.IsSentMeetingRescheduleNotification,
            ChannelsIDs: this.state.FormData.Channels,
            ServerNumber: this.state.FormData.ServerNumber,
            ClientBillingMethodID: this.state.FormData.ClientBillingMethod,
            BillingContact: this.state.FormData.ClientBillingContact,
            BillingEmail: this.state.FormData.ClientBillingEmail,
            BillingAddress: this.state.FormData.ClientBillingAddress,
            BillingDate: this.state.FormData.ClientBillingDate,
            BillingStatusID: this.state.FormData.ClientBillingStatus,
            BillingAmount: this.state.FormData.ClientBillingAmount,
            BillingPeriodID: this.state.FormData.ClientBillingPeriod,
            AvgMonthlyRevenue: this.state.FormData.AvgMonthlyRevenue,
            PPMEstMonths: this.state.FormData.PPMEstMonths,
            IsAllowEmod:this.state.FormData.IsAllowEmod,
            IsSeoReporting:this.state.FormData.IsSeoReporting,
            ParentUserID: this.state.ParentUserID,
            ClientID: this.state.ClientID,
            ProfileImage: ImageName,
            UserID: this.state.UserID,
            IsDeleted: false,
            LastUpdatedBy: this.state.UserID,
            LastUpdatedDate: new Date(),
            InternalChannelID: this.state.FormData.SlackChannelInternal?.trim() != "" ? this.state.FormData.InternalChannelID : "",
            ExternalChannelID: this.state.FormData.SlackChannelExternal?.trim() !="" ? this.state.FormData.ExternalChannelID : "",
            IsAllowEmod: this.state.FormData.IsAllowEmod,
            LimitEmailsPerMonthPerClient:this.state.FormData.LimitEmailsPerMonthPerClient,
              LimitEmailsEmodPerMonthPerClient:this.state.FormData.LimitEmailsEmodPerMonthPerClient,
              LimitUsersPerClient:this.state.FormData.LimitUsersPerClient,
              ZoomInfoContactLimitType:this.state.FormData.ZoomInfoContactLimitType,
              ZoomInfoContactLimit:this.state.FormData.ZoomInfoContactLimit,
              LimitTotalContactsPerClient:this.state.FormData.LimitTotalContactsPerClient,
              OpenAI_API_KEY:this.state.FormData.OpenAI_API_KEY
          };
        } else {
          
          InputParameters = {
            ID: this.state.ID,
            Name: this.state.FormData.ClientName,
            ResponderID: this.state.FormData.ResponderName,
            SalesStrategistID: this.state.FormData.SalesStrategistName,
            ColdsCallerID: this.state.FormData.ColdCallersName || [],
            ClientStatusID: this.state.FormData.ClientStatus,
            ClientStartDate: this.state.FormData.ClientStartDate,
            ClientEndDate: this.state.FormData.ClientEndDate,
            TargetAccountsperQtr: this.state.FormData.TargetAccountsperQtr,
            DefaultContractValue: this.state.FormData.DefaultContractValue,
            Website: this.state.FormData.Website,
            ReasonLostID: this.state.FormData.ReasonLost,
            LogoApprovalID: this.state.FormData.LogoApproval,
            TimeZoneID: this.state.FormData.TimeZone,
            TrackDomain: this.state.FormData.TrackDomain,
            ClosedBy: this.state.FormData.ClosedBy,
            BCCEmail: this.state.FormData.BCCEmail,
            SlackChannelInternal: this.state.FormData.SlackChannelInternal,
            SlackChannelExternal: this.state.FormData.SlackChannelExternal,
            ISAddMeetingsToBlacklist:
              this.state.FormData.DoNotAddMeetingsToBlacklist,
            IsSentMeetingCreateNotification:
              this.state.FormData.IsSentMeetingCreateNotification,
            IsSentMeetingRescheduleNotification:
              this.state.FormData.IsSentMeetingRescheduleNotification,
            ChannelsIDs: this.state.FormData.Channels,
            ServerNumber: this.state.FormData.ServerNumber,
            ClientBillingMethodID: this.state.FormData.ClientBillingMethod,
            BillingContact: this.state.FormData.ClientBillingContact,
            BillingEmail: this.state.FormData.ClientBillingEmail,
            BillingAddress: this.state.FormData.ClientBillingAddress,
            BillingDate: this.state.FormData.ClientBillingDate,
            BillingStatusID: this.state.FormData.ClientBillingStatus,
            BillingAmount: this.state.FormData.ClientBillingAmount,
            BillingPeriodID: this.state.FormData.ClientBillingPeriod,
            AvgMonthlyRevenue: this.state.FormData.AvgMonthlyRevenue,
            PPMEstMonths: this.state.FormData.PPMEstMonths,
            IsAllowEmod:this.state.FormData.IsAllowEmod,
            IsSeoReporting:this.state.FormData.IsSeoReporting,
            ProfileImage: ImageName,
            ParentUserID: this.state.ParentUserID,
            ClientID: this.state.ClientID,
            UserID: this.state.UserID,
            LastUpdatedBy: this.state.UserID,
            LastUpdatedDate: new Date(),
            IsDeleted: false,
            InternalChannelID: this.state.FormData.SlackChannelInternal?.trim() != "" ? this.state.FormData.InternalChannelID : "",
            ExternalChannelID: this.state.FormData.SlackChannelExternal?.trim() !="" ? this.state.FormData.ExternalChannelID : "",
            LimitEmailsPerMonthPerClient:this.state.FormData.LimitEmailsPerMonthPerClient,
              LimitEmailsEmodPerMonthPerClient:this.state.FormData.LimitEmailsEmodPerMonthPerClient,
              LimitUsersPerClient:this.state.FormData.LimitUsersPerClient,
              ZoomInfoContactLimitType:this.state.FormData.ZoomInfoContactLimitType,
              ZoomInfoContactLimit:this.state.FormData.ZoomInfoContactLimit,
              LimitTotalContactsPerClient:this.state.FormData.LimitTotalContactsPerClient,
              OpenAI_API_KEY:this.state.FormData.OpenAI_API_KEY
          };
        }
  
        Axios({
          url: CommonConstants.MOL_APIURL + "/clients/ClientUpdate",
          method: "POST",
          data: InputParameters,
        }).then((res) => {
          if (res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div>
                Client Edit
                <br />
                Client updated successfully.
              </div>
            );
            if(this.props.location.state.Pagename === "AllClientkpi"){
              history.push("/clientkpis");
            }else{
              history.push("/clients");
            }
            this.setState({ IsButtonDisabled: false });
          } else {
            toast.error(res.data.Message);
            this.setState({ IsLoading: false });
            this.setState({ IsButtonDisabled: false });
          }
        });
      }
     
  
    } else {
      // Form is invalid, display errors
     // toast.error("Please fill all the required fields");
      this.setState({ errors });
      this.setState({ IsLoading: false, IsButtonDisabled: false });
    }
  }else{
    toast.error("Please fill all the required fields");
    this.setState({ errors });
    this.setState({ IsLoading: false, IsButtonDisabled: false });
  }
  };

  handleCheckboxChange = (e) => {
    const { checked } = e.target;
    this.setState((prevState) => ({
      FormData: {
        ...prevState.FormData,
        IsAllowEmod: checked,
      },
    }));
  };

  render() {
    
    let ResponderOption = this.state.ResponderList.map((val) => {
      return { value: val._id, label: `${val.ResponderName} ${val.LastName}` };
    });
    let ChannelsOption = this.state.ChannelList.map((val) => {
      return { value: val.FieldID, label: val.FieldName };
    });

    return (
      <>
        {this.state.IsLoading === true ? (
          <div id="hideloding" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitlepad lg-headertitle  float-left">Edit Client </h4>
              </div>
            </div>

            <div className="bg-white">
              <div className=" borderbtnm row mx-0 px-3">
                {/* <div className="col"> */}
                <h4 className="headertitlepad xs-headertitle">General</h4>
                {/* </div> */}
                {/* <div className="col"> */}
                {/* <div className="toggleswich">
                  <input
                    type="checkbox"
                    className="checktogle"
                    id="IsUpdateExistEmod"
                    checked={this.state.FormData.IsAllowEmod}
                    onChange={this.handleCheckboxChange}
                  />
                  <b className="switch">
                    <span className="checkion"></span>
                    <span className="uncheckion"></span>
                  </b>
                  <b className="track"></b>
                </div> */}
                {/* </div> */}
              </div>

              <div className="row pt-4 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Name</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Name"
                        value={this.state.FormData.ClientName}
                        name="ClientName"
                        type="text"
                        id="Name"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Responder
                    </label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={ResponderOption}
                        id="ResponderTag"
                        value={this.state.FormData.ResponderName}
                        isMulti
                        onChange={(val) => this.ResponderDropDownHandler(val)}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ResponderName"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Sales Strategist
                    </label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={this.state.SalesOption}
                        id="SalesStrategistTag"
                        value={this.state.FormData.SalesStrategistName}
                        isMulti
                        onChange={(val) => {
                          this.SalesStrategistHandler(val);
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["SalesStrategistName"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Cold Callers
                    </label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={this.state.ColdCallerOption}
                        id="ColdCallersTag"
                        isMulti
                        onChange={(val) => this.ColdCallersHandler(val)}
                        value={this.state.FormData.ColdCallersName}
                      />
                     {/* <span style={{ color: "red" }}>
                        {this.state.errors["ColdCallersName"]}
                      </span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientStatus"
                        value={this.state.FormData.ClientStatus}
                        name="ClientStatus"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option>--Select--</option>}
                        {this.state.ClientStatusList.map((value) => (
                          <option key={value._id} value={value._id}>
                            {value.ClientStatusName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientStatus"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Start Date
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder=""
                        value={this.state.FormData.ClientStartDate}
                        name="ClientStartDate"
                        type="date"
                        id="ClientStartDate"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientStartDate"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client End Date
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder=""
                        type="date"
                        value={this.state.FormData.ClientEndDate}
                        name="ClientEndDate"
                        id="ClientEndDate"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Target Accounts per Qtr
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder=""
                        value={this.state.FormData.TargetAccountsperQtr}
                        name="TargetAccountsperQtr"
                        type="number"
                        id="TargetAccountsPer"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Default Contract Value
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter meeting value"
                        type="text"
                        id="DefaultContractValue"
                        value={this.state.FormData.DefaultContractValue}
                        name="DefaultContractValue"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Enter Website
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Website"
                        type="text"
                        id="Website"
                        value={this.state.FormData.Website}
                        name="Website"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Reason Lost{" "}
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ReasonLost"
                        name="ReasonLost"
                        autoComplete="off"
                        value={this.state.FormData.ReasonLost}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ReasonLostList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ReasonLost"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Logo Approval
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="LogoApproval"
                        name="LogoApproval"
                        value={this.state.FormData.LogoApproval}
                        autoComplete="off"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.LogoApprovalList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Time Zone{" "}
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="Timezone"
                        name="TimeZone"
                        value={this.state.FormData.TimeZone}
                        autoComplete="off"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.TimezoneList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["TimeZone"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Track Domain
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Track Domain"
                        type="text"
                        id="TrackDomain"
                        name="TrackDomain"
                        value={this.state.FormData.TrackDomain}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Closed By
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClosedBy"
                        name="ClosedBy"
                        value={this.state.FormData.ClosedBy}
                        autoComplete="off"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClosedByList.map((value) => (
                          <option key={value._id} value={value._id}>{value.Name}</option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClosedBy"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      BCC Email
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter BCC Email"
                        value={this.state.FormData.BCCEmail}
                        name="BCCEmail"
                        type="text"
                        id="BCCEmail"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["BCCEmail"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Slack Channel Internal
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Slack Channel Internal"
                        type="text"
                        id="SlackChannelInternal"
                        value={this.state.FormData.SlackChannelInternal}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        name="SlackChannelInternal"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Slack Channel External
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Slack Channel External"
                        type="text"
                        id="SlackChannelExternal"
                        value={this.state.FormData.SlackChannelExternal}
                        name="SlackChannelExternal"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Email Sent Per Month Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Email Sent Per Month Per Client"
                        type="number"
                        min={-1}
                        name="LimitEmailsPerMonthPerClient"
                        id="LimitEmailsPerMonthPerClient"
                        value={this.state.FormData.LimitEmailsPerMonthPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LimitEmailsPerMonthPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Emails Emod Per Month Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Emails Emod Per Month Per Client"
                        type="number"
                        min={-1}
                        name="LimitEmailsEmodPerMonthPerClient"
                        id="LimitEmailsEmodPerMonthPerClient"
                        value={this.state.FormData.LimitEmailsEmodPerMonthPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["LimitEmailsEmodPerMonthPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Users Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Users Per Client"
                        type="number"
                        min={-1}
                        name="LimitUsersPerClient"
                        id="LimitUsersPerClient"
                        value={this.state.FormData.LimitUsersPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["LimitUsersPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Limit Total Contacts Per Client</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Limit Total Contacts Per Client"
                        type="number"
                        min={-1}
                        name="LimitTotalContactsPerClient"
                        id="LimitTotalContactsPerClient"
                        value={this.state.FormData.LimitTotalContactsPerClient}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                       <span style={{ color: "red" }}>
                        {this.state.errors["LimitTotalContactsPerClient"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Zoom Info Contact Limit Type</label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ZoomInfoContactLimitType"
                        name="ZoomInfoContactLimitType"
                        autoComplete="off"
                        value={this.state.FormData.ZoomInfoContactLimitType}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        <option value="">--Select--</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                       
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Zoom Info Contact Limit</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Zoom Info Contact Limit"
                        type="number"
                        min={-1}
                        name="ZoomInfoContactLimit"
                        id="ZoomInfoContactLimit"
                        value={this.state.FormData.ZoomInfoContactLimit}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ZoomInfoContactLimit"]}
                      </span>
                    </div>
                  </div>
                </div>

               

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet"> Open AI API Key</label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Open AI API Key"
                        type="text"
                        name="OpenAI_API_KEY"
                        id="OpenAI_API_KEY"
                        value={this.state.FormData.OpenAI_API_KEY}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>       
                <div className="col-lg-6 boxsinput_group">
                  <label className="check_bx floatrighter mr-3">
                    <input
                      id="DoNotAddMeetingToBlackList"
                      type="checkbox"
                      onChange={this.HandleChange}
                      onBlur={this.HandleBlur}
                      checked={this.state.FormData.DoNotAddMeetingsToBlacklist}
                      name="DoNotAddMeetingsToBlacklist"
                    />
                    <span className="checkmark"></span>
                    Do Not Add Meetings To Blacklist?
                  </label>
                </div>
                <div className="col-lg-6"></div>

                <div className="col-lg-6 boxsinput_group">
                  <label className="check_bx floatrighter mr-3">
                    <input
                      id="IsSentMeetingCreateNotif"
                      type="checkbox"
                      onChange={this.HandleChange}
                      onBlur={this.HandleBlur}
                      checked={
                        this.state.FormData.IsSentMeetingCreateNotification
                      }
                      name="IsSentMeetingCreateNotification"
                    />
                    <span className="checkmark"></span>
                    Is Sent Meeting Create Notification
                  </label>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <label className="check_bx floatrighter mr-3">
                    <input
                      id="IsSentMeetingReschedulesNotif"
                      onChange={this.HandleChange}
                      onBlur={this.HandleBlur}
                      type="checkbox"
                      checked={
                        this.state.FormData.IsSentMeetingRescheduleNotification
                      }
                      name="IsSentMeetingRescheduleNotification"
                    />
                    <span className="checkmark"></span>
                    Is Sent Meeting Reschedule Notification
                  </label>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">Channels</label>
                    <div className="col-lg-8">
                      <Select
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={ChannelsOption}
                        id="Channels"
                        value={this.state.FormData.Channels}
                        isMulti
                        onChange={(val) => this.ChannelDropDownHandler(val)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Server Number
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control"
                        id="ServerNumber"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        value={this.state.FormData.ServerNumber}
                        name="ServerNumber"
                      >
                        <option value="">--Select--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ServerNumber"]}
                      </span>
                    </div>
                  </div>
                </div>
              <ProfileImageUpload
                profileImage={this.state.FinalImg}
                ref={this.childRef}
              />
               <div className="col-lg-4 boxsinput_group mb-4"></div>
                  <div className="col-lg-4 boxsinput_group mb-4">
                    <div className="row">
                    <label className="col-lg-6 view-lable-meet"> eMod </label>

                      <div className="toggleswich mt-2">
                        <input type="checkbox" className="checktogle" id="eMod" name="IsAllowEmod" checked={this.state.FormData.IsAllowEmod}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}/>
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-4 boxsinput_group mb-4">
                    <div className="row">
                    <label className="col-lg-6 view-lable-meet"> SEO </label>

                      <div className="toggleswich mt-2">
                        <input type="checkbox" className="checktogle" id="SEO" name="IsSeoReporting" checked={this.state.FormData.IsSeoReporting}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}/>
                        <b className="switch">
                          <span className="checkion"></span>
                          <span className="uncheckion"></span>
                        </b>
                        <b className="track"></b>
                      </div>

                    </div>
                  </div>
              </div>
            </div>

            <div className="bg-white mt-3">
              <div className="row borderbtnm mx-0">
                <div className="col">
                  <h4 className="headertitlepad xs-headertitle float-left">Billing</h4>
                </div>
              </div>

              <div className="row py-2 mx-0">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Method
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientBillingMethod"
                        name="ClientBillingMethod"
                        autoComplete="off"
                        value={this.state.FormData.ClientBillingMethod}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClientBillingMethodList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Contact
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Contact"
                        value={this.state.FormData.ClientBillingContact}
                        name="ClientBillingContact"
                        type="text"
                        id="ClientBillingContact"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Email
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Email"
                        type="text"
                        id="ClientBillingEmail"
                        value={this.state.FormData.ClientBillingEmail}
                        name="ClientBillingEmail"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientBillingEmail"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Address
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Address"
                        type="text"
                        value={this.state.FormData.ClientBillingAddress}
                        name="ClientBillingAddress"
                        id="ClientBillingAddress"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Date
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enter Client Billing Email"
                        type="date"
                        value={this.state.FormData.ClientBillingDate}
                        name="ClientBillingDate"
                        id="ClientBillingDate"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Status
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientBillingStatus"
                        name="ClientBillingStatus"
                        autoComplete="off"
                        value={this.state.FormData.ClientBillingStatus}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClientBillingStatusList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Amount
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        value={this.state.FormData.ClientBillingAmount}
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        name="ClientBillingAmount"
                        placeholder="Enter Client Billing Amount"
                        type="text"
                        id="ClientBillingAmount"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientBillingAmount"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Client Billing Period
                    </label>
                    <div className="col-lg-8">
                      <select
                        className="form-control  m-input"
                        id="ClientBillingPeriod"
                        value={this.state.FormData.ClientBillingPeriod}
                        name="ClientBillingPeriod"
                        autoComplete="off"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                      >
                        {<option value="">--Select--</option>}
                        {this.state.ClientBillingPeriodList.map((value) => (
                          <option key={value.FieldID} value={value.FieldID}>
                            {value.FieldName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientBillingPeriod"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      Avg. Monthly Revenue
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        placeholder="Enteravg monthly revenue"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        value={this.state.FormData.AvgMonthlyRevenue}
                        name="AvgMonthlyRevenue"
                        type="text"
                        id="AvgMonthlyRevenue"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols">
                    <label className="col-lg-4 view-lable-meet">
                      PPM Est. Months
                    </label>
                    <div className="col-lg-8">
                      <input
                        className="form-control"
                        value={this.state.FormData.PPMEstMonths}
                        name="PPMEstMonths"
                        onChange={this.HandleChange}
                        onBlur={this.HandleBlur}
                        placeholder="Enter avg ppm est months"
                        type="text"
                        id="PPMEstMonths"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row py-4">
              <div className="col-lg-12 pull-left">
                <button
                  id="submit"
                  // onClick={this.SaveBtn}
                  onClick={this.handleSubmit}
                  disabled={this.state.IsButtonDisabled}
                  className="btn btn-primary btn-lightgreen mr-1"
                >
                  <i className="la la-save"></i> Save
                </button>
                <a
                  id="backtolist"
                  onClick={this.BackBtn}
                  className="btn btn-secondary"
                >
                  <i className="la la-arrow-circle-left"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedEditClientsPage = connect(
  mapState,
  actionCreators
)(EditClientsPage);
export { connectedEditClientsPage as EditClientsPage };
