import React,  { useEffect } from 'react';
import Axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { toast } from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import loadingicon from "../../../images/loading.gif";
import StyleHeader from "../../user/StickyHeader/StickyHeader";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


export default function AccountAllTable() {
  const [KeywordArray, SetKeywordArray] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [BackupdateData,SetBackupdateData] = React.useState([])
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [OrderSort,SetOrderSort]  = React.useState(1);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [CUserID,SetCUserID] = React.useState(0)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    
  }, []);
  // 
  function extractSubstr(str, regexp) {
    return str.replace(/[^\w\s]|_/g, '')
        .replace(/\s+/g, ' ')
        .toLowerCase().match(regexp) || [];
}
  // Find words by searching for sequences of non-whitespace characters.
function getWordsByNonWhiteSpace(str) {
  return extractSubstr(str, /\S+/g);
}

//search for record
const RequestSearch = (Event) => {
  if (Event.key === "Enter") {
    SetIsLoading(true)
    var SearchedVal = document.getElementById("search").value.trim();
    if(SearchedVal.length != 0){
      function searchWithRegex(data, searchPattern) {
        const combinedRegex = new RegExp(searchPattern, 'i'); // Case-insensitive search
    
        return data.filter(item => {
          SetIsLoading(false)
            return (combinedRegex.test(item.Keyword) || combinedRegex.test(item.Counts.toString()));
        });
      }
      SetRows(searchWithRegex(BackupdateData,SearchedVal))

    }else{
      SetRows(BackupdateData)
  SetIsLoading(false)

    }
    
  }
};

//change Page
const HandleChangePage = (Event, NewPage) => {
  SetPage(NewPage);
 
};

//change display rows
const ChangeRowSelected = (Event) => {
  SetRowsPerPage(Event.target.value);
  Rows.slice((Page - 1) * RowsPerPage,(Page - 1) * RowsPerPage + RowsPerPage)
  SetPage(1);
  SetRows(Rows.slice((Page - 1) * RowsPerPage,(Page - 1) * RowsPerPage + RowsPerPage))
  SetRows(Rows.slice((Page - 1) * RowsPerPage,(Page - 1) * RowsPerPage + RowsPerPage));
  SetRlen(Rows.slice((Page - 1) * RowsPerPage,(Page - 1) * RowsPerPage + RowsPerPage).length);
  SetFlen(Rows.slice((Page - 1) * RowsPerPage,(Page - 1) * RowsPerPage + RowsPerPage).length);
  SetCountPage(parseInt((Rows.slice((Page - 1) * RowsPerPage,(Page - 1) * RowsPerPage + RowsPerPage).length + (RowsPerPage - 1)) / RowsPerPage));

  
};
const ParagrapghCount = async() =>{
  SetIsLoading(true)
  var Paragrapgh = document.getElementById("CopyMail").value;
//  var word1 = getWordsByNonWhiteSpace(Paragrapgh)
 var InputParameter = {
  KeywordCheck :Paragrapgh,
  Role:Role
 }
 var res = await Axios({
  url:
    CommonConstants.MOL_APIURL +
    "/despammer/CheckParagraphKeyword",
  method: "POST",
  data: InputParameter,
  
});
//
if (res.data.StatusMessage == "SUCCESS") {
  SetBackupdateData(res.data.Data)
  SetRows(res.data.Data)
  SetRows(res.data.Data);
  SetRlen(res.data.Data.length);
  SetFlen(res.data.Data.length);
  SetCountPage(parseInt((res.data.Data.length + (RowsPerPage - 1)) / RowsPerPage));
  SetIsLoading(false)
  setIsDataLoaded(true);
} else {
  toast.error(res.data?.Message);
  SetIsLoading(false)
  setIsDataLoaded(true);
}

}

const SortingMethod = (Field) =>{
  SetIsLoading(true)

  if(OrderSort == 1){
    SetOrderSort(-1)
  }else if(OrderSort == -1){
    SetOrderSort(1)
  }
  function customSort(data, sortBy, sortOrder) {
    return [...data].sort((a, b) => {
        if (sortBy === "Keyword") {
            if (sortOrder === 1) {
              SetIsLoading(false)
                return a.Keyword.localeCompare(b.Keyword) || a.Counts - b.Counts;
            } else if (sortOrder === -1) {
            SetIsLoading(false)

                return b.Keyword.localeCompare(a.Keyword) || b.Counts - a.Counts;
            }
            SetIsLoading(false)

        } else if (sortBy === "Counts") {
            if (sortOrder === 1) {
            SetIsLoading(false)

                return a.Counts - b.Counts || a.Keyword.localeCompare(b.Keyword);
            } else if (sortOrder === -1) {
            SetIsLoading(false)

                return b.Counts - a.Counts || b.Keyword.localeCompare(a.Keyword);
            }
            SetIsLoading(false)
        }

    });
  }
  SetRows(customSort(BackupdateData,Field,OrderSort))

}

  return (
    <>
       {
      IsLoading ? <div id="hideloding" className="loding-display">
      <img src={loadingicon} />
    </div> : <></>
    }
    <div class='bg-white'>
    <div className='pt-3'>
    <div className="row mb-3">
    <div className="col-xl-12">
        <form>
          <div class="col-lg-12">
            <label>Email Copy </label>
              <textarea id="CopyMail" class="form-control m-input hwt-content hwt-input" rows="15" autocomplete="off" spellcheck="false"></textarea>
          </div>
          <div class="col-lg-12 alignright">
              <a id="lnkDespam" onClick={ParagrapghCount}  class="btn btn-primary btnColor" > Despam</a>
          </div>
        </form>
    </div>
    </div>
  </div>

  <div className='whitebg'>
  <div className="row my-3 border-bottom border-top mx-0">
        <div className="col">
            <h4 className="headertitle xs-headertitle px-0 float-left">Total Issues ({Rows.length})</h4> 
        </div>

      </div>
      <div className="row my-3 mx-0">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:{" "}
            <input
              type="search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div class='px-3'>
    <div className="table-bordered">
    <TableContainer component={Paper}>
    <StyleHeader isDataLoaded={isDataLoaded} />
      <Table class='table-ref w-100' aria-label="collapsible table">
        <TableHead>
          <TableRow> 
            <TableCell ><a onClick={()=>{SortingMethod("Keyword")}}>Terms</a></TableCell> 
            <TableCell ><a onClick={()=>{SortingMethod("Counts")}}>Terms Used?</a></TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.length ===  0?(
            <p className="text-center">No data available in table</p>
          ):(
            Rows?.map((Row,i)=>{
              return (<TableRow key={i}>
              <TableCell>{Row.Keyword}</TableCell>
              <TableCell>{Row.Counts}</TableCell>
            </TableRow>)
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
      </div>
    {Sflag ? (
      <div className="row mx-0 pb-2">
        <Pagination
          component="div"
          count={CountPage}
          onChange={HandleChangePage}
          showFirstButton
          showLastButton
        />
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries (filtered from {Flen} total entries)
          </p>
        </div>
      </div>
    ) : (
      <div className="row mx-0 pb-2">
        <div className="col dataTables_info">
          <p>
            Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
            {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
            entries
          </p>
        </div>
        <div className="col pageright">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    )}
    
  </div>
    </div>
    </>
  );
}
