import React,{ useEffect }   from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
const moment = require("moment");
import loadingicon from "../../../images/loading.gif";
import Axios from "axios";
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import Swal from "sweetalert2";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import StyleHeader from "../../user/StickyHeader/StickyHeader";


export default function AccountAllTable({updateFromChild}) {
  const [data, setdata] = React.useState([]);
  const [flen, setflen] = React.useState(0);
  const [sflag, setsflag] = React.useState(false);
  const [rlen, setrlen] = React.useState(0);
  const [countpage, setCountPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [search, setSearch] = React.useState("");
  const [sortField, setsortField] = React.useState("Name");
  const [sortedBy, setsortedBy] = React.useState(1);
  const [ClientID, setClientID] = React.useState(0);
  const [UserID, setUserID] = React.useState(0);
  const [Role, setRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0);
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = "Custom Variable | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      setClientID(Details.ClientID);
      setUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      setRole(Details.Role);
    }

    GetCustomVariableList(Details.ClientID,Details.ParentUserID,Details.Role);
  }, [search, page, RowsPerPage]);

  
  const GetCustomVariableList = (CID, UID) => {
    SetIsLoading(true)
    var str_in = {
      Page: page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: sortField,
      SortBy: sortedBy,
      Search: search,
      type: "User",
      ClientID: CID,
      UserID: UID
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/customvariable/GetCustomVariable",
      method: "POST",
      data: str_in,
    });
    rows1.then((result) => {
      setdata(result.data.PageData);
      setRows(result.data.PageData);
      setrlen(result.data.TotalCount);
      setflen(result.data.TotalCount);
      setCountPage(result.data.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    });
  };

  const chengeRowSelected = (event) => {

    SetRowsPerPage(event.target.value);
    setPage(1);
    GetCustomVariableList(ClientID, UserID, Role);
  };
  const DeleteCustomvariable = (id) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete a custom variable.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons:true,
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          CustomVariableID: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDt: new Date(),
        };
        
        Axios({
          url:
            CommonConstants.MOL_APIURL + "/customvariable/CustomVariableDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "custom variable deleted successfully.",
                "success"
              );
              
              history.push("/CCustomVariable")    
              GetCustomVariableList(ClientID,UserID);
              updateFromChild(true);
            
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };
  const EditCustomVariable = (id) => {
    history.push({ pathname: "/CEditCustomVariable", state: { data: id } });
  };
  const handleChangePage = (event, newPage) => {
    if (newPage == page) {
      setPage(newPage);
    } else {
      setPage(newPage);
      SetIsLoading(true)
    }
  };
  const searchQuery = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      if (SearchedVal == search) {
        setSearch(SearchedVal);
        setPage(1);
      } else {
        setSearch(SearchedVal);
        setPage(1);
        SetIsLoading(true)
      }
    }
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("search").value;

    var SerchBox;
    if (SearchedVal == "") {
      SetIsLoading(true)
      SerchBox = false;
      setsflag(false);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      setsortField(Field);
      var InputParameter = {
        Page: page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: false,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
      };

      const CustomVariableList = Axios({
        url: CommonConstants.MOL_APIURL + "/customvariable/GetCustomVariable",
        method: "POST",
        data: InputParameter,
      });
      CustomVariableList.then((Result) => {

        setdata(Result.data.PageData);
        setRows(Result.data.PageData);
        setrlen(Result.data.TotalCount);
        setflen(Result.data.TotalCount);
        setCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    } else {
      SerchBox = true;
      setsflag(true);
      var SortField = Field;
      var SortBy;
      if (sortedBy == 1) {
        SortBy = -1;
        setsortedBy(-1);
      } else {
        SortBy = 1;
        setsortedBy(1);
      }
      SetIsLoading(true)

      setsortField(Field);
      var InputParameter = {
        Page: page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,

      };

      const CustomVariableList = Axios({
        url: CommonConstants.MOL_APIURL + "/customvariable/GetCustomVariable",
        method: "POST",
        data: InputParameter,
      });
      CustomVariableList.then((Result) => {
        setdata(Result.data.PageData);
        setRows(Result.data.PageData);
        setrlen(Result.data.TotalCount);
        setflen(Result.data.TotalCount);
        setCountPage(Result.data.PageCount);
        SetIsLoading(false)

      });
    }
  };

  return (
    <>
             {IsLoading ? <div id="hideloding" className="loding-display">
              <img src={loadingicon} />
            </div> :<></>
             
            } 
      <div className="row pt-3">
          <div className="col-sm-12 col-md-6">
            <label className="textlabelte">
              Show
              <select
                name="tbl_meeting_length"
                onChange={chengeRowSelected}
                aria-controls="tbl_meeting"
                className="form-control form-control-sm"
                value={RowsPerPage}
              >
                {CommonConstants.show_rows.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
              entries
            </label>
          </div>
          <div className="col-sm-12 col-md-6 full-right">
            <label className="textlabelte">
              Search:{" "}
              <input
                type="search"
                id="search"
                onKeyPress={searchQuery}
                className="form-control form-control-sm ml-2"
                placeholder=""
                aria-controls="tbl_meeting"
              />
            </label>
          </div>
        </div>
        <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table class='table-ref w-100' aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell         onClick={() => {
                    SortData("Name");
                  }}>   <a
          
                >Name
                    <span className="shorting">
                      <ArrowUpward className={sortedBy === 1 && sortField === "Name" ? "active" : null} />
                      <ArrowDownward className={sortedBy === -1 && sortField === "Name" ? "active" : null} />
                    </span>
                </a></TableCell>
                <TableCell  onClick={() => {
                    SortData("VariableTypeName");
                  }}><a
                 
                >Variable Type
                   <span className="shorting">
                      <ArrowUpward className={sortedBy === 1 && sortField === "VariableTypeName" ? "active" : null} />
                      <ArrowDownward className={sortedBy === -1 && sortField === "VariableTypeName" ? "active" : null} />
                    </span>
                </a></TableCell>
                <TableCell        onClick={() => {
                    SortData("CreatedDate");
                  }}><a
           
                >Created Date
                   <span className="shorting">
                      <ArrowUpward className={sortedBy === 1 && sortField === "CreatedDate" ? "active" : null} />
                      <ArrowDownward className={sortedBy === -1 && sortField === "CreatedDate" ? "active" : null} />
                    </span>
                </a></TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (rows.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.VariableTypeName}</TableCell>
                  <TableCell>      {moment(new Date(row.CreatedDate).toDateString()).format(
                    "MM/DD/YYYY"
                  )}</TableCell>
                  <TableCell>
                    <a

                      onClick={() => {
                        EditCustomVariable(row._id);
                      }}
                      className="btn-eyesicon" title="Edit"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a

                      onClick={() => {
                        DeleteCustomvariable(row._id);
                      }}
                      className="btn-eyesicon" title="Delete"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
          {sflag ? (
          <div className="row">
            <Pagination
              component="div"
              count={countpage}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
            <div className="col dataTables_info">
              <p>
                Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of{" "}
                {rlen} entries 
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col dataTables_info">
            <p>
                Showing {rlen == 0 ? 0 : (page - 1) * RowsPerPage + 1} to{" "}
                {page * RowsPerPage > rlen ? rlen : page * RowsPerPage} of{" "}
                {rlen} entries 
              </p>
            </div>
            <div className="col pageright">
              <Pagination
                component="div"
                count={countpage}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
    </div>
    </>
  );
}
