import React from "react";
import Axios from "axios";
import CreatableSelect from 'react-select/creatable';
import moment from "moment";

import { CommonConstants } from "../../../_constants/common.constants";
import { toast } from "react-toastify";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { connect } from "react-redux";
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { userActions } from "../../../_actions";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Sidebar from "../../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../../_components/user/footer/footer";
import "react-toastify/dist/ReactToastify.css";
import loadingicon from "../../../images/loading.gif";

class AddContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],  
      fields: {},
      errors: {},
      statusAvailable: null,
      data: [],
      rows: [],
      rlen: 0,
      flen: 0,
      countpage: 0,
      data1: [],
      rows1: [],
      rlen1: 0,
      flen1: 0,
      countpage1: 0,
      ClientID: null,
      UserID: null,
      CUserID: null,
      ContactSourceData: [],
      ClientPOCData: [],
      ReasonUnqualifiedData: [],
      ContactTagData: [],
      ContactOwnerData: [],
      ContactSalesDevlopMenData: [],
      ContactCustomFieldData: [],
      checked: false,
      fields: {},
      errors: {},
      FirstNameAvailable: null,
      LastNameAvailable: null,
      TitleNameAvailable: null,
      ContactTagSelectArrayCreatable:[],
      ContactTagSelectArrayAlready:[],
      IsWebsite: true,
      WebsiteNotNull: false,
      ContactTagsNamesArrayCreatable:[],
      ContactTagsNamesArrayAlready:[],
      TagArrayCreatable:[],
      TagArrayAlready:[],
      IsInValidWebsite:false,
      IsValidEmail:false,
      SalesDevelopmentRepsData:[],
      IsLoading:true,
      IsBtnDisabled:false,
      TagNameArray:[],
      TagsArrayCreatable:[],
      Role:"",
      UserName:"",
      ColdCallerLoginSalesDevlopmentName:"",
      ColdCallerLoginSalesDevlopmentID:null,
      FollowUpDate:""
    };
    this.backbtn = this.backbtn.bind(this);
    this.savebtn = this.savebtn.bind(this);
  }

  componentDidMount() {
    document.title = "Add SalesHive Contact | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      this.setState({ ClientID: Details.ClientID });
      this.setState({ UserID: Details.ParentUserID });
      this.setState({ CUserID: Details.ChildUserID });
      this.setState({ Role: Details.Role });
      this.setState({ UserName: Details.Username });

    }
    this.ContactInfoGetData()
  
  }
  

  handleChangecheck = (e) => {
    const { checked } = e.target;
    this.setState({
      checked: checked,
    });
  };

async  FromValidation() {
  let formIsValid = true;
  let errors = {}
  var FirstName = document.getElementById("FirstName").value.trim();
    var LastName=document.getElementById("LastName").value.trim()
    var Email = document.getElementById("Email").value.trim();
    var Company = document.getElementById("Company").value.trim();
    var Website = document.getElementById("Website").value.trim();
    let IsExistWebsite = await this.CheckWebSite(Website);
    let IsExistEmail = await this.EmailValidationCheck(Email);
    if (FirstName == "") {
      formIsValid = false;
      toast.error("Please enter First name")
      // this.setState({ errors: errors });
    return formIsValid;
    }
    if (Website == "" && FirstName != "") {
      formIsValid = false;
      toast.error("Please enter Website")
      // this.setState({ errors: errors });
    return formIsValid;
    }
    // if (Email == "" && Website.length > 0 && FirstName != "") {
    //   formIsValid = false;
    //   toast.error("Please enter email")
    // }
    if (IsExistWebsite == true) {
      formIsValid = false;
      errors["Website"] = "Invalid website!";
      // toast.error("Please enter First name, Last name and Company OR First name and Email")
      this.setState({ errors: errors });
    // return formIsValid;
    }
    if (IsExistEmail == true) {
      formIsValid = false;
      // errors["Website"] = "Invalid email!";
      toast.error("Invalid email!")
    }
    if ((Email === "" && (Company === "" || LastName === "")) || (Company === "" && LastName === "" && Email === "")) {
      formIsValid = false;
      toast.error("Please enter First name, Last name and Company OR First name and Email");
    }
    let MobilePhone = document.getElementById("MobilePhone").value.trim(); 
    // Check if the value is not numeric or is negative
     if (MobilePhone !== "" && !/^[0-9]+$/.test(MobilePhone)) {
     formIsValid=false;
     errors["MobilePhone"] = "Invalid MobilePhone!";
     return formIsValid;
     } else {
   
     errors["MobilePhone"] = null; // Reset the error if the value is valid or empty
     }
   
     let OtherPhone1 = document.getElementById("OtherPhone1").value.trim(); 
     // Check if the value is not numeric or is negative
      if (OtherPhone1 !== "" && !/^[0-9]+$/.test(OtherPhone1)) {
      formIsValid=false;
      errors["OtherPhone1"] = "Invalid OtherPhone1!";
      return formIsValid;
      } else {
      errors["OtherPhone1"] = null; // Reset the error if the value is valid or empty
      }
   
      let OtherPhone2 = document.getElementById("OtherPhone2").value.trim(); 
      // Check if the value is not numeric or is negative
       if (OtherPhone2 !== "" && !/^[0-9]+$/.test(OtherPhone2)) {
       formIsValid=false;
       errors["OtherPhone2"] = "Invalid OtherPhone2!";
       return formIsValid;
       } else {
       errors["OtherPhone2"] = null; // Reset the error if the value is valid or empty
       }
   
       let BestPhone = document.getElementById("BestPhone").value.trim(); 
       // Check if the value is not numeric or is negative
        if (BestPhone !== "" && !/^[0-9]+$/.test(BestPhone)) {
        formIsValid=false;
        errors["BestPhone"] = "Invalid BestPhone!";
        return formIsValid;
        } else {
        errors["BestPhone"] = null; // Reset the error if the value is valid or empty
        }
   
   
        let Phone = document.getElementById("Phone").value.trim(); 
        // Check if the value is not numeric or is negative
         if (Phone !== "" && !/^[0-9]+$/.test(Phone)) {
         formIsValid=false;
         errors["Phone"] = "Invalid Phone!";
         return formIsValid;
         } else {
         errors["Phone"] = null; // Reset the error if the value is valid or empty
         }
   
         let Phone2 = document.getElementById("Phone2").value.trim(); 
         // Check if the value is not numeric or is negative
          if (Phone2 !== "" && !/^[0-9]+$/.test(Phone2)) {
          formIsValid=false;
          errors["Phone2"] = "Invalid Phone2!";
          return formIsValid;
          } else {
   
          errors["Phone2"] = null; // Reset the error if the value is valid or empty
          }
   
          let Phone3 = document.getElementById("Phone3").value.trim(); 
          // Check if the value is not numeric or is negative
           if (Phone3 !== "" && !/^[0-9]+$/.test(Phone3)) {
           formIsValid=false;
           errors["Phone3"] = "Invalid Phone3!";
           return formIsValid;
           } else {
   
           errors["Phone3"] = null; // Reset the error if the value is valid or empty
           }
   
           let Phone4 = document.getElementById("Phone4").value.trim(); 
           // Check if the value is not numeric or is negative
            if (Phone4 !== "" && !/^[0-9]+$/.test(Phone4)) {
            formIsValid=false;
            errors["Phone4"] = "Invalid Phone4!";
            return formIsValid;
            } else {
   
            errors["Phone4"] = null; // Reset the error if the value is valid or empty
            }
   
            let DirectPhoneExt = document.getElementById("DirectPhoneExt").value.trim(); 
            // Check if the value is not numeric or is negative
             if (DirectPhoneExt !== "" && !/^[0-9]+$/.test(DirectPhoneExt)) {
             formIsValid=false;
             errors["DirectPhoneExt"] = "Invalid DirectPhoneExt!";
             return formIsValid;
             } else {  
   
             errors["DirectPhoneExt"] = null; // Reset the error if the value is valid or empty
             }
   
             let CompanyPhone = document.getElementById("CompanyPhone").value.trim(); 
             // Check if the value is not numeric or is negative
              if (CompanyPhone !== "" && !/^[0-9]+$/.test(CompanyPhone)) {
              formIsValid=false;
              errors["CompanyPhone"] = "Invalid CompanyPhone!";
              return formIsValid;
              } else {
   
              errors["CompanyPhone"] = null; // Reset the error if the value is valid or empty
              }
   
              let DirectPhone = document.getElementById("DirectPhone").value.trim(); 
              // Check if the value is not numeric or is negative
               if (DirectPhone !== "" && !/^[0-9]+$/.test(DirectPhone)) {
               formIsValid=false;
               errors["DirectPhone"] = "Invalid DirectPhone!";
               return formIsValid;
               } else {
   
               errors["DirectPhone"] = null; // Reset the error if the value is valid or empty
               }
   
               let ZoomID = document.getElementById("ZoomID").value.trim(); 
     // Check if the value is negative
     if (ZoomID !== "" && parseInt(ZoomID) < 0) {
       formIsValid=false;
       errors["ZoomID"] = "Invalid ZoomID!";
       return formIsValid;
     } else {
   
       errors["ZoomID"] = null; // Reset the error if the value is valid or empty
   
     }
    
    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChangeWebsitecheck() {
    var Website = document.getElementById("Website").value.trim();
    if (Website != "") {
      let errors = {};
      this.state.errors.Website = null;
      this.CheckWebSite(Website)
      errors["Website"] = "Invalid website!";
    } else {
      // this.state.statusAvailable = null;
      this.state.errors.Website = null;
    }
  }

  
  handleChangePhoneValidation(fieldName) {
    const value = document.getElementById(fieldName).value.trim();
    let errors = { ...this.state.errors }; // Copy the current state of errors
 // Check if the value is not numeric or is negative
 if (value !== "" && !/^[0-9]+$/.test(value)) {
  errors[fieldName] = "Invalid " + fieldName + "!";
} else {
  errors[fieldName] = null; // Reset the error if the value is valid or empty
}

  // Update the state with the new errors
    this.setState({ errors });
}

hadleChangeZoomID(fieldName){
  const value = document.getElementById(fieldName).value.trim();
  let errors = { ...this.state.errors }; // Copy the current state of errors

  // Check if the value is negative
  if (value !== "" && parseInt(value) < 0) {
    errors[fieldName] = "Invalid " + fieldName + "!";
  } else {
    errors[fieldName] = null; // Reset the error if the value is valid or empty

  }
  this.setState({ errors });
}
  // handleChange(field, e) {
  //   let fields = this.state.fields;
  //   fields[field] = e.target.value;
  //   this.setState({ fields });
  //   if (fields.FirstName != "") {
  //     this.state.errors.FirstName = null;
  //     this.state.FirstNameAvailable = null;
  //     this.CheckExistContactName(e.target.value);
  //   } else {
  //     this.state.FirstNameAvailable = null;
  //     this.state.errors.FirstName = null;
  //   }

  //   if (fields.LastName != "") {
  //     this.state.errors.LastName = null;
  //     this.state.LastNameAvailable = null;
  //     this.CheckExistContactName(e.target.value);
  //   } else {
  //     this.state.LastNameAvailable = null;
  //     this.state.errors.LastName = null;
  //   }
  // }

  backbtn(){
    if (this.props.location.state["Pagename"] == "AccountContact") {
      history.push({
        pathname: "/viewaccount",
        state: { data: this.props.location.state?.data},
      });
    }else if(this.props.location.state["Pagename"] == "ColdCallingContacts"){
      history.push("/ColdCallingContact");
    }else{
      history.push("/contacts");
    }

  }

  // // for last name
  // handleChangeLastname(field, e) {
  //   let fields = this.state.fields;
  //   fields[field] = e.target.value;
  //   this.setState({ fields });
  //   if (fields.LastName != "") {
  //     this.state.errors.LastName = null;
  //     this.state.LastNameAvailable = null;
  //     this.CheckExistLastContactName(e.target.value);
  //   } else {
  //     this.state.LastNameAvailable = null;
  //     this.state.errors.LastName = null;
  //   }
  // }
  // for title check
  // handleChangeTitlename(field, e) {
  //   let fields = this.state.fields;
  //   fields[field] = e.target.value;
  //   this.setState({ fields });

  //   if (fields.Title != "") {
  //     this.state.errors.Title = null;
  //     this.state.TitleNameAvailable = null;
  //     this.CheckExistTitleContact(e.target.value);
  //   } else {
  //     this.state.TitleNameAvailable = null;
  //     this.state.errors.Title = null;
  //   }
  // }

  // CheckExistContactName(FirstName) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     FirstName: FirstName,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/ContactExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data.length > 0) {
  //         this.setState({ FirstNameAvailable: resdata.data.Data[0].FirstName });
  //       }
  //     }
  //   });
  // }
  // // Last Name
  // CheckExistLastContactName(LastName) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     LastName: LastName,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/ContactLastNameExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data.length > 0) {
  //         this.setState({ LastNameAvailable: resdata.data.Data[0].LastName });
  //       }
  //     }
  //   });
  // }

  // // Check title name
  // CheckExistTitleContact(Title) {
  //   var str_in = {
  //     ClientID: this.state.ClientID,
  //     Title: Title,
  //   };
  //   Axios({
  //     url: CommonConstants.MOL_APIURL + "/contacts/ContactTitleExists",
  //     method: "POST",
  //     data: str_in,
  //   }).then((resdata) => {
  //     if (resdata.data.StatusMessage == "SUCCESS") {
  //       if (resdata.data.Data.length > 0) {
  //         this.setState({ TitleNameAvailable: resdata.data.Data[0].Title });
  //       }
  //     }
  //   });
  // }

  // check website name
  async CheckWebSite(Email) {
      // let fields = this.state.fields;
      // fields[field] = e.target.value;
      // this.setState({ fields });
      let Errors = {};
      var regex = new RegExp(
        "^(?!(https?://)?www\\.)" + // Ensure it does not start with https://www.
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
    
        // let WebsiteGet = document.getElementById("Website").value;
        if (!regex.test(Email) && Email!="") {
          // this.setState({ IsWebsite: false });
          Errors["Website"] = "Invalid website";
          this.setState({ errors: Errors });
          this.setState({ IsInValidWebsite: true });
          return true
        } else {
          // this.setState({ IsWebsite: true });
          this.setState({ WebsiteNotNull: true });
          return false;
        }
    }

  // cheack email valid
  async EmailValidationCheck (Email) {
      let regexp =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let Errors = {};
      if (!regexp.test(Email) && Email != "") {
        Errors["Email"] = "invalid email.";
        this.setState({ IsValidEmail: true });
        this.setState({errors:Errors});
        return true;
      } else {
        this.setState({ IsValidEmail: false });

        return false;
      }
    };

  //     // Regex for Url Validation
  //     WebsitePatternValidation = (Website) => {
  //   var regex = new RegExp(
  //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
  //       "((\\d{1,3}\\.){3}\\d{1,3}))" +
  //       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
  //       "(\\?[;&a-z\\d%_.~+=-]*)?" +
  //       "(\\#[-a-z\\d_]*)?$",
  //     "i"
  //   ); //validation
  //   return regex.test(Website);
  // };
  // check info
  async ContactInfoGetData() {
    this.setState({IsLoading:true})
    var Details = GetUserDetails();
    var str_in = {
      ClientID: Details.ClientID,
      UserID:Details.ParentUserID,
      Role:Details.Role,
      Email:Details.Username
    };

    const rows = await Axios({
      url: CommonConstants.MOL_APIURL + "/contacts/AddContactInfoDetailsGet",
      method: "POST",
      data: str_in,
    });
    this.setState({
      ContactSourceData: rows.data?.ContactSourceData,
      ClientPOCData: rows.data?.ClientPOCData,
      ReasonUnqualifiedData: rows.data?.ReasonUnqualifiedData,
      ContactTagData: rows.data?.ContactTagData,
      ContactOwnerData: rows.data?.ContactOwnerData,
      ContactSalesDevlopMenData: rows.data?.ContactSalesDevlopMenData,
      ContactCustomFieldData: rows.data?.ContactCustomFieldData,
      SalesDevelopmentRepsData:rows.data?.SalesReplyDevlopmentData,
      ColdCallerLoginSalesDevlopmentID:rows.data?.ColdCallLoginData[0]?._id,
      ColdCallerLoginSalesDevlopmentName: (rows.data?.ColdCallLoginData[0]?.FirstName || '') + 
      (rows.data?.ColdCallLoginData[0]?.LastName ? ` ${rows.data.ColdCallLoginData[0].LastName}` : ''),
      IsLoading:false
    });


  }


  // handleChangeContactOption = (val) => {
  //   var ContactTagIDArray = [];
  //   var ContactTagNameArray = [];
  //   var TagsArray = [];
  //   val?.map((item) => {
  //     ContactTagIDArray.push(item.cid);
  //   })
  //   this.setState({
  //     ContactTagSelectArray:ContactTagIDArray
  //   })
  //   // contact tag name
  //   val?.map((item) => {
  //     ContactTagNameArray.push(item.label);
  //   })
  //   this.setState({
  //     ContactTagsNamesArray:ContactTagNameArray
  //   })
  //   // tag id and name
  //   val?.map((item) => {
  //     TagsArray.push({
  //       ContactTagID:item.cid,
  //       TagName:item.label
  //     })
  //   })
  //   this.setState({
  //     TagArray:TagsArray
  //   })
   

  // };
  handleChangeContactOption = (val, actionMeta) => {
    const { action } = actionMeta;
  
    let ContactTagsNamesArray = [];
    let TagArray = [];
  
    if (action === 'create-option' || action === 'select-option') {

      ContactTagsNamesArray = val.map((item) => item.label);
      TagArray = val.map((item) => ({
        TagName: item.label,
      }));
      this.setState({
        TagNameArray:TagArray
      })
    }

  
    if (action === 'create-option' || action === 'select-option') {

      const TagsArrayAlready = val.map((item) => ({
        ContactTagID: item.cid,
        TagName: item.label,
      }));
  
      this.setState({
        ContactTagsNamesArrayAlready: ContactTagsNamesArray,
        TagArrayAlready: TagsArrayAlready,
      });
    }
  };
  
  

 async savebtn(e) {
try {
    var FormValidationFlag = await this.FromValidation();
    if(FormValidationFlag === true){

      this.setState({ IsLoading: true});
      this.setState({ IsBtnDisabled: true });
      var FirstName = document.getElementById("FirstName").value;
      var LastName = document.getElementById("LastName").value;
      var Email = document.getElementById("Email").value;
      var Title = document.getElementById("Title").value;
      var Company = document.getElementById("Company").value;
      var CompanyNameLong = document.getElementById("CompanyNameLong").value;
      var Website = document.getElementById("Website").value.trim();
      var Industry = document.getElementById("Industry").value;
      var Address1 = document.getElementById("Address1").value;
      var Address2 = document.getElementById("Address2").value;
      var City = document.getElementById("City").value;
      var State = document.getElementById("State").value;
      var Zip = document.getElementById("Zip").value;
      var LinkedInURL = document.getElementById("LinkedInURL").value;
      var Country = document.getElementById("Country").value;
      var ContactSourceID = document.getElementById("ContactSourceID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var ClientPOCID = document.getElementById("ClientPOCID").value;
      var ContactOwnerID = document.getElementById("ContactOwnerID").value;
      var ReasonUnqualifiedID = document.getElementById(
        "ReasonUnqualifiedID"
      ).value;
      var ContactCategory = document.getElementById("ContactCategory").value;
      var AccountCategory = document.getElementById("AccountCategory").value;
      var MobilePhone = document.getElementById("MobilePhone").value;

      var DirectPhone = document.getElementById("DirectPhone").value;
      var CompanyPhone = document.getElementById("CompanyPhone").value;
      var CompanyRevenue = document.getElementById("CompanyRevenue").value.trim();
      var CompanyEmployeeCount = document.getElementById(
        "CompanyEmployeeCount"
      ).value.trim();
      var CompanyEmployeeCountDecimal = document.getElementById(
        "CompanyEmployeeCount"
      ).value.trim();
      var FollowUpDate;
      if (this.state.FollowUpDate === "" || this.state.FollowUpDate === undefined || this.state.FollowUpDate === null) {
        FollowUpDate = null;
      } else {
        FollowUpDate = moment(this.state.FollowUpDate).format("YYYY-MM-DD");
      }
      var ColdCallersID = document.getElementById("ColdCallersID").value;
      var SalesRep = document.getElementById("SalesRep").value;
      var OtherPhone1 = document.getElementById("OtherPhone1").value;
      var OtherPhone2 = document.getElementById("OtherPhone2").value;
      var ExternalAccountID = document.getElementById("ExternalAccountID").value;
      var ExternalContactID = document.getElementById("ExternalContactID").value;
      var ExternalField1 = document.getElementById("ExternalField1").value;
      var ExternalField2 = document.getElementById("ExternalField2").value;
      var ExternalField3 = document.getElementById("ExternalField3").value;
      var DirectPhoneExt = document.getElementById("DirectPhoneExt").value;
      var ZoomID = document.getElementById("ZoomID").value;
      var BestPhone = document.getElementById("BestPhone").value;
      var BestPhoneStatus = document.getElementById("BestPhoneStatus").value;
      var Phone = document.getElementById("Phone").value;
      var PhoneStatus = document.getElementById("PhoneStatus").value;
      var Phone2 = document.getElementById("Phone2").value;
      var Phone2Status = document.getElementById("Phone2Status").value;
      var Phone3 = document.getElementById("Phone3").value;
      var Phone3Status = document.getElementById("Phone3Status").value;
      var Phone4 = document.getElementById("Phone4").value;
      var Phone4Status = document.getElementById("Phone4Status").value;
      var PRLLeadID = document.getElementById("PRLLeadID").value;
      var FollowUpNotes = document.getElementById("FollowUpNotes").value;

      
      var Domain = Email.substring(Email.lastIndexOf("@") + 1);
      let revenueParts = CompanyRevenue.split("."); // Split the string into integer and decimal parts
      
      if (revenueParts.length === 2) { // Ensure there's a decimal part
        let decimalPart = revenueParts[1]; // Get the decimal part
        if (decimalPart.length >= 3) { // Ensure there are at least three digits after the decimal point
            let thirdDigit = parseInt(decimalPart[2]); // Get the third digit after the decimal point
            let secondDigit = parseInt(decimalPart[1]); // Get the second digit after the decimal point
    
            if (thirdDigit >= 5) { // If the third digit is greater than or equal to 5
                secondDigit += 1; // Increment the second digit
            }
    
            // Reconstruct the revenue string with the adjusted second digit
            CompanyRevenue = revenueParts[0] + "." + decimalPart[0] + secondDigit;
        }


      }

            // Check if the trimmed value is not empty and is a valid number
            if (CompanyEmployeeCount !== "" && !isNaN(CompanyEmployeeCount)) {
              // If the value is a decimal, assign 0
              CompanyEmployeeCount = CompanyEmployeeCount.includes(".") ? 0 : parseFloat(CompanyEmployeeCount);
        } else {
              // If the value is empty or not a valid number, assign 0
              CompanyEmployeeCount = 0;
        }
    var  trimEmail;
    if(Email == "" || undefined){
      trimEmail = Email
    }else{
      trimEmail = Email.trim()
    }

      var ContactCustomFieldValueArray = [];
      this.state.ContactCustomFieldData.map((contactvalue) => {
        
        var customval = document.getElementById(contactvalue._id).value;
        var conid = contactvalue._id;
        if (customval.length !== 0) {
          ContactCustomFieldValueArray.push({
            customval,
            conid,
          });
        }
      });
      var data = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        FirstName: FirstName,
        LastName: LastName,
        Email: trimEmail,
        Title: Title,
        Company: Company,
        CompanyNameLong: CompanyNameLong,
        Website: Website,
        LinkedInURL: LinkedInURL,
        Industry: Industry,
        Address1: Address1,
        Address2: Address2,
        City: City,
        State: State,
        Zip: Zip,
        Country: Country,
        ContactSourceID: ContactSourceID,
        ContactOwnerID: ContactOwnerID === "--Select--" ? null : ContactOwnerID,
        ClientPOCID: ClientPOCID,
        ReasonUnqualifiedID: ReasonUnqualifiedID,
        ContactCategory: ContactCategory,
        AccountCategory: AccountCategory,
        MobilePhone: MobilePhone,
        DirectPhone: DirectPhone,
        DirectPhoneExt: DirectPhoneExt,
        CompanyPhone: CompanyPhone,
        CompanyRevenue: CompanyRevenue,
        CompanyEmployeeCount: CompanyEmployeeCount,
        FollowUpDate: FollowUpDate,
        SalesRepID: ColdCallersID,
        ColdCallersID: ColdCallersID,
        SalesRep: SalesRep,
        OtherPhone1: OtherPhone1,
        OtherPhone2: OtherPhone2,
        ExternalAccountID: ExternalAccountID,
        ExternalContactID: ExternalContactID,
        ExternalField1: ExternalField1,
        ExternalField2: ExternalField2,
        ExternalField3: ExternalField3,
        IsDoNotCallContact: this.state.checked,
        CreatedBy: this.state.CUserID,
        CreatedDate: new Date(),
        IsDeleted: false,
        EmailDomain: Domain,
        ContactsCustomFieldArray: ContactCustomFieldValueArray,
        AccountTypeID: (this.props.location.state.data === null || isNaN(this.props.location.state.data)) ? 1 : this.props.location.state.data,
        TagArrayAlready: this.state.TagArrayAlready == [] || null || undefined ? []:this.state.TagArrayAlready,
        ContactTags:this.state.TagNameArray == [] || null || undefined ? "" : this.state.TagNameArray.map((tag) => tag.TagName).join(', '),
      ZoomID :ZoomID,
       BestPhone :BestPhone,
       BestPhoneStatus :BestPhoneStatus,
       Phone :Phone,
       PhoneStatus :PhoneStatus,
       Phone2 :Phone2,
       Phone2Status :Phone2Status,
       Phone3 :Phone3,
       Phone3Status :Phone3Status,
       Phone4 :Phone4,
       Phone4Status :Phone4Status,
       PRLLeadID :PRLLeadID,
       FollowUpNotes:FollowUpNotes,
       CompanyEmployeeCountDecimal:CompanyEmployeeCountDecimal
      };
      console.log(data)
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactsAdd",
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div>
              Add SalesHive Contacts.<br/>
              Contact added successfully.
            </div>
          );
          this.setState({ IsLoading: false});
          this.setState({ IsBtnDisabled: false });
          if (this.props.location.state["Pagename"] == "AccountContact") {
            history.push({
              pathname: "/viewaccount",
              state: { data: this.props.location.state.data },
            });
            this.setState({ IsBtnDisabled: false });
          }else if(this.props.location.state["Pagename"] == "ColdCallingContacts"){
            history.push("/ColdCallingContact");
            this.setState({ IsBtnDisabled: false });
          }else{
            history.push("/contacts");
            this.setState({ IsBtnDisabled: false });
          }

        } else if (res.data.StatusMessage == "EXITS") {
          toast.error(
            <div>
            Contacts.<br/>
            Email already exits
            </div>
          );
          this.setState({ IsLoading: false});
          this.setState({ IsBtnDisabled: false });
        }
        else {
          toast.error(res.data.Message);
          this.setState({ IsLoading: false});
          this.setState({ IsBtnDisabled: false });
        }
      }).catch((error) => {
        toast.error("An error occurred. Please try again.");
        SetIsLoading(false);
        this.setState({ IsBtnDisabled: false });
      });
    } else{
      this.setState({ IsLoading: false});
      this.setState({ IsBtnDisabled: false });
    }
  } catch (error) {
    toast.error("An error occurred. Please try again.");
    this.setState({IsLoading:false})
    this.setState({ IsBtnDisabled: false });
  }
  }

  handleDateChange = (date) => {
    this.setState({FollowUpDate:date})
  }

  render() {
  let contactoptions = this.state.ContactTagData.map((val) => {
    return {value :val.Tag, label: val.Tag, cid: val._id}
  })
    return (
      <>
      {
        this.state.IsLoading === true ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
      </div> : null
      }
        <div className="adminmain"> 
            <Sidebar className="" /> 
          <div className="bodyhome px-3">
            <div className="mt-3">
              <div className="row">
                <div className="col-md-6 col-sm-6 padd-0 d-flex align-items-center">
                  <h3 className="float-left headertitlepad lg-headertitle nonhed ">
                    Add SalesHive Contact
                  </h3>
                </div>
              </div> 

             <div className="bg-white pb-2"> 
              <div className="col-lg-12 pl-0 py-4 border-bottom px-3">
                <h3 className="addpotential_header mb-0  xs-headertitle mb-0">
                Contact Info
                </h3>
              </div>

              <div className="row px-3">
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols  d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      First Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-required="Please enter first name"
                        id="FirstName"
                        name="FirstName"
                        placeholder="First name"
                        type="text"
                        // onChange={this.handleChange.bind(this, "FirstName")}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["FirstName"]}
                      </span> */}
                      {/* {this.state.FirstNameAvailable && (
                        <span style={{ color: "red" }}>
                          First name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Last Name
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LastName"
                        name="LastName"
                        type="text"
                        placeholder="Last name"
                        // onChange={this.handleChangeLastname.bind(
                        //   this,
                        //   "LastName"
                        // )}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["LastName"]}
                      </span>
                      {this.state.LastNameAvailable && (
                        <span style={{ color: "red" }}>
                          Last name already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Email
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        data-val-regex="Invalid email."
                        data-val-remote="Email already exist."
                        data-val-remote-additionalfields="*.Email,*.PageName,*.CampaignID,*.ClientID,*.UserID"
                        data-val-remote-url="/Utility/IsProspectEmailExistsAndBlacklist"
                        data-val-required="Please enter email."
                        id="Email"
                        placeholder="Email"
                        name="Email"
                        type="text"
                        // onChange={this.handleChange.bind(this, "Email")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Email"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Title
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Title"
                        name="Title"
                        placeholder="Title"
                        type="text"
                        // onChange={this.handleChangeTitlename.bind(
                        //   this,
                        //   "Title"
                        // )}
                      />

                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Title"]}
                      </span> */}
                      {/* {this.state.TitleNameAvailable && (
                        <span style={{ color: "red" }}>
                          Title already exist.
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Company"
                        placeholder="Company"
                        name="Company"
                        type="text"
                        // onChange={this.handleChange.bind(this, "Company")}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Company"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Name Long
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="CompanyNameLong"
                        name="CompanyNameLong"
                        placeholder="Company name long"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "CompanyNameLong"
                        // )}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["CompanyNameLong"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Website
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        data-val="true"
                        id="Website"
                        name="Website"
                        placeholder="Website"
                        type="text"
                        onBlur={this.handleChangeWebsitecheck.bind(this, "Website")}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["Website"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      LinkedIn URL
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="LinkedInURL"
                        name="LinkedInURL"
                        placeholder="Linkedin url"
                        type="text"
                        // onChange={this.handleChange.bind(this, "LinkedInURL")}
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["LinkedInURL"]}
                      </span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Industry
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Industry"
                        placeholder="Industry"
                        type="text"
                        id="Industry"
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Industry"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Address 1
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Address1"
                        placeholder="Address 1"
                        type="text"
                        id="Address1"
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Address1"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Address 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Address2"
                        placeholder="Address 2"
                        type="text"
                        id="Address2"
                      />
                      {/* <span style={{ color: "red" }}>
                        {this.state.errors["Address2"]}
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      City
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        placeholder="City"
                        name="City"
                        id="City"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      State
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="State"
                        id="State"
                        placeholder="State"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Zip
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="Zip"
                        id="Zip"
                        type="text"
                        placeholder="Zip"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Country
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        id="Country"
                        name="Country"
                        type="text"
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Source
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactSource"
                        id="ContactSourceID"
                        
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ContactSourceID"
                        // )}
                      >
                        <option value="">--Select--</option>
                        {this.state.ContactSourceData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactSourceID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Owner
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ContactOwner"
                        id="ContactOwnerID"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ContactOwnerID"
                        // )}
                      >
                        <option value={null}>--Select--</option>
                        {this.state.ContactOwnerData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactOwnerID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Client POC
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="Client POC"
                        id="ClientPOCID"
                        // onChange={this.handleChange.bind(this, "ClientPOCID")}
                      >
                        <option value="">--Select--</option>
                        {this.state.ClientPOCData.map((value) => (
                          <option value={value._id}>
                            {value.Name}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ClientPOCID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Reason Unqualified
                    </label>
                    <div className="col-lg-7">
                      <select
                        className="form-control  m-input"
                        name="ReasonUnqualified"
                        id="ReasonUnqualifiedID"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ReasonUnqualifiedID"
                        // )}
                      >
                        <option value="">--Select--</option>
                        {this.state.ReasonUnqualifiedData.map((value) => (
                          <option value={value._id}>
                            {value.Reason}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {this.state.errors["ReasonUnqualifiedID"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Contact Category
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="ContactCategory"
                        placeholder="Contact category"
                        id="ContactCategory"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "ContactCategory"
                        // )}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["ContactCategory"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Account Category
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control m-input"
                        name="AccountCategory"
                        placeholder="Account category"
                        id="AccountCategory"
                        type="text"
                        // onChange={this.handleChange.bind(
                        //   this,
                        //   "AccountCategory"
                        // )}
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors["AccountCategory"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Mobile Phone
                    </label>
                    <div className="col-lg-7">
                    <input
                      className="form-control m-input"
                      data-val="true"
                      id="MobilePhone"
                      name="MobilePhone"
                      placeholder="Mobile Phone"
                      type="text"
                      onBlur={() => this.handleChangePhoneValidation("MobilePhone")}
                    />
                      <span style={{ color: "red" }}>
                          {this.state.errors["MobilePhone"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone
                    </label>
                    <div className="col-lg-7">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        id="DirectPhone"
                        name="DirectPhone"
                        placeholder="Direct Phone"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("DirectPhone")}
                    />
<span style={{ color: "red" }}>
    {this.state.errors["DirectPhone"]}
</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Direct Phone Ext
                    </label>
                    <div className="col-lg-7">
                    <input
                        className="form-control m-input"
                        data-val="true"
                        id="DirectPhoneExt"
                        name="DirectPhoneExt"
                        placeholder="Direct Phone Ext"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("DirectPhoneExt")}
                    />
                      <span style={{ color: "red" }}>
                          {this.state.errors["DirectPhoneExt"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Phone
                    </label>
                    <div className="col-lg-7">
                    <input
                          className="form-control m-input"
                          data-val="true"
                          id="CompanyPhone"
                          name="CompanyPhone"
                          placeholder="Company Phone"
                          type="text"
                          onBlur={() => this.handleChangePhoneValidation("CompanyPhone")}
                      />
                      <span style={{ color: "red" }}>
                          {this.state.errors["CompanyPhone"]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Company Revenue
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyRevenue"
                        placeholder="Company Revenue"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Employee Count
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="CompanyEmployeeCount"
                        placeholder="Employee Count"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Follow Up Date
                    </label>
                    <div className="col-lg-7 custom-datepicker datepicker-32">
                    <LocalizationProvider class="custom-datepicker" 	size="small" dateAdapter={AdapterMoment}>
                      <DatePicker size="small"
                        label={false}  
                        value={this.state.FollowUpDate}
                        minDate={moment()}
                        onChange={(newDate) => this.handleDateChange(moment(newDate))}
                        renderInput={(params) => <TextField className="form-control m-input" {...params} />}
                        showToolbar={false} 
                      />
                    </LocalizationProvider>
                      {/* <input
                        class="form-control m-input"
                        id="FollowUpDate"
                        type="date"
                        placeholder="Follow Up Date"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Tag
                    </label>
                    <div className="col-lg-7">
               
                      <CreatableSelect
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={contactoptions}
                          id="ContactTagID"
                          isMulti
                          onChange={this.handleChangeContactOption}
                        />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Rep
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="SalesRep"
                        type="text"
                        placeholder="Sales Rep"
                      />
                    </div>
                  </div>
                </div>
               
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Other Phone 1
                    </label>
                    <div className="col-lg-7">
                    <input
    className="form-control m-input"
    data-val="true"
    id="OtherPhone1"
    name="OtherPhone1"
    placeholder="Other Phone 1"
    type="text"
    onBlur={() => this.handleChangePhoneValidation("OtherPhone1")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["OtherPhone1"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Other Phone 2
                    </label>
                    <div className="col-lg-7">
                    <input
    className="form-control m-input"
    data-val="true"
    id="OtherPhone2"
    name="OtherPhone2"
    placeholder="Other Phone 2"
    type="text"
    onBlur={() => this.handleChangePhoneValidation("OtherPhone2")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["OtherPhone2"]}
</span>
                    </div>
                  </div>
                </div>
          
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Account ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalAccountID"
                        placeholder="External Account ID"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Contact ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalContactID"
                        placeholder="External Contact ID"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 1
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField1"
                        placeholder="External Field 1"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 2
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField2"
                        placeholder="External Field 2"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      External Field 3
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ExternalField3"
                        placeholder="External Field 3"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
     
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Zoom ID
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="ZoomID"
                        placeholder="Zoom ID"
                        type="text"
                        onBlur={() => this.hadleChangeZoomID("ZoomID")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["ZoomID"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Best Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="BestPhone"
                        placeholder="Best Phone"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("BestPhone")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["BestPhone"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Best Phone Status
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="BestPhoneStatus"
                        placeholder="Best Phone Status"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone"
                        placeholder="Phone"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("Phone")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["Phone"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone Status
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="PhoneStatus"
                        placeholder="Phone Status"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone2
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone2"
                        placeholder="Phone2"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("Phone2")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["Phone2"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone2 Status
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone2Status"
                        placeholder="Phone2 Status"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone3
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone3"
                        placeholder="Phone3"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("Phone3")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["Phone3"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone3 Status
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone3Status"
                        placeholder="Phone3 Status"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone4
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone4"
                        placeholder="Phone4"
                        type="text"
                        onBlur={() => this.handleChangePhoneValidation("Phone4")}
/>
<span style={{ color: "red" }}>
    {this.state.errors["Phone4"]}
</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    Phone4 Status
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="Phone4Status"
                        placeholder="Phone4 Status"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                    PRL Lead ID (SalesForce ID)
                    </label>
                    <div className="col-lg-7">
                      <input
                        class="form-control m-input"
                        id="PRLLeadID"
                        placeholder=" PRL Lead ID (SalesForce ID)"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Sales Development Reps
                    </label>
                    <div className="col-lg-7">
                    {
                      this.state.Role === "ColdCallers" ? <select
                        className="form-control  m-input"
                        name="ColdCallersID"
                        id="ColdCallersID"
                        disabled={true}
                      >
                          <option value={this.state.ColdCallerLoginSalesDevlopmentID}>
                            {this.state.ColdCallerLoginSalesDevlopmentName === "" || null || undefined ? "" : this.state.ColdCallerLoginSalesDevlopmentName} 
                          </option>

                      </select> : <select
                        className="form-control  m-input"
                        name="ColdCallersID"
                        id="ColdCallersID"
                      >
                        <option value="">--Select--</option>
                        {this.state.SalesDevelopmentRepsData.map((value) => (
                          <option value={value._id} key={value._id}>
                            {value.ColdCallersName} {value?.LastName}
                          </option>
                        ))}
                      </select>
                    }
             
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-6 boxsinput_group">
                  <div className="row max-cols d-flex align-items-center">
                    <label className="col-lg-4 view-lable-meet potential_text">
                      Follow Up Notes
                    </label>
                    <div className="col-lg-7">
                    <textarea
                      class="form-control m-input"
                      id="FollowUpNotes"
                      name="FollowUpNotes"
                      placeholder="Follow Up Notes"
                  ></textarea>
                   
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group">
                  <div className="max-cols d-flex align-items-center">
                    <label className="check_bx"> 
                      <input
                        class=""
                        onChange={(e) => this.handleChangecheck(e)}
                        defaultChecked={this.state.checked}
                        type="checkbox"
                        id="IsDoNotCallContact"
                      />{" "} <span className="checkmark"></span>
                      <label>Do Not Call Contact</label>
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 boxsinput_group"></div> 
              </div>


              
       
                  <div className="row mt-4 mb-2 mx-0">
                               <div className="col-lg-12 pl-0 py-4 border-top border-bottom  px-3 mb-3">
                    <h3 className="addpotential_header mb-0  xs-headertitle mb-0">
                    Custom Contact Field
                    </h3>
                  </div>
              {this.state.ContactCustomFieldData.length == 0 ? <><div></div></> : this.state.ContactCustomFieldData.map((value, index) => {
                    return (
                      <>
         
                  <div className="col-lg-6 py-1 px-2">
                          <div
                            className="row max-cols d-flex align-items-center mx-0"
                            key={index}
                          >
                            <label className="col-lg-4 view-lable-meet potential_text">{value.FieldName}</label>
                            <div className="col-lg-7">
                              <input
                                class="form-control m-input"
                                // value={value._id}
                                type="text"
                                id={value._id}
                              />
                            </div>
                          </div>
                        </div>
                      </>
            
                       
                  
                    );
                  })}
              </div>
              

              <div className="row mx-0 border-top pt-3 pb-2">
                <div className="col-lg-12 pull-left">
                  <button
                    id="submit"
                    href="javascript:void(0);"
                    className="btn btn-primary btn-lightgreen mr-1"
                    value="Save"
                    onClick={this.savebtn}
                    disabled={this.state.IsBtnDisabled}
                  >
                    <i className="la la-save"></i> Save
                  </button>
                  <a
                    id="backtolist"
                    href="javascript:void(0);"
                    className="btn btn-secondary"
                    value="Save"
                    onClick={this.backbtn}
                  >
                    <i className="la la-arrow-circle-left"></i> Back
                  </a>
                </div>
              </div>

              </div>

            </div>
          </div>
        </div>

        {/* EDIT PAGE AND NOTES END */}

        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedAddContactPage = connect(
  mapState,
  actionCreators
)(AddContactPage);
export { connectedAddContactPage as AddContactPage };
