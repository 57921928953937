import React, { useEffect } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
const moment = require("moment");
import { CSVReader, CSVDownloader } from "react-papaparse";
import $ from "jquery";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Pagination from "@material-ui/lab/Pagination";
const Fileupload = require("../../../_helpers/fileupload");
import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";
import loadingicon from "../../../images/loading.gif";
import { GetClientDetails } from "../../../_helpers/Utility";
import StyleHeader from "../../user/StickyHeader/StickyHeader";

import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



export default function EmailAccountTable({ ImportOpenCsv }) {
  const [Data, SetData] = React.useState([]);
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(15);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Name");
  const [SortedBy, SetSortedBy] = React.useState(1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [AccountType, SetAccountType] = React.useState([]);
  const [AccountAuthType, SetAccountAuthType] = React.useState({});
  const [AccountSslType, SetAccountSslType] = React.useState([]);
  const [CName, SetCName] = React.useState("");

  const [FromEmail, SetFromEmail] = React.useState(null);
  const [FullName, SetFullName] = React.useState(null);
  const [AliasTitle, SetAliasTitle] = React.useState(null);
  const [SMTPEmail, SetSMTPEmail] = React.useState(null);
  const [IMAPEmail, SetIMAPEmail] = React.useState(null);
  const [SMTPPassword, SetSMTPPassword] = React.useState(null);
  const [IMAPPassword, SetIMAPPassword] = React.useState(null);
  const [SMTPHost, SetSMTPHost] = React.useState(null);
  const [IMAPPort, SetIMAPPort] = React.useState(null);
  const [ThrottleStartingVolume, SetThrottleStartingVolume] = React.useState(null);
  const [ThrottleEndingVolume, SetThrottleEndingVolume] = React.useState(null);
  const [DaysTillFullThrottle, SetDaysTillFullThrottle] = React.useState(null);
  const [EmailDelay, SetEmailDelay] = React.useState(null);
  const [SMTPSecurity, SetSMTPSecurity] = React.useState(null);
  const [IMAPSecurity, SetIMAPSecurity] = React.useState(null);
  const [SendingSchedule, SetSendingSchedule] = React.useState(null);

  const [Reset, SetReset] = React.useState(false);
  const [IsMapping, SetIsMapping] = React.useState(false);
  const [DropboxData, SetDropboxData] = React.useState([]);
  const [filename, setfilename] = React.useState("");
  const [csvData, setcsvData] = React.useState([]);
  const [files, setfiles] = React.useState([]);

  const [Total, SetTotal] = React.useState(0);
  const [TotalSucess, SetTotalSucess] = React.useState(0);
  const [TotalError, SetTotalError] = React.useState(0);
  const [TotalDuplicate, SetTotalDuplicate] = React.useState(0);
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

 const [MapAttrBox, SetMapAttrBox] = React.useState(false);

  useEffect(() => {
    localStorage.setItem("FromEmail", "");
    document.title="Email Accounts | SalesHive";
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }
    GetClientDetails(Details.ClientID).then((result) => {
      SetCName(result[0].Name);
    });
    EmailAccountGet(Details.ClientID, Details.ParentUserID, Details.Role);
    AllCommonFiledGet();

    if (ImportOpenCsv == true) {
      document.getElementById("import_account").style.display = "block";
      document.getElementById("total_div_display").style.display = "none";
    }
  }, [Search, Page, RowsPerPage]);

  // common filed get
  const AllCommonFiledGet = () => {
    var CommonFiledData = {
      IsDeleted: false,
      Role:Role
    };
    const rows = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/AccountTypeGetAll",
      method: "POST",
      data: CommonFiledData,
    });
    rows.then((result) => {
      SetAccountType(result.data.Accounttype),
        SetAccountSslType(result.data.AccountSsltype),
        SetAccountAuthType(result.data.Accountauthtype);
    });
  };

  // Email account get
  const EmailAccountGet = (Cid, Uid, role) => {
    SetIsLoading(true);
    var EmailAccountData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: Cid,
      UserID: Uid,
      Role:role
    };
    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/emailaccount/EmailaccountsGet",
      method: "POST",
      data: EmailAccountData,
    });
    rows1.then((result) => {
      SetData(result.data.PageData);
      SetRows(result.data.PageData);
      SetRlen(result.data.TotalCount);
      SetFlen(result.data.TotalCount);
      SetCountPage(result.data.PageCount);
      SetIsLoading(false);
      setIsDataLoaded(true);
    });
  };

  // change display rows
  const ChangeRowSelected = (event) => {
    SetRowsPerPage(Number(event.target.value));
    SetPage(1);
  };

  // search for record
  const RequestSearch = (event) => {
    if (event.key === "Enter") {
      var SearchedVal = document.getElementById("Search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
      }
    }
  };

  // change page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
    }
  };

  // Delete btn email accounts
  const DeleteBtn = (id) => {
    const accountstatusincampaign = Rows
  .filter(ele => ele._id === id)
  .flatMap(data => data.Campaigndetails.map(ele => ele.Name));

  if (accountstatusincampaign.length > 0) {
    const errorMessage = `Account is exists in ${accountstatusincampaign.map(name => `"${name}"`).join(' and ')} !`;
    toast.error(errorMessage);
    
  } else{

    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete an account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          _id: id,
          IsDeleted: true,
          LastUpdatedDt: new Date(),
          LastUpdatedBy: CUserID,
          Role:Role,
          ClientID:ClientID
        };
        debugger
        Axios({
          url: CommonConstants.MOL_APIURL + "/emailaccount/EmailAccountDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Email account deleted successfully.",
                "success"
              );
              EmailAccountGet(ClientID, UserID, Role);
              history.push("/CEmailAccounts");
              SetPage(1);
              SetSearch("");
              document.getElementById("Search").value = "";
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }   

  };

  // email accounts edit
  const EmailAccountsEdit = (id) => {
    history.push("/CEditEmailAccounts", {id:id,pagename:"emailaccounts"});
  };

  //get sort field data
  const SortData = (Field) => {
    var SearchedVal = document.getElementById("Search").value;
    SetIsLoading(true);
    var SerchBox;
    if (SearchedVal == "") {
      SerchBox = false;
      SetSflag(false);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        SerchBox: false,
        Search: SearchedVal,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/emailaccount/EmailaccountsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    } else {
      SerchBox = true;
      SetSflag(true);
      var SortField = Field;
      var SortBy;
      if (SortedBy == 1) {
        SortBy = -1;
        SetSortedBy(-1);
      } else {
        SortBy = 1;
        SetSortedBy(1);
      }
      SetSortField(Field);
      var InputParameter = {
        Page: Page,
        RowsPerPage: RowsPerPage,
        Search: SearchedVal,
        SerchBox: SerchBox,
        Archive: false,
        Sort: true,
        Field: SortField,
        SortBy: SortBy,
        Type: "User",
        ClientID: ClientID,
        UserID: UserID,
        Role: Role,
      };
      const AccountCategoriesList = Axios({
        url: CommonConstants.MOL_APIURL + "/emailaccount/EmailaccountsGet",
        method: "POST",
        data: InputParameter,
      });
      AccountCategoriesList.then((Result) => {
        SetRows(Result.data.PageData);
        SetRlen(Result.data.TotalCount);
        SetFlen(Result.data.TotalCount);
        SetCountPage(Result.data.PageCount);
        SetIsLoading(false);
      });
    }
  };

  // reset function
  const ResetCsv = () => {
    SetReset(true);
  };

  // handle on drop
  const HandleOnDrop = (data, fileInfo) => {
    var filename = fileInfo.name;

    // this.setState({ files: fileInfo });
    setfiles(fileInfo);
    var sep = filename.split(".");
    if (sep[sep.length-1]!= "csv") {
      // this.setState({ reset: true });
      SetReset(true);
      // this.ResetCsv();
      ResetCsv();
    } else {
      var csvfile =  sep.slice(0, -1).join(".") + new Date().getTime() + "." + sep[sep.length-1];
      // this.setState({ filename: csvfile });
      setfilename(csvfile);
      // Filter out rows with all empty strings
      const filteredData = data.filter(row => !row.data.every(item => item.trim() === ''));

      setcsvData(filteredData);
      SetDropboxData(filteredData[0].data);
    }
  };

  // handle on error
  const HandleOnError = (err, file, inputElem, reason) => {};

  // handle on remove file
  const HandleOnRemoveFile = (data) => {};

  // map csv
  const AccountMapCsv = () => {
   document.getElementById("map_attribute").style.display = "block";
    if (csvData.length != 0) {
      DropboxData.map((dddd, index) => {});
      SetMapAttrBox(false);
      SetReset(true);
    } else {
      SetReset(true);
      toast.error(<div className="toastsize">Email Account <br/>Please select file</div>)
      SetMapAttrBox(true);
    }
    hideCSVHeaderOptions();
  };

  const hideCSVHeaderOptions = () => {
    var $select = $(".csvcontent");
    $select.find("option").show();
    $select.each(function () {
      var $this = $(this);
      var value = $this.val();
      var $options = $this
        .parents("#divContactSelection")
        .find(".csvcontent")
        .not(this)
        .find("option");
      var $option = $options.filter('[value="' + value + '"]');
      if (value) {
        $option.hide();
      }
    });
  };

  $("select").on("change", function () {
    var $this = $(this);
    var value = $this.val();
    var Name = $(this).attr("id");
    if (Name == "FromEmail") {
      SetFromEmail(value);
    } else if (Name == "FullName") {
      SetFullName(value);
    } else if (Name == "AliasTitle") {
      SetAliasTitle(value);
    } else if (Name == "SMTPEmail") {
      SetSMTPEmail(value);
    } else if (Name == "IMAPEmail") {
      SetIMAPEmail(value);
    } else if (Name == "SMTPPassword") {
      SetSMTPPassword(value);
    } else if (Name == "IMAPPassword") {
      SetIMAPPassword(value);
    } else if (Name == "SMTPHost") {
      SetSMTPHost(value);
    } else if (Name == "IMAPPort") {
      SetIMAPPort(value);
    } else if (Name == "ThrottleStartingVolume") {
      SetThrottleStartingVolume(value);
    } else if (Name == "ThrottleEndingVolume") {
      SetThrottleEndingVolume(value);
    } else if (Name == "DaysTillFullThrottle") {
      SetDaysTillFullThrottle(value);
    } else if (Name == "EmailDelay") {
      SetEmailDelay(value);
    } else if (Name == "SMTPSecurity") {
      SetSMTPSecurity(value);
    } else if (Name == "IMAPSecurity") {
      SetIMAPSecurity(value);
    } else if (Name == "SendingSchedule") {
      SetSendingSchedule(value);
    }

    hideCSVHeaderOptions();
  });

  // cancel button import
  const CancelImportEmailAccount = () => {
    ResetCsv();
    setcsvData([]);
    SetMapAttrBox(false);
    SetDropboxData([]);
    document.getElementById("map_attribute").style.display = "none";
    document.getElementById("import_account").style.display = "none";
    document.getElementById("total_div_display").style.display = "none";
  };

 // import for email 100+csv
 const AccountSaveCsvAddBulk = () => {
  // var OriginalFileName = files.name
  // var onlyname   = OriginalFileName.substring(0, OriginalFileName.lastIndexOf(".csv"));

  // var UserEmail = document.getElementById("UserEmail").value;
  var SaveAllEmailAccount = [];
  var FromEmail = parseInt(document.getElementById("FromEmail").value);
  if (FromEmail >= 0) {
    var ObjFromEmail = {
      FieldAlias: "From Email",
      Fieldname: "FromEmail",
      Customfield: null,
      index: FromEmail,
    };
    SaveAllEmailAccount.push(ObjFromEmail);
  }
  var FullName = parseInt(document.getElementById("FullName").value);
  if (FullName >= 0) {
    var ObjFullName = {
      FieldAlias: "Full Name",
      Fieldname: "FullName",
      Customfield: null,
      index: FullName,
    };
    SaveAllEmailAccount.push(ObjFullName);
  }
  var AliasTitle = parseInt(document.getElementById("AliasTitle").value);
  if (AliasTitle >= 0) {
    var ObjAliasTitle = {
      FieldAlias: "Alias Title",
      Fieldname: "AliasTitle",
      Customfield: null,
      index: AliasTitle,
    };
    SaveAllEmailAccount.push(ObjAliasTitle);
  }
  var SMTPEmail = parseInt(document.getElementById("SMTPEmail").value);
  if (SMTPEmail >= 0) {
    var ObjSMTPEmail = {
      FieldAlias: "SMTP Email",
      Fieldname: "SMTPEmail",
      Customfield: null,
      index: SMTPEmail,
    };
    SaveAllEmailAccount.push(ObjSMTPEmail);
  }
  var IMAPEmail = parseInt(document.getElementById("IMAPEmail").value);
  if (IMAPEmail >= 0) {
    var ObjIMAPEmail = {
      FieldAlias: "IMAP Email",
      Fieldname: "IMAPEmail",
      Customfield: null,
      index: IMAPEmail,
    };
    SaveAllEmailAccount.push(ObjIMAPEmail);
  }
  var SMTPPassword = parseInt(document.getElementById("SMTPPassword").value);
  if (SMTPPassword >= 0) {
    var ObjSMTPPassword = {
      FieldAlias: "SMTP Password",
      Fieldname: "SMTPPassword",
      Customfield: null,
      index: SMTPPassword,
    };
    SaveAllEmailAccount.push(ObjSMTPPassword);
  }
  var IMAPPassword = parseInt(document.getElementById("IMAPPassword").value);
  if (IMAPPassword >= 0) {
    var ObjIMAPPassword = {
      FieldAlias: "IMAP Password",
      Fieldname: "IMAPPassword",
      Customfield: null,
      index: IMAPPassword,
    };
    SaveAllEmailAccount.push(ObjIMAPPassword);
  }
  var SMTPHost = parseInt(document.getElementById("SMTPHost").value);
  if (SMTPHost >= 0) {
    var ObjSMTPHost = {
      FieldAlias: "SMTP Host",
      Fieldname: "SMTPHost",
      Customfield: null,
      index: SMTPHost,
    };
    SaveAllEmailAccount.push(ObjSMTPHost);
  }
  var IMAPPort = parseInt(document.getElementById("IMAPPort").value);
  if (IMAPPort >= 0) {
    var ObjIMAPPort = {
      FieldAlias: "IMAP Port",
      Fieldname: "IMAPPort",
      Customfield: null,
      index: IMAPPort,
    };
    SaveAllEmailAccount.push(ObjIMAPPort);
  }

  var ThrottleStartingVolume = parseInt(
    document.getElementById("ThrottleStartingVolume").value
  );
  if (ThrottleStartingVolume >= 0) {
    var ObjThrottleStartingVolume = {
      FieldAlias: "Throttle Starting Volume",
      Fieldname: "ThrottleStartingVolume",
      Customfield: null,
      index: ThrottleStartingVolume,
    };
    SaveAllEmailAccount.push(ObjThrottleStartingVolume);
  }
  var ThrottleEndingVolume = parseInt(
    document.getElementById("ThrottleEndingVolume").value
  );
  if (ThrottleEndingVolume >= 0) {
    var ObjThrottleEndingVolume = {
      FieldAlias: "Throttle Ending Volume",
      Fieldname: "ThrottleEndingVolume",
      Customfield: null,
      index: ThrottleEndingVolume,
    };
    SaveAllEmailAccount.push(ObjThrottleEndingVolume);
  }
  var DaysTillFullThrottle = parseInt(
    document.getElementById("DaysTillFullThrottle").value
  );
  if (DaysTillFullThrottle >= 0) {
    var ObjDaysTillFullThrottle = {
      FieldAlias: "Days Till Full Throttle",
      Fieldname: "DaysTillFullThrottle",
      Customfield: null,
      index: DaysTillFullThrottle,
    };
    SaveAllEmailAccount.push(ObjDaysTillFullThrottle);
  }

  var EmailDelay = parseInt(document.getElementById("EmailDelay").value);
  if (EmailDelay >= 0) {
    var ObjEmailDelay = {
      FieldAlias: "Email Delay",
      Fieldname: "EmailDelay",
      Customfield: null,
      index: EmailDelay,
    };
    SaveAllEmailAccount.push(ObjEmailDelay);
  }

  var SMTPSecurity = parseInt(document.getElementById("SMTPSecurity").value);
  if (SMTPSecurity >= 0) {
    var ObjSMTPSecurity = {
      FieldAlias: "SMTP Security",
      Fieldname: "SMTPSecurity",
      Customfield: null,
      index: SMTPSecurity,
    };
    SaveAllEmailAccount.push(ObjSMTPSecurity);
  }

  var IMAPSecurity = parseInt(document.getElementById("IMAPSecurity").value);
  if (IMAPSecurity >= 0) {
    var ObjIMAPSecurity = {
      FieldAlias: "IMAP Security",
      Fieldname: "IMAPSecurity",
      Customfield: null,
      index: IMAPSecurity,
    };

    SaveAllEmailAccount.push(ObjIMAPSecurity);
  }
  var SendingSchedule = parseInt(
    document.getElementById("SendingSchedule").value
  );
  if (SendingSchedule >= 0) {
    var ObjSendingSchedule = {
      FieldAlias: "Sending Schedule",
      Fieldname: "SendingSchedule",
      Customfield: null,
      index: SendingSchedule,
    };
    SaveAllEmailAccount.push(ObjSendingSchedule);
  }

  var csvdata = csvData;
  var csvdata2 = csvData.length;

  var csvdata = csvData;

  var FromEmails = document.getElementById("FromEmail").value;
  var FullNames = document.getElementById("FullName").value;
  var AliasTitles = document.getElementById("AliasTitle").value;
  var SMTPEmails = document.getElementById("SMTPEmail").value;
  var IMAPEmails = document.getElementById("IMAPEmail").value;
  var SMTPPasswords = document.getElementById("SMTPPassword").value;
  var IMAPPasswords = document.getElementById("IMAPPassword").value;
  var SMTPHosts = document.getElementById("SMTPHost").value;
  var IMAPPorts = document.getElementById("IMAPPort").value;
  var ThrottleStartingVolumes = document.getElementById("ThrottleStartingVolume").value;
  var ThrottleEndingVolumes = document.getElementById("ThrottleEndingVolume").value;
  var DaysTillFullThrottles = document.getElementById("DaysTillFullThrottle").value;
  var SMTPSecuritys = document.getElementById("SMTPSecurity").value;
  var IMAPSecuritys = document.getElementById("IMAPSecurity").value;
  var SendingSchedules = document.getElementById("SendingSchedule").value;


  if (FromEmails == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        From email is required!
      </div>
    );
  } else if (FullNames == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        Full name is required!
      </div>
    );
  } else if (SMTPEmails == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        SMTP email is required!
      </div>
    );
  } else if (IMAPEmails == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        IMAP Email is required!
      </div>
    );
  } else if (SMTPPasswords == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        IMAP Password is required!
      </div>
    );
  } else if (IMAPPasswords == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        IMAP Password is required!
      </div>
    );
  } else if (SMTPHosts == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        SMTP Host is required!
      </div>
    );
  } else if (IMAPPorts == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        IMAP Port is required!
      </div>
    );
  } else if (ThrottleStartingVolumes == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        Throttle Starting Volume is required!
      </div>
    );
  } else if (ThrottleEndingVolumes == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        Throttle Ending Volume is required!
      </div>
    );
  } else if (DaysTillFullThrottles == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        Daystill full throttle is required!
      </div>
    );
  } else if (SMTPSecuritys == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        SMTP Security is required!
      </div>
    );
  } else if (IMAPSecuritys == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        IMAP Security is required!
      </div>
    );
  } else if (SendingSchedules == "") {
    toast.error(
      <div className="toastsize">
        Email Account
        <br />
        Sending Schedule is required!
      </div>
    );
  } else {
    Fileupload.getBase64(files, (result) => {
      var rs = result;
      var splirs = rs.split("base64,");
      var _base64 = splirs[1];
      var ObjData = {
        Fields: "Coverimg",
        ClientID:ClientID,
        UserID:UserID,
        ChildUserID:CUserID,
        Filepath: "./Content/UploadedFiles/EmailValidations",
        File: _base64,
        ImageName: filename,
        FileData: csvdata,
        SaveAllEmailAccount: SaveAllEmailAccount,
        MappingFiled: JSON.stringify(SaveAllEmailAccount),
        FromEmail:FromEmails,
        FullName:FullNames,
        AliasTitle:AliasTitles,
        SMTPEmail:IMAPEmails,
        IMAPEmail:SMTPPasswords,
        SMTPPassword:IMAPPasswords,
        IMAPPassword:SMTPHosts,
        SMTPHost:IMAPPorts,
        IMAPPort:IMAPPorts,
        ThrottleStartingVolume:ThrottleStartingVolumes,
        ThrottleEndingVolume:ThrottleEndingVolumes,
        DaysTillFullThrottle:DaysTillFullThrottles,
        SMTPSecurity:SMTPSecuritys,
        IMAPSecurity:IMAPSecuritys,
        SendingSchedule:SendingSchedules,
        Role:Role
      };

      Axios({
        url: CommonConstants.MOL_APIURL + "/emailaccount/EmailaccountsImport",
        method: "POST",
        data: ObjData,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          EmailAccountGet(ClientID, UserID, Role);
          SetMapAttrBox(false);
          SetTotal(res.data.Total);
          SetTotalDuplicate(res.data.TotalDuplication);
          SetTotalSucess(res.data.TotalSuccess);
          SetTotalError(res.data.TotalError);
          document.getElementById("import_account").style.display = "block";
          document.getElementById("map_attribute").style.display = "block";
          document.getElementById("total_div_display").style.display = "block";
          
          setcsvData([]);
        } else {
          toast.error(res.data.Message);
        }
      });
    });
  }
};

  // // import black list csv
  // const ContactSaveCsv = () => {

  //   if (csvData.length >= 100) {
  //     SetShowPopupImport(true)
  //     toast.success("File size is more than 100 successfully.");
  //   } else {
  //     var SaveAllEmailAccount = [];
  //     var FromEmail = parseInt(document.getElementById("FromEmail").value);
  //     if (FromEmail >= 0) {
  //       var ObjFromEmail = {
  //         FieldAlias: "From Email",
  //         Fieldname: "FromEmail",
  //         Customfield: null,
  //         index: FromEmail,
  //       };
  //       SaveAllEmailAccount.push(ObjFromEmail);
  //     }
  //     var FullName = parseInt(document.getElementById("FullName").value);
  //     if (FullName >= 0) {
  //       var ObjFullName = {
  //         FieldAlias: "Last Name",
  //         Fieldname: "FullName",
  //         Customfield: null,
  //         index: FullName,
  //       };
  //       SaveAllEmailAccount.push(ObjFullName);
  //     }
  //     var Email = parseInt(document.getElementById("Email").value);
  //     if (Email >= 0) {
  //       var ObjEmail = {
  //         FieldAlias: "Email",
  //         Fieldname: "Email",
  //         Customfield: null,
  //         index: Email,
  //       };
  //       SaveAllEmailAccount.push(ObjEmail);
  //     }
  //     var Title = parseInt(document.getElementById("Title").value);
  //     if (Title >= 0) {
  //       var ObjTitle = {
  //         FieldAlias: "Title",
  //         Fieldname: "Title",
  //         Customfield: null,
  //         index: Title,
  //       };
  //       SaveAllEmailAccount.push(ObjTitle);
  //     }
  //     var Company = parseInt(document.getElementById("Company").value);
  //     if (Company >= 0) {
  //       var ObjCompany = {
  //         FieldAlias: "Company",
  //         Fieldname: "Company",
  //         Customfield: null,
  //         index: Company,
  //       };
  //       SaveAllEmailAccount.push(ObjCompany);
  //     }
  //     var CompanyNameLong = parseInt(
  //       document.getElementById("CompanyNameLong").value
  //     );
  //     if (CompanyNameLong >= 0) {
  //       var ObjCompanyNameLong = {
  //         FieldAlias: "Company Name Long",
  //         Fieldname: "CompanyNameLong",
  //         Customfield: null,
  //         index: Company,
  //       };
  //       SaveAllEmailAccount.push(ObjCompanyNameLong);
  //     }
  //     var Website = parseInt(document.getElementById("Website").value);
  //     if (Website >= 0) {
  //       var ObjWebsite = {
  //         FieldAlias: "Website",
  //         Fieldname: "Website",
  //         Customfield: null,
  //         index: Website,
  //       };
  //       SaveAllEmailAccount.push(ObjWebsite);
  //     }
  //     var CompanyPhone = parseInt(
  //       document.getElementById("CompanyPhone").value
  //     );
  //     if (CompanyPhone >= 0) {
  //       var ObjCompanyPhone = {
  //         FieldAlias: "Company Phone",
  //         Fieldname: "CompanyPhone",
  //         Customfield: null,
  //         index: CompanyPhone,
  //       };
  //       SaveAllEmailAccount.push(ObjCompanyPhone);
  //     }
  //     var DirectPhone = parseInt(document.getElementById("DirectPhone").value);
  //     if (DirectPhone >= 0) {
  //       var ObjDirectPhone = {
  //         FieldAlias: "Direct Phone",
  //         Fieldname: "DirectPhone",
  //         Customfield: null,
  //         index: DirectPhone,
  //       };
  //       SaveAllEmailAccount.push(ObjDirectPhone);
  //     }

  //     var DirectPhoneExt = parseInt(
  //       document.getElementById("DirectPhoneExt").value
  //     );
  //     if (DirectPhoneExt >= 0) {
  //       var ObjDirectPhoneExt = {
  //         FieldAlias: "Direct Phone Ext",
  //         Fieldname: "DirectPhoneExt",
  //         Customfield: null,
  //         index: DirectPhoneExt,
  //       };
  //       SaveAllEmailAccount.push(ObjDirectPhoneExt);
  //     }
  //     var LinkedInURL = parseInt(document.getElementById("LinkedInURL").value);
  //     if (LinkedInURL >= 0) {
  //       var ObjLinkedInURL = {
  //         FieldAlias: "LinkedIn URL",
  //         Fieldname: "LinkedInURL",
  //         Customfield: null,
  //         index: LinkedInURL,
  //       };
  //       SaveAllEmailAccount.push(ObjLinkedInURL);
  //     }
  //     var Industry = parseInt(document.getElementById("Industry").value);
  //     if (Industry >= 0) {
  //       var ObjIndustry = {
  //         FieldAlias: "Industry",
  //         Fieldname: "Industry",
  //         Customfield: null,
  //         index: Industry,
  //       };
  //       SaveAllEmailAccount.push(ObjIndustry);
  //     }

  //     var Address1 = parseInt(document.getElementById("Address1").value);
  //     if (Address1 >= 0) {
  //       var ObjAddress1 = {
  //         FieldAlias: "Address 1",
  //         Fieldname: "Address1",
  //         Customfield: null,
  //         index: Address1,
  //       };
  //       SaveAllEmailAccount.push(ObjAddress1);
  //     }

  //     var Address2 = parseInt(document.getElementById("Address2").value);
  //     if (Address2 >= 0) {
  //       var ObjAddress2 = {
  //         FieldAlias: "Address 2",
  //         Fieldname: "Address2",
  //         Customfield: null,
  //         index: Address2,
  //       };
  //       SaveAllEmailAccount.push(ObjAddress2);
  //     }

  //     var City = parseInt(document.getElementById("City").value);
  //     if (City >= 0) {
  //       var ObjCity = {
  //         FieldAlias: "City",
  //         Fieldname: "City",
  //         Customfield: null,
  //         index: City,
  //       };

  //       SaveAllEmailAccount.push(ObjCity);
  //     }
  //     var State = parseInt(document.getElementById("State").value);
  //     if (State >= 0) {
  //       var ObjState = {
  //         FieldAlias: "State",
  //         Fieldname: "State",
  //         Customfield: null,
  //         index: State,
  //       };
  //       SaveAllEmailAccount.push(ObjState);
  //     }

  //     var Zip = parseInt(document.getElementById("Zip").value);
  //     if (Zip >= 0) {
  //       var ObjZip = {
  //         FieldAlias: "Zip",
  //         Fieldname: "Zip",
  //         Customfield: null,
  //         index: Zip,
  //       };
  //       SaveAllEmailAccount.push(ObjZip);
  //     }

  //     var Country = parseInt(document.getElementById("Country").value);
  //     if (Country >= 0) {
  //       var ObjCountry = {
  //         FieldAlias: "Country",
  //         Fieldname: "Country",
  //         Customfield: null,
  //         index: Country,
  //       };
  //       SaveAllEmailAccount.push(ObjCountry);
  //     }

  //     var ContactCategory = parseInt(
  //       document.getElementById("ContactCategory").value
  //     );
  //     if (ContactCategory >= 0) {
  //       var ObjContactCategory = {
  //         FieldAlias: "Contact Category",
  //         Fieldname: "ContactCategory",
  //         Customfield: null,
  //         index: ContactCategory,
  //       };
  //       SaveAllEmailAccount.push(ObjContactCategory);
  //     }
  //     var AccountCategory = parseInt(
  //       document.getElementById("AccountCategory").value
  //     );
  //     if (AccountCategory >= 0) {
  //       var ObjAccountCategory = {
  //         FieldAlias: "Account Category",
  //         Fieldname: "AccountCategory",
  //         Customfield: null,
  //         index: AccountCategory,
  //       };
  //       SaveAllEmailAccount.push(ObjAccountCategory);
  //     }

  //     var ValidationScore = parseInt(
  //       document.getElementById("ValidationScore").value
  //     );
  //     if (ValidationScore >= 0) {
  //       var ObjValidationScore = {
  //         FieldAlias: "Validation Score",
  //         Fieldname: "ValidationScore",
  //         Customfield: null,
  //         index: ValidationScore,
  //       };
  //       SaveAllEmailAccount.push(ObjValidationScore);
  //     }
  //     var ListName = document.getElementById("ListName").value;
  //     if (ListName != "") {
  //       var ObjListName = {
  //         FieldAlias: "List Name",
  //         Fieldname: "ListName",
  //         Customfield: null,
  //         index: ListName,
  //       };
  //       SaveAllEmailAccount.push(ObjListName);
  //     }

  //     var ContactSource = parseInt(
  //       document.getElementById("ContactSource").value
  //     );
  //     if (ContactSource >= 0) {
  //       var ObjContactSource = {
  //         FieldAlias: "Contact Source",
  //         Fieldname: "ContactSource",
  //         Customfield: null,
  //         index: ContactSource,
  //       };
  //       SaveAllEmailAccount.push(ObjContactSource);
  //     }
  //     var ContactOwner = parseInt(
  //       document.getElementById("ContactOwner").value
  //     );
  //     if (ContactOwner >= 0) {
  //       var ObjContactOwner = {
  //         FieldAlias: "Contact Owner",
  //         Fieldname: "ContactOwner",
  //         Customfield: null,
  //         index: ContactOwner,
  //       };
  //       SaveAllEmailAccount.push(ObjContactOwner);
  //     }
  //     var ClientPOC = parseInt(document.getElementById("ClientPOC").value);
  //     if (ClientPOC >= 0) {
  //       var ObjClientPOC = {
  //         FieldAlias: "Client POC",
  //         Fieldname: "ClientPOC",
  //         Customfield: null,
  //         index: ClientPOC,
  //       };
  //       SaveAllEmailAccount.push(ObjClientPOC);
  //     }
  //     var ReasonUnqualified = parseInt(
  //       document.getElementById("ReasonUnqualified").value
  //     );
  //     if (ReasonUnqualified >= 0) {
  //       var ObjReasonUnqualified = {
  //         FieldAlias: "Reason Unqualified",
  //         Fieldname: "ReasonUnqualified",
  //         Customfield: null,
  //         index: ReasonUnqualified,
  //       };
  //       SaveAllEmailAccount.push(ObjReasonUnqualified);
  //     }
  //     var AccountType = parseInt(document.getElementById("AccountType").value);
  //     if (AccountType >= 0) {
  //       var ObjAccountType = {
  //         FieldAlias: "Account Type",
  //         Fieldname: "AccountType",
  //         Customfield: null,
  //         index: AccountType,
  //       };
  //       SaveAllEmailAccount.push(ObjAccountType);
  //     }
  //     var CompanyRevenue = parseInt(
  //       document.getElementById("CompanyRevenue").value
  //     );
  //     if (CompanyRevenue >= 0) {
  //       var ObjCompanyRevenue = {
  //         FieldAlias: "Company Revenue",
  //         Fieldname: "CompanyRevenue",
  //         Customfield: null,
  //         index: CompanyRevenue,
  //       };
  //       SaveAllEmailAccount.push(ObjCompanyRevenue);
  //     }
  //     var EmployeeCount = parseInt(
  //       document.getElementById("EmployeeCount").value
  //     );
  //     if (EmployeeCount >= 0) {
  //       var ObjEmployeeCount = {
  //         FieldAlias: "Employee Count",
  //         Fieldname: "EmployeeCount",
  //         Customfield: null,
  //         index: EmployeeCount,
  //       };
  //       SaveAllEmailAccount.push(ObjEmployeeCount);
  //     }

  //     var MobilePhone = parseInt(document.getElementById("MobilePhone").value);
  //     if (MobilePhone >= 0) {
  //       var ObjMobilePhone = {
  //         FieldAlias: "Mobile Phone",
  //         Fieldname: "MobilePhone",
  //         Customfield: null,
  //         index: MobilePhone,
  //       };
  //       SaveAllEmailAccount.push(ObjMobilePhone);
  //     }

  //     var IsDoNotCallContact = parseInt(
  //       document.getElementById("IsDoNotCallContact").value
  //     );
  //     if (IsDoNotCallContact >= 0) {
  //       var ObjIsDoNotCallContact = {
  //         FieldAlias: "Do not call contact",
  //         Fieldname: "IsDoNotCallContact",
  //         Customfield: null,
  //         index: IsDoNotCallContact,
  //       };
  //       SaveAllEmailAccount.push(ObjIsDoNotCallContact);
  //     }

  //     var SalesRep = parseInt(document.getElementById("SalesRep").value);
  //     if (SalesRep >= 0) {
  //       var ObjSalesRep = {
  //         FieldAlias: "Sales Rep",
  //         Fieldname: "SalesRep",
  //         Customfield: null,
  //         index: SalesRep,
  //       };
  //       SaveAllEmailAccount.push(ObjSalesRep);
  //     }

  //     var ColdCaller = parseInt(document.getElementById("ColdCaller").value);
  //     if (ColdCaller >= 0) {
  //       var ObjColdCaller = {
  //         FieldAlias: "Sales Development Reps",
  //         Fieldname: "ColdCaller",
  //         Customfield: null,
  //         index: ColdCaller,
  //       };
  //       SaveAllEmailAccount.push(ObjColdCaller);
  //     }

  //     var Tag = parseInt(document.getElementById("Tag").value);
  //     if (Tag >= 0) {
  //       var ObjTag = {
  //         FieldAlias: "Tag",
  //         Fieldname: "Tag",
  //         Customfield: null,
  //         index: Tag,
  //       };
  //       SaveAllEmailAccount.push(ObjTag);
  //     }

  //     var CallNotes = parseInt(document.getElementById("CallNotes").value);
  //     if (CallNotes >= 0) {
  //       var ObjCallNotes = {
  //         FieldAlias: "Call Notes",
  //         Fieldname: "CallNotes",
  //         Customfield: null,
  //         index: CallNotes,
  //       };
  //       SaveAllEmailAccount.push(ObjCallNotes);
  //     }

  //     var OtherPhone1 = parseInt(document.getElementById("OtherPhone1").value);
  //     if (OtherPhone1 >= 0) {
  //       var ObjOtherPhone1 = {
  //         FieldAlias: "Other Phone 1",
  //         Fieldname: "OtherPhone1",
  //         Customfield: null,
  //         index: OtherPhone1,
  //       };
  //       SaveAllEmailAccount.push(ObjOtherPhone1);
  //     }
  //     var OtherPhone2 = parseInt(document.getElementById("OtherPhone2").value);
  //     if (OtherPhone2 >= 0) {
  //       var ObjOtherPhone2 = {
  //         FieldAlias: "Other Phone 2",
  //         Fieldname: "OtherPhone2",
  //         Customfield: null,
  //         index: OtherPhone2,
  //       };
  //       SaveAllEmailAccount.push(ObjOtherPhone2);
  //     }

  //     var ContactID = parseInt(document.getElementById("ContactID").value);
  //     if (ContactID >= 0) {
  //       var ObjContactID = {
  //         FieldAlias: "ContactID",
  //         Fieldname: "ContactID",
  //         Customfield: null,
  //         index: ContactID,
  //       };
  //       SaveAllEmailAccount.push(ObjContactID);
  //     }

  //     var csvdata = csvData;
  //     var csvdata2 = csvData.length;

  //     Fileupload.getBase64(files, (result) => {
  //       var rs = result;
  //       var splirs = rs.split("base64,");
  //       var _base64 = splirs[1];

  //       var ObjData = {
  //         Fields: "Coverimg",
  //         Path: "./Content/UploadedFiles/SalesReplyContact",
  //         File: _base64,
  //         ImageName: filename,
  //         FileData: csvdata,
  //         SaveAllEmailAccount: SaveAllEmailAccount,
  //         ClientID: ClientID,
  //         UserID: UserID,
  //         CreatedBy: UserID,
  //         CreatedDate: new Date(),
  //         IsUpdateExistContacts: IsUpdateExistContacts,
  //         IsInsertNullValues: IsInsertNullValues,
  //         IsDoNotupdatecompanyname: IsDoNotupdatecompanyname,
  //       };
  //       Axios({
  //         url: CommonConstants.MOL_APIURL + "/contacts/ContactImport",
  //         method: "POST",
  //         data: ObjData,
  //       }).then((res) => {
  //         ;
  //         if (res.data.StatusMessage == "SUCCESS") {
  //           toast.success(
  //             "Contacts Save Imports",
  //           );
  //           SetTotal(res.data.TotalContacts);
  //           SetTotalSucess(res.data.TotalSuccess);
  //           SetTotalError(res.data.TotalError);
  //           SetTotalDuplicate(res.data.TotalDuplicate);
  //           SetTotalUpdated(res.data.TotalUpdated);
  //         document.getElementById("Show_csv_details").style.display = "block";
  //         document.getElementById("map_attribute").style.display = "none";
  //         document.getElementById("import_account").style.display = "none";
  //         document.getElementById("hideloding").style.display = "none";

  //         }
  //       });
  //     });
  //   }
  // };

    // Detach Email from all campaign
    const DetachEmailAccount = async(ID) => {

      Swal.fire({
        title: "Are you sure?",
        text: "you want to detach a Email from all campaign.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34bfa3",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, detach it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          SetIsLoading(false);
          var EmailAccountData = {
            Role:Role,
            ClientID:ClientID,
            ID:ID
          }
          const rows1 = await Axios({
            url: CommonConstants.MOL_APIURL + "/emailaccount/DetachEmailAccount",
            method: "POST",
            data: EmailAccountData,
          });
          if(rows1.data.StatusMessage === "SUCCESS"){
            Swal.fire(
              "Detach!",
              "Email account detach successfully.",
              "success"
            );
            SetIsLoading(false);
            EmailAccountGet(ClientID, UserID, Role);
                history.push("/EmailAccounts");
                SetPage(1);
                SetSearch("");
                document.getElementById("Search").value = "";
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          SetIsLoading(false);
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    }

  return (
    <div>
    {IsLoading ? (
        <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div>
      ) : (
        <></>
      )}

      <div className="import" id="import_account" style={{ display: "none" }}>
        <div className="row mt-5">
          <div className="col-xl-8 offset-xl-2">
            <h3 className="uploadheadingtitle">Import Account Details </h3>
            <div className="uplodfilesbox">
            <CSVReader
                accept={["text/csv", ".csv", "application/vnd.ms-excel"]}
                onDrop={HandleOnDrop}
                onError={HandleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={HandleOnRemoveFile}
                isReset={Reset}
              >
                <span>Select .CSV file</span>
                <p>Add Account data from csv file to bulk upload.</p>
              </CSVReader>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-xl-8 offset-xl-2 align-right">
            <a onClick={AccountMapCsv} class="btn btn-primary btnColor">
              <i class="la la-upload"></i> Upload
            </a>
            <a
              onClick={CancelImportEmailAccount}
              class="btn btn-secondary"
            >
              <i class="la flaticon-cancel"></i> Cancel
            </a>
          </div>
        </div>
      </div>

      {/* Mapped div */}
     
        <div
        className="mappedbody "
        id="map_attribute"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Map attributes</h4>
        </div>
        <div className="col-xs-12" id="divContactSelection">
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn1"
                  disabled="disabled"
                >
                  <option selected="" value="FromEmail">
                    From Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="FromEmail"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    FromEmail == null
                      ? DropboxData.indexOf("From Email")
                      : FromEmail
                  }
                >
                  <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn2"
                  disabled="disabled"
                >
                  <option selected="" value="FullName">
                    Full Name
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="FullName"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    FullName == null
                      ? DropboxData.indexOf("Full Name")
                      : FullName
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn3"
                  disabled="disabled"
                >
                  <option selected="" value="AliasTitle">
                    Alias Title
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="AliasTitle"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    AliasTitle == null
                      ? DropboxData.indexOf("Alias Title")
                      : AliasTitle
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn4"
                  disabled="disabled"
                >
                  <option selected="" value="SMTPEmail">
                    SMTP Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="SMTPEmail"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SMTPEmail == null
                      ? DropboxData.indexOf("SMTP Email")
                      : SMTPEmail
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn5"
                  disabled="disabled"
                >
                  <option selected="" value="IMAPEmail">
                    IMAP Email
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="IMAPEmail"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    IMAPEmail == null
                      ? DropboxData.indexOf("IMAP Email")
                      : IMAPEmail
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>

            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="SMTPPassword">
                    SMTP Password
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="SMTPPassword"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SMTPPassword == null
                      ? DropboxData.indexOf("SMTP Password")
                      : SMTPPassword
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn11"
                  disabled="disabled"
                >
                  <option selected="" value="IMAPPassword">
                    IMAP Password
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="IMAPPassword"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    IMAPPassword == null
                      ? DropboxData.indexOf("IMAP Password")
                      : IMAPPassword
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn6"
                  disabled="disabled"
                >
                  <option selected="" value="SMTPHost">
                    SMTP Host
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="SMTPHost"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SMTPHost == null
                      ? DropboxData.indexOf("SMTP Host")
                      : SMTPHost
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn22"
                  disabled="disabled"
                >
                  <option selected="" value="IMAPPort">
                    IMAP Port
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="IMAPPort"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    IMAPPort == null
                      ? DropboxData.indexOf("IMAP Port")
                      : IMAPPort
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn23"
                  disabled="disabled"
                >
                  <option selected="" value="ThrottleStartingVolume">
                    Throttle Starting Volume
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="ThrottleStartingVolume"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ThrottleStartingVolume == null
                      ? DropboxData.indexOf("Throttle Starting Volume")
                      : ThrottleStartingVolume
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn14"
                  disabled="disabled"
                >
                  <option selected="" value="ThrottleEndingVolume">
                    Throttle Ending Volume
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="ThrottleEndingVolume"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    ThrottleEndingVolume == null
                      ? DropboxData.indexOf("Throttle Ending Volume")
                      : ThrottleEndingVolume
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn18"
                  disabled="disabled"
                >
                  <option selected="" value="DaysTillFullThrottle">
                    Days Till Full Throttle
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="DaysTillFullThrottle"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    DaysTillFullThrottle == null
                      ? DropboxData.indexOf("Days Till Full Throttle")
                      : DaysTillFullThrottle
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn12"
                  disabled="disabled"
                >
                  <option selected="" value="EmailDelay">
                    Email Delay
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="EmailDelay"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    EmailDelay == null
                      ? DropboxData.indexOf("Email Delay")
                      : EmailDelay
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn13"
                  disabled="disabled"
                >
                  <option selected="" value="SMTPSecurity">
                    SMTP Security
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="SMTPSecurity"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SMTPSecurity == null
                      ? DropboxData.indexOf("SMTP Security")
                      : SMTPSecurity
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group m-form__group d-flex">
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn7"
                  disabled="disabled"
                >
                  <option selected="" value="IMAPSecurity">
                    IMAP Security
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x "></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="IMAPSecurity"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    IMAPSecurity == null
                      ? DropboxData.indexOf("IMAP Security")
                      : IMAPSecurity
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 nopadding">
              <div>
                <select
                  className="dbcontent form-control  m-input"
                  id="dbColumn8"
                  disabled="disabled"
                >
                  <option selected="" value="SendingSchedule">
                    Sending Schedule
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-1 text-center">
              <span className="fa fa-arrows-h fa-2x"></span>
            </div>
            <div className="col-lg-2 nopadding">
              <div>
                <select disabled={MapAttrBox}
                  id="SendingSchedule"
                  className="csvcontent form-control  m-input"
                  autocomplete="off"
                  value={
                    SendingSchedule == null
                      ? DropboxData.indexOf("Sending Schedule")
                      : SendingSchedule
                  }
                >
                   <option value="">Not Mapped</option>
                  {DropboxData.map((dbdata, index) => (
                    <option value={index}>{dbdata}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <button
            disabled={MapAttrBox}
              onClick={AccountSaveCsvAddBulk}
              className="btn btn-primary btn-lightgreen"
            >
              <i className="la la-save"></i>Save
            </button>
          </div>
        </div>
      </div>
       
      
      {/* Mapped end div */}

      {/* Total Details for csv */}
      <div
        className="portletbody px-4"
        id="total_div_display"
        style={{ display: "none" }}
      >
        <div className="row col-xs-12">
          <h4 className="headertitle float-left">Results</h4>
        </div>

        <div className="col-xs-12">
          <div className="row colfive">
            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-primary">{Total}</span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-success">{TotalSucess}</span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total success</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-danger">{TotalError}</span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total error</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="shadowcard boxcardcounter">
                <div className="d-flex align-center">
                  <div className="contectboxes w-100">
                    <div className="line-title mb-2">
                      <span className="box-main-title font-warning">{TotalDuplicate}</span>
                    </div>
                    <div className="box-inner-title">
                      <string className="clr-sky">Total Duplicate</string>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              class="form-control form-control-sm"
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label class="textlabelte">
            Search:{" "}
            <input
              type="Search"
              id="Search"
              onKeyPress={RequestSearch}
              class="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>

      <div className="table-bordered">
      <TableContainer component={Paper}>
      <StyleHeader isDataLoaded={isDataLoaded} />
        <Table class='table-ref' aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsAuthenticated");
                  }}
                >
                  Authenticate
                </a>
              </TableCell>
              {/* <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPStatus");
                  }}
                >
                  SMTP Status
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPStatus");
                  }}
                >
                  IMAP Status
                </a>
              </TableCell> */}
              <TableCell>
                <a
                  onClick={() => {
                    SortData("Name");
                  }}
                >
                  Name
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("AliasTitle");
                  }}
                >
                  Alias Title
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPFromEmail");
                  }}
                >
                  From Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPEmail");
                  }}
                >
                  SMTP Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPHost");
                  }}
                >
                  SMTP Host
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPPort");
                  }}
                >
                  SMTP Port
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPEmail");
                  }}
                >
                  IMAP Email
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPHost");
                  }}
                >
                  IMAP Host
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPPort");
                  }}
                >
                  IMAP Port
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ThrottleStartingVolume");
                  }}
                >
                  Throttle Starting Volume
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ThrottleEndingVolume");
                  }}
                >
                  Throttle Ending Volume
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("EmailDelay");
                  }}
                >
                  Email Delay
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("DaysTillFullThrottle");
                  }}
                >
                  Days Till Full Throttle
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("ThrottleStartingVolume");
                  }}
                >
                  Today's Potential Volume
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("SMTPSSLType");
                  }}
                >
                  SMTP Security
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IMAPSSLType");
                  }}
                >
                  IMAP Security
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("StartTime");
                  }}
                >
                  Sending Schedule
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsEmailWarmingSending");
                  }}
                >
                  Email Warming Sending
                </a>
              </TableCell>
              <TableCell>
                <a
                  onClick={() => {
                    SortData("IsEmailWarmingReceiving");
                  }}
                >
                  Email Warming Receiving
                </a>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.length === 0 ? (
              <p className="text-center">No data available in table</p>
            ) : (
              Rows?.map((row) => (
                <TableRow key={Rows?._id}>
                  <TableCell>
                    {row.IsAuthenticated === true ? (
                      <span class="btnyes">Yes</span>
                    ) : (
                      <span class="btnno">No</span>
                    )}
                  </TableCell>
                  {/* <TableCell>
                    {row.SMTPStatus ? (
                      <span class="btnyes">Active</span>
                    ) : (
                      <span class="btnno">Inactive</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.IMAPStatus ? (
                      <span class="btnyes">Active</span>
                    ) : (
                      <span class="btnno">Inactive</span>
                    )}
                  </TableCell> */}
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.AliasTitle}</TableCell>
                  <TableCell>{row.SMTPFromEmail}</TableCell>
                  <TableCell>{row.SMTPEmail}</TableCell>
                  <TableCell>{row.SMTPHost}</TableCell>
                  <TableCell>{row.SMTPPort}</TableCell>
                  <TableCell>{row.IMAPEmail}</TableCell>

                  <TableCell>{row.IMAPHost}</TableCell>
                  <TableCell>{row.IMAPPort}</TableCell>
                  <TableCell>{row.ThrottleStartingVolume}</TableCell>
                  <TableCell>{row.ThrottleEndingVolume}</TableCell>
                  <TableCell>{row.EmailDelay}</TableCell>
                  <TableCell>{row.DaysTillFullThrottle}</TableCell>
                  <TableCell>{row.CampaignMailSentTodaypotential?row.CampaignMailSentTodaypotential:row.CampaignMailSentToday}</TableCell>

                  <TableCell>{row.SMTPSSLType}</TableCell>

                  <TableCell>{row.IMAPSSLType}</TableCell>
                  <TableCell> {row?.SS?.ScheduleStartTime!=null?moment(row?.SS?.ScheduleStartTime,'HH:mm').format('h:mm A'):("")}
                            {" "}To{" "}
                            {row?.SS.ScheduleEndTime!=null?moment(row?.SS.ScheduleEndTime,'HH:mm').format('h:mm A'):("")}</TableCell>
                  <TableCell>
                    {row.IsEmailWarmingSending ? (
                      <span class="btnyes">Yes</span>
                    ) : (
                      <span class="btnno">No</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.IsEmailWarmingReceiving ? (
                      <span class="btnyes">Yes</span>
                    ) : (
                      <span class="btnno">No</span>
                    )}
                  </TableCell>
                  <TableCell>
                    <a title="Edit"
                      onClick={() => {
                        EmailAccountsEdit(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-edit-1 edit-icon"></i>
                    </a>
                    <a title="Delete"
                      href="#"
                      onClick={() => {
                        DeleteBtn(row._id);
                      }}
                      className="btn-eyesicon"
                    >
                      <i class="la flaticon-delete-1 delete-icon"></i>
                    </a>
                    {row?.Campaigndetails?.length >0?(<a
                      onClick={() => {DetachEmailAccount(row._id)}}
                      className="btn btn-primary btnColor ml-2" title="Detach from all Campaign"
                    >
                      <span>Detach</span>
                    </a>):"" }
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      {Sflag ? (
        <div class="row">
          <Pagination
            component="div"
            count={CountPage}
            onChange={HandleChangePage}
            page={Page}
            showFirstButton
            showLastButton
          />
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries (filtered from {Flen} total entries)
            </p>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div class="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </div>
  );
}
