import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar'; 
import Slacknotification from '../../_components/clients/slacknotification/DefaultSlackNotificationTabing';

// Note that Froala Editor has to be required separately
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css'; 
import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor';
import FroalaEditor from 'react-froala-wysiwyg'; 
import Axios from 'axios';
import { CommonConstants } from '../../_constants/common.constants';
import { toast } from 'react-toastify';
import { GetUserDetails } from "../../_helpers/Utility";

class CSlackNotificationsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ClientID:null,
            UserID:null,
            Role:null,
            Signature:null,
            id:null
        };
        this.handleModelChange = this.handleModelChange.bind(this);
       

        this.savebtn = this.savebtn.bind(this);
      }
      componentDidMount() {
        var Details = GetUserDetails();
        if (Details != null) {
          this.state.ClientID = Details.ClientID;
          this.state.UserID = Details.ParentUserID;
          this.state.Role = Details.Role;
        }
       
          this.GetEmailSignature()
          this.state.Signature
      }

      GetEmailSignature()
      {
        var str_in = {
            "ClientID": this.state.ClientID,
            "UserID": this.state.UserID,
        };

        Axios({
            //url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureList",
            method: "POST",
            data: str_in
        }).then(resdata => {
            ;
            if (resdata.data.StatusMessage == "SUCCESS") {
                if (resdata.data.pagedata.length > 0) {
                    this.setState({ id: resdata.data.pagedata[0]._id });
                    document.getElementById("unsubscribe").value = resdata.data.pagedata[0].UnsubscribeText;
                    this.setState({Signature : resdata.data.pagedata[0].Signature});
                }
            }
        })
      }
      handleModelChange (model) {
        this.setState({
            Signature: model
        });
      }

    //   editor = new FroalaEditor('.selector', {}, function () {

    //     // Call the method inside the initialized event.
      
    //     editor.SVG_KEY("cogs");
      
    //   })
     
      
      config={
        placeholderText: 'Edit Your Content Here!',
        charCounterCount: false,
       // pluginsEnabled: ['bold','italic','underline','createLink'],
       
       my_dropdown: {
            title: 'Advanced options',
            type: 'dropdown',
            focus: false,
            undo: false,
                // Dropdown Title
                refreshAfterCallback: true,
           
              options: {
                'v1': 'Option 1',
                'v2': 'Option 2'
              },
              callback: function (cmd, val) {
                console.log (val);
              },
              // Callback on refresh.
              refresh: function ($btn) {
                console.log ('do refresh');
              },
              // Callback on dropdown show.
              refreshOnShow: function ($btn, $dropdown) {
                console.log ('do refresh when show');
              }
            
              // Options.
        
        },
        toolbarButtons: ['bold', 'italic', 'underline', 'insertLink', 'insertImage', 'html' ,'my_dropdown'],
    }
       
    savebtn(e) {
        ;
        e.preventDefault();
            var data = {
                "id":this.state.id,
                "Signature": this.state.Signature,
                "UnsubscribeText":document.getElementById("unsubscribe").value,
                "ClientID": this.state.ClientID,
                "UserID": this.state.UserID,
                "CreatedBy": this.state.CUserID,
            };
            Axios({
                url: CommonConstants.MOL_APIURL + "/emailsignature/EmailSignatureAdd",
                method: "POST",
                data: data
            }).then(res => {
                ;
                if (res.data.StatusMessage == "SUCCESS") {
                    this.state.id=res.data.data._id
                    toast.success("EmailSignature save added successfully.", "Add Email Signature");
                    this.GetEmailSignature()
                }
                else {
                    toast.error(res.data.Message)
                }
            });
}
    render() {
        return (
            <>
                <div className="adminmain">
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitle  lg-headertitle pl-0 mb-0">Slack Notifications</h4> 
                        </div> 
                        <div className="col">
                            <div className="listing-li float-right padb-15">
                               
                            </div>
                        </div>
                    </div>

                    <div className="row"> 
                        <div className="col"> 
                                <Slacknotification /> 
                        </div> 
                    </div>

                </div>

                </div>
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCSlackNotificationsPage = connect(mapState, actionCreators)(CSlackNotificationsPage);
export { connectedCSlackNotificationsPage as CSlackNotificationsPage };