import React from 'react';
import Axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let CryptoJS = require("crypto-js");
import { connect } from 'react-redux';
import Popupone from "reactjs-popup";
import { userActions } from '../../_actions';
import Sidebar from '../../_components/clients/nav-sidebar/Sidebar';
import Footer from '../../_components/user/footer/footer';
import UsersPerClientTabing from '../../_components/clients/usersperclient/UsersPerClientTabing';
import { history } from '../../_helpers';
const EmailTemplete = require("../../_helpers/email-templete");

import { CommonConstants } from '../../_constants/common.constants';
import{GetUserDetails,EmailValidation} from '../../_helpers/Utility'
toast.configure();
import loadingicon from "../../images/loading.gif";

class CUsersPerClientPage extends React.Component {
   constructor(props){
    super(props);

    this.state = {
      PopUpVal: true,
      InvitePopup: false,
      ClientID:null ,
        UserID:null,
        CUserID:null,
        fields: {},
        errors: {},
        IsExist:false,
        IsExist1:false,
        EmailNotNull:false,
        Message:"",
        ListUpdate:false,
        Role:null,
        IsLoading:false,
        ButtonDisabled:false,
        IsSendingBtn: false,
        FormData:{
          Email:""
        },
    };
    this.sendButtonRef = React.createRef();
    this.SendInvite = this.SendInvite.bind(this);
   }

   componentDidMount(){
    let Details = GetUserDetails();
    if(Details != null)
    {
        this.state.ClientID= (Details.ClientID);
        this.state.UserID=(Details.ParentUserID);
        this.state.CUserID=(Details.ChildUserID);
        this.state.Role=(Details.Role);
    }
    }

    OpenInvitePopup = async () => {
      if(await this.CheckUsersPerClientLimit(this.state.ClientID,this.state.UserID,this.state.Role)){
        toast.error(<div className="toastsize">Client User <br/>You have reached your limit for sending invites and creating client users.</div>);
        this.setState({IsLoading:false})
      }else{
        this.setState({ InvitePopup: true,ButtonDisabled:false });
        this.setState({errors:{},FormData:{Email:""}});
      }
    }
    
    CloseInvitePopup = () => {
      this.setState({ InvitePopup: false });
      this.setState({errors:{},FormData:{Email:""}});
    }

      // Check Users per client limit exhaust or not
  CheckUsersPerClientLimit = async(ClientID,UserID,Role) => {
    try{
      this.setState({IsLoading:true})
      let InputParameters = {
        UserID: UserID,
        ClientID: ClientID,
        Role:Role
      };
      let ApiResponse = await Axios({
        url: CommonConstants.MOL_APIURL + "/users_per_client/CheckUsersPerClientLimit",
        method: "POST",
        data: InputParameters,
      });
  
      if (ApiResponse.data.StatusMessage == "SUCCESS") {
        this.setState({IsLoading:false})
          return ApiResponse.data?.Data?.IsExhaustLimit
      }else if(ApiResponse.data.StatusMessage == 'ERROR'){
        this.setState({IsLoading:false})
        return true;
      }
    }catch(err){
      this.setState({IsLoading:false})

    }
  }

  async CheckAdminInvite(Email) {
    this.setState({IsLoading:true})
    var InputParameters = {
      ParentUserID: this.state.UserID,
      UserID: this.state.UserID,
      Email: Email?.toLowerCase(),
      ClientID: this.state.ClientID,
    };
    let resdata = await Axios({
      url: CommonConstants.MOL_APIURL + "/AdminUser/InviteValidation",
      method: "POST",
      data: InputParameters,
    });

    if (resdata.data.StatusMessage == "SUCCESS") {
      if (resdata.data.Flag) {
        this.setState({ Message: resdata.data.Message,IsLoading:false });
        return false;
      } else {
        this.setState({ Message: "",IsLoading:false });
        return true;
      }
    }
  }

     // Whole form validation
     async FormValidation() {
      let formIsValid = true;
      let Email = document.getElementById("EmailInvite").value?.toLowerCase();
      let IsExists = await this.CheckAdminInvite(Email);
  
      if(IsExists){
        formIsValid = true;
      }else{
        formIsValid = false;
      }
      if(Email.trim().length == 0){
        this.setState({Message:"Please enter email."})
        formIsValid = false;
      }
    
      return formIsValid;
    }

 async SendInvite(e){
  e.preventDefault();
  e.currentTarget.disabled = true;
  let Final_flag =  await this.FormValidation();
  // document.getElementById("hidelod").style.display = "block";
  if(Final_flag==true){
        let Email=document.getElementById('EmailInvite').value;
        let VerificationCodeChar = (Math.random() + 1)
                    .toString(36)
                    .substring(7);
        
          let re =
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
          let EmailFlg = re.test(Email);
          if (EmailFlg == true) {
            let FinalEmail = CryptoJS.AES.encrypt(
              JSON.stringify(Email),
              "my-secret-key@123"
            ).toString();
            let UID = CryptoJS.AES.encrypt(
              JSON.stringify(this.state.UserID),
              "my-secret-key@123"
            ).toString();
            let CID = CryptoJS.AES.encrypt(
              JSON.stringify(this.state.ClientID),
              "my-secret-key@123"
            ).toString();
            
            let EmailData = EmailTemplete.userEmailInvite(
              FinalEmail,
              CID,
              UID,
              CommonConstants.usersperclient_invitation
            );
                let str_mail = {
                  Email: Email,
                  html: EmailData,
                  subject: "SalesHive User Account",
                };
                Axios({
                  url: CommonConstants.MOL_APIURL + "/user/profileemail",
                  method: "POST",
                  data: str_mail,
                }).then((res) => {
                  
                    let InputParameters={
                      Email:Email,
                      UserID:this.state.UserID,
                      ClientID:this.state.ClientID,
                      CreatedDate:new Date(),
                      CreatedBy:this.state.CUserID,
                      VerificationCode:VerificationCodeChar,
                      Role:this.state.Role
                  }
                   Axios({
            url: CommonConstants.MOL_APIURL + "/users_per_client/UserPerClientInvite",
            method: "POST",
            data: InputParameters,
                  }).then((res) => {
                    
                    if (res.data.StatusMessage == "SUCCESS") {
                        toast.success(<div className="toastsize">Users Per Client <br/>
                          Invitation mail sent successfully.</div>)
                        // document.getElementById("hidelod").style.display = "none";
                        this.setState({ InvitePopup: false });
                        this.setState({ListUpdate:!this.state.ListUpdate})
                      } else {
                        toast.error(res.data.Message);
                      }
                      this.componentDidMount()
                  })
                  this.setState({ InvitePopup: false });
                  // document.getElementById("hidelod").style.display = "none";

                });
          }else{
              this.setState({Message:"Invalid email"})

          }
  } else {
    document.getElementById("m_emailInvite_submit").disabled = false;
    // document.getElementById("hidelod").style.display = "none";
  }
            
 }

 // Function to validate individual form field
async ValidateField(fieldName, value) {
  const newErrors = { ...this.state.errors };
  
  switch (fieldName) {
    case "Email":
      const trimmedValue = value.trim();
      newErrors.Email = !trimmedValue ? "Please enter email." : EmailValidation(trimmedValue) ? "" : "Invalid email.";
  
      // Check if the email already exists
      if (newErrors.Email === "") {
        const emailExists = await this.CheckAdminInvite(trimmedValue);
        if (!emailExists) {
          newErrors.Email = this.state.Message;
        }
      }
  
      break;
  
    default:
      break;
  }
  
  this.setState({ errors: newErrors });
  }
   //Update OnChange Handler
   HandleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((prevState) => {
      let updatedFormData = { ...prevState.FormData };
    
      if (type === "checkbox") {
        // For the checkbox, store the boolean value directly
        updatedFormData[name] = checked;
      } else {
        // For other fields, store their values
        updatedFormData[name] = value;
      }
    
      return {
        FormData: updatedFormData,
      };
    });
    };

    HandleBlur = (e) => {
      if (this.sendButtonRef.current && this.sendButtonRef.current.contains(e.relatedTarget)) {
        return;
      }
    
      const { name, value } = e.target;
      this.ValidateField(name, value);
    };

   //Send Invite
   HandleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ IsSendingBtn: true, ButtonDisabled: true, IsLoading: true });
  
  const { FormData } = this.state;
  const fieldNames = Object.keys(FormData);
  let isValid = true;
  // Validate each field manually
  for (let fieldName of fieldNames) {
    const value = FormData[fieldName];
    await this.ValidateField(fieldName, value);
    if (this.state.errors[fieldName]) {
      isValid = false;
    }
  }
  if (isValid && this.state.FormData.Email?.trim() !== "") {
    let Email = this.state.FormData.Email;
    this.setState({ IsLoading:true });
  const RequestBody = {
    Email: Email?.toLowerCase(),
      UserID: this.state.UserID,
      ClientID: this.state.ClientID,
      CreatedDate: new Date(),
      CreatedBy: this.state.CUserID,
      Role: this.state.Role,
      Navigation:CommonConstants.usersperclient_invitation
  }
  Axios({
    url:CommonConstants.MOL_APIURL +"/users_per_client/UserPerClientInvite",
    method: "POST",
    data: RequestBody,
  }).then((res) => {
    if (res.data.StatusMessage == "SUCCESS") {
      this.setState({ ButtonDisabled: false,IsLoading:false });
      this.setState({ IsSendingBtn: false });
      toast.success(
        <div className="toastsize">
          Client Users <br />
          Invitation mail sent successfully.
        </div>
      );
      this.setState({ InvitePopup: false });
      this.setState({ ListUpdate: !this.state.ListUpdate });
    } else {
      toast.error(res.data.Message);
      this.setState({ ButtonDisabled: false,IsLoading:false });
 
    
    }
  });
          
                      

    // You can submit the form data to your server or perform other actions here
  } else {
    this.setState({ ButtonDisabled: false,IsLoading:false });
    this.setState({ IsSendingBtn: false });
  }

  };

    render() {
      const { FormData, errors } = this.state;
        return (
            <>
                <Popupone open={this.state.InvitePopup} modal>
          <div className="modal-black"></div>
          <div className="filterPopup largerPopup">
            <div className="paddingboxTerms">
              <div className="modal-header px-4 py-4">
                <h5>Sent Mail</h5>
                <a className="close-ion text-black-50" onClick={this.CloseInvitePopup}>
                  <span aria-hidden="true" className="la la-remove"></span>
                </a>
              </div>
              <div className="modal-content bordernone p-4">
                <div className="row max-cols">
                  <label className="col-lg-4 view-lable-meet px-5">Email</label>
                  <div className="col-lg-8">
                  <input
                      className="form-control m-input"
                      data-val="true"
                      data-val-required="Please enter first name"
                      placeholder="Enter email"
                      id="EmailInvite"
                      name="Email"
                      type="text"
                      value={FormData.Email}
                      onChange={this.HandleInputChange}
                      onBlur={this.HandleBlur}
                    />
                      {errors.Email && (
                        <div className="errorinput">{errors.Email}</div>
                      )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.CloseInvitePopup}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="m_emailInvite_submit"
                  ref={this.sendButtonRef}
                  onClick={this.HandleSubmit}
                  disabled={this.state.ButtonDisabled}
                  className="btn btn-primary btnColor"
                >
                  Send{" "}
                </button>
              </div>
            </div>
          </div>
                </Popupone>
                {
                  this.state.IsLoading ? <div id="hidelod" className="loding-display">
                  <img src={loadingicon} />
                </div> : <></>
                }
                
		
                <div className="adminmain mheight"> 
                <Sidebar className=""/> 
                <div className="bodyhome">
                    <div className="row">
                        <div className="col">
                            <h4 className="headertitlebd lg-headertitle py-4 mb-0 pl-0">Client Users</h4>
                        </div>
                    <div className="col">
                        <div className="listing-li float-right mt-2">
                            <ul>
                                <li>
                                    <a className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air" onClick={this.OpenInvitePopup}>
                                        <i className="la la-plus la-icon-font-size-13"></i>
                                        <span>Invite</span>
                                    </a> 
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div> 
                    <div className="col px-0">
                        <UsersPerClientTabing listupdate={this.state.ListUpdate}/>
                    </div>
                </div>


                </div>
                <Footer />
            </>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedCUsersPerClientPage = connect(mapState, actionCreators)(CUsersPerClientPage);
export { connectedCUsersPerClientPage as CUsersPerClientPage };