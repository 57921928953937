import React from "react";
import { connect } from "react-redux";
const moment = require("moment");
import { userActions } from "../../_actions";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import ClientPotentialMeeting from "../../_components/user/ClientPotentialMeeting/ClientPotentialMeeting";
import $ from "jquery";
import { toast } from "react-toastify";


class ClientPotentialMeetingsPage extends React.Component {
  constructor(props) {
    const date13 = new Date(moment().subtract(14, "day"));
    // Extract the year, month, and day from the Date object
    const year13 = date13.getFullYear();
    const month13 = String(date13.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day13 = String(date13.getDate()).padStart(2, "0");
    const date = new Date(moment());
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    super(props);
    this.state = {
      FromDate: `${year13}-${month13}-${day13}`,
      ToDate: `${year}-${month}-${day}`,
    };
    this.Analyze = this.Analyze.bind(this);
    this.daterefect = React.createRef();
    this.daterefect2 = React.createRef();
  }
  componentDidMount() {
    // Get today's date
    var today = new Date();

    // Subtract 14 days from today's date
    var twoWeeksBefore = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);

    // Set the value of the "fromdate" variable to two weeks before
    var fromdate = twoWeeksBefore;

    // Convert to UTC date components
    const utcYear = fromdate.getUTCFullYear();
    const utcMonth = String(fromdate.getUTCMonth() + 1).padStart(2, "0");
    const utcDay = String(fromdate.getUTCDate()).padStart(2, "0");

    // Format the date
    const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

    // Set the value of the "from-date" input field
    document.getElementById("from-date").value = formattedDate;

    const $datepickerElement = $(this.daterefect.current);
    $datepickerElement.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });
    document.getElementById("to-date").value = new Date().toLocaleDateString(
      "en-US",
      { month: "2-digit", day: "2-digit", year: "numeric" }
    );
    const $datepickerElement2 = $(this.daterefect2.current);
    $datepickerElement2.datetimepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      container: "#app",
      todayBtn: true,
      todayHighlight: true,
      minView: "2",
    });

    var FromDate = moment
      .utc(document.getElementById("from-date").value)
      .format("YYYY-MM-DD");
    var ToDate = moment
      .utc(document.getElementById("to-date").value)
      .format("YYYY-MM-DD");
    this.setState({ FromDate: FromDate, ToDate: ToDate });
  }

  Analyze() {
    var FromDate = moment.utc(document.getElementById("from-date").value).format("YYYY-MM-DD");
    var ToDate = moment.utc(document.getElementById("to-date").value).format("YYYY-MM-DD");
    if (moment(FromDate, "YYYY-MM-DD").isAfter(moment(ToDate, "YYYY-MM-DD"))) {
      toast.error("Please select an appropriate date range");
    } else {
      this.setState({ FromDate: FromDate, ToDate: ToDate });
    }
  }

  render() {
    return (
      <>
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome">
            <div className="row align-items-center ">
              <div className="col">
                <h4 className="headertitle lg-headertitle  float-left pl-0">
                  Client Potential Meetings
                </h4>
              </div>
              <div className="col">
                <div className="btn-group datepicker-hed">
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="from-date"
                      ref={this.daterefect}
                    />
                  </div>
                  <div className="col-lg-6 timedatepikker date-input">
                    <input
                      className="form-control m-input"
                      type="text"
                      id="to-date"
                      ref={this.daterefect2}
                    />
                  </div>
                  <button
                    onClick={this.Analyze}
                    id="aCompanyState"
                    class="btn btn-primary btnColor m-btn brrb72"
                    autocomplete="off"
                  >
                    <i class="la flaticon-diagram"></i>
                    <span> Analyze</span>
                  </button>
                </div>
              </div>
            
            </div>
            <div className="row">
              <div className="col">
                <ClientPotentialMeeting
                  FromDates={this.state.FromDate}
                  ToDates={this.state.ToDate}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedClientPotentialMeetingsPage = connect(
  mapState,
  actionCreators
)(ClientPotentialMeetingsPage);
export { connectedClientPotentialMeetingsPage as ClientPotentialMeetingsPage };
