import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import Paper from '@material-ui/core/Paper';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactApexChart from "react-apexcharts";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import loadingicon from "../../../images/loading.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyleHeader from "../../user/StickyHeader/StickyHeader";

toast.configure();
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function Contactsdetailstable({
  FromChildDatesContact,
  ToChildDatesContact,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("DateContacted");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [sortedColumn, setSortedColumn] = React.useState("DateContacted");
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [DeleteArray, SetDeleteArray] = React.useState([]);
  const [ExportData, SetExportData] = React.useState([]);
  const [TotalSentEmail, SetTotalSentEmail] = React.useState(0);
  const [TotalReceivedEmail, SetTotalReceivedEmail] = React.useState(0);
  const [TotalMeeting, SetTotalMeeting] = React.useState(0);
  const [ResponsePer, SetResponsePer] = React.useState(0);
  const [ResponsetoMeetingPer, SetResponsetoMeetingPer] = React.useState(0);
  const [TotalSent, SetTotalSent] = React.useState([]);
  const [TotalResponse, SetTotalResponse] = React.useState([]);
  const [WeekDay, SetWeekDay] = React.useState([]);
  const [TotalMeetingList, SetTotalMeetingList] = React.useState([]);
  const [IsLoading, SetIsLoading] = React.useState(false);
  const [CUserID, SetCUserID] = React.useState(0)
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  const ContactsDetails = {
    series: [
      {
        name: "Sent",
        color: "#360947",
        data: [44, 55, 57, 56, 61, 8, 63, 60, 66, 56, 45, 65],
      },
      {
        name: "Responses",
        color: "#F8BF58",
        data: [76, 85, 19, 98, 87, 105, 11, 114, 94, 40, 115, 110],
      },
      {
        name: "Meetings",
        color: "#ffb963",
        data: [0, 85, 0, 98, 87, 105, 10, 114, 5, 40, 50, 50],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
            show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "04/01 - 04/04",
          "04/05 - 04/11",
          "04/12 - 04/18",
          "04/19 - 04/25",
          "04/26 - 05/02",
          "05/03",
          "05/10",
          "05/17",
          "05/24",
          "06/07",
          "06/14",
          "06/21",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };
  useEffect(() => {
    var Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    // ContactExportHandler(Details.ClientID, FromChildDatesContact, ToChildDatesContact, Details.Role)
    KpiContactGet(
      Details.ClientID,
      Details.ParentUserID,
      Details.Role,
      FromChildDatesContact,
      ToChildDatesContact
    );
    ContactKeyMatricGet(Details.ClientID, FromChildDatesContact, ToChildDatesContact, Details.ParentUserID, Details.Role)

  }, [Search, Page, RowsPerPage, FromChildDatesContact, ToChildDatesContact]);
  charts(FusionCharts);
  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };
  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      var SearchedVal = document.getElementById("search").value;
      SetSearch(SearchedVal);
      SetPage(1);
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    SetPage(NewPage);
  };


  // Export contact in kpu page
  // kpiMeeting get list
  const KpiContactGet = (CID, UID, URole) => {
    SetIsLoading(true)

    var KipsContactData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
      ToDate: ToChildDatesContact,
      FromDate: FromChildDatesContact,
    };


    const rows1 = Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiContactListGet",
      method: "POST",
      data: KipsContactData,
    });
    rows1.then((result) => {
      if (result.data.StatusMessage == "SUCCESS") {
        SetRows(result.data.PageData);
        SetRlen(result.data.TotalCount);
        SetFlen(result.data.TotalCount);
        SetCountPage(result.data.PageCount);
        SetIsLoading(false)
        setIsDataLoaded(true);

      }
      else {
        SetRows([]);
        SetRlen(0);
        SetFlen(0);
        SetCountPage(0);
        SetIsLoading(false)
        setIsDataLoaded(true);

      }

    });
  };

  // contact add to blacklist domain
  const ContactAddToBlackList = () => {

    if (DeleteArray.length == 0) {
      toast.error("Please select contact at least contact from list");
    } else {
      SetIsLoading(true)
      // Function to filter and map the array
      // const modifiedArray = DeleteArray.map(obj => ({ Email: obj?.MailTo, _id: obj?.ProspectID }));
      var ContactData = {
        ClientID: ClientID,
        LastUpdatedBy: CUserID,
        ContactBlackListArray: DeleteArray,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/contacts/ContactToAddToBlackList",
        method: "POST",
        data: ContactData,
      }).then((res) => {
        if (res) {
          if ((res.statusText = "Ok")) {
            Swal.fire("Blacklisted!", "Add to BlackListed Succesfully.", "success");
            SetDeleteArray([])
            SetIsLoading(false)
            // {
            //   Rows?.map((val) => {
            //       document.getElementById(val?.ProspectID).checked = false;
            //   });
            // }
            {
              Rows?.map((val) => {
                document.getElementById(val?.ProspectID).checked = false;
              })
            }
          } else {
            SetDeleteArray([])
            SetIsLoading(false)
          }
        }
      });
    }
  };

  // all check handler
  const CheckHandler = (e) => {
    {
      Rows?.map((val) => {
        if (e.target.checked) {
          document.getElementById(val.ProspectID).checked = true;
          var temArray = DeleteArray;
          temArray.push(val);
          SetDeleteArray(temArray);
        } else if (!e.target.checked) {
          document.getElementById(val.ProspectID).checked = false;
          DeleteArray.splice(val, 1);
        }
      });
    }
  }
  // when check box update data
  const CheckBoxClick = (e, row) => {
    if (e.target.checked) {
      var temArray = DeleteArray;
      temArray.push(row);
      SetDeleteArray(temArray);
    } else if (!e.target.checked) {
      DeleteArray.splice(row, 1);
    }
  };

  //Export contact file
  const ContactExportHandler = async() => {
    SetIsLoading(true)

    let InputParameter = {
      ClientID: ClientID,
      ToDate: ToChildDatesContact,
      FromDate: FromChildDatesContact,
      Role: Role

    };

   await Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpisContactExport",
      method: "POST",
      data: InputParameter,
    }).then((res) => {
      const fileUrl = res.data.Data; // Assuming this is the file URL
      if (fileUrl) {
        const link = document.createElement('a');
        link.href = CommonConstants.MOL_APIURL+"/Content/UploadedFiles/KpiContactExport/" +fileUrl;
        link.setAttribute('download', 'file'); // Optional: You can specify the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
            SetExportData(res.data.Data);
      SetIsLoading(false)

      toast.success("Data exported successfully.");
    }).catch((err) => {
      SetIsLoading(false)

      toast.error("Error in exporting contact file, please try again");

    });
  };

  // contact key matric get for graph
  const ContactKeyMatricGet = (CID, FromDates, ToDates, UID, role) => {
    const InputParameters = {
      ClientID: CID,
      ToDate: ToDates,
      FromDate: FromDates,
      UserID: UID,
      Role: role
    };
    Axios({
      url: CommonConstants.MOL_APIURL + "/kpis/KpiContactKeyMatric",
      method: "POST",
      data: InputParameters,
    }).then((Res) => {

      if (Res.data.StatusMessage == "SUCCESS") {
        if (Res.data.Data.length > 0) {
          var lstTotalSent = [];
          var lstTotalResponse = [];
          var lstTotalMeeting = [];
          var lstWeekDay = [];
          var objtotalmeeting = 0;
          var objTotalContact = 0;
          var objtotalReceivedEmail = 0;
          for (var i = 0; i < Res.data.Data.length; i++) {
            var totalcontactcount = Res.data.Data[i].TotalContact;
            objTotalContact = objTotalContact + totalcontactcount;

            var totalReceivedEmail = Res.data.Data[i]?.ReceivedDomain;
            objtotalReceivedEmail = objtotalReceivedEmail + totalReceivedEmail;

            var totalmeeting = Res.data.Data[i].TotalEvents;
            objtotalmeeting = objtotalmeeting + totalmeeting;

            var objWeekDay = Res.data.Data[i].WeekDay;
            lstTotalSent.push(totalcontactcount);
            lstTotalResponse.push(totalReceivedEmail)
            lstTotalMeeting.push(totalmeeting)
            lstWeekDay.push(objWeekDay)
          }
          SetTotalSentEmail(objTotalContact)
          SetTotalReceivedEmail(objtotalReceivedEmail)
          SetTotalMeeting(objtotalmeeting)
          SetTotalMeetingList(lstTotalMeeting)
          SetTotalSent(lstTotalSent)
          SetTotalResponse(lstTotalResponse)
          SetWeekDay(lstWeekDay)

          if (objTotalContact > 0 && objtotalReceivedEmail > 0) {
            SetResponsePer(Math.round(objtotalReceivedEmail * 100 / objTotalContact))
          }

          if (objtotalReceivedEmail > 0 && objtotalmeeting > 0) {
            SetResponsetoMeetingPer(Math.round(objtotalmeeting * 100 / objtotalReceivedEmail));
          }



        } else {
          SetTotalSentEmail(0)
          SetTotalReceivedEmail(0)
          SetTotalMeetingList(0)
          SetTotalMeeting([])
          SetTotalSent([])
          SetTotalResponse([])
          SetWeekDay([])


        }
      }
      else {
        SetTotalSentEmail(0)
        SetTotalReceivedEmail(0)
        SetTotalMeetingList(0)
        SetTotalMeeting([])
        SetTotalSent([])
        SetTotalResponse([])
        SetWeekDay([])
      }
    });
  };

  const AccountState = {
    series: [
      {
        name: "Sent",
        color: "#360947",
        data: TotalSent,
      },
      {
        name: "Responses",
        color: "#F8BF58",
        data: TotalResponse,
      },
      {
        name: "Meetings",
        color: "#20BF55",
        data: TotalMeetingList,
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
            show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: WeekDay,
        title: {
          text: "Weeks",
        },
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "right",
      },
      markers: {
        size: 4,
        strokeWidth: 1.5,
        hover: {
          size: 6 // Size of the marker when hovered
        }
      }
    },
  };

    //Get sort field data
    const SortData = (Field) => {
      SetIsLoading(true);
      var SearchedVal = document.getElementById("search").value;
      var SerchBox;
      if (SearchedVal == "") {
        SerchBox = false;
        SetSflag(false);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var KipsContactData = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Search: Search,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          ToDate: ToChildDatesContact,
          FromDate: FromChildDatesContact,
        };
        const AccountCategoriesList = Axios({
          url: CommonConstants.MOL_APIURL + "/kpis/KpiContactListGet",
          method: "POST",
          data: KipsContactData,
        });
        AccountCategoriesList.then((result) => {
          SetRows(result.data.PageData);
          SetRlen(result.data.TotalCount);
          SetFlen(result.data.TotalCount);
          SetCountPage(result.data.PageCount);
          SetIsLoading(false)
        });
      } else {
        SerchBox = true;
        SetSflag(true);
        var SortField = Field;
        var SortBy;
        if (SortedBy == 1) {
          SortBy = -1;
          SetSortedBy(-1);
        } else {
          SortBy = 1;
          SetSortedBy(1);
        }
        SetSortField(Field);
        var KipsContactData = {
          Page: Page,
          RowsPerPage: RowsPerPage,
          Sort: true,
          Field: SortField,
          SortBy: SortBy,
          Search: Search,
          Type: "User",
          ClientID: ClientID,
          UserID: UserID,
          Role: Role,
          ToDate: ToChildDatesContact,
          FromDate: FromChildDatesContact,
        };
        const AccountCategoriesList = Axios({
          url: CommonConstants.MOL_APIURL + "/kpis/KpiContactListGet",
          method: "POST",
          data: KipsContactData,
        });
        AccountCategoriesList.then((result) => {
          SetRows(result.data.PageData);
          SetRlen(result.data.TotalCount);
          SetFlen(result.data.TotalCount);
          SetCountPage(result.data.PageCount);
          SetIsLoading(false)
        });
      }
    };


  const ExportPopup = () => {
    toast.success("Data exported successfully.");
  };
  return (
    <>
      {IsLoading ? <><div id="hideloding" className="loding-display">
        <img src={loadingicon} />
      </div></> : <></>}
      <div className="row pt-0"></div>
      <div className="row colfive px-0">
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalSentEmail}</span>
                </div>
                <div class="box-inner-title xs-headertitle">
                  <string class="clr-sky">Sent</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsePer}%</span>
                </div>
                <div class="box-inner-title xs-headertitle">
                  <string class="clr-sky">Response %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalReceivedEmail}</span>
                </div>
                <div class="box-inner-title xs-headertitle">
                  <string class="clr-sky">Responses</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{ResponsetoMeetingPer}%</span>
                </div>
                <div class="box-inner-title xs-headertitle">
                  <string class="clr-sky">Response-to-Meeting %</string>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2">
          <div class="bg-white boxcardcounter align-center">
            <div class="d-flex">
              <div className="contectboxes w-100">
                <div class="line-title">
                  <span class="clr-sky box-main-title">{TotalMeeting}</span>
                </div>
                <div class="box-inner-title xs-headertitle">
                  <string class="clr-sky">Meetings</string>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3 px-2">
        <div class="col-xl-12 bg-white p-3">
          <div id="chart chart-max450">
            <ReactApexChart
              options={AccountState.options}
              series={AccountState.series}
              type="line"
              height={450}
            />
          </div>
        </div>
      </div>
      <div class='mx-2'>
      <div className="row mt-4 bg-white">
        <div className="col border-bottom py-4">
          <h4 className="headertitlenop xs-headertitle float-left pl-0">Contact Details</h4>
          {Rows.length === 0 ? (
            <></>
          ) : (
            <div className="listing-li float-right padb-15">
              <ul>
                <li>
                  <a
                    onClick={ContactAddToBlackList}
                    className="btn btngroup m-btn activebloack"
                  >
                    <i class="la la-icon-font-size-13 la-ban"></i>
                    <span>Blacklist All</span>
                  </a>
                </li>
                <li>
                    <a
                      onClick={() => {
                        ContactExportHandler();
                      }}
                      className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                    >
                      <i class="la la-icon-font-size-13 la-download"></i>  Export
                  {/* <CSVDownloader
                    className="px-0"
                    data={ExportData}
                    filename={"KpisContact"}
                    bom={true}
                  >
                  Export
                  </CSVDownloader> */}
                  </a>
                </li>
              </ul>
            </div>
          )}

        </div> 
        <div className="w-100">
          <div className="row">
            <div className="col padd-0"></div>
          </div>
          <div className="row padt-25 mx-0">
            <div className="col-sm-12 col-md-6">
              <label className="textlabelte">
                Show
                <select
                  name="tbl_meeting_length"
                  onChange={ChangeRowSelected}
                  aria-controls="tbl_meeting"
                  class="form-control form-control-sm"
                  value={RowsPerPage}
                >
                  {CommonConstants.show_rows.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
                entries
              </label>
            </div>
            <div className="col-sm-12 col-md-6 full-right">
              <label class="textlabelte">
                Search:
                <input
                  type="Search"
                  id="search"
                  onKeyPress={RequestSearch}
                  class="form-control form-control-sm ml-2"
                  placeholder=""
                  aria-controls="tbl_meeting"
                />
              </label>
            </div>
          </div>
          <div class='px-3'>
          <div className="table-bordered">
            <TableContainer component={Paper}>
            <StyleHeader isDataLoaded={isDataLoaded} />
              <Table class='table-ref w-100' aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <input type="checkbox" onChange={(e) => CheckHandler(e)} />
                    </TableCell>
                    <TableCell onClick={() => { SortData("Email"); setSortedColumn("Email"); }}>
                      Email
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "Email" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "Email" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("ContactName"); setSortedColumn("ContactName"); }}>
                    Contact Name
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "ContactName" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "ContactName" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("AccountName"); setSortedColumn("AccountName"); }}>
                    Account Name
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "AccountName" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "AccountName" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("Domain"); setSortedColumn("Domain"); }}>
                    Domain
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "Domain" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "Domain" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("DateContacted"); setSortedColumn("DateContacted"); }}>
                    Date Contacted
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "DateContacted" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "DateContacted" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("DateResponded"); setSortedColumn("DateResponded"); }}>
                    Date Responded
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "DateResponded" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "DateResponded" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("SentDomain"); setSortedColumn("SentDomain"); }}>
                    Sent
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "SentDomain" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "SentDomain" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("TotalResponses"); setSortedColumn("TotalResponses"); }}>
                    Responses
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "TotalResponses" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "TotalResponses" ? "active" : null} />
                      </span>
                    </TableCell>
                    <TableCell onClick={() => { SortData("TotalEvents"); setSortedColumn("TotalEvents"); }}>
                    Meetings
                      <span className="shorting">
                        <ArrowUpward className={SortedBy === 1 && sortedColumn === "TotalEvents" ? "active" : null} />
                        <ArrowDownward className={SortedBy === -1 && sortedColumn === "TotalEvents" ? "active" : null} />
                      </span>
                    </TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Rows.length == 0 ? (
                    <p className="text-center">No data available in table</p>
                  ) : (
                    Rows.map((row) => (
                      <TableRow>
                        <TableCell><input type="checkbox" id={row?.ProspectID} onChange={(e) => CheckBoxClick(e, row)} /></TableCell>
                        <TableCell>{row?.Email}</TableCell>
                        <TableCell>{row?.ContactName}</TableCell>
                        <TableCell>{row?.AccountName}</TableCell>
                        <TableCell>{row?.Domain}</TableCell>
                        <TableCell>
                          {" "}
                          {moment(new Date(row?.DateContacted).toDateString()).format("MM/DD/YYYY") != "Invalid date" ? moment(new Date(row.DateContacted).toDateString()).format("MM/DD/YYYY") : ""}

                        </TableCell>
                        <TableCell>
                          {" "}
                          {moment(new Date(row?.DateResponded).toDateString()).format("MM/DD/YYYY") != "Invalid date" ? moment(new Date(row.DateResponded).toDateString()).format("MM/DD/YYYY") : ""}
                        </TableCell>
                        <TableCell>{row?.SentDomain}</TableCell>
                        <TableCell>{row?.TotalResponses}</TableCell>
                        <TableCell>{row?.TotalEvents}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          </div>
            <div class="row mx-0 pb-2">
              <div class="col dataTables_info">
                <p>
                  Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
                  {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
                  entries
                </p>
              </div>
              <div class="col pageright">
                <Pagination
                  component="div"
                  count={CountPage}
                  onChange={HandleChangePage}
                  page={Page}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
        </div>
      </div>
      </div>
    </>
  );
}