import React  from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { toast } from "react-toastify";
import { CSVReader, CSVDownloader } from "react-papaparse";
import Popup from "reactjs-popup";
import "react-toastify/dist/ReactToastify.css";

import { history } from "../../_helpers";
const Fileupload = require("../../_helpers/fileupload");
import { userActions } from "../../_actions";
import Lists from "../../_components/user/Lists/Lists";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { GetClientDetails } from "../../_helpers/Utility";
import Sidebar from "../../_components/user/NavigationSidebar/Sidebar";
import Footer from "../../_components/user/footer/footer";
import loadingicon from "../../images/loading.gif";

toast.configure();

class Listspage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Pager: {},
      PageOfItems: [],
      Files: [],
      List: [],
      Columns: [],
      Reset: false,
      CsvData: [],
      DropBoxData: [],
      FileName: "",
      ExportData: [],
      TotalResult: 0,
      TotalDuplicate: 0,
      TotalError: 0,
      TotalSuccess: 0,
      ShowPopupForExport: false,
      Useremail: "",
      UserID: 0,
      CUserID: 0,
      ClientID: 0,
      Role: "",
      Errors: {},
      CName: "",
      TotalDisplayCount: false,
      ImportBox: false,
      MapBox: false,
      DisabledBtnSelect: false,
      IsLoading: false,
      UpdateKey: 0,
      DefaultIndexMap:null,
      downloadReady: false,
    };
    this.AddListField = this.AddListField.bind(this);
    this.HandleOnDrop = this.HandleOnDrop.bind(this);
    this.HandleOnError = this.HandleOnError.bind(this);
    this.HandleOnRemoveFile = this.HandleOnRemoveFile.bind(this);
    this.ImportCsv = this.ImportCsv.bind(this);
    this.MapCsv = this.MapCsv.bind(this);
    this.CancleCsv = this.CancleCsv.bind(this);
    this.SaveCsv = this.SaveCsv.bind(this);
    this.ExportBtn = this.ExportBtn.bind(this);
    this.HandleExportListcsv = this.HandleExportListcsv.bind(this);
  }

  componentDidMount() {
    document.title = "Lists | SalesHive";

    // document.getElementById("hidelod").style.display = "none";
    // document.getElementById("import_csv").style.display = "none";
    // document.getElementById("map_attribute").style.display = "none";
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ParentUserID;
      this.state.CUserID = Details.ChildUserID;
      this.state.Useremail = Details.Username;
      this.state.Role = Details.Role;
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ CName: result[0].Name });
    });

   
  }

  //reset csv
  ResetCsv() {
    this.setState({ Reset: !this.state.Reset });
  }

  //change dropdown
  HandleOnDrop(Data, FileInfo) {
    var FileName = FileInfo.name;
    this.setState({ Files: FileInfo });
    var sep = FileName.split(".");
    if (sep[sep.length-1]!= "csv") {
      this.setState({ Reset: true });
      this.ResetCsv();
    } else {
      var csvfile = sep.slice(0, -1) + new Date().getTime() + "." + sep[sep.length-1];
      const filteredData = Data.filter(
        (row) => !row.data.every((item) => item.trim() === "")
      );
      this.setState({ FileName: csvfile });
      this.setState({ CsvData: filteredData });
      this.setState({ DropBoxData: filteredData[0].data });
    }
  }

  //Handle error
  HandleOnError(Err, File, InputElem, Reason) {}

  //Handle remove file
  HandleOnRemoveFile(Data) {}

  //import csv
  ImportCsv() {
    this.setState({
      ImportBox: true,
      MapBox: false,
      TotalDisplayCount: false,
      DisabledBtnSelect: false,
    });
    // document.getElementById("import_csv").style.display = "block";
  }

  //map csv
  MapCsv() {
    // document.getElementById("hidelod").style.display = "block";
    if (this.state.CsvData.length != 0) {
      this.setState({
        ImportBox: true,
        MapBox: true,
        TotalDisplayCount: false,
        DisabledBtnSelect: false,
      });

      // document.getElementById("map_attribute").style.display = "block";
      this.state.DropBoxData.map((dddd, index) => {
        if(dddd.toLowerCase() ==="name"){
          this.setState({DefaultIndexMap:index})
        }
      });
      this.ResetCsv();
      // document.getElementById("hidelod").style.display = "none";
    } else {
      toast.error(
        <div className="toastsize">
          SalesHive List <br />
          Please select file
        </div>
      );
      if (this.state.TotalDisplayCount === false) {
        this.setState({
          ImportBox: true,
          MapBox: false,
          TotalDisplayCount: false,
          DisabledBtnSelect: false,
        });
      }
      this.ResetCsv();
      // document.getElementById("hidelod").style.display = "none";
    }
  }
 async HandleExportListcsv(){
    try {
      this.setState({ IsLoading: true });
  
      var InputParameter = {
        ClientID: this.state.ClientID,
        UserID: this.state.UserID,
        Role: this.state.Role,
      };
      await Axios({
        url: CommonConstants.MOL_APIURL + "/list/ExportCSVList",
        method: "POST",
        data: InputParameter,
      }).then((res) => {
        this.setState({ ExportData: res.data });
        this.setState({ IsLoading: false });
       
      });
    } catch (error) {
      toast.error(<div>Something Went Wrong.</div>);
  
    }
  }
  //Export Button
 async ExportBtn() {
  
    await this.HandleExportListcsv()
    this.downloadCSV();
    toast.success(<div>Data exported successfully.</div>);
  }

  downloadCSV() {
    const { ExportData, CName } = this.state;

    const headers = Object.keys(ExportData[0]).join(',');
    const rows = ExportData.map(row => Object.values(row).join(',')).join('\n');
    const csvContent = `${headers}\n${rows}`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${CName}-List.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  //cancle Csv
  CancleCsv() {
    this.setState({
      ImportBox: false,
      MapBox: false,
      TotalDisplayCount: false,
      DisabledBtnSelect: false,
    });
    this.ResetCsv();
  }

  //add List
  SaveCsv() {
    var ListImport = document.getElementById("ListImport").value;
    if (ListImport === "not mapped") {
      toast.error(
        <spam>
          SalesHive List <br />
          Name is required!
        </spam>
      );
    } else {
      this.setState({ IsLoading: true });
      Fileupload.getBase64(this.state.Files, (Result) => {
        var RreglarString = Result;
        var Splirs = RreglarString.split("base64,");
        var Base64 = Splirs[1];
        var InputParameter = {
          Fields: "Coverimg",
          Path: "./Content/UploadedFiles/UserList",
          File: Base64,
          ImageName: this.state.FileName,
          FileData: this.state.CsvData,
          Name: ListImport,
          ClientID: this.state.ClientID,
          UserID: this.state.UserID,
          Role: this.state.Role,
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/list/ImportCSVListData",
          method: "POST",
          data: InputParameter,
        }).then((Res) => {
          if (Res.data.StatusMessage == "SUCCESS") {
            toast.success(
              <div className="toastsize">
                SalesHive List
                <br />
                SalesHive List added successfully.
              </div>
            );
            history.push("/list");
            this.setState({ CsvData: [] });
            this.setState({ UpdateKey: this.state.UpdateKey + 1 });
            this.setState({
              TotalDisplayCount: true,
              ImportBox: true,
              MapBox: true,
              DisabledBtnSelect: true,
            });
            this.setState({ TotalResult: Res.data.Total });
            this.setState({ TotalDuplicate: Res.data.TotalDuplication });
            this.setState({ TotalError: Res.data.TotalError });
            this.setState({ TotalSuccess: Res.data.TotalSuccess });

            this.setState({ IsLoading: false });
          } else {
            toast.error(Res.data.Message);
            this.setState({ IsLoading: false });
          }
        });
      });
    }
  }

  //add function
  AddListField() {
    history.push({ pathname: "/addlist", state: { Pagename: "AddList" } });
  }

  // formvalidation
  FromValidation = async () => {
    let FormIsValid = true;
    let Errors = {};
    var UserEmail = document.getElementById("Username").value.trim();
    let IsExist = await this.EmailValidationCheck(UserEmail);
    if (UserEmail == "") {
      FormIsValid = false;
      Errors["Username"] = "Please enter email.";
    }

    if (IsExist == true) {
      FormIsValid = false;
      Errors["Username"] = "invalid email.";
    }

    this.setState({ Errors: Errors });

    return FormIsValid;
  };

  // find exits Email
  EmailValidationCheck = async (UserEmail) => {
    let regexp =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let Errors = {};
    if (!regexp.test(UserEmail) && UserEmail != "") {
      Errors["Username"] = "invalid email.";
      this.setState({ Errors: Errors });
      return true;
    } else {
      return false;
    }
  };

  // handle change
  HandleEmailCheck = () => {
    var UserEmail = document.getElementById("Username").value.trim();
    this.setState({ Errors: "" });
    if (UserEmail != "") {
      var UserEmail = document.getElementById("Username").value.trim();

      this.EmailValidationCheck(UserEmail);
    } else {
      Errors.Username = "";
    }
  };

  ContactExportCsvBulk = async (e) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    var Final_flag = await this.FromValidation();
    var UserEmail = document.getElementById("Username").value.trim();
    if (Final_flag == true) {
      var InputParaMeter = {
        ClientIDs: "",
        AccountTypeID: null,
        AccountType: null,
        UserID: this.state.UserID,
        CreatedDate: new Date(),
        CreatedBy: this.state.CUserID,
        LastUpdatedBy: null,
        LastUpdatedDate: null,
        Email: UserEmail,
        InProcess: false,
        IsComplete: false,
        InProcessDate: null,
        IsCompleteDate: null,
        Page: "List",
        ClientID: this.state.ClientID,
        CampaignID: null,
        DynamicQuery: null,
        Role: this.state.Role,
      };
      Axios({
        url: CommonConstants.MOL_APIURL + "/list/exportcontactlist",
        method: "POST",
        data: InputParaMeter,
      }).then((res) => {
        if (res.data.StatusMessage == "SUCCESS") {
          toast.success(
            <div className="toastsize">
              Contact
              <br />
              You will get file soon on Submitted Email.
            </div>
          );
          this.setState({ ShowPopupForExport: false });
          // history.push("/list");
        } else {
          toast.error(res.data.Message);
        }
      });
    } else {
      document.getElementById("submit").disabled = false;
    }
  };

  render() {
    return (
      <>
        {this.state.IsLoading ? (
          <div id="hidelod" className="loding-display">
            <img src={loadingicon} />
          </div>
        ) : (
          <></>
        )}
        <div className="adminmain mheight">
          <Sidebar className="" />
          <div className="bodyhome px-3">
            <div className="row">
              <div className="col">
                <h4 className="headertitlebd lg-headertitle float-left pl-0">Lists</h4>
              </div>
              <div className="col">
                <div className="listing-li float-right">
                  <ul>
                    <li className="px-0">
                      <a
                        onClick={this.ImportCsv}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-upload"></i>
                        <span>Import</span>
                      </a>
                    </li>
                    <li className="px-1">
                      <a
                        onClick={this.AddListField}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </a>
                    </li>
                    <li className="px-0">
                      {/* <CSVDownloader
                        className="px-0"
                        data={this.state.ExportData}
                        filename={this.state.CName + "-List"}
                        bom={true}
                      > */}
                        <a
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                          onClick={this.ExportBtn}
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      {/* </CSVDownloader> */}
                    </li>
                    <li className="px-1">
                      <a
                        onClick={() => {
                          this.setState({ ShowPopupForExport: true });
                        }}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-download"></i>
                        <span>Export Contact</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Popup open={this.state.ShowPopupForExport}>
              <div>
                <div className="modal-black"></div>
                <div className="filterPopup largerPopup">
                  <div className="paddingboxTerms">
                    <div className="modal-header py-4 px-3">
                      <div className="w-100 d-flex px-3">
                        <h5 className="mb-0">Email</h5>
                        <button
                          className="close"
                          onClick={() => {
                            this.setState({ ShowPopupForExport: false });
                          }}
                        >
                          <span aria-hidden="true" class="la la-remove"></span>
                        </button>
                      </div>
                    </div>
                    <div className="pt-3">
                      <div className="modal-body px-4">
                        <div className="col-sm-9">
                          <div class="row pb-2">
                            <label class="col-lg-4 col-form-label text-left">
                              Email
                            </label>
                            <div class="col-lg-8">
                              <input
                                class="form-control m-input"
                                id="Username"
                                data-val="true"
                                data-val-remote="Title already exist."
                                data-val-remote-url="/Utility/IsPotentialMeetingNoteTitleExist"
                                data-val-required="Please enter Email "
                                name="Username"
                                onBlur={this.HandleEmailCheck}
                                defaultValue={this.state.Useremail}
                                type="text"
                              />
                              <span style={{ color: "red" }}>
                                {this.state.Errors["Username"]}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-top modal-content text-right px-3">
                        <div class="row pb-2">
                          <div class="col-lg-12 pull-right">
                            <a
                              id="backtolist"
                              class="btn btn-secondary mr-2"
                              onClick={() => {
                                this.setState({ ShowPopupForExport: false });
                              }}
                            >
                              Cancel
                            </a>
                            <button
                              id="submit"
                              onClick={this.ContactExportCsvBulk}
                              class="btn btn-primary btn-lightgreen mr-1"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            <div class="">
              {/* import csv */}
              {this.state.ImportBox ? (
                <div className="bg-white p-3 py-4" id="import_csv">
                  <div className="row">
                    <div className="col-xl-8 offset-xl-2">
                      <h3 className="uploadheadingtitle">
                        Import Contact Details
                      </h3>
                      <div className="uplodfilesbox">
                        <CSVReader
                          accept={[
                            "text/csv",
                            ".csv",
                            "application/vnd.ms-excel",
                          ]}
                          onDrop={this.HandleOnDrop}
                          onError={this.HandleOnError}
                          addRemoveButton
                          removeButtonColor="#659cef"
                          onRemoveFile={this.HandleOnRemoveFile}
                          isReset={this.state.Reset}
                        >
                          <span>Select .CSV file</span>
                          <p>Add List data from csv file to bulk upload.</p>
                        </CSVReader>
                      </div>
                    </div>
                    <div className="col-xl-8 offset-xl-2">
                      <div className="alignright">
                        <a
                          onClick={this.MapCsv}
                          className="btn btn-primary btnColor"
                        >
                          <i className="la la-upload"></i> Upload
                        </a>
                        <a
                          onClick={this.CancleCsv}
                          className="btn btn-secondary"
                        >
                          <i className="la flaticon-cancel"></i> Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {this.state.MapBox ? (
                <div
                  className="portletbody border-right border-left p-0 mt-0  map_attribute"
                  id="map_attribute"
                >
                  <div className="row col-xs-12 mx-0 border-bottom px-3">
                    <h4 className="headertitlebd lg-headertitle float-left pl-0">
                      Map attributes
                    </h4>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group m-form__group row mx-0 mb-0 px-3 py-4">
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            className="dbcontent form-control  m-input"
                            disabled="disabled"
                          >
                            <option selected="" value="ListImports">
                              Name
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 text-center">
                        <span className="fa fa-arrows-h fa-2x "></span>
                      </div>
                      <div className="col-lg-2 nopadding">
                        <div>
                          <select
                            id="ListImport"
                            className="csvcontent form-control  m-input"
                            autocomplete="off"
                            disabled={this.state.DisabledBtnSelect}
                            defaultValue={this.state.DefaultIndexMap}
                          >
                            <option value="not mapped">Not Mapped</option>
                            {this.state.DropBoxData.map((dbdata, index) => (
                              <option value={index}>{dbdata}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 border-top py-3 px-3">
                      <button
                        disabled={this.state.DisabledBtnSelect}
                        onClick={this.SaveCsv}
                        className="btn btn-primary btn-lightgreen"
                      >
                        <i className="la la-save"></i>Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {this.state.TotalDisplayCount == true ? (
                <div className="portletbody p-0 my-0 border border-bottom-0 p-3 pt-4 pb-3" id=" ">
                  <div className="row col-xs-12 mx-0">
                    <h4 className="headertitle xs-headertitle float-left mb-0 p-0 pb-4">Results</h4>
                  </div>

                  <div className="col-xs-12 mx-0">
                    <div class="row colfive mx-0">
                      <div class="col-md-3 mb-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 text-center d-block">
                                <span class="box-main-title font-primary">
                                  {this.state.TotalResult}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 mb-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 text-center d-block">
                                <span class="box-main-title font-success">
                                  {this.state.TotalSuccess}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total success</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 mb-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 text-center d-block">
                                <span class="box-main-title font-danger">
                                  {this.state.TotalError}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total error</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 mb-3">
                        <div class="boxcardcounter">
                          <div class="d-flex align-center">
                            <div class="contectboxes w-100">
                              <div class="line-title mb-2 text-center d-block">
                                <span class="box-main-title font-warning">
                                  {this.state.TotalDuplicate}
                                </span>
                              </div>
                              <div class="box-inner-title">
                                <string class="clr-sky">Total Duplicate</string>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* End Import csv */} 
              <div className="row">
                <div className="col">
                  <Lists key={this.state.UpdateKey} />
                </div>
              </div> 
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedListspage = connect(mapState, actionCreators)(Listspage);
export { connectedListspage as Listspage };
