import React from "react";
import Axios from "axios";

import Sidebar from "../../_components/clients/nav-sidebar/Sidebar";
import ContactCustomfield from "../../_components/clients/contactsettings/customfield/customfield";
import Footer from "../../_components/user/footer/footer";

import { connect } from "react-redux";
import { CSVDownloader } from "react-papaparse";
import { userActions } from "../../_actions";
import { CommonConstants } from "../../_constants/common.constants";
import { GetUserDetails } from "../../_helpers/Utility";
import { history } from "../../_helpers";
import { GetClientDetails } from "../../_helpers/Utility";

class CContactsCustomFieldpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientName: "",
      FileName: "",
      ExportData: [],
      Role: "",
    };
    this.AddBtn = this.AddBtn.bind(this);
    this.ExportCsv = this.ExportCsv.bind(this);
  }

  componentDidMount() {
    var Details = GetUserDetails();
    if (Details != null) {
      this.state.ClientID = Details.ClientID;
      this.state.UserID = Details.ChildUserID;
      this.state.Role = Details.Role;
    }
    GetClientDetails(Details.ClientID).then((result) => {
      this.setState({ ClientName: result[0].Name});

    });
    var ContactCustomFieldData = {
      ClientID: this.state.ClientID,
      Role: this.state.Role,
    };
    Axios({
      url:
        CommonConstants.MOL_APIURL + "/contactcustom/ContactCustomFieldExport",
      method: "POST",
      data: ContactCustomFieldData,
    }).then((res) => {
      this.setState({ ExportData: res.data.Data });
      // this.setState({ ClientName: res.data.ClientName.Name });
    });
  }

  //  add button
  AddBtn() {
    history.push("/CAddContactsCustomField");
  }

  //Reload page while delete perform
  UpdateFromChild = (value) => {
    if (value == true) {
      this.componentDidMount();
    }
  };

  // contact custom field export csv
  ExportCsv() {}

  render() {
    return (
      <>
        <div className="adminmain mheight"> 
            <Sidebar className="" /> 
          <div className="bodyhome">
            <div className="row">
              <div className="col">
                <h4 className="headertitle lg-headertitle pl-0 mb-0">
                  Custom Contact Fields
                </h4>
              </div>
              <div className="col">
                <div className="listing-li float-right padb-15">
                  <ul>
                    <li>
                      <CSVDownloader className="px-0"
                        data={this.state.ExportData}
                        filename={`${this.state.ClientName}-CustomContactField`}
                        bom={true}
                      >
                        <a
                          onClick={this.ExportCsv}
                          className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                        >
                          <i class="la la-icon-font-size-13 la-download"></i>
                          <span>Export</span>
                        </a>
                      </CSVDownloader>
                    </li>
                    <li>
                      <button
                        onClick={this.AddBtn}
                        className="btn btngroup m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
                      >
                        <i class="la la-icon-font-size-13 la-plus"></i>
                        <span>Add</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="bg-white">
              <div className="row mx-0 pb-3">
                <div className="col">
                  <ContactCustomfield updateFromChild={this.UpdateFromChild} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedCContactsCustomFieldpage = connect(
  mapState,
  actionCreators
)(CContactsCustomFieldpage);
export { connectedCContactsCustomFieldpage as CContactsCustomFieldpage };
