import React, { useEffect } from "react";
import Axios from "axios";
const moment = require("moment");
import Swal from "sweetalert2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { CommonConstants } from "../../../_constants/common.constants";
import { GetUserDetails } from "../../../_helpers/Utility";
import { history } from "../../../_helpers";
import StyleHeader from "../../user/StickyHeader/StickyHeader";
import loadingicon from "../../../images/loading.gif";


export default function AccountAllTable({
  listupdate,
  updateFromChild,
}) {
  const [Flen, SetFlen] = React.useState(0);
  const [Sflag, SetSflag] = React.useState(false);
  const [Rlen, SetRlen] = React.useState(0);
  const [CountPage, SetCountPage] = React.useState(0);
  const [Rows, SetRows] = React.useState([]);
  const [Page, SetPage] = React.useState(1);
  const [RowsPerPage, SetRowsPerPage] = React.useState(100);
  const [Search, SetSearch] = React.useState("");
  const [SortField, SetSortField] = React.useState("Weeks");
  const [SortedBy, SetSortedBy] = React.useState(-1);
  const [ClientID, SetClientID] = React.useState(0);
  const [UserID, SetUserID] = React.useState(0);
  const [Role, SetRole] = React.useState("");
  const [CUserID,SetCUserID] = React.useState(0)
  const [IsLoading,SetIsLoading] = React.useState(false)
  const [sortedColumn, setSortedColumn] = React.useState("Weeks");
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  useEffect(() => {
    document.title = `Meeting Goal | SalesHive`;
    let Details = GetUserDetails();
    if (Details != null) {
      SetClientID(Details.ClientID);
      SetUserID(Details.ParentUserID);
      SetCUserID(Details.ChildUserID);
      SetRole(Details.Role);
    }

    MeetingGoalGet(Details.ClientID, Details.ParentUserID, Details.Role);
  }, [Search, Page, RowsPerPage,listupdate]);

  const imgstyle = {
    height: "40px",
    width: "40px",
    borderradius: "50%",
  };

  // meeting goal get list
  const MeetingGoalGet = (CID, UID, URole) => {
    SetIsLoading(true)
    let MeetingGoalData = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Sort: true,
      Field: SortField,
      SortBy: SortedBy,
      Search: Search,
      Type: "User",
      ClientID: CID,
      UserID: UID,
      Role: URole,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalGet",
      method: "POST",
      data: MeetingGoalData,
    });
    ApiResponse.then((Result) => {
      SetRows(Result?.data?.PageData);
      SetRlen(Result?.data?.TotalCount);
      SetFlen(Result?.data?.TotalCount);
      SetCountPage(Result?.data?.PageCount);
      SetIsLoading(false)
      setIsDataLoaded(true);
    });
  };

  //search for record
  const RequestSearch = (Event) => {
    if (Event.key === "Enter") {
      let SearchedVal = document.getElementById("search").value;
      if (SearchedVal == Search) {
        SetSearch(SearchedVal);
        SetPage(1);
      } else {
        SetSearch(SearchedVal);
        SetPage(1);
       
      }
    }
  };

  //change Page
  const HandleChangePage = (Event, NewPage) => {
    if (NewPage == Page) {
      SetPage(NewPage);
    } else {
      SetPage(NewPage);
      
    }
  };

  //change display rows
  const ChangeRowSelected = (Event) => {
    SetRowsPerPage(Number(Event.target.value));
    SetPage(1);
  };

  // meeting goal delete
  const DeleteBtn = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete a meeting goal.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34bfa3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          _id: id,
          LastUpdatedBy: CUserID,
          LastUpdatedDate: new Date(),
          Role:Role
        };
        Axios({
          url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalDelete",
          method: "POST",
          data: data,
        }).then((res) => {
          if (res) {
            if ((res.statusText = "Ok")) {
              Swal.fire(
                "Deleted!",
                "Meeting Goal field deleted successfully.",
                "success"
              );         
              MeetingGoalGet(ClientID, UserID, Role);
              SetPage(1);
              SetSearch("");
              document.getElementById("search").value = "";
              updateFromChild(true);
            } else {
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  // Edit btn
  const EditBtn = (id) => {
    history.push("/CEditMeetinggoal", id);
  };

  //get sort field data
  const SortData = (Field) => {
    setSortedColumn(Field);
    SetIsLoading(true);
    const SearchedVal = document.getElementById("search").value?.trim();
    const isNewSortField = Field !== SortField;
    let SortBy = SortedBy === 1 ? -1 : 1;
  
    if (isNewSortField) {
      SetSortedBy(1);
      SortBy = 1;
    } else {
      SetSortedBy(SortBy);
    }
  
    SetSortField(Field);
    SetSflag(SearchedVal !== "");
    let InputParameter = {
      Page: Page,
      RowsPerPage: RowsPerPage,
      Search: SearchedVal,
      Sort: true,
      Field: Field,
      SortBy: SortBy,
      Type: "User",
      ClientID: ClientID,
      UserID: UserID,
      Role: Role,
    };
    const ApiResponse = Axios({
      url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalGet",
      method: "POST",
      data: InputParameter,
    });
    ApiResponse.then((Result) => {
      SetRows(Result.data.PageData);
      SetRlen(Result.data.TotalCount);
      SetFlen(Result.data.TotalCount);
      SetCountPage(Result.data.PageCount);
      SetPage(1);
      SetIsLoading(false);
    });

    // let SearchedVal = document.getElementById("search").value;
    // SetIsLoading(true)
    // // SetPage(1);
    // // SetRowsPerPage(10);
    // let SerchBox;
    // if (SearchedVal == "") {
    //   SerchBox = false;
    //   SetSflag(false);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     SerchBox: false,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //     Search:SearchedVal
    //   };
    //   const ApiResponse = Axios({
    //     url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false)
    //   });
    // } else {
    //   SerchBox = true;
    //   SetSflag(true);
    //   let SortField = Field;
    //   let SortBy;
    //   if (SortedBy == 1) {
    //     SortBy = -1;
    //     SetSortedBy(-1);
    //   } else {
    //     SortBy = 1;
    //     SetSortedBy(1);
    //   }
    //   SetSortField(Field);
    //   let InputParameter = {
    //     Page: Page,
    //     RowsPerPage: RowsPerPage,
    //     Search: SearchedVal,
    //     SerchBox: SerchBox,
    //     Archive: false,
    //     Sort: true,
    //     Field: SortField,
    //     SortBy: SortBy,
    //     Type: "User",
    //     ClientID: ClientID,
    //     UserID: UserID,
    //     Role: Role,
    //   };
    //   const ApiResponse = Axios({
    //     url: CommonConstants.MOL_APIURL + "/meetinggoal/MeetingGoalGet",
    //     method: "POST",
    //     data: InputParameter,
    //   });
    //   ApiResponse.then((Result) => {
    //     SetRows(Result.data.PageData);
    //     SetRlen(Result.data.TotalCount);
    //     SetFlen(Result.data.TotalCount);
    //     SetCountPage(Result.data.PageCount);
    //     SetIsLoading(false)
    //   });
    // }
  };

  return (
    <div>
 {
      IsLoading ? <div id="hideloding" className="loding-display">
          <img src={loadingicon} />
        </div> : <></>
    }
      <div className="row">
        <div className="col padd-0"></div>
      </div>
      <div className="row padt-25">
        <div className="col-sm-12 col-md-6">
          <label className="textlabelte">
            Show
            <select
              name="tbl_meeting_length"
              onChange={ChangeRowSelected}
              aria-controls="tbl_meeting"
              className="form-control form-control-sm"
              value={RowsPerPage}
            >
              {CommonConstants.show_rows.map((value) => (
                <option value={value} key={value}>{value}</option>
              ))}
            </select>
            entries
          </label>
        </div>
        <div className="col-sm-12 col-md-6 full-right">
          <label className="textlabelte">
            Search:
            <input
              type="Search"
              id="search"
              onKeyPress={RequestSearch}
              className="form-control form-control-sm ml-2"
              placeholder=""
              aria-controls="tbl_meeting"
            />
          </label>
        </div>
      </div>
      <div className="table-bordered">
        <TableContainer component={Paper}>
        <StyleHeader isDataLoaded={isDataLoaded} />
          <Table className='table-ref' stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
              <TableCell  onClick={() => {
                      SortData("Weeks"); setSortedColumn("Weeks");
                    }}>
                    Weeks
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Weeks" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Weeks" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell  onClick={() => {
                      SortData("Goal"); setSortedColumn("Goal");
                    }}>
                    Goal
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Goal" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Goal" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell  onClick={() => {
                      SortData("Meeting"); setSortedColumn("Meeting");
                    }}>
                    Meeting
                    <span className="shorting">
                  <ArrowUpward className={SortedBy === 1 && sortedColumn === "Meeting" ? "active" : null} />
                  <ArrowDownward className={SortedBy === -1 && sortedColumn === "Meeting" ? "active" : null} />
                </span>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows?.length === 0 ? (
                <p className="text-center">No data available in table</p>
              ) : (
                Rows?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>
                      {moment.utc(new Date(row?.Weeks).toDateString()).format(
                        "MM/DD/YYYY"
                      )}
                      
                    </TableCell>
                    <TableCell>{row?.Goal}</TableCell>
                    <TableCell>{row?.Meeting}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          EditBtn(row._id);
                        }}
                        className="btn-eyesicon"
                        title="Edit"
                      >
                        <i className="la flaticon-edit-1 edit-icon"></i>
                      </a>
                      {
                        new Date(row?.Weeks)>new Date()?(<a
                          onClick={() => {
                            DeleteBtn(row._id);
                          }}
                          className="btn-eyesicon"
                        >
                          <i className="la flaticon-delete-1 delete-icon"></i>
                        </a>):''
                      }
                      
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

            <div className="row"></div>
          </Table>
        </TableContainer>
      </div>
     
        <div className="row">
          <div className="col dataTables_info">
            <p>
              Showing {Rlen == 0 ? 0 : (Page - 1) * RowsPerPage + 1} to{" "}
              {Page * RowsPerPage > Rlen ? Rlen : Page * RowsPerPage} of {Rlen}{" "}
              entries
            </p>
          </div>
          <div className="col pageright">
            <Pagination
              component="div"
              count={CountPage}
              onChange={HandleChangePage}
              page={Page}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
    </div>
  );
}
